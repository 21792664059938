import React, { useState, useEffect } from "react";
import services from "./apiServices";
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Button,
    Grid,
    Typography,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    IconButton,
    TextField
} from "@mui/material";

function Adm_Affilate_Lve_Type() {

    const [leave_type, setLeave_type] = useState({ aflt_lve_type_result: [] })
    useEffect(() => {
        const fecth_lev_type = async () => {
            try {
                const lve_typ = await services.get_lev_types();

                if (lve_typ) {
                    setLeave_type(lve_typ); // Wrap it in an object if necessary
                } else {
                    console.error("Unexpected data format:", lve_typ);
                }
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };
        fecth_lev_type();

    }, [])

    console.log("Leave types are..", leave_type)
    return (
        <div className="wrapper_form">
            <Grid item xs={12} style={{ paddingLeft: ".7%", paddingRight: ".9%" }}>
                <div className="title" style={{ marginTop: ".5%" }}>Our Leave Types</div>
                <TableContainer
                    component={Paper}
                    style={{
                        margin: "5px auto 25px auto",
                        // maxWidth: "50%",
                        overflow: "hidden", // Prevents content overflow
                        boxShadow: "0px 6px 16px 3px #bababa", // Shadow only on the top and bottom
                        borderRadius: "20px", // Rounded corners for the container
                        border: "2px solid gray",

                    }}
                >
                    <Table style={{ width: "100%", }}>
                        <TableHead>
                            <TableRow style={{ width: "100%", }}>
                                <TableCell
                                    style={{
                                        fontSize: "0.8rem",
                                        padding: "1px 18px",
                                        backgroundColor: "#4a6d8c",
                                        border: "1px solid gray", color: "white", textAlign: "center"
                                    }}
                                    className=" txt_ttl1"
                                >
                                    Sr.No.
                                </TableCell>
                                <TableCell
                                    style={{
                                        fontSize: "0.8rem",
                                        padding: "1px 18px",
                                        backgroundColor: "#4a6d8c",
                                        border: "1px solid gray", color: "white", textAlign: "center"
                                    }}
                                    className=" txt_ttl1"
                                >
                                    Leave Code
                                </TableCell>
                                <TableCell
                                    style={{
                                        fontSize: "0.8rem",
                                        padding: "1px 18px",
                                        backgroundColor: "#4a6d8c",
                                        border: "1px solid gray", color: "white", textAlign: "center"
                                    }}
                                    className=" txt_ttl1"
                                >
                                    Display Name
                                </TableCell>

                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {leave_type.aflt_lve_type_result.map((leavetype, index) => (
                                <TableRow style={{ height: "15px" }} key={leavetype.leaveTypeId}>
                                    <TableCell align="center" style={{ fontSize: "0.8rem", padding: "1px 18px", border: "1px solid gray" }}>
                                        {index + 1}
                                    </TableCell>
                                    <TableCell align="center" style={{ fontSize: "0.8rem", padding: "1px 18px", border: "1px solid gray" }}>
                                        {leavetype.lve_typ_cd}
                                    </TableCell>
                                    <TableCell align="center" style={{ fontSize: "0.8rem", padding: "1px 18px", border: "1px solid gray" }}>
                                        {leavetype.disp_nm}
                                    </TableCell>

                                </TableRow>
                            ))}
                            {leave_type.aflt_lve_type_result.length < 10 &&
                                Array.from({ length: 10 - leave_type.aflt_lve_type_result.length }).map((_, index) => (
                                    <TableRow style={{ height: "5px" }} key={`empty-row-${index}`}>
                                        <TableCell align="center" style={{ fontSize: "0.8rem", padding: "1px 18px", border: "1px solid gray", lineHeight: ".2" }}>
                                            &nbsp; {/* Empty cell for spacing */}
                                        </TableCell>
                                        <TableCell align="center" style={{ fontSize: "0.8rem", padding: "1px 18px", border: "1px solid gray" }}>
                                            &nbsp; {/* Empty cell for spacing */}
                                        </TableCell>
                                        <TableCell align="center" style={{ fontSize: "0.8rem", padding: "1px 18px", border: "1px solid gray" }}>
                                            &nbsp; {/* Empty cell for spacing */}
                                        </TableCell>

                                    </TableRow>
                                ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>
        </div>
    )
}

export default Adm_Affilate_Lve_Type