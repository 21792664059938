import React, { useState, useEffect } from "react";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import services from "./apiServices";
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Button,
    Grid,
    Typography,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    IconButton,
    TextField
} from "@mui/material";
import EditIcon from '@mui/icons-material/Edit';
import moment from 'moment';
function Adm_Historical_Annocement_managment() {
    const [selectedOption, setSelectedOption] = useState('');
    const [issuedByOptions, setIssuedByOptions] = useState({ all_aflt_mangr_lst_result: [] });
    const [formData, setFormData] = useState({
        announcementDate: '',
        announcementDateFrom: '',
        announcementDateTo: '',
        announcementHeading: '',
        issuedBy: '',
        displayFor: '',
        severity: '',
        displayOnScreen: 'Y',
        announcementDescription: '',
        editannouncementDate: '',
        editannouncementDateFrom: '',
        editannouncementDateTo: '',
        editannouncementHeading: '',
        editissuedBy: '',
        editdisplayFor: '',
        editseverity: '',
        editdisplayOnScreen: 'Y',
        editannouncementDescription: '',
        editannocement_key: ""
    });

    const [ancement, setAncement] = useState({ histrical_annocement_result: [] })

    const [annocement, setEditAnnocement] = useState();

    useEffect(() => {
        const fetch_annocement_lst = async () => {
            try {
                const annocement_lst = await services.get_annocement_lst();
                if (annocement_lst) {
                    setAncement(annocement_lst);
                } else {
                    console.error("Unexpected data format:", annocement_lst);
                }
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };
        fetch_annocement_lst();
    }, []);
    const formatDate = (dateString) => {
        if (!dateString) return "N/A"; // Return a default value if dateString is undefined or null
        const date = new Date(dateString);

        const day = String(date.getDate()).padStart(2, '0'); // Ensure 2 digits for day
        const month = date.toLocaleString('en-GB', { month: 'short' }); // Get short month name
        const year = date.getFullYear(); // Get full year

        return `${day}-${month}-${year}`;
    };

    useEffect(() => {
        const aflt_mangr_lst = async () => {
            try {
                const mangr_lst = await services.get_aflt_mangr_lst();
                if (mangr_lst) {
                    setIssuedByOptions(mangr_lst);
                } else {
                    console.error("Unexpected data format:", mangr_lst);
                }
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };
        aflt_mangr_lst();
    }, []);

    const handleOptionChange = (event) => {
        setSelectedOption(event.target.value);
    };

    const fetchupdteddata = () => {
        const aflt_mangr_lst = async () => {
            try {
                const mangr_lst = await services.get_aflt_mangr_lst();
                if (mangr_lst) {
                    setIssuedByOptions(mangr_lst);
                } else {
                    console.error("Unexpected data format:", mangr_lst);
                }
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };
        aflt_mangr_lst();
    }

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };
    console.log(formData);
    const handleSubmit = async (event) => {
        event.preventDefault();
        console.log(formData);
        try {
            const mangr_lst = await services.add_historical_anncoment(formData);
            if (mangr_lst) {
                alert("Historical Annocement Added Succesfully..")
            } else {
                console.error("Unexpected data format:", mangr_lst);
                alert("Failed to Add Historical Annocement..")
            }
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };

    const handleEditAnnocement = (annocement) => {
        console.log("selected annocement is ", annocement);
        const edit_announcementDate = moment(annocement.LVE_ISSUE_ON).format('YYYY-MM-DD');
        const edit_announcementDateFrom = moment(annocement.LVE_DSP_DT_FROM).format('YYYY-MM-DD');
        const edit_announcementDateTo = moment(annocement.LVE_DSP_DT_TO).format('YYYY-MM-DD');
        setFormData({
            editannouncementDate: edit_announcementDate,
            editannouncementDateFrom: edit_announcementDateFrom,
            editannouncementDateTo: edit_announcementDateTo,
            editannouncementHeading: annocement.LVE_NTC_HEAD_SUB,
            editissuedBy: annocement.LVE_ISSUE_BY,
            editdisplayFor: annocement.LVE_DSP_FOR,
            editseverity: annocement.LVE_NEWS_SEVERITY,
            editdisplayOnScreen: annocement.LVE_DSP_NTC,
            editannouncementDescription: annocement.LVE_ACTUL_NTC,
            editannocement_key: annocement.LVE_NEWS_KEY
        });
        setEditAnnocement(annocement);
    };

    const handleClose = () => {
        setEditAnnocement(null);
    };

    const handle_update_annocement = async (event) => {
        event.preventDefault();
        console.log(formData);
        try {
            const updatedAnnocement = await services.update_historical_anncoment(formData);
            if (updatedAnnocement) {
                alert("Historical Announcement Updated Successfully..");
                setFormData({
                    editannouncementDate: '',
                    editannouncementDateFrom: '',
                    editannouncementDateTo: '',
                    editannouncementHeading: '',
                    editissuedBy: '',
                    editdisplayFor: '',
                    editseverity: '',
                    editdisplayOnScreen: 'Y',
                    editannouncementDescription: '',
                    editannocement_key: ""
                });

                setEditAnnocement(null);
                // Call the function to fetch updated data
                fetchupdteddata(); // This will fetch the updated list of managers
            } else {
                console.error("Unexpected data format:", updatedAnnocement);
                alert("Failed to Update Historical Announcement..");
            }
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };

    return (
        <div className='wrapper_form'>
            <div className="title" style={{ marginTop: ".3%" }}>Historical Announcements Management</div>
            <div className="radio-row" style={{ marginBottom: "5px" }}>
                <label>
                    <input
                        type="radio"
                        name="addOption"
                        value="addAnnouncement"
                        checked={selectedOption === "addAnnouncement"}
                        onChange={handleOptionChange}
                        style={{ marginRight: "5px", width: "3%" }}
                    />
                    Add Announcements
                </label>
            </div>
            <hr className="hr1" />
            {selectedOption === "addAnnouncement" && (
                <form className="frm_wd" onSubmit={handleSubmit}>
                    <hr className="hr_ttl" />
                    <p style={{ fontSize: "25px", paddingTop: "5px", color: "black" }}>
                        Announcement Information
                    </p>
                    <hr className="hr_dt" />
                    <div className="row">
                        <div className="column drp_ln_hg">
                            <label className="drp_mg" style={{ marginLeft: "5px" }}>Announcement Date:</label>
                            <div className="field">
                                <input
                                    type="date"
                                    name="announcementDate"
                                    value={formData.announcementDate}
                                    onChange={handleInputChange}
                                    style={{ marginBottom: '10px', lineHeight: "25px", height: "25px", padding: "2px", borderRadius: "8px" }}
                                />
                            </div>
                        </div>
                        <div className="column drp_ln_hg">
                            <label className="drp_mg" style={{ marginLeft: "5px" }}>Announcement Date From:</label>
                            <div className="field">
                                <input
                                    type="date"
                                    name="announcementDateFrom"
                                    value={formData.announcementDateFrom}
                                    onChange={handleInputChange}
                                    style={{ marginBottom: '10px', lineHeight: "25px", height: "25px", padding: "2px", borderRadius: "8px" }}
                                />
                            </div>
                        </div>
                        <div className="column drp_ln_hg">
                            <label className="drp_mg" style={{ marginLeft: "5px" }}>Announcement Date To:</label>
                            <div className="field">
                                <input
                                    type="date"
                                    name="announcementDateTo"
                                    value={formData.announcementDateTo}
                                    onChange={handleInputChange}
                                    style={{ marginBottom: '10px', lineHeight: "25px", height: "25px", padding: "2px", borderRadius: "8px" }}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="column drp_ln_hg">
                            <label className="drp_mg" style={{ marginLeft: "1%" }}>Announcement Heading:</label>
                            <div className="field">
                                <input
                                    type="text"
                                    name="announcementHeading"
                                    value={formData.announcementHeading}
                                    onChange={handleInputChange}
                                    style={{ marginBottom: '10px', lineHeight: "25px", height: "25px", borderRadius: "8px" }}
                                    placeholder="Enter Announcement Heading"
                                />
                            </div>
                        </div>
                        <div className="column drp_ln_hg">
                            <label className="drp_mg" style={{ marginLeft: "1%" }}>Issued By:</label>
                            <div className="field">
                                <select
                                    name="issuedBy"
                                    value={formData.issuedBy}
                                    onChange={handleInputChange}
                                    style={{ marginBottom: '10px', lineHeight: "25px", height: "25px", borderRadius: "8px", width: "100%", paddingLeft: "3%" }}
                                >
                                    <option value="">Select Issued By</option>
                                    {issuedByOptions.all_aflt_mangr_lst_result.map(option => (
                                        <option key={option.USR_NTT_ID} value={option.USR_NTT_ID}>{option.Manager}</option>
                                    ))}
                                </select>
                            </div>
                        </div>
                        <div className="column drp_ln_hg">
                            <label className="drp_mg" style={{ marginLeft: "1%" }}>Display For:</label>
                            <div className="field">
                                <select
                                    name="displayFor"
                                    value={formData.displayFor}
                                    onChange={handleInputChange}
                                    style={{ marginBottom: '10px', lineHeight: "25px", height: "25px", borderRadius: "8px", width: "100%", paddingLeft: "3%" }}
                                >
                                    <option value="">Select Display For</option>
                                    <option value="O">Owner</option>
                                    <option value="Adm">Application Admin</option>
                                    <option value="C">Customer</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="column drp_ln_hg">
                            <label className="drp_mg" style={{ marginLeft: "1%" }}>Severity:</label>
                            <div className="field">
                                <select
                                    name="severity"
                                    value={formData.severity}
                                    onChange={handleInputChange}
                                    style={{ marginBottom: '10px', lineHeight: "25px", height: "25px", borderRadius: "8px", width: "100%", paddingLeft: "3%" }}
                                >
                                    <option value="">Select Severity</option>
                                    <option value="H">High</option>
                                    <option value="M">Medium</option>
                                    <option value="L">Low</option>
                                </select>
                            </div>
                        </div>
                        <div className="column" style={{ lineHeight: "12px", marginTop: "5px" }}>
                            <label style={{ marginLeft: "0%" }}>Display On Screen?</label>
                            <div style={{ marginLeft: "0%" }}>
                                <FormControl>
                                    <RadioGroup row
                                        name="displayOnScreen"
                                        value={formData.displayOnScreen}
                                        onChange={handleInputChange}
                                    >
                                        <FormControlLabel
                                            value="Y"
                                            control={<Radio />}
                                            label="Yes"
                                        />
                                        <FormControlLabel
                                            value="N"
                                            control={<Radio />}
                                            label="No"
                                        />
                                    </RadioGroup>
                                </FormControl>
                            </div>
                        </div>
                        <div className="column drp_ln_hg">
                            <label className="drp_mg" style={{ marginLeft: "1%" }}>
                                Announcement Description:
                                <textarea
                                    name="announcementDescription"
                                    value={formData.announcementDescription}
                                    onChange={handleInputChange}
                                    placeholder="Enter Announcement Description"
                                    style={{ marginBottom: '10px', width: '100%', height: '50px', borderRadius: "8px" }}
                                />
                            </label>
                        </div>
                    </div>
                    <hr className="hr_dt" />
                    <Button className="btn-action action-button" type="submit" style={{ lineHeight: "30px", height: "30px", marginLeft: "5%", width: "15%", fontSize: "bold", textAlign: "right", float: "right", marginBottom: ".5%" }} >
                        Add Announcement
                    </Button>
                    <hr className="hr_dt" />
                </form>
            )}
            <div className="radio-row" style={{ marginBottom: "5px" }}>
                <label>
                    <input
                        type="radio"
                        name="addOption"
                        value="editAnnouncement"
                        checked={selectedOption === "editAnnouncement"}
                        onChange={handleOptionChange}
                        style={{ marginRight: "5px", width: "3%" }}
                    />
                    Edit Announcements
                </label>
            </div>

            {selectedOption === "editAnnouncement" && (

                <>

                    {annocement == null ? (
                        <>
                            <div className="frm_wd" style={{ padding: "10px" }}>
                                <div className="title" style={{ marginTop: ".01%" }}> Historical Announcements Details</div>
                                <Grid item xs={12} style={{ paddingLeft: ".1%", paddingRight: ".1%" }}>
                                    <TableContainer
                                        component={Paper}
                                        style={{
                                            margin: "5px auto 25px auto",
                                            // maxWidth: "50%",
                                            overflow: "hidden", // Prevents content overflow
                                            boxShadow: "0px 6px 16px 3px #bababa", // Shadow only on the top and bottom
                                            borderRadius: "20px", // Rounded corners for the container
                                            border: "2px solid gray",

                                        }}
                                    >
                                        <Table style={{ width: "100%", }}>
                                            <TableHead>
                                                <TableRow style={{ width: "100%", }}>
                                                    <TableCell
                                                        style={{
                                                            fontSize: "0.8rem",
                                                            padding: "1px 18px",
                                                            backgroundColor: "#4a6d8c",
                                                            border: "1px solid gray", color: "white", textAlign: "center"
                                                        }}
                                                        className=" txt_ttl1"
                                                    >
                                                        Sr. No.
                                                    </TableCell>
                                                    <TableCell
                                                        style={{
                                                            fontSize: "0.8rem",
                                                            padding: "1px 18px",
                                                            backgroundColor: "#4a6d8c",
                                                            border: "1px solid gray", color: "white", textAlign: "center"
                                                        }}
                                                        className=" txt_ttl1"
                                                    >
                                                        Annocement Date
                                                    </TableCell>
                                                    <TableCell
                                                        style={{
                                                            fontSize: "0.8rem",
                                                            padding: "1px 18px",
                                                            backgroundColor: "#4a6d8c",
                                                            border: "1px solid gray", color: "white", textAlign: "center"
                                                        }}
                                                        className=" txt_ttl1"
                                                    >
                                                        Annocement Heading
                                                    </TableCell>
                                                    <TableCell
                                                        style={{
                                                            fontSize: "0.8rem",
                                                            padding: "1px",
                                                            backgroundColor: "#4a6d8c",
                                                            border: "1px solid gray", color: "white", textAlign: "center"
                                                        }}
                                                        className=" txt_ttl1"
                                                    >
                                                        Display From Date
                                                    </TableCell>
                                                    <TableCell
                                                        style={{
                                                            fontSize: "0.8rem",
                                                            padding: "1px",
                                                            backgroundColor: "#4a6d8c",
                                                            border: "1px solid gray", color: "white", textAlign: "center"
                                                        }}
                                                        className=" txt_ttl1"
                                                    >
                                                        Display From To
                                                    </TableCell>
                                                    <TableCell
                                                        style={{
                                                            fontSize: "0.8rem",
                                                            padding: "1px",
                                                            backgroundColor: "#4a6d8c",
                                                            border: "1px solid gray", color: "white", textAlign: "center"
                                                        }}
                                                        className=" txt_ttl1"
                                                    >
                                                        Display On Screen ?
                                                    </TableCell>
                                                    <TableCell
                                                        style={{
                                                            fontSize: "0.8rem",
                                                            padding: "1px",
                                                            backgroundColor: "#4a6d8c",
                                                            border: "1px solid gray", color: "white", textAlign: "center"
                                                        }}
                                                        className=" txt_ttl1"
                                                    >
                                                        Action
                                                    </TableCell>

                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {ancement.histrical_annocement_result.map((annocement, index) => (
                                                    <TableRow style={{ height: "10px" }} key={annocement.leaveTypeId}>
                                                        <TableCell align="center" style={{ fontSize: "0.8rem", padding: "1px 18px", border: "1px solid gray" }}>
                                                            {index + 1} {/* Serial number */}
                                                        </TableCell>
                                                        <TableCell align="center" style={{ fontSize: "0.8rem", padding: "1px 18px", border: "1px solid gray" }}>
                                                            {formatDate(annocement.LVE_ISSUE_ON)}
                                                        </TableCell>
                                                        <TableCell align="center" style={{ fontSize: "0.8rem", padding: "1px 18px", border: "1px solid gray" }}>
                                                            {annocement.LVE_NTC_HEAD_SUB}
                                                        </TableCell>
                                                        <TableCell align="center" style={{ fontSize: "0.8rem", padding: "1px", border: "1px solid gray" }}>

                                                            {formatDate(annocement.LVE_DSP_DT_FROM)}
                                                        </TableCell>
                                                        <TableCell align="center" style={{ fontSize: "0.8rem", padding: "1px", border: "1px solid gray" }}>

                                                            {formatDate(annocement.LVE_DSP_DT_TO)}
                                                        </TableCell>
                                                        <TableCell align="center" style={{ fontSize: "0.8rem", padding: "1px", border: "1px solid gray" }}>
                                                            {annocement.LVE_DSP_NTC == "Y" ? "Yes" : "No"}
                                                        </TableCell>
                                                        <TableCell align="center" style={{
                                                            fontSize: "0.8rem", padding: "1px", border: "1px solid gray"
                                                        }}
                                                        >
                                                            <Button
                                                                style={{ minWidth: "auto" }}
                                                                onClick={() => handleEditAnnocement(annocement)}
                                                                size="small"
                                                            >
                                                                <EditIcon style={{ color: "#4a6d8c" }} fontSize="small" />
                                                            </Button>

                                                        </TableCell>

                                                    </TableRow>
                                                ))}
                                                {ancement.histrical_annocement_result.length < 10 &&
                                                    Array.from({ length: 5 - ancement.histrical_annocement_result.length }).map((_, index) => (
                                                        <TableRow style={{ height: "5px" }} key={`empty-row-${index}`}>
                                                            <TableCell align="center" style={{ fontSize: "0.8rem", padding: "1px 18px", border: "1px solid gray" }}>
                                                                &nbsp; {/* Empty cell for spacing */}
                                                            </TableCell>
                                                            <TableCell align="center" style={{ fontSize: "0.8rem", padding: "1px 18px", border: "1px solid gray" }}>
                                                                &nbsp; {/* Empty cell for spacing */}
                                                            </TableCell>
                                                            <TableCell align="center" style={{ fontSize: "0.8rem", padding: "1px 18px", border: "1px solid gray" }}>
                                                                &nbsp; {/* Empty cell for spacing */}
                                                            </TableCell>
                                                            <TableCell align="center" style={{ fontSize: "0.8rem", padding: "1px", border: "1px solid gray" }}>
                                                                &nbsp; {/* Empty cell for spacing */}
                                                            </TableCell>
                                                            <TableCell align="center" style={{ fontSize: "0.8rem", padding: "1px", border: "1px solid gray" }}>
                                                                &nbsp; {/* Empty cell for spacing */}
                                                            </TableCell>
                                                            <TableCell align="center" style={{ fontSize: "0.8rem", padding: "1px", border: "1px solid gray" }}>
                                                                &nbsp; {/* Empty cell for spacing */}
                                                            </TableCell>
                                                            <TableCell align="center" style={{ fontSize: "0.8rem", padding: "1px", border: "1px solid gray" }}>
                                                                &nbsp; {/* Empty cell for spacing */}
                                                            </TableCell>
                                                        </TableRow>
                                                    ))}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Grid>
                            </div>
                        </>
                    ) : (
                        <div>
                            {/* <Typography variant="h6">Holiday Details</Typography> */}
                            <form className="frm_wd">
                                <div className="title" style={{ marginTop: ".01%", marginBottom: "1%" }}>Edit Holiday Details</div>
                                <div className="row">
                                    <div className="column drp_ln_hg">
                                        <label className="drp_mg" style={{ marginLeft: "5px" }}>Announcement Date:</label>
                                        <div className="field">
                                            <input
                                                type="date"
                                                name="editannouncementDate"
                                                value={formData.editannouncementDate}
                                                onChange={handleInputChange}
                                                style={{ marginBottom: '10px', lineHeight: "25px", height: "25px", padding: "2px", borderRadius: "8px" }}
                                            />
                                        </div>
                                    </div>
                                    <div className="column drp_ln_hg">
                                        <label className="drp_mg" style={{ marginLeft: "5px" }}>Announcement Date From:</label>
                                        <div className="field">
                                            <input
                                                type="date"
                                                name="editannouncementDateFrom"
                                                value={formData.editannouncementDateFrom}
                                                onChange={handleInputChange}
                                                style={{ marginBottom: '10px', lineHeight: "25px", height: "25px", padding: "2px", borderRadius: "8px" }}
                                            />
                                        </div>
                                    </div>
                                    <div className="column drp_ln_hg">
                                        <label className="drp_mg" style={{ marginLeft: "5px" }}>Announcement Date To:</label>
                                        <div className="field">
                                            <input
                                                type="date"
                                                name="editannouncementDateTo"
                                                value={formData.editannouncementDateTo}
                                                onChange={handleInputChange}
                                                style={{ marginBottom: '10px', lineHeight: "25px", height: "25px", padding: "2px", borderRadius: "8px" }}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="column drp_ln_hg">
                                        <label className="drp_mg" style={{ marginLeft: "1%" }}>Announcement Heading:</label>
                                        <div className="field">
                                            <input
                                                type="text"
                                                name="editannouncementHeading"
                                                value={formData.editannouncementHeading}
                                                onChange={handleInputChange}
                                                style={{ marginBottom: '10px', lineHeight: "25px", height: "25px", borderRadius: "8px" }}
                                                placeholder="Enter Announcement Heading"
                                            />
                                        </div>
                                    </div>
                                    <div className="column drp_ln_hg">
                                        <label className="drp_mg" style={{ marginLeft: "1%" }}>Issued By:</label>
                                        <div className="field">
                                            <select
                                                name="editissuedBy"
                                                value={formData.editissuedBy}
                                                onChange={handleInputChange}
                                                style={{ marginBottom: '10px', lineHeight: "25px", height: "25px", borderRadius: "8px", width: "100%", paddingLeft: "3%" }}
                                            >
                                                <option value="">Select Issued By</option>
                                                {issuedByOptions.all_aflt_mangr_lst_result.map(option => (
                                                    <option key={option.USR_NTT_ID} value={option.USR_NTT_ID}>{option.Manager}</option>
                                                ))}
                                            </select>
                                        </div>
                                    </div>
                                    <div className="column drp_ln_hg">
                                        <label className="drp_mg" style={{ marginLeft: "1%" }}>Display For:</label>
                                        <div className="field">
                                            <select
                                                name="editdisplayFor"
                                                value={formData.editdisplayFor}
                                                onChange={handleInputChange}
                                                style={{ marginBottom: '10px', lineHeight: "25px", height: "25px", borderRadius: "8px", width: "100%", paddingLeft: "3%" }}
                                            >
                                                <option value="">Select Display For</option>
                                                <option value="O">Owner</option>
                                                <option value="Adm">Application Admin</option>
                                                <option value="C">Customer</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="column drp_ln_hg">
                                        <label className="drp_mg" style={{ marginLeft: "1%" }}>Severity:</label>
                                        <div className="field">
                                            <select
                                                name="editseverity"
                                                value={formData.editseverity}
                                                onChange={handleInputChange}
                                                style={{ marginBottom: '10px', lineHeight: "25px", height: "25px", borderRadius: "8px", width: "100%", paddingLeft: "3%" }}
                                            >
                                                <option value="">Select Severity</option>
                                                <option value="H">High</option>
                                                <option value="M">Medium</option>
                                                <option value="L">Low</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="column" style={{ lineHeight: "12px", marginTop: "5px" }}>
                                        <label style={{ marginLeft: "0%" }}>Display On Screen?</label>
                                        <div style={{ marginLeft: "0%" }}>
                                            <FormControl>
                                                <RadioGroup row
                                                    name="editdisplayOnScreen"
                                                    value={formData.editdisplayOnScreen}
                                                    onChange={handleInputChange}
                                                >
                                                    <FormControlLabel
                                                        value="Y"
                                                        control={<Radio />}
                                                        label="Yes"
                                                    />
                                                    <FormControlLabel
                                                        value="N"
                                                        control={<Radio />}
                                                        label="No"
                                                    />
                                                </RadioGroup>
                                            </FormControl>
                                        </div>
                                    </div>
                                    <div className="column drp_ln_hg">
                                        <label className="drp_mg" style={{ marginLeft: "1%" }}>
                                            Announcement Description:
                                            <textarea
                                                name="editannouncementDescription"
                                                value={formData.editannouncementDescription}
                                                onChange={handleInputChange}
                                                placeholder="Enter Announcement Description"
                                                style={{ marginBottom: '10px', width: '100%', height: '50px', borderRadius: "8px" }}
                                            />
                                        </label>
                                    </div>
                                </div>
                                <div
                                    style={{
                                        marginTop: "10px",
                                        display: "flex",
                                    }}
                                >
                                    <Button
                                        className="action-button"
                                        style={{ width: "10%", marginRight: "15px" }}
                                        onClick={handleClose}
                                    >
                                        Cancel
                                    </Button>
                                    <Button
                                        className="action-button"
                                        style={{ width: "10%", marginRight: "15px", textAlign: "right", float: "right", alignItems: "end" }}
                                        onClick={handle_update_annocement}

                                    >
                                        Update
                                    </Button>
                                </div>
                            </form>


                        </div>
                    )}

                </>
            )
            }
        </div >
    );
}

export default Adm_Historical_Annocement_managment;