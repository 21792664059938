import React, { useState, useEffect } from "react";
import services from "./apiServices";

export default function Sys_Usr_Change_Aflt_Pass() {
    const [aflttotalcnt, setaflttotalcnt] = useState({
        aflt_cnt_res: [],
        aflt_wise_usr_cnt_res: [],
        alt_wise_lve_types_res: [],
        aflt_wise_lve_apl_cnt_res: [],
        pendng_aprved_aflt_apl_cnt_res: [],
        all_aflt_confg_dt: [],
        all_alf_auth_ntt_res: [] // Users related to affiliates
    });

    const [searchTerm, setSearchTerm] = useState(''); // For searching affiliates
    const [filteredAffiliates, setFilteredAffiliates] = useState([]); // Filtered list of affiliates
    const [users, setUsers] = useState([]); // Filtered list of users
    const [selectedAffiliate, setSelectedAffiliate] = useState(''); // Selected affiliate
    const [selectedUser, setSelectedUser] = useState(''); // Selected user from affiliate
    const [passwordFields, setPasswordFields] = useState({
        newPassword: '',
        confirmPassword: '',
        loginid: "",
        aflt_key: ""
    });
    const [errors, setErrors] = useState({});
    console.log("passwordFields", passwordFields);

    useEffect(() => {
        const fetch = async () => {
            try {
                const res = await services.get_total_aflt_cnt();
                if (res) {
                    setaflttotalcnt(res);
                }
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };
        fetch();
    }, []);

    const handleSearch = () => {
        if (!searchTerm) {
            alert("Please enter an affiliate name.");
            return;
        }

        const results = aflttotalcnt.aflt_cnt_res.filter(aff =>
            aff.AFLT_NAME.toLowerCase() === searchTerm.toLowerCase()
        );

        if (results.length > 0) {
            setFilteredAffiliates(results);
            const filteredUsers = aflttotalcnt.all_alf_auth_ntt_res.filter(user =>
                user.AFLT_NAME.toLowerCase() === results[0].AFLT_NAME.toLowerCase()
            );
            setUsers(filteredUsers);
        } else {
            alert("No affiliates found!");
        }
    };

    const handleAffiliateSelect = (affiliateName) => {
        setSelectedAffiliate(affiliateName);
        const selectedUsers = aflttotalcnt.all_alf_auth_ntt_res.filter(user =>
            user.AFLT_NAME === affiliateName
        );
        setUsers(selectedUsers);
        setSelectedUser('');
        setPasswordFields({ newPassword: '', confirmPassword: '' });
    };

    const handleUserSelect = (user) => {
        setSelectedUser(user);

        // Find the affiliate key for the selected user
        const selectedUserDetails = aflttotalcnt.all_alf_auth_ntt_res.find(u => u.NTT_LOGIN_ID === user);

        // Store the affiliate key in passwordFields
        if (selectedUserDetails) {
            setPasswordFields({
                ...passwordFields,
                loginid: user,
                aflt_key: selectedUserDetails.AFLT_KY // Store the aflt_key here
            });
        }
    };

    const handlePasswordChange = (e) => {
        setPasswordFields({
            ...passwordFields,
            [e.target.name]: e.target.value
        });
    };

    const validateForm = () => {
        const newErrors = {};

        if (!passwordFields.newPassword) newErrors.newPassword = "New Pass is required";
        if (!passwordFields.confirmPassword) newErrors.confirmPassword = "Confirm Pass is required";
        if (passwordFields.newPassword !== passwordFields.confirmPassword) newErrors.confirmPassword = "Passwords do not match!";

        setErrors(newErrors);
        return Object.keys(newErrors).length === 0; // Return true if no errors
    };

    const handleSave = async () => {
        if (validateForm()) {
            console.log("Saving password for", passwordFields);
            try {
                const res = await services.updt_aflt_pass(passwordFields);
                if (res) {
                    console.log("res", res);
                    alert("Password Changed Successfully");
                }
            } catch (error) {
                console.error("Error changing password", error);
            }
        } else {
            alert("Please fix the form validation errors before submitting.");
        }
    };

    const handlecancel = () => {
        setSelectedUser(null);
        setPasswordFields({ newPassword: '', confirmPassword: '', aflt_key: "" });
        setErrors({});
    };

    return (
        <div className="wrapper_form" style={{ backgroundColor: "#dddddf" }}>
            <div className="title" style={{ marginBottom: "1.4%" }}>Client Password Management</div>
            <form className="frm_wd">
                <div className="row" style={{ marginBottom: "1.6%" }}>
                    <div className="column ">
                        <label htmlFor="searchAffiliate">Search Client Name:-</label>
                        <input
                            id="searchAffiliate"
                            type="text"
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}
                            placeholder="Enter Client name"
                            style={{
                                paddingLeft: "1.5%",
                                marginLeft: "3px",
                                lineHeight: "25px",
                                height: "25px",
                                padding: "2px",
                                borderRadius: "8px"
                            }}
                        />
                        <button
                            type="button"
                            onClick={handleSearch}
                            className="btn-action action-button"
                            style={{
                                padding: "4px 15px",
                                marginLeft: "1%",
                                width: "15%",
                                fontSize: "bold",
                                marginBottom: "1px",
                                marginTop: "1px"
                            }}
                        >
                            Search
                        </button>
                    </div>
                    <div className="column">
                        {filteredAffiliates.length > 0 && (
                            <div className="user_dropdown">
                                <label>Select User:-</label>
                                <select
                                    value={selectedUser}
                                    onChange={(e) => handleUserSelect(e.target.value)}
                                    style={{
                                        marginLeft: "3px",
                                        lineHeight: "25px",
                                        height: "25px",
                                        padding: "2px",
                                        borderRadius: "8px",
                                        width: "50%",
                                        paddingLeft: "1.5%"
                                    }}
                                >
                                    <option value="">Select User</option>
                                    {users.map((user) => (
                                        <option key={user.NTT_LOGIN_ID} value={user.NTT_LOGIN_ID}>
                                            {user.USR_DISPLAY_NAME}
                                        </option>
                                    ))}
                                </select>
                            </div>
                        )}
                    </div>
                </div>
                <hr className="hr_dt" />
                {selectedUser && (
                    <div className="wrapper_form wd_log">
                        <div className="title">Change Password</div>

                        <form method="Post"><br />
                            <div className="field">
                                <input
                                    type="text"
                                    name="usr_name"
                                    value={selectedUser || ""}
                                />
                                <label>
                                    Login ID
                                    {errors.usr_name && (
                                        <span className="error-message">{errors.usr_name}</span>
                                    )}
                                </label>
                            </div><br />
                            <div className="field">
                                <input
                                    type="password"
                                    name="newPassword"
                                    value={passwordFields.newPassword}
                                    onChange={handlePasswordChange}
                                    placeholder="Enter New Password"
                                />
                                <label>
                                    New Password
                                    {errors.newPassword && (
                                        <span className="error-message">{errors.newPassword}</span>
                                    )}
                                </label>
                            </div><br />

                            <div className="field">
                                <input
                                    type="password"
                                    name="confirmPassword"
                                    value={passwordFields.confirmPassword}
                                    onChange={handlePasswordChange}
                                    placeholder="Enter Confirm Password"
                                />
                                <label>
                                    Confirm Password
                                    {errors.confirmPassword && (
                                        <span className="error-message">{errors.confirmPassword}</span>
                                    )}
                                </label>
                            </div><br />
                            <hr className="hr_dt" />
                            <div className="field">
                                <button
                                    style={{ width: "40%", float: "left", margin: " 2% 0% 2% 0%" }}
                                    type="button"
                                    className="btn-action action-button"
                                    onClick={handlecancel}
                                >
                                    Cancel
                                </button>
                                <button
                                    style={{ width: "40%", float: "right", margin: " 2% 0% 2% 0%" }}
                                    type="button"
                                    className="btn-action action-button"
                                    onClick={handleSave}
                                >
                                    Save Password
                                </button>
                            </div>
                            <hr className="hr_dt" />
                        </form>
                    </div>
                )}
            </form>
        </div>
    );
}
