import React, { useState, useEffect } from "react";
import services from "./apiServices";
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Button,
    Checkbox,
    Grid
} from "@mui/material";
import EditIcon from '@mui/icons-material/Edit';
import FormLabel from '@mui/material/FormLabel';
import { FormControl, FormControlLabel, Radio, RadioGroup, Divider } from '@mui/material';
import { FormGroup, Typography } from '@mui/material';
function Sys_Usr_Aflt_Confg_info() {
    const [selectedaffilate, setSelectedaffilate] = useState();
    const [afltconfginfo, setafltconfginfo] = useState({ aflt_wise_conf_info_res: [] });
    const [aflttotalcnt, setaflttotalcnt] = useState({
        aflt_cnt_res: [],
        aflt_wise_usr_cnt_res: [],
        alt_wise_lve_types_res: [],
        aflt_wise_lve_apl_cnt_res: [],
        pendng_aprved_aflt_apl_cnt_res: [],
        all_aflt_confg_dt: [],
        sys_usr_lve_db_res: [],
    });
    const [afltKey, setAfltKey] = useState(''); // New state for affiliate key

    console.log("afltconfginfo", afltconfginfo)
    console.log("aflttotalcnt", aflttotalcnt)
    console.log("selectedaffilate", selectedaffilate)
    console.log("afltKey", afltKey) // Log the afltKey
    useEffect(() => {
        const fetch = async () => {
            try {
                const res = await services.get_total_aflt_cnt();
                if (res) {
                    setaflttotalcnt(res);
                }
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };
        fetch();
    }, []);
    useEffect(() => {
        const fetch = async () => {
            try {
                const res = await services.get_aflt_confg_info();
                if (res) {
                    setafltconfginfo(res);
                }
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };
        fetch();
    }, []);

    const handleaffilate = (event) => {
        const selectedAffilate = event.target.value;
        setSelectedaffilate(selectedAffilate);
        // Find the affiliate object from aflttotalcnt.alt_wise_lve_types_res based on selected affiliate name
        const afltKeyObj = aflttotalcnt.alt_wise_lve_types_res.find((aflt) => aflt.AFLT_NAME === selectedAffilate);

        // Check if afltKeyObj is found, then set the afltKey state
        if (afltKeyObj) {
            console.log("afltkey", afltKeyObj);
            setAfltKey(afltKeyObj.AFLT_KY); // Set the afltKey when affiliate is selected
        } else {
            console.error("Client not found!");
        }
    };
    const getFilteredLeaveTypesData = () => {
        return afltconfginfo.aflt_wise_conf_info_res
            .filter(leave => leave.AFLT_KY === afltKey && leave.disp_nm) // Filter by selected affiliate and leave type
            .map(leave => ({
                leaveType: leave.disp_nm,
                question: leave.ATTRIB_NAME,
                answer: leave.AFLT_CONF_PARA_VAL,
            }));
    };
    const getFilteredQuestionsAndAnswersData = () => {
        return afltconfginfo.aflt_wise_conf_info_res
            .filter(leave => leave.AFLT_KY === afltKey && leave.disp_nm === '') // Filter for the second table's conditions
            .map(leave => ({
                question: leave.ATTRIB_NAME,
                answer: leave.AFLT_CONF_PARA_VAL,
            }));
    };



    const handleDownload = async (event) => {
        event.preventDefault();  // Prevent form submission and page reload
        if (!selectedaffilate) {
            console.error('No affiliate selected!');
            return;
        }

        // Extract data from both tables
        const leaveTypesData = getFilteredLeaveTypesData();
        const questionsAndAnswersData = getFilteredQuestionsAndAnswersData();

        console.log("Leave Types Data: ", leaveTypesData);
        console.log("Questions & Answers Data: ", questionsAndAnswersData);

        try {
            const response = await fetch('rdbs/sys_usr_rpt/afltconfgprint', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    affiliate: selectedaffilate, // Include selected affiliate
                    afltKey: afltKey, // Include afltKey
                    leaveTypesData: leaveTypesData, // Send the leave types data
                    questionsAndAnswersData: questionsAndAnswersData, // Send the Q&A data
                }),
            });

            if (response.ok) {
                // Convert the response to a blob
                const blob = await response.blob();

                // Create a URL for the blob
                const url = window.URL.createObjectURL(blob);

                // Open the PDF in a new window or tab
                window.open(url, '_blank');
            } else {
                console.error('Error generating PDF:', response.statusText);
            }
        } catch (error) {
            console.error('Error fetching PDF:', error);
        }
    };

    return (
        <div className="wrapper_form">
            <div className="title" style={{ marginBottom: "1.4%" }}>Client Configration Details</div>
            <form className="frm_wd">
                <div style={{ margin: "1% 1% 1% 0% " }}>
                    <label htmlFor="affilate">Select Client : </label>
                    <select
                        id="affilate"
                        value={selectedaffilate}
                        onChange={handleaffilate}
                        style={{ borderRadius: "8px", width: "30%" }}
                    >
                        <option value="">Select Client</option>
                        {Array.from(new Set(aflttotalcnt.alt_wise_lve_types_res.map(item => item.AFLT_NAME)))
                            .map((affilate) => (
                                <option key={affilate} value={affilate}>
                                    {affilate}
                                </option>
                            ))}
                    </select>
                </div>

                {selectedaffilate && (
                    <>
                        <hr className="hr_dt" />
                        <Grid item xs={12} style={{ paddingLeft: "2%", paddingRight: "1%", width: "100%" }}>
                            <div className="title" style={{ marginTop: "1%", lineHeight: "16px" }}>Selected Client Holidays configuration</div>
                            <TableContainer
                                component={Paper}
                                style={{
                                    margin: "5px auto 25px auto",
                                    borderRadius: "10px",
                                    border: "2px solid gray",
                                    maxHeight: "400px",
                                }}
                            >
                                <Table style={{ width: "100%" }}>
                                    <TableHead>
                                        <TableRow style={{ lineHeight: "0.3" }}>
                                            <TableCell style={{ fontSize: "0.8rem", padding: "1px 18px", backgroundColor: "#4a6d8c", border: "1px solid gray", color: "white", textAlign: "center" }} className="txt_ttl">
                                                Sr. No.
                                            </TableCell>
                                            <TableCell style={{ fontSize: "0.8rem", padding: "1px 18px", backgroundColor: "#4a6d8c", border: "1px solid gray", color: "white", textAlign: "center" }} className="txt_ttl">
                                                Leave Type
                                            </TableCell>
                                            <TableCell style={{ fontSize: "0.8rem", padding: "1px 18px", backgroundColor: "#4a6d8c", border: "1px solid gray", color: "white", textAlign: "center" }} className="txt_ttl">
                                                Question
                                            </TableCell>
                                            <TableCell style={{ fontSize: "0.8rem", padding: "1px 18px", backgroundColor: "#4a6d8c", border: "1px solid gray", color: "white", textAlign: "center" }} className="txt_ttl">
                                                Answer
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {/* Track rendered leave types to display them only once */}
                                        {afltconfginfo.aflt_wise_conf_info_res
                                            .filter(leave => leave.AFLT_KY === afltKey && leave.disp_nm) // Filter by selected affiliate and leave type
                                            .reduce((acc, leave, index, arr) => {
                                                // Check if the leave type (disp_nm) is already rendered
                                                if (!acc.leaveTypeIndexMap[leave.disp_nm]) {
                                                    acc.leaveTypeIndexMap[leave.disp_nm] = acc.index; // Store the index for this leave type
                                                    const leaveRows = arr.filter(item => item.disp_nm === leave.disp_nm); // All rows for this leave type

                                                    // Add rows for this leave type (displayed only once)
                                                    leaveRows.forEach((leaveRow, rowIndex) => {
                                                        acc.rows.push(
                                                            <TableRow style={{ height: "10px" }} key={leaveRow.leaveTypeId}>
                                                                {rowIndex === 0 ? (
                                                                    <TableCell rowSpan={leaveRows.length} align="center" style={{ fontSize: "0.8rem", padding: "1px 18px", border: "1px solid gray" }}>
                                                                        {acc.leaveTypeIndexMap[leave.disp_nm]}  {/* Sr. No. only displayed for the first row of each leave type */}
                                                                    </TableCell>
                                                                ) : null}
                                                                {rowIndex === 0 ? (
                                                                    <TableCell rowSpan={leaveRows.length} align="center" style={{ fontSize: "0.8rem", padding: "1px 18px", border: "1px solid gray" }}>
                                                                        {leaveRow.disp_nm}  {/* Display leave type only once */}
                                                                    </TableCell>
                                                                ) : null}
                                                                <TableCell align="center" style={{ fontSize: "0.8rem", padding: "1px 18px", border: "1px solid gray" }}>
                                                                    {leaveRow.ATTRIB_NAME}
                                                                </TableCell>
                                                                <TableCell align="center" style={{ fontSize: "0.8rem", padding: "1px 18px", border: "1px solid gray" }}>
                                                                    {leaveRow.AFLT_CONF_PARA_VAL}
                                                                </TableCell>
                                                            </TableRow>
                                                        );
                                                    });

                                                    // Add an empty row after each leave type's records (after all the questions for that leave type)
                                                    acc.rows.push(
                                                        <TableRow key={`empty-row-after-${leave.disp_nm}`}>
                                                            <TableCell colSpan={4} style={{ height: "20px", border: "none" }}>&nbsp;</TableCell>
                                                        </TableRow>
                                                    );

                                                    // Increment index for the next leave type
                                                    acc.index++;
                                                }
                                                return acc;
                                            }, { rows: [], leaveTypeIndexMap: {}, index: 1 }).rows} {/* start Sr. No. from 1 */}

                                        {/* Rendering empty rows if there are less than 10 rows */}
                                        {afltconfginfo.aflt_wise_conf_info_res.length < 10 && Array.from({ length: 10 - afltconfginfo.aflt_wise_conf_info_res.length }).map((_, index) => (
                                            <TableRow style={{ height: "10px" }} key={`empty-row-${index}`}>
                                                <TableCell align="center" style={{ fontSize: "0.8rem", padding: "1px 18px", border: "1px solid gray" }}>
                                                    &nbsp;
                                                </TableCell>
                                                <TableCell align="center" style={{ fontSize: "0.8rem", padding: "1px 18px", border: "1px solid gray" }}>
                                                    &nbsp;
                                                </TableCell>
                                                <TableCell align="center" style={{ fontSize: "0.8rem", padding: "1px 18px", border: "1px solid gray" }}>
                                                    &nbsp;
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Grid>
                        <hr className="hr_dt" style={{ marginBottom: "1%" }} />

                        <Grid item xs={12} style={{ paddingLeft: "2%", paddingRight: "1%", width: "100%" }}>
                            <div className="title" style={{ marginTop: "1%", lineHeight: "16px" }}>Selected Client Other configuration</div>
                            <TableContainer
                                component={Paper}
                                style={{
                                    margin: "5px auto 25px auto",
                                    borderRadius: "10px",
                                    border: "2px solid gray",
                                    maxHeight: "400px",
                                }}
                            >
                                <Table style={{ width: "100%" }}>
                                    <TableHead>
                                        <TableRow style={{ lineHeight: "0.3" }}>
                                            <TableCell style={{ padding: "1px 18px", backgroundColor: "#4a6d8c", border: "1px solid gray", color: "white", textAlign: "center" }} className="txt_ttl">
                                                Sr. No.
                                            </TableCell>

                                            <TableCell style={{ padding: "1px 18px", backgroundColor: "#4a6d8c", border: "1px solid gray", color: "white", textAlign: "center" }} className="txt_ttl">
                                                Question
                                            </TableCell>
                                            <TableCell style={{ padding: "1px 18px", backgroundColor: "#4a6d8c", border: "1px solid gray", color: "white", textAlign: "center" }} className="txt_ttl">
                                                Answer
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {afltconfginfo.aflt_wise_conf_info_res
                                            .filter(leave => leave.AFLT_KY === afltKey && leave.disp_nm === '')
                                            .map((leave, index) => (
                                                <TableRow style={{ height: "10px" }} key={leave.leaveTypeId}>
                                                    <TableCell align="center" style={{ fontSize: "0.8rem", padding: "1px 18px", border: "1px solid gray" }}>
                                                        {index + 1}
                                                    </TableCell>

                                                    <TableCell align="center" style={{ fontSize: "0.8rem", padding: "1px 18px", border: "1px solid gray" }}>
                                                        {leave.ATTRIB_NAME}
                                                    </TableCell>
                                                    <TableCell align="center" style={{ fontSize: "0.8rem", padding: "1px 18px", border: "1px solid gray" }}>
                                                        {leave.AFLT_CONF_PARA_VAL}
                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                        {afltconfginfo.aflt_wise_conf_info_res.length < 10 && Array.from({ length: 10 - afltconfginfo.aflt_wise_conf_info_res.length }).map((_, index) => (
                                            <TableRow style={{ height: "10px" }} key={`empty-row-${index}`}>
                                                <TableCell align="center" style={{ fontSize: "0.8rem", padding: "1px 18px", border: "1px solid gray" }}>
                                                    &nbsp;
                                                </TableCell>
                                                <TableCell align="center" style={{ fontSize: "0.8rem", padding: "1px 18px", border: "1px solid gray" }}>
                                                    &nbsp;
                                                </TableCell>
                                                <TableCell align="center" style={{ fontSize: "0.8rem", padding: "1px 18px", border: "1px solid gray" }}>
                                                    &nbsp;
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Grid>
                        {/* <hr className="hr_dt" /> */}
                        <hr className="hr1" style={{ marginTop: "3%" }} />
                        <div className="row" style={{ marginBottom: "15px" }}>
                            <div className="column"></div>
                            <div className="column"></div>
                            <div className="column"></div>
                            <div className="column"></div>
                            <div className="column">
                                <div className="field">
                                    <button type="submit" className="btn-action action-button" onClick={handleDownload}>
                                        Print
                                    </button>
                                </div>
                            </div>
                        </div>
                        <hr className="hr1" style={{ marginTop: "2.5%" }} />
                    </>
                )}
            </form>
        </div>
    )
}

export default Sys_Usr_Aflt_Confg_info