import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useNavigate } from 'react-router-dom';
import services from "./apiServices";
// import './LoginForm.css'; // Import the CSS file

export default function LoginForm(props) {
  const [errors, setErrors] = useState({});
  const [formData, setFormData] = useState({
    org_ucd: "",
    usr_name: "",
    usr_pass: "",
  });
  const navigate = useNavigate();

  const validateForm = () => {
    const newErrors = {};
    if (!formData.org_ucd) newErrors.org_ucd = "Unique code required";
    if (!formData.usr_name) newErrors.usr_name = "User name is required";
    if (!formData.usr_pass) newErrors.usr_pass = "Password is required";

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0; // Return true if no errors
  };

  const submit_login = () => {
    if (validateForm()) {
      const usr_login = async () => {
        try {
          const response = await services.usr_login(formData);
          if (response && Object.keys(response).length > 0) {
            const usr_log = response;
            sessionStorage.setItem("user_data", JSON.stringify(usr_log));
            props.ut(usr_log[0].NTT_TYP_FKY);
            props.dnm(usr_log[0].USR_DISPLAY_NAME);
            props.usr_post(usr_log[0].USR_POST);
          } else {
            alert("No data returned from the server.");
          }
        } catch (error) {
          if (error.response) {
            if (error.response.data.response.data.failedLoginCount) {
              alert("Your account is locked. Please reset your password.");
              navigate('/forget_pass');
            }
          } else {
            alert("Login failed. Please check your credentials.");
          }
        }
      };
      usr_login();
    }
  };

  return (
    <div className="login-container">
      <div className="wrapper_form wd_log">
        <div className="title">Login Form</div>
        <form method="Post" style={{ marginTop: "4%" }}>
          <div className="field mgtop1">
            <input
              className="mgtop"
              type="text"
              name="org_ucd"
              value={formData.org_ucd || ""}
              placeholder="Enter Organization Unique Code"
              onChange={(e) =>
                setFormData({ ...formData, org_ucd: e.target.value })
              }
            />
            <label>
              Unique Code{" "}
              {errors.org_ucd && (
                <span className="error-message">{errors.org_ucd}</span>
              )}
            </label>
          </div>

          <div className="field mgtop1">
            <input
              className="mgtop"
              type="text"
              name="usr_name"
              value={formData.usr_name || ""}
              placeholder="Enter User Name"
              onChange={(e) =>
                setFormData({ ...formData, usr_name: e.target.value })
              }
            />
            <label>
              User Name{" "}
              {errors.usr_name && (
                <span className="error-message">{errors.usr_name}</span>
              )}
            </label>
          </div>

          <div className="field mgtop1">
            <input
              className="mgtop"
              type="password"
              name="usr_pass"
              placeholder="Enter Password"
              value={formData.usr_pass || ""}
              onChange={(e) =>
                setFormData({ ...formData, usr_pass: e.target.value })
              }
            />
            <label>
              Password{" "}
              {errors.usr_pass && (
                <span className="error-message">{errors.usr_pass}</span>
              )}
            </label>
          </div>

          <div className="row field mgtop1">
            <button
              style={{ width: "50%", marginLeft: 'auto', marginRight: 'auto', display: 'block' }}
              type="button"
              className="btn-action action-button"
              onClick={submit_login}
            >
              Log in
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}