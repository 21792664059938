import React, { useState, useEffect } from "react";
import services from "./apiServices";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Grid,
} from "@mui/material";

const headerCellStyle = {
  fontSize: "0.8rem",
  padding: "1px 18px",
  backgroundColor: "#4a6d8c",
  border: "1px solid gray",
  color: "white",
  textAlign: "center",
};

const cellStyle = {
  fontSize: "0.8rem",
  padding: "1px 18px",
  border: "1px solid gray",
};

function System_Adm_Dash() {
  const [aflttotalcnt, setaflttotalcnt] = useState({ aflt_cnt_res: [], aflt_wise_usr_cnt_res: [], alt_wise_lve_types_res: [], aflt_wise_lve_apl_cnt_res: [], pendng_aprved_aflt_apl_cnt_res: [] });

  const [groupedUsers, setGroupedUsers] = useState({});
  const [groupedLeaveTypes, setGroupedLeaveTypes] = useState({});

  useEffect(() => {
    const fetch = async () => {
      try {
        const res = await services.get_total_aflt_cnt();
        if (res) {
          setaflttotalcnt(res);
          groupUsersByAffiliate(res.aflt_wise_usr_cnt_res);
          groupLeaveTypesByAffiliate(res.alt_wise_lve_types_res);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetch();
  }, []);

  const groupUsersByAffiliate = (users) => {
    console.log("Fetched Users:", users); // Log the users data to check what we are working with

    // Temporarily skip filtering to see all the users
    const allUsers = users; // All users before filtering

    console.log("All Users:", allUsers); // Log all users to verify

    // Group users by their affiliate ID (AFLT_ID_BY_CUST)
    const grouped = allUsers.reduce((acc, user) => {
      const affiliateId = user.AFLT_ID_BY_CUST; // Assuming this is the affiliate ID
      if (!acc[affiliateId]) {
        acc[affiliateId] = [];
      }
      acc[affiliateId].push(user);
      return acc;
    }, {});

    console.log("Grouped Users:", grouped); // Log the grouped users to verify the output
    setGroupedUsers(grouped);
  };

  const groupLeaveTypesByAffiliate = (leaveTypes) => {
    const grouped = leaveTypes.reduce((acc, leaveType) => {
      const affiliateId = leaveType.AFLT_ID_BY_CUST; // Assuming this is the affiliate ID
      if (!acc[affiliateId]) {
        acc[affiliateId] = [];
      }
      acc[affiliateId].push(leaveType);
      return acc;
    }, {});
    setGroupedLeaveTypes(grouped);
  };

  const totalAffiliateCount = aflttotalcnt.aflt_cnt_res.length;
  const totalUserCount = aflttotalcnt.aflt_wise_usr_cnt_res.length;
  console.log("aflttotalcnt", aflttotalcnt)
  return (
    <div className="wrapper_form" style={{ marginBlock: "1%" }}>

      <Grid item xs={12} style={{ paddingRight: "1%", marginLeft: "1%" }}>
        <div className="title" style={{ marginTop: "1%", }}>
          Total Client Count
        </div>
        <TableContainer
          component={Paper}
          style={{
            margin: "5px auto 25px auto",
            overflow: "auto",
            borderRadius: "12px",
            border: "2px solid gray",
            maxHeight: "400px",
            width: "99.8%",
          }}
        >
          <Table style={{ width: "100%" }}>
            <TableHead>
              <TableRow style={{ width: "100%" }}>
                <TableCell style={headerCellStyle}>Sr.No.</TableCell>
                <TableCell style={headerCellStyle}>Client ID</TableCell>
                <TableCell style={headerCellStyle}>Organization Unique identification</TableCell>
                <TableCell style={headerCellStyle}>Organization Short Name</TableCell>
                <TableCell style={headerCellStyle}>Client Name</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {aflttotalcnt.aflt_cnt_res.map((afltcntdt, index) => (
                <TableRow key={afltcntdt.leaveTypeId}>
                  <TableCell align="center" style={cellStyle}>
                    {index + 1}
                  </TableCell>
                  <TableCell align="center" style={cellStyle}>
                    {afltcntdt.AFLT_KY}
                  </TableCell>
                  <TableCell align="center" style={cellStyle}>
                    {afltcntdt.AFLT_ID_BY_CUST}
                  </TableCell>
                  <TableCell align="center" style={cellStyle}>
                    {afltcntdt.AFLT_SHORT_NAME}
                  </TableCell>
                  <TableCell align="center" style={cellStyle}>
                    {afltcntdt.AFLT_NAME}
                  </TableCell>
                </TableRow>
              ))}
              {aflttotalcnt.aflt_cnt_res.length < 2 &&
                Array.from({ length: 2 - aflttotalcnt.aflt_cnt_res.length }).map((_, index) => (
                  <TableRow key={`empty-row-manager-${index}`}>
                    <TableCell align="center" style={cellStyle}>
                      &nbsp;
                    </TableCell>
                    <TableCell align="center" style={cellStyle}>
                      &nbsp;
                    </TableCell>
                    <TableCell align="center" style={cellStyle}>
                      &nbsp;
                    </TableCell>
                    <TableCell align="center" style={cellStyle}>
                      &nbsp;
                    </TableCell>
                    <TableCell align="center" style={cellStyle}>
                      &nbsp;
                    </TableCell>
                  </TableRow>
                ))}
              <TableRow>
                <TableCell align="center" style={{ ...cellStyle, fontWeight: "bold" }}>
                  Total Client
                </TableCell>
                <TableCell align="center" style={cellStyle}>
                  &nbsp;
                </TableCell>
                <TableCell align="center" style={cellStyle}>
                  &nbsp;
                </TableCell>
                <TableCell align="center" style={cellStyle}>
                  &nbsp;
                </TableCell>
                <TableCell align="center" style={{ ...cellStyle, fontWeight: "bold" }}>
                  {totalAffiliateCount}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>

      </Grid>

      <hr
        style={{
          border: "1px solid #4a6d8c",
          width: "97%", // Set HR width to 90%
          marginBottom: "1%", // Remove margin
        }}
      />{" "}

      <Grid item xs={12} style={{ paddingRight: "1%", marginLeft: "1%" }}>
        <div className="title" style={{ marginTop: "1%" }}>
          Client Wise Total User Count
        </div>
        <TableContainer
          component={Paper}
          style={{
            margin: "5px auto 25px auto",
            borderRadius: "12px",
            border: "2px solid gray",
            width: "99.8%",

            maxHeight: "400px",
          }}
        >
          <Table style={{ width: "100%" }}>
            <TableHead>
              <TableRow style={{ width: "100%" }}>
                <TableCell style={headerCellStyle}>Sr.No.</TableCell>
                <TableCell style={headerCellStyle}>Client Name</TableCell>
                <TableCell style={headerCellStyle}>Client Admin</TableCell>
                <TableCell style={headerCellStyle}>User Name</TableCell>
                <TableCell style={headerCellStyle}>Post</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {Object.entries(groupedUsers).flatMap(([affiliateId, users], index) => {
                const affiliateRows = users.map((user, userIndex) => (
                  <TableRow key={`${affiliateId}-${userIndex}`} style={{ verticalAlign: "top" }}>
                    {/* Display the Client Info only for the first admin of the group */}
                    {userIndex === 0 && (
                      <>
                        <TableCell rowSpan={users.length} align="center" style={cellStyle}>
                          {index + 1}
                        </TableCell>
                        <TableCell rowSpan={users.length} align="center" style={cellStyle}>
                          {user.AFLT_NAME}
                        </TableCell>
                      </>
                    )}
                    {/* Display the Client Admin and User Name for each user in the affiliate group */}
                    <TableCell align="center" style={cellStyle}>
                      {user['Admin Usr']}
                    </TableCell>
                    <TableCell align="center" style={cellStyle}>
                      {user.USR_DISPLAY_NAME}
                    </TableCell>
                    <TableCell align="center" style={cellStyle}>
                      {user.ATTRIB_NAME}
                    </TableCell>
                  </TableRow>
                ));

                // Add an empty row after all the users for the current affiliate
                affiliateRows.push(
                  <TableRow key={`${affiliateId}-empty-row`} style={{ backgroundColor: '#dddddf' }}>
                    <TableCell colSpan={5} style={cellStyle}>&nbsp;</TableCell>
                  </TableRow>
                );

                return affiliateRows; // Return the rows for this affiliate group
              })}

              {Object.keys(groupedUsers).length < 2 &&
                Array.from({ length: 2 - Object.keys(groupedUsers).length }).map((_, index) => (
                  <TableRow key={`empty-row-${index}`}>
                    <TableCell align="center" style={cellStyle}>
                      &nbsp;
                    </TableCell>
                    <TableCell align="center" style={cellStyle}>
                      &nbsp;
                    </TableCell>
                    <TableCell align="center" style={cellStyle}>
                      &nbsp;
                    </TableCell>
                    <TableCell align="center" style={cellStyle}>
                      &nbsp;
                    </TableCell>
                    <TableCell align="center" style={cellStyle}>
                      &nbsp;
                    </TableCell>
                  </TableRow>
                ))}

              <TableRow>
                <TableCell align="center" style={{ ...cellStyle, fontWeight: "bold" }}>
                  Total Users
                </TableCell>
                <TableCell align="center" style={cellStyle}>
                  &nbsp;
                </TableCell>
                <TableCell align="center" style={cellStyle}>
                  &nbsp;
                </TableCell>
                <TableCell align="center" style={cellStyle}>
                  &nbsp;
                </TableCell>
                <TableCell align="center" style={{ ...cellStyle, fontWeight: "bold" }}>
                  {totalUserCount}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>

      <hr
        style={{
          border: "1px solid #4a6d8c",
          width: "97%", // Set HR width to 90%
          marginBottom: "1%", // Remove margin
        }}
      />{" "}

      <Grid item xs={12} style={{ padding: "1%" }}>
        <div
          className="title"
          style={{ textAlign: "center", fontSize: "1.5rem" }}
        >
          Client Wise Leave Types
        </div>
        <TableContainer
          component={Paper}
          style={{
            margin: "5px auto 25px auto",
            boxShadow: "0px 6px 16px 3px #bababa",
            borderRadius: "12px",
            border: "2px solid gray",
            width: "99.8%",

            maxHeight: "500px",
            overflowY: "auto",
          }}
        >
          <Table style={{ width: "100%" }}>
            <TableHead>
              <TableRow>
                <TableCell style={headerCellStyle}>Sr. No</TableCell>
                <TableCell style={headerCellStyle}>Client Name</TableCell>
                <TableCell style={headerCellStyle}>Organization Short Name</TableCell>
                <TableCell style={headerCellStyle}>Leave Types</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {Object.entries(groupedLeaveTypes).flatMap(
                ([affiliateId, leaveTypes], index) => {
                  const leaveTypeRows = leaveTypes.map((leaveType, leaveIndex) => (
                    <TableRow key={`${affiliateId}-${leaveIndex}`} style={{ verticalAlign: "top" }}>
                      {leaveIndex === 0 && (
                        <>
                          <TableCell rowSpan={leaveTypes.length} align="center" style={cellStyle}>
                            {index + 1}
                          </TableCell>
                          <TableCell rowSpan={leaveTypes.length} align="center" style={cellStyle}>
                            {leaveType.AFLT_NAME}
                          </TableCell>
                          <TableCell rowSpan={leaveTypes.length} align="center" style={cellStyle}>
                            {leaveType.AFLT_SHORT_NAME}
                          </TableCell>
                        </>
                      )}
                      <TableCell align="center" style={cellStyle}>
                        {leaveType.disp_nm}
                      </TableCell>
                    </TableRow>
                  ));

                  // Add empty row after each affiliate's data
                  leaveTypeRows.push(
                    <TableRow key={`${affiliateId}-empty-row`} style={{ backgroundColor: "#dddddf" }}>
                      <TableCell colSpan={4} style={cellStyle}>&nbsp;</TableCell>
                    </TableRow>
                  );

                  return leaveTypeRows;
                }
              )}

              {Object.keys(groupedLeaveTypes).length < 2 &&
                Array.from({ length: 2 - Object.keys(groupedLeaveTypes).length }).map(
                  (_, index) => (
                    <TableRow key={`empty-row-${index}`}>
                      <TableCell align="center" style={cellStyle}>
                        &nbsp;
                      </TableCell>
                      <TableCell align="center" style={cellStyle}>
                        &nbsp;
                      </TableCell>
                      <TableCell align="center" style={cellStyle}>
                        &nbsp;
                      </TableCell>
                      <TableCell align="center" style={cellStyle}>
                        &nbsp;
                      </TableCell>
                    </TableRow>
                  )
                )}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>

      <hr
        style={{
          border: "1px solid #4a6d8c",
          width: "97%", // Set HR width to 90%
          marginBottom: "1%", // Remove margin
        }}
      />{" "}

      <Grid item xs={12} style={{ padding: "1%" }}>
        <div
          className="title"
          style={{ textAlign: "center", fontSize: "1.5rem" }}
        >
          Client Wise Leave Application Count
        </div>
        <TableContainer
          component={Paper}
          style={{
            margin: "5px auto 25px auto",
            boxShadow: "0px 6px 16px 3px #bababa",
            borderRadius: "12px",
            border: "2px solid gray",
            width: "99.8%",

            maxHeight: "500px",
            overflowY: "auto",
          }}
        >
          <Table style={{ width: "100%" }}>
            <TableHead>
              <TableRow>
                <TableCell style={headerCellStyle}>Sr. No</TableCell>
                <TableCell style={headerCellStyle}>Client Name</TableCell>
                <TableCell style={headerCellStyle}>Organization Short Name</TableCell>
                <TableCell style={headerCellStyle}>Leave Type</TableCell>
                <TableCell style={headerCellStyle}>Application Count</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {Object.entries(
                aflttotalcnt.aflt_wise_lve_apl_cnt_res.reduce((acc, leaveApp) => {
                  const key = `${leaveApp.AFLT_ID_BY_CUST}-${leaveApp.AFLT_NAME}-${leaveApp.AFLT_SHORT_NAME}`;
                  if (!acc[key]) {
                    acc[key] = { ...leaveApp, count: 1 };
                  } else {
                    acc[key].count += 1;
                  }
                  return acc;
                }, {})
              ).map(([key, leaveApp], index) => {
                const leaveTypeRows = aflttotalcnt.aflt_wise_lve_apl_cnt_res.filter(
                  (app) =>
                    app.AFLT_ID_BY_CUST === leaveApp.AFLT_ID_BY_CUST &&
                    app.AFLT_NAME === leaveApp.AFLT_NAME &&
                    app.AFLT_SHORT_NAME === leaveApp.AFLT_SHORT_NAME
                );

                const rows = leaveTypeRows.map((leaveType, leaveIndex) => (
                  <TableRow key={`${leaveType.APP_TYP}-${index}`}>
                    {leaveIndex === 0 && (
                      <>
                        <TableCell rowSpan={leaveTypeRows.length} align="center" style={cellStyle}>
                          {index + 1}
                        </TableCell>
                        <TableCell rowSpan={leaveTypeRows.length} align="center" style={cellStyle}>
                          {leaveApp.AFLT_NAME}
                        </TableCell>
                        <TableCell rowSpan={leaveTypeRows.length} align="center" style={cellStyle}>
                          {leaveApp.AFLT_SHORT_NAME}
                        </TableCell>
                      </>
                    )}
                    <TableCell align="center" style={cellStyle}>
                      {leaveType.disp_nm}
                    </TableCell>
                    <TableCell align="center" style={cellStyle}>
                      {leaveType.Application_Count}
                    </TableCell>
                  </TableRow>
                ));

                // Add an empty row after each affiliate's data
                rows.push(
                  <TableRow key={`${leaveApp.AFLT_NAME}-empty-row`} style={{ backgroundColor: "#dddddf" }}>
                    <TableCell colSpan={5} style={cellStyle}>&nbsp;</TableCell>
                  </TableRow>
                );

                return rows;
              })}

              {aflttotalcnt.aflt_wise_lve_apl_cnt_res.length < 2 &&
                Array.from({ length: 2 - aflttotalcnt.aflt_wise_lve_apl_cnt_res.length }).map(
                  (_, index) => (
                    <TableRow key={`empty-row-leave-app-${index}`}>
                      <TableCell align="center" style={cellStyle}>
                        &nbsp;
                      </TableCell>
                      <TableCell align="center" style={cellStyle}>
                        &nbsp;
                      </TableCell>
                      <TableCell align="center" style={cellStyle}>
                        &nbsp;
                      </TableCell>
                      <TableCell align="center" style={cellStyle}>
                        &nbsp;
                      </TableCell>
                      <TableCell align="center" style={cellStyle}>
                        &nbsp;
                      </TableCell>
                    </TableRow>
                  )
                )}

              <TableRow>
                <TableCell align="center" style={{ ...cellStyle, fontWeight: "bold" }}>
                  Total Applications
                </TableCell>
                <TableCell align="center" style={{ ...cellStyle, fontWeight: "bold" }}>
                  &nbsp;
                </TableCell>
                <TableCell align="center" style={{ ...cellStyle, fontWeight: "bold" }}>
                  &nbsp;
                </TableCell>
                <TableCell align="center" style={{ ...cellStyle, fontWeight: "bold" }}>
                  &nbsp;
                </TableCell>
                <TableCell align="center" style={{ ...cellStyle, fontWeight: "bold" }}>
                  {aflttotalcnt.aflt_wise_lve_apl_cnt_res.reduce(
                    (total, leaveApp) => total + leaveApp.Application_Count,
                    0
                  )}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>

      <hr
        style={{
          border: "1px solid #4a6d8c",
          width: "97%", // Set HR width to 90%
          marginBottom: "1%", // Remove margin
        }}
      />{" "}

      <Grid item xs={12} style={{ padding: "1%" }}>
        <div
          className="title"
          style={{ textAlign: "center", fontSize: "1.5rem" }}
        >
          Client Wise Approve and Pending Applications Count
        </div>
        <TableContainer
          component={Paper}
          style={{
            margin: "5px auto 20px auto",
            boxShadow: "0px 6px 16px 3px #bababa",
            borderRadius: "12px",
            border: "2px solid gray",
            width: "99.8%",

            maxHeight: "500px",
            overflowY: "auto",
          }}
        >
          <Table style={{ width: "100%" }}>
            <TableHead>
              <TableRow>
                <TableCell style={headerCellStyle}>Sr. No</TableCell>
                <TableCell style={headerCellStyle}>Client Name</TableCell>
                <TableCell style={headerCellStyle}>Organization Short Name</TableCell>
                <TableCell style={headerCellStyle}>Leave Type</TableCell>
                <TableCell style={headerCellStyle}>Leave Status</TableCell>
                <TableCell style={headerCellStyle}>Application Count</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {Object.entries(
                aflttotalcnt.pendng_aprved_aflt_apl_cnt_res.reduce((acc, leaveApp) => {
                  const key = `${leaveApp.AFLT_NAME}-${leaveApp.AFLT_SHORT_NAME}`;
                  if (!acc[key]) {
                    acc[key] = {
                      ...leaveApp,
                      leaveTypes: [
                        {
                          Leave_Type: leaveApp.Leave_Type,
                          APP_LVE_STTS: leaveApp.APP_LVE_STTS,
                          Application_Count: leaveApp.Application_Count,
                        },
                      ],
                    };
                  } else {
                    acc[key].leaveTypes.push({
                      Leave_Type: leaveApp.Leave_Type,
                      APP_LVE_STTS: leaveApp.APP_LVE_STTS,
                      Application_Count: leaveApp.Application_Count,
                    });
                  }
                  return acc;
                }, {})
              ).map(([key, leaveApp], index) => {
                const leaveTypeRows = leaveApp.leaveTypes.map((leaveType, leaveIndex) => (
                  <TableRow
                    key={`${leaveApp.AFLT_NAME}-${leaveType.Leave_Type}-${leaveType.APP_LVE_STTS}`}
                  >
                    {leaveIndex === 0 && (
                      <>
                        <TableCell rowSpan={leaveApp.leaveTypes.length} align="center" style={cellStyle}>
                          {index + 1}
                        </TableCell>
                        <TableCell rowSpan={leaveApp.leaveTypes.length} align="center" style={cellStyle}>
                          {leaveApp.AFLT_NAME}
                        </TableCell>
                        <TableCell rowSpan={leaveApp.leaveTypes.length} align="center" style={cellStyle}>
                          {leaveApp.AFLT_SHORT_NAME}
                        </TableCell>
                      </>
                    )}
                    <TableCell align="center" style={cellStyle}>
                      {leaveType.Leave_Type}
                    </TableCell>
                    <TableCell align="center" style={cellStyle}>
                      {leaveType.APP_LVE_STTS}
                    </TableCell>
                    <TableCell align="center" style={cellStyle}>
                      {leaveType.Application_Count}
                    </TableCell>
                  </TableRow>
                ));

                // Add an empty row after each affiliate's data
                leaveTypeRows.push(
                  <TableRow key={`${leaveApp.AFLT_NAME}-empty-row`} style={{ backgroundColor: "#dddddf" }}>
                    <TableCell colSpan={6} style={cellStyle}>&nbsp;</TableCell>
                  </TableRow>
                );

                return leaveTypeRows;
              })}

              <TableRow>
                <TableCell align="center" style={{ ...cellStyle, fontWeight: "bold" }}>
                  Total Applications
                </TableCell>
                <TableCell align="center" style={{ ...cellStyle, fontWeight: "bold" }}>
                  &nbsp;
                </TableCell>
                <TableCell align="center" style={{ ...cellStyle, fontWeight: "bold" }}>
                  &nbsp;
                </TableCell>
                <TableCell align="center" style={{ ...cellStyle, fontWeight: "bold" }}>
                  &nbsp;
                </TableCell>
                <TableCell align="center" style={{ ...cellStyle, fontWeight: "bold" }}>
                  &nbsp;
                </TableCell>
                <TableCell align="center" style={{ ...cellStyle, fontWeight: "bold" }}>
                  {Object.entries(
                    aflttotalcnt.pendng_aprved_aflt_apl_cnt_res.reduce((acc, leaveApp) => {
                      const key = `${leaveApp.AFLT_NAME}-${leaveApp.AFLT_SHORT_NAME}`;
                      if (!acc[key]) {
                        acc[key] = { totalCount: 0 };
                      }
                      acc[key].totalCount += leaveApp.Application_Count;
                      return acc;
                    }, {})
                  ).reduce((total, [key, value]) => total + value.totalCount, 0)}
                </TableCell>
              </TableRow>

              {aflttotalcnt.pendng_aprved_aflt_apl_cnt_res.length < 2 &&
                Array.from({ length: 2 - aflttotalcnt.pendng_aprved_aflt_apl_cnt_res.length }).map(
                  (_, index) => (
                    <TableRow key={`empty-row-leave-app-${index}`}>
                      <TableCell align="center" style={cellStyle}>
                        &nbsp;
                      </TableCell>
                      <TableCell align="center" style={cellStyle}>
                        &nbsp;
                      </TableCell>
                      <TableCell align="center" style={cellStyle}>
                        &nbsp;
                      </TableCell>
                      <TableCell align="center" style={cellStyle}>
                        &nbsp;
                      </TableCell>
                      <TableCell align="center" style={cellStyle}>
                        &nbsp;
                      </TableCell>
                    </TableRow>
                  )
                )}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
      <hr
        style={{
          border: "1px solid #4a6d8c",
          width: "97%", // Set HR width to 90%
          marginBottom: "1%", // Remove margin
        }}
      />{" "}
    </div >
  );
}

export default System_Adm_Dash;