import React, { useState, useEffect } from "react";
import services from "./apiServices";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Grid,
} from "@mui/material";
import moment from "moment";

export default function Adm_Projected_On_Lve_Emp_List() {
  const [data, Setdata] = useState({ projected_lve_emp_list_res: [] });
  const [managerWiseEmployees, setManagerWiseEmployees] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");

  useEffect(() => {
    const projcted_on_lve_emp = async () => {
      try {
        const res = await services.get_projcted_on_lve_emp();
        if (res) {
          Setdata(res);
          groupEmployeesByManager(res.projected_lve_emp_list_res); // Grouping data by manager
        } else {
          console.error("Unexpected data format:", res);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    projcted_on_lve_emp();
  }, []);

  // Grouping the employees by Manager
  function groupEmployeesByManager(data) {
    const grouped = data.reduce((acc, emp) => {
      if (!acc[emp.Manager]) {
        acc[emp.Manager] = [];
      }
      acc[emp.Manager].push(emp);
      return acc;
    }, {});

    // Converting to an array of managers and their employees
    const managerData = Object.keys(grouped).map(manager => ({
      managerName: manager,
      employees: grouped[manager],
    }));

    setManagerWiseEmployees(managerData); // Setting the grouped data
    setFilteredData(managerData); // Initially, no filter, so show all data
  }

  // Function to format date
  function formatDate(dateString) {
    return moment(dateString).format('DD/MMM/YYYY').replace(/\/([a-z])/g, (match) => '/' + match[1].toUpperCase());
  }

  // Handle filter
  const handleFilter = () => {
    if (!fromDate || !toDate) {
      alert("Please select both from and to dates.");
      return;
    }

    const filtered = managerWiseEmployees.map(manager => {
      const filteredEmployees = manager.employees.filter(emp => {
        const leaveFromDate = moment(emp.APP_LVE_FRM_DT);
        const leaveToDate = moment(emp.APP_LVE_TO_DT);
        return leaveFromDate.isBetween(fromDate, toDate, undefined, '[]') || leaveToDate.isBetween(fromDate, toDate, undefined, '[]');
      });

      return {
        managerName: manager.managerName,
        employees: filteredEmployees,
      };
    }).filter(manager => manager.employees.length > 0); // Remove managers with no filtered employees

    if (filtered.length === 0) {
      // If no data matches the filter, set a "No data" placeholder row
      setFilteredData([{ managerName: "No Data", employees: [] }]);
    } else {
      setFilteredData(filtered); // Update the filtered data
    }
  };

  // Handle date input changes
  const handleDateChange = (e, setter) => {
    setter(e.target.value);
  };

  return (
    <div className="wrapper_form">
      <Grid item xs={12} style={{ padding: "1%" }}>
        <div
          className="title"
          style={{
            textAlign: "center",
            fontSize: "1.5rem",
          }}
        >
          Projected Employees On Leave
        </div>

        {/* Date Range Filter - Custom HTML Inputs */}
        <div
          style={{
            textAlign: "center",
            marginBottom: "10px",
            marginTop: "20px",
          }}
        >
          <label htmlFor="fromDate" style={{ marginRight: "10px" }}>
            From Date:
          </label>
          <input
            type="date"
            id="fromDate"
            value={fromDate}
            onChange={(e) => handleDateChange(e, setFromDate)}
            style={{
              marginRight: "20px",
              padding: "5px",
              borderRadius: "5px",
              border: "1px solid #ccc",
              width: "17%",
            }}
          />

          <label htmlFor="toDate" style={{ marginRight: "10px" }}>
            To Date:
          </label>
          <input
            type="date"
            id="toDate"
            value={toDate}
            onChange={(e) => handleDateChange(e, setToDate)}
            style={{
              marginRight: "20px",
              padding: "5px",
              borderRadius: "5px",
              border: "1px solid #ccc",
              width: "17%",
            }}
          />

          <button
            onClick={handleFilter}
            className="btn-action action-button"
            style={{ width: "8%", margin: "1px", padding: "3.7px 12px" }}
          >
            Show
          </button>
        </div>

        <TableContainer
          component={Paper}
          style={{
            margin: "5px auto 25px auto",
            boxShadow: "0px 6px 16px 3px #bababa",
            borderRadius: "20px",
            border: "2px solid gray",
            maxHeight: "500px",
            overflowY: "auto",
          }}
        >
          <Table style={{ width: "100%" }}>
            <TableHead>
              <TableRow>
                <TableCell style={headerCellStyle}>Sr. No</TableCell>
                <TableCell style={headerCellStyle}>Manager</TableCell>
                <TableCell style={headerCellStyle}>Employee Name</TableCell>
                <TableCell style={headerCellStyle}>Leave Types</TableCell>
                <TableCell style={headerCellStyle}>Application Date</TableCell>
                <TableCell style={headerCellStyle}>From Date</TableCell>
                <TableCell style={headerCellStyle}>To Date</TableCell>
                <TableCell style={headerCellStyle}>Duration (Days)</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredData.length > 0 ? (
                filteredData.map((manager, managerIndex) => {
                  const employees =
                    manager.employees.length > 0
                      ? manager.employees
                      : [
                        {
                          name: "No employees",
                          appDate: "",
                          leaveType: "",
                        },
                      ];
                  return employees.map((emp, empIndex) => (
                    <TableRow key={emp.APP_ID || managerIndex}>
                      {empIndex === 0 && (
                        <TableCell rowSpan={employees.length} align="center" style={cellStyle}>
                          {managerIndex + 1}
                        </TableCell>
                      )}
                      {empIndex === 0 && (
                        <TableCell rowSpan={employees.length} align="center" style={cellStyle}>
                          {manager.managerName}
                        </TableCell>
                      )}
                      <TableCell align="center" style={cellStyle}>
                        {emp.USR_DISPLAY_NAME}
                      </TableCell>
                      <TableCell align="center" style={cellStyle}>
                        {emp.disp_nm}
                      </TableCell>
                      <TableCell align="center" style={cellStyle}>
                        {emp.APP_DT ? formatDate(emp.APP_DT) : "--"}
                      </TableCell>
                      <TableCell align="center" style={cellStyle}>
                        {emp.APP_LVE_FRM_DT ? formatDate(emp.APP_LVE_FRM_DT) : "--"}
                      </TableCell>
                      <TableCell align="center" style={cellStyle}>
                        {emp.APP_LVE_TO_DT ? formatDate(emp.APP_LVE_TO_DT) : "--"}
                      </TableCell>
                      <TableCell align="center" style={cellStyle}>
                        {emp.APP_LVE_DES ? emp.APP_LVE_DES : 0}
                      </TableCell>
                    </TableRow>
                  ));
                })
              ) : (
                <TableRow>
                  <TableCell colSpan={8} style={{ textAlign: "center", padding: "20px" }}>
                    No Data Available
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </div>
  );
}

const headerCellStyle = {
  fontSize: "0.8rem",
  padding: "1px",
  backgroundColor: "#4a6d8c",
  color: "white",
  textAlign: "center",
  border: "1px solid gray",
};

const cellStyle = {
  fontSize: "0.8rem",
  padding: "2px",
  border: "1px solid gray",
};
