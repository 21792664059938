import React, { useState, useEffect } from "react";
import services from "./apiServices";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Grid,
} from "@mui/material";
import Button from '@mui/material/Button'; // Button component
import VisibilityIcon from '@mui/icons-material/Visibility';
import EditIcon from '@mui/icons-material/Edit';
import CancelIcon from '@mui/icons-material/Cancel'
const headerCellStyle = {
  fontSize: "0.8rem",
  padding: "1px 18px",
  backgroundColor: "#4a6d8c",
  border: "1px solid gray",
  color: "white",
  textAlign: "center",
};

const cellStyle = {
  fontSize: "0.8rem",
  padding: "1px 18px",
  border: "1px solid gray",
};
function Sys_Usr_Lve_Config() {
  const [syslveinfo, setSyslveinfo] = useState({ result: [], latestsyslvetypesres: [], syslvetypesres: [] });
  const [mainTypes, setMainTypes] = useState([]); // To store main types (paid, unpaid)
  const [selectedOption, setSelectedOption] = useState('');
  const [editingHoliday, setEditingHoliday] = useState(null); // Track the holiday being edited
  // Create a single state to hold all form data
  const [formData, setFormData] = useState({
    selectedmaintyp: '',
    otherMainTypedescription: '',
    selectedsubtyp: '',
    otherMainType: '',
    otherSubType: '',
    leaveTypeCode: '',
    description: '',
  });
  const [formData1, setFormData1] = useState({
    selectedmaintyp: '',
    otherMainTypedescription: '',
    selectedsubtyp: '',
    otherMainType: '',
    otherSubType: '',
    leaveTypeCode: '',
    description: '',
  });
  const [filteredData, setFilteredData] = useState([]);
  const [detail, setDetails] = useState();
  const [isEditMode, setIsEditMode] = useState(false);
  console.log("detail", detail)
  useEffect(() => {
    const fetch = async () => {
      try {
        const res = await services.getlvetypdata();

        if (res) {
          setSyslveinfo(res);  // Storing the entire response
          // Extract the parent types (LVE_TYP) from the data
          const mainTypes = Array.from(new Set(res.result
            .filter(item => item.ATTRIB_GRP_TYP === 'LVE_TYP')  // Filter for parent types
            .map(item => item.ATTRIB_CD) // Get the ATTRIB_CD for the main type (like 'paid', 'unpaid')
          ));
          setMainTypes(mainTypes); // Update the state with the distinct main types
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    fetch();
  }, []);
  const handleOptionChange = (e) => {
    setSelectedOption(e.target.value);
    if (e.target.value === "editHoliday") {
      // Reset editing state if Edit is selected
      setEditingHoliday(null);
    }

  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    const updatedFormData = { ...formData };
    if (formData.selectedsubtyp === 'other') {
      updatedFormData.selectedsubtyp = formData.otherSubType;
    }
    if (formData.selectedmaintyp === 'paid' && formData.selectedsubtyp !== 'other') {
      updatedFormData.selectedmaintyp = formData.selectedsubtyp;
      updatedFormData.selectedsubtyp = formData.leaveTypeCode;
    }

    if (formData.selectedmaintyp === 'paid' && formData.selectedsubtyp === 'other') {
      updatedFormData.selectedsubtyp = formData.otherSubType;

    }
    console.log('Form Data on Submit:', updatedFormData);

    try {
      const res = await services.addlvesysusr(updatedFormData)
      if (res) {
        alert("Leave Added Successfully")
        setFormData({
          selectedmaintyp: '',
          selectedsubtyp: '',
          otherMainType: '',
          otherSubType: '',
          leaveTypeCode: '',
          description: '',
          otherMainTypedescription: '',
        })
      }
    } catch (error) {
      console.error(`Error for fetching the data ${error}`)
    }
  };

  // Handle main leave type selection and update formData state
  const handlemainlvetyp = (event) => {
    const selectedValue = event.target.value;
    setFormData((prevState) => ({
      ...prevState,
      selectedmaintyp: selectedValue,
      otherMainType: '', // Clear the other main type field if a parent type is selected
      selectedsubtyp: '', // Clear the selected subtype when root type changes
      leaveTypeCode: '', // Clear leave type code on main type change
      description: '', // Clear description on main type change
    }));
  };

  const handlemainlvetypedit = (event) => {
    const selectedValue = event.target.value;
    setFormData1((prevState) => ({
      ...prevState,
      selectedmaintyp: selectedValue,
      otherMainType: '', // Clear the other main type field if a parent type is selected
      selectedsubtyp: '', // Clear the selected subtype when root type changes
      leaveTypeCode: '', // Clear leave type code on main type change
      description: '', // Clear description on main type change
    }));

    // Filter the leave types based on the selected main type
    const filteredLeaveTypes = syslveinfo.syslvetypesres.filter(item => {
      if (item.parentyp === 'LVE_TYP') {
        return item.parentcode === selectedValue; // Compare with parentcode
      } else {
        return item.parentyp === selectedValue; // Compare with parentyp
      }
    });

    setFilteredData(filteredLeaveTypes);
  };
  // Handle sub leave type selection and update formData state
  const handlesublvetyp = (event) => {
    const selectedValue = event.target.value;
    setFormData((prevState) => ({
      ...prevState,
      selectedsubtyp: selectedValue,
      otherSubType: '', // Clear the other sub type field if a subtype is selected
      leaveTypeCode: '', // Clear leave type code on sub-type change
      description: '', // Clear description on sub-type change

    }));
    // Function to filter data based on selected main leave type and IS_DEFAULT === 1
    filterData(selectedValue)
  };
  const filterData = (selectedType) => {
    // Filter syslvetypesres based on the selected type and IS_DEFAULT === "1"
    const filtered = syslveinfo.syslvetypesres.filter(item =>
      item.ATTRIB_GRP_TYP === selectedType && item.IS_DEFAULT === "1"
    );
    setFilteredData(filtered);
  };
  // Handle input changes for any field and update formData state
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value, // Dynamically set the field value based on name
    }));
  };
  const handleView = (afltcntdt) => {
    console.log("afltcntdt", afltcntdt);
    setDetails(afltcntdt);
    setIsEditMode(false); // Set to view mode
  };

  const handleDelete = async (afltcntdt) => {
    try {
      const res = await services.deletecustomlve(afltcntdt);
      if (res) {
        alert("Client Deleted Successfully");
        const fetchUpdatedData = async () => {
          try {
            const updatedRes = await services.getlvetypdata();
            if (updatedRes) {
              setSyslveinfo(updatedRes); // Update the state with the new data
              const updatedFilteredData = updatedRes.syslvetypesres.filter(item => {
                if (item.parentyp === 'LVE_TYP') {
                  return item.parentcode === formData1.selectedmaintyp; // Adjust this condition as needed
                } else {
                  return item.parentyp === formData1.selectedmaintyp
                }
              });
              setFilteredData(updatedFilteredData); // Set the filtered data to reflect the updated data
              setDetails(null); // Clear the details
              setIsEditMode(false); // Reset to view mode
            }
          } catch (error) {
            console.error(`Error fetching updated data: ${error}`);
          }
        };
        fetchUpdatedData();
      }
    } catch (error) {
      console.error(`Error deleting the affiliate data ${error}`);
    }
  };

  const cancelbutton = () => {
    setDetails(null);
    setIsEditMode(false); // Reset to view mode
  };
  const handleEdit1 = (afltcntdt) => {
    setDetails(afltcntdt);
    setIsEditMode(true); // Set to edit mode
  };

  const notchnagble = () => {
    alert("You Can't Edit the Parent Leave Type Code")
  }
  const handleupdte = async (e) => {
    e.preventDefault();
    try {
      const res = await services.updtsyslve(detail);
      if (res) {
        alert("Leave Updated Successfully!");
        const fetchUpdatedData = async () => {
          try {
            const updatedRes = await services.getlvetypdata();
            if (updatedRes) {
              setSyslveinfo(updatedRes); // Update the state with the new data
              const updatedFilteredData = updatedRes.syslvetypesres.filter(item => {
                if (item.parentyp === 'LVE_TYP') {
                  return item.parentcode === detail.ATTRIB_GRP_TYP; // Adjust this condition as needed
                } else {
                  return item.parentyp === formData1.selectedmaintyp
                }
              });
              setFilteredData(updatedFilteredData); // Set the filtered data to reflect the updated data
              setDetails(null); // Clear the details
              setIsEditMode(false); // Reset to view mode
            }
          } catch (error) {
            console.error(`Error fetching updated data: ${error}`);
          }
        };
        fetchUpdatedData();
      }
    } catch (error) {
      console.error(`Error fetching data: ${error}`);
    }
  };
  return (
    <div className='wrapper_form'>
      <div className="radio-row" style={{ marginBottom: "5px" }}>
        <label>
          <input
            type="radio"
            name="addOption"
            value="insertlve"
            checked={selectedOption === "insertlve"}
            onChange={handleOptionChange}
            style={{ width: "2%" }}
          />
          Add Leaves
        </label> <hr className="hr1" />

        {selectedOption === "insertlve" && (
          <div className="radio-content">
            <div className="title">Add Leave Types</div>
            <form onSubmit={handleSubmit} method="Post" className="frm_wd">
              <div className="row" style={{ marginBottom: '1px' }}>
                <div className="column drp_ln_hg">
                  <label htmlFor="maintype">Select Main Type : </label>
                  <select
                    id="maintype"
                    name="selectedmaintyp"
                    value={formData.selectedmaintyp}
                    onChange={handlemainlvetyp}
                    style={{ borderRadius: '8px', width: '100%' }}
                  >
                    <option value="">Select Main Type</option>
                    {mainTypes.map((mainType) => (
                      <option key={mainType} value={mainType}>
                        {mainType.charAt(0).toUpperCase() + mainType.slice(1)} {/* Capitalizing the first letter */}
                      </option>
                    ))}
                  </select>

                </div>

                <div className="column drp_ln_hg">
                  <label htmlFor="subtype">Select Sub Leave Type : </label>
                  <select
                    id="subtype"
                    name="selectedsubtyp"
                    value={formData.selectedsubtyp}
                    onChange={handlesublvetyp}
                    style={{ borderRadius: '8px', width: '100%' }}
                    disabled={!formData.selectedmaintyp} // Disable if no main type is selected
                  >
                    <option value="">Select Sub Type</option>
                    {formData.selectedmaintyp === 'unpaid' ? (
                      <option value="other">Other</option>
                    ) : (
                      <>

                        {syslveinfo.result
                          .filter(item => item.ATTRIB_GRP_TYP === formData.selectedmaintyp)
                          .map((item) => (
                            <option key={item.ATTRIB_CD} value={item.ATTRIB_CD}>
                              {item.ATTRIB_NAME}
                            </option>
                          ))
                        }
                        <option value="other">Other</option>
                      </>
                    )}
                  </select>
                  {formData.selectedsubtyp === 'other' && (
                    <div className="column" style={{ marginTop: '10px', padding: "0" }}>
                      <label htmlFor="otherSubType">Custom Sub Leave Type Code:</label>
                      <input
                        type="text"
                        name="otherSubType"
                        placeholder="Enter Custom Sub Leave Type Code"
                        value={formData.otherSubType}
                        onChange={handleInputChange}
                        style={{ width: '100%' }}
                      />
                    </div>
                  )}
                </div>
              </div>
              {(formData.selectedsubtyp && formData.selectedsubtyp !== 'other') && (
                <div className="row" style={{ marginBottom: '1px', marginTop: "10px" }}>
                  <div className="column drp_ln_hg">
                    <label htmlFor="leaveTypeCode">Leave Type Code:</label>
                    <input
                      type="text"
                      name="leaveTypeCode"
                      placeholder="Enter Leave Code"
                      value={formData.leaveTypeCode}
                      onChange={handleInputChange}
                      style={{ width: '100%' }}
                    />
                  </div>
                  <div className="column drp_ln_hg">
                    <label htmlFor="description">Description:</label>
                    <input
                      type="text"
                      name="description"
                      placeholder="Enter Leave Description"
                      value={formData.description}
                      onChange={handleInputChange}
                      style={{ width: '100%' }}
                    />
                  </div>
                </div>
              )}

              {formData.selectedsubtyp === 'other' && (
                <div className="column" style={{ padding: "0" }}>
                  <label htmlFor="description">Sub Leave Type Description:</label>
                  <input
                    type="text"
                    name="description"
                    placeholder="Enter Sub Leave Type Description"
                    value={formData.description}
                    onChange={handleInputChange}
                    style={{ marginBottom: '10px', width: '100%' }}
                  />
                </div>
              )}

              <hr className="hr_dt" style={{ marginTop: "2%" }} />
              <div style={{ display: "flex", justifyContent: "flex-end" }}>
                <button
                  type="submit"
                  className="btn-action action-button"
                  style={{ margin: "1px", width: "20%" }}
                >
                  Submit
                </button>
              </div>
              <hr className="hr_dt" />

            </form>
          </div>
        )}
      </div>
      <div className="radio-row" style={{ marginBottom: "5px" }}>
        <label>
          <input
            type="radio"
            name="addOption"
            value="editlves"
            checked={selectedOption === "editlves"}
            onChange={handleOptionChange}
            style={{ width: "2%" }}
          />
          Edit Leave
        </label> <hr className="hr1" />
        {selectedOption === "editlves" && (
          <div className="radio-content">
            <div className="title">Edit Leave Types</div>
            <div className="row frm_wd" style={{ border: "none" }}>
              <div className="column drp_ln_hg">
                <label htmlFor="maintype">Select Main Type : </label>
                <select
                  id="maintype"
                  name="selectedmaintyp"
                  value={formData1.selectedmaintyp}
                  onChange={handlemainlvetypedit}
                  style={{ borderRadius: '8px', width: '100%' }}
                >
                  <option value="">Select Main Type</option>
                  {mainTypes.map((mainType) => (
                    <option key={mainType} value={mainType}>
                      {mainType.charAt(0).toUpperCase() + mainType.slice(1)} {/* Capitalizing the first letter */}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            {filteredData.length > 0 && (
              <hr className="hr1" style={{ marginTop: "1%", marginBottom: "1%", width: "100%" }} />
            )}
            {detail ? (
              isEditMode ? (
                <form style={{ backgroundColor: "#fff" }}>
                  <div className=" form-container">
                    <p
                      style={{
                        fontSize: "25px",
                        paddingTop: ".1px",
                        color: "black",
                      }}
                    >
                      Leave information
                    </p>
                    <hr className="hr_dt" style={{ marginBottom: "19px" }} />

                    <div className="row" style={{ marginBottom: "16px" }}>
                      <div className="column">
                        <div className="field">
                          <input
                            style={{ cursor: "default" }}
                            type="text"
                            value={detail.ATTRIB_GRP_TYP || " "}
                            onChange={notchnagble}
                          />
                          <label>Parent Leave Type Code</label>
                        </div>
                      </div>
                      <div className="column">
                        <div className="field">
                          <input
                            type="text"
                            value={detail.ATTRIB_CD || " "}
                            onChange={(e) =>
                              setDetails({ ...detail, ATTRIB_CD: e.target.value })
                            }
                          />
                          <label>
                            Leave Type Code{" "}

                          </label>
                        </div>
                      </div>

                      <div className="column">
                        <div className="field">
                          <input
                            type="text"
                            value={detail.ATTRIB_NAME || " "}
                            onChange={(e) =>
                              setDetails({ ...detail, ATTRIB_NAME: e.target.value })
                            }

                          />

                          <label>
                            Leave Description{" "}

                          </label>
                        </div>
                      </div>
                    </div>
                    <hr className="hr_dt" />
                    <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                      <button
                        type="button"
                        className="btn-action action-button"
                        onClick={cancelbutton}
                        style={{ margin: "1px", width: "20%" }}
                      >
                        Cancel
                      </button>

                      <button
                        style={{ margin: "1px", width: "20%" }}
                        type="submit"
                        className="btn-action action-button"
                        disabled={!isEditMode}
                        onClick={handleupdte}
                      >
                        Save
                      </button>
                    </div>

                    <hr className="hr_dt" style={{ marginTop: "5px" }} />
                  </div>
                </form>
              ) : (
                <form style={{ backgroundColor: "#fff" }}>
                  <div className=" form-container">

                    <p
                      style={{
                        fontSize: "25px",
                        paddingTop: ".1px",
                        color: "black",
                      }}
                    >
                      Leave information
                    </p>

                    <hr className="hr_dt" style={{ marginBottom: "19px" }} />

                    <div className="row" style={{ marginBottom: "16px" }}>
                      <div className="column">
                        <div className="field">
                          <input
                            className='cursr_view'
                            value={detail.ATTRIB_GRP_TYP || "No Data"}
                          />
                          <label>Parent Leave Type Code</label>
                        </div>
                      </div>
                      <div className="column">
                        <div className="field">
                          <input
                            className='cursr_view'
                            value={detail.ATTRIB_CD || "No Data"}
                          />
                          <label>
                            Leave Type Code{" "}
                          </label>
                        </div>
                      </div>
                      <div className="column">
                        <div className="field">
                          <input
                            className='cursr_view'
                            value={detail.ATTRIB_NAME || "No Data"}
                          />
                          <label>
                            Leave Description{" "}
                          </label>
                        </div>
                      </div>
                    </div>
                    <hr className="hr_dt" />

                    <div style={{ display: "flex", justifyContent: "flex-end" }}>
                      <button
                        type="button"
                        className="btn-action action-button"
                        onClick={cancelbutton}
                        style={{ margin: "1px", width: "20%" }}
                      >
                        Cancel
                      </button>
                    </div>
                    <hr className="hr_dt" />
                  </div>
                </form>
              )
            ) : (
              filteredData.length > 0 && (
                <Grid item xs={12} style={{ paddingRight: "1%", marginLeft: "1%", marginTop: "1%" }}>
                  <div className="title" style={{ marginTop: "1%", lineHeight: "12px", fontSize: "18px" }}>
                    {`Changed ${formData1.selectedmaintyp === "paid" ? "Paid" : "Unpaid"} Leave Types`}
                  </div>

                  <TableContainer
                    component={Paper}
                    style={{
                      margin: "5px auto 25px auto",
                      overflow: "auto",
                      borderRadius: "12px",
                      border: "2px solid gray",
                      maxHeight: "400px",
                      width: "99.8%",
                    }}
                  >
                    <Table style={{ width: "100%" }}>
                      <TableHead>
                        <TableRow style={{ width: "100%" }}>
                          <TableCell style={headerCellStyle}>Sr. No</TableCell>
                          <TableCell style={headerCellStyle}>Parent Leave Type</TableCell>
                          <TableCell style={headerCellStyle}>Leave Type Code</TableCell>
                          <TableCell style={headerCellStyle}>Description</TableCell>
                          <TableCell style={headerCellStyle}>Action</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {filteredData.map((afltcntdt, index) => (
                          <TableRow key={afltcntdt.leaveTypeId}>
                            <TableCell align="center" style={cellStyle}>
                              {index + 1}
                            </TableCell>
                            <TableCell align="center" style={cellStyle}>
                              {afltcntdt.parentyp === 'LVE_TYP' ? "Unpaid" : "Paid"}
                            </TableCell>
                            <TableCell align="center" style={cellStyle}>
                              {afltcntdt.ATTRIB_CD}
                            </TableCell>
                            <TableCell align="center" style={cellStyle}>
                              {afltcntdt.ATTRIB_NAME}
                            </TableCell>
                            <TableCell style={{ padding: "4px", display: "flex", justifyContent: "center", height: "12px", borderBottom: ".1px solid gray" }}>
                              <Button title='View' style={{ minWidth: "auto", padding: "1" }} onClick={() => handleView(afltcntdt)} size="small">
                                <VisibilityIcon style={{ color: "#4a6d8c" }} fontSize="small" />
                              </Button>
                              <Button title='Update' style={{ minWidth: "auto", padding: "1" }} onClick={() => handleEdit1(afltcntdt)} size="small">
                                <EditIcon style={{ color: "#4a6d8c" }} fontSize="small" />
                              </Button>
                              <Button title='Delete' style={{ minWidth: "auto", padding: "1" }} onClick={() => handleDelete(afltcntdt)} size="small">
                                <CancelIcon style={{ color: "#4a6d8c" }} fontSize="small" />
                              </Button>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>

                </Grid>
              )
            )}
          </div>
        )}
      </div>
    </div >
  );
}

export default Sys_Usr_Lve_Config;
