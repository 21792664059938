import React, { useState, useEffect } from "react";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import services from "./apiServices";
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Button,
    Grid,
    Typography,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    IconButton,
    TextField
} from "@mui/material";
import EditIcon from '@mui/icons-material/Edit';
import moment from 'moment';

function Adm_Historical_Annocement() {

    const [ancement, setAncement] = useState({ histrical_annocement_result: [] })

    useEffect(() => {
        const fetch_annocement_lst = async () => {
            try {
                const annocement_lst = await services.get_annocement_lst();
                if (annocement_lst) {
                    setAncement(annocement_lst);
                } else {
                    console.error("Unexpected data format:", annocement_lst);
                }
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };
        fetch_annocement_lst();
    }, []);
    const formatDate = (dateString) => {
        if (!dateString) return "N/A"; // Return a default value if dateString is undefined or null
        const date = new Date(dateString);

        const day = String(date.getDate()).padStart(2, '0'); // Ensure 2 digits for day
        const month = date.toLocaleString('en-GB', { month: 'short' }); // Get short month name
        const year = date.getFullYear(); // Get full year

        return `${day}-${month}-${year}`;
    };


    console.log("ancement list", ancement)
    return (
        <div className="wrapper_form">
            <div className="title" style={{ marginTop: "2%" }}> Historical Announcements</div>
            <Grid item xs={6} style={{ paddingLeft: "2%", paddingRight: "1%" }}>
                <TableContainer
                    component={Paper}
                    style={{
                        margin: "5px auto 25px auto",
                        // maxWidth: "50%",
                        overflow: "hidden", // Prevents content overflow
                        boxShadow: "0px 6px 16px 3px #bababa", // Shadow only on the top and bottom
                        borderRadius: "20px", // Rounded corners for the container
                        border: "2px solid gray",

                    }}
                >
                    <Table style={{ width: "100%", }}>
                        <TableHead>
                            <TableRow style={{ width: "100%", }}>
                                <TableCell
                                    style={{
                                        fontSize: "0.8rem",
                                        padding: "1px 18px",
                                        backgroundColor: "#4a6d8c",
                                        border: "1px solid gray", color: "white", textAlign: "center"
                                    }}
                                    className=" txt_ttl1"
                                >
                                    Sr. No.
                                </TableCell>
                                <TableCell
                                    style={{
                                        fontSize: "0.8rem",
                                        padding: "1px 18px",
                                        backgroundColor: "#4a6d8c",
                                        border: "1px solid gray", color: "white", textAlign: "center"
                                    }}
                                    className=" txt_ttl1"
                                >
                                    Annocement Date
                                </TableCell>
                                <TableCell
                                    style={{
                                        fontSize: "0.8rem",
                                        padding: "1px 18px",
                                        backgroundColor: "#4a6d8c",
                                        border: "1px solid gray", color: "white", textAlign: "center"
                                    }}
                                    className=" txt_ttl1"
                                >
                                    Annocement Heading
                                </TableCell>
                                <TableCell
                                    style={{
                                        fontSize: "0.8rem",
                                        padding: "1px",
                                        backgroundColor: "#4a6d8c",
                                        border: "1px solid gray", color: "white", textAlign: "center"
                                    }}
                                    className=" txt_ttl1"
                                >
                                    Display From Date
                                </TableCell>
                                <TableCell
                                    style={{
                                        fontSize: "0.8rem",
                                        padding: "1px",
                                        backgroundColor: "#4a6d8c",
                                        border: "1px solid gray", color: "white", textAlign: "center"
                                    }}
                                    className=" txt_ttl1"
                                >
                                    Display From To
                                </TableCell>
                                <TableCell
                                    style={{
                                        fontSize: "0.8rem",
                                        padding: "1px",
                                        backgroundColor: "#4a6d8c",
                                        border: "1px solid gray", color: "white", textAlign: "center"
                                    }}
                                    className=" txt_ttl1"
                                >
                                    Display On Screen ?
                                </TableCell>

                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {ancement.histrical_annocement_result.map((annocement, index) => (
                                <TableRow style={{ height: "10px" }} key={annocement.leaveTypeId}>
                                    <TableCell align="center" style={{ fontSize: "0.8rem", padding: "1px 18px", border: "1px solid gray" }}>
                                        {index + 1} {/* Serial number */}
                                    </TableCell>
                                    <TableCell align="center" style={{ fontSize: "0.8rem", padding: "1px 18px", border: "1px solid gray" }}>
                                        {formatDate(annocement.LVE_ISSUE_ON)}
                                    </TableCell>
                                    <TableCell align="center" style={{ fontSize: "0.8rem", padding: "1px 18px", border: "1px solid gray" }}>
                                        {annocement.LVE_NTC_HEAD_SUB}
                                    </TableCell>
                                    <TableCell align="center" style={{ fontSize: "0.8rem", padding: "1px", border: "1px solid gray" }}>

                                        {formatDate(annocement.LVE_DSP_DT_FROM)}
                                    </TableCell>
                                    <TableCell align="center" style={{ fontSize: "0.8rem", padding: "1px", border: "1px solid gray" }}>

                                        {formatDate(annocement.LVE_DSP_DT_TO)}
                                    </TableCell>
                                    <TableCell align="center" style={{ fontSize: "0.8rem", padding: "1px", border: "1px solid gray" }}>
                                        {annocement.LVE_DSP_NTC == "Y" ? "Yes" : "No"}
                                    </TableCell>
                                </TableRow>
                            ))}
                            {/* Add empty rows if there are less than 10 rows */}
                            {ancement.histrical_annocement_result.length < 10 &&
                                Array.from({ length: 10 - ancement.histrical_annocement_result.length }).map((_, index) => (
                                    <TableRow style={{ height: "10px" }} key={`empty-row-${index}`}>
                                        <TableCell align="center" style={{ fontSize: "0.8rem", padding: "1px 18px", border: "1px solid gray" }}>
                                            &nbsp; {/* Empty cell for spacing */}
                                        </TableCell>
                                        <TableCell align="center" style={{ fontSize: "0.8rem", padding: "1px 18px", border: "1px solid gray" }}>
                                            &nbsp; {/* Empty cell for spacing */}
                                        </TableCell>
                                        <TableCell align="center" style={{ fontSize: "0.8rem", padding: "1px 18px", border: "1px solid gray" }}>
                                            &nbsp; {/* Empty cell for spacing */}
                                        </TableCell>
                                        <TableCell align="center" style={{ fontSize: "0.8rem", padding: "1px", border: "1px solid gray" }}>
                                            &nbsp; {/* Empty cell for spacing */}
                                        </TableCell>
                                        <TableCell align="center" style={{ fontSize: "0.8rem", padding: "1px", border: "1px solid gray" }}>
                                            &nbsp; {/* Empty cell for spacing */}
                                        </TableCell>
                                        <TableCell align="center" style={{ fontSize: "0.8rem", padding: "1px", border: "1px solid gray" }}>
                                            &nbsp; {/* Empty cell for spacing */}
                                        </TableCell>
                                    </TableRow>
                                ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>
        </div>
    )
}

export default Adm_Historical_Annocement