import React, { useState, useEffect } from "react";
import services from "./apiServices";

function Adm_Change_Emp_Password() {
  const [employeelist, setEmployeelist] = useState({ aflt_emp_lst_result: [] });
  const [searchTerm, setSearchTerm] = useState(""); // Employee ID to search
  const [formData, setFormData] = useState({
    NTT_LOGIN_ID: "",
    USR_EMP_ID: "",          // Employee ID
    NTT_PWD: "",             // Old password
    newPassword: "",         // New password
    confirmPassword: "",     // Confirm password
  });
  const [errors, setErrors] = useState({});

  // Fetch all employees once the component mounts
  useEffect(() => {
    const aflt_emp_lst = async () => {
      try {
        const emp_lst = await services.get_aflt_emp_lst();
        if (emp_lst) {
          setEmployeelist(emp_lst);
        } else {
          console.error("Unexpected data format:", emp_lst);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    aflt_emp_lst();
  }, []);

  const validateForm = () => {
    const newErrors = {};

    // Validate employee ID
    if (!searchTerm) {
      newErrors.emp_id = "Employee ID is required.";
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };
  const validateForm1 = () => {
    const newErrors = {};

    if (!formData.newPassword) {
      newErrors.newPassword = "New Password is required.";
    }

    if (!formData.confirmPassword) {
      newErrors.confirmPassword = "Confirm Password is required.";
    }
    // Check if new password and confirm password match
    if (formData.newPassword && formData.confirmPassword && formData.newPassword !== formData.confirmPassword) {
      newErrors.passwordMismatch = "New password and confirm password must match.";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  // Handle the search based on employee ID
  // Handle the search based on employee ID
  const handleSearch = (e) => {
    e.preventDefault(); // Prevent form submission

    if (validateForm()) {
      const foundEmployee = employeelist.aflt_emp_lst_result.find(
        (employee) => employee.USR_EMP_ID === searchTerm
      );
      if (foundEmployee) {
        setFormData({
          ...formData,
          USR_EMP_ID: foundEmployee.USR_EMP_ID,
          NTT_PWD: foundEmployee.NTT_PWD,
          NTT_LOGIN_ID: foundEmployee.NTT_LOGIN_ID,
        });
      } else {
        setFormData({
          ...formData,
          USR_EMP_ID: "",
          NTT_LOGIN_ID: "",
          NTT_PWD: "",
          newPassword: "",
          confirmPassword: "",
        });
        alert("Employee not found."); // Show alert when employee is not found
      }
    }
  };


  // Handle the save action (you'll need to implement the save logic)
  const handleSave = async () => {
    if (validateForm1()) {
      try {
        // Call the submission service with the updated data
        const insrt_res = await services.sbmit_emp_chnge_pass(formData);

        if (insrt_res) {
          console.log(" Employee Password Updated Successfully....");
          alert("Employee Password Updated Successfully....");

        }
      } catch (error) {
        console.error("Failed to Updated Employee Password..", error);
        alert("Failed to Updated Employee Password.. ");
      }
    }
  };

  const handleCancel = () => {
    setFormData({
      USR_EMP_ID: "",
      NTT_PWD: "",
      newPassword: "",
      confirmPassword: "",
    });
  };
  console.log("formData", formData)
  return (
    <div className="wrapper_form">
      <div className="title" style={{ marginTop: ".3%" }}>
        Password Change/Reset of Employees
      </div>

      {/* Employee ID search input */}
      {!formData.USR_EMP_ID ? (
        <>
          <form>
            <div className="row" style={{ marginTop: ".5%", display: "flex", alignItems: "center" }}>
              <div className="column drp_ln_hg" style={{ flex: "1" }}>
                <label style={{ margin: "1% 1% 1% 1.8%" }}>
                  Employee ID: {errors.emp_id && <span className="error-message">{errors.emp_id}</span>}
                </label>
                <div className="field">
                  <input
                    type="text"
                    name="emp_id"
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    placeholder="Enter Employee ID"
                    style={{ width: "90%" }}
                  />
                </div>
              </div>
              <button className="action-button" style={{ width: "20%", margin: "2px", borderRadius: "35px" }} onClick={handleSearch}>
                Search
              </button>
            </div>
          </form>
        </>
      ) : (
        <div className="wrapper_form wd_log">
          <div className="title">Password Change Form</div>
          <form onSubmit={(e) => e.preventDefault()}>
            <br />
            <div className="field">
              <input
                type="text"
                value={formData.USR_EMP_ID}
                style={{ color: "black" }}
              // disabled
              />
              <label>Emp. ID.</label>
            </div>
            <br />

            <div className="field">
              <input
                type="text"
                value={formData.NTT_PWD}
                style={{ color: "black" }}
              // disabled
              />
              <label>Old Password</label>
            </div>
            <br />

            <div className="field">
              <input
                type="password"
                value={formData.newPassword}
                onChange={(e) => setFormData({ ...formData, newPassword: e.target.value })}
                placeholder="Enter New Password"
                style={{ color: "black" }}
              />
              <label>New Password: {errors.newPassword && <span className="error-message">{errors.newPassword}</span>}</label>
            </div>
            <br />

            <div className="field">
              <input
                type="password"
                value={formData.confirmPassword}
                onChange={(e) => setFormData({ ...formData, confirmPassword: e.target.value })}
                placeholder="Confirm New Password"
                style={{ color: "black" }}
              />
              <label>Confirm Password: {errors.confirmPassword && <span className="error-message">{errors.confirmPassword}</span>}</label>
              {errors.passwordMismatch && <span className="error-message">{errors.passwordMismatch}</span>}
            </div>
            <br />

            <div style={{ marginTop: "20px" }}>
              <button className="action-button" style={{ width: "25%", margin: "10px" }} onClick={handleCancel}>Cancel</button>
              <button className="action-button" style={{ width: "25%", margin: "10px", float: "right" }} onClick={handleSave}>Save</button>
            </div>
          </form>
        </div>
      )}
    </div>
  );
}

export default Adm_Change_Emp_Password;
