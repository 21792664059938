import React, { useEffect, useState } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Grid, Paper, RadioGroup, FormControl, FormControlLabel, Radio } from '@mui/material'; // Importing Material UI components
import Button from '@mui/material/Button'; // Button component
import VisibilityIcon from '@mui/icons-material/Visibility';
import EditIcon from '@mui/icons-material/Edit';
import CancelIcon from '@mui/icons-material/Cancel';
import services from './apiServices';

export default function Sys_Usr_client_Viewedit_Mng() {
  const [data, setdata] = useState({ aflt_for_editview_res: [] });
  const [detail, setDetails] = useState();
  const [isEditMode, setIsEditMode] = useState(false);

  useEffect(() => {
    const fetch = async () => {
      try {
        const res = await services.getafltdt();
        if (res) {
          setdata(res);
        } else {
          console.error("Unexpected data format:", res);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetch();
  }, []);

  const handleEdit1 = (afltinfo) => {
    setDetails(afltinfo);
    setIsEditMode(true); // Set to edit mode
  };

  const handleView = (afltinfo) => {
    setDetails(afltinfo);
    setIsEditMode(false); // Set to view mode
  };

  const handleDelete = async (afltinfo) => {
    try {
      const res = await services.deleteaffilate(afltinfo);
      if (res) {
        alert("Client Deleted Successfully");
        const fetch = async () => {
          try {
            const res = await services.getafltdt();
            if (res) {
              setdata(res);
            } else {
              console.error("Unexpected data format:", res);
            }
          } catch (error) {
            console.error("Error fetching data:", error);
          }
        };
        fetch();
      }
    } catch (error) {
      console.error(`Error deleting the affiliate data ${error}`);
    }
  };

  const cancelbutton = () => {
    setDetails(null);
    setIsEditMode(false); // Reset to view mode
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const res = await services.updateAffiliate(detail);
      if (res) {
        alert("Client Updated Successfully!");
        setDetails(null);
        setIsEditMode(false); // Reset to view mode
        const fetch = async () => {
          try {
            const res = await services.getafltdt();
            if (res) {
              setdata(res);
            } else {
              console.error("Unexpected data format:", res);
            }
          } catch (error) {
            console.error("Error fetching data:", error);
          }
        };
        fetch();
      }
    } catch (error) {
      console.error("Error updating affiliate:", error);
    }
  };

  return (
    <div className="wrapper_form" style={{ backgroundColor: "#dddddf" }}>
      <div className="title" style={{ margin: "2% 1% 0 1%" }}>Client Management</div>
      {detail ? (
        isEditMode ? (
          // Edit Form
          <form onSubmit={handleSubmit} style={{ backgroundColor: "#fff" }}>
            <div className=" form-container">
              <hr className="hr_ttl" />
              <p
                style={{
                  fontSize: "25px",
                  paddingTop: "5px",
                  color: "black",
                }}
              >
                Organization information
              </p>
              <hr className="hr_dt" style={{ marginBottom: "19px" }} />
              {/* Group fields as needed */}
              <div className="row" style={{ marginBottom: "16px" }}>
                <div className="column">
                  <div className="field">
                    <input
                      type="text"
                      value={detail.AFLT_NAME || " "}
                      onChange={(e) =>
                        setDetails({ ...detail, AFLT_NAME: e.target.value })
                      }
                    />
                    <label>Organization Full Name</label>
                  </div>
                </div>
                <div className="column">
                  <div className="field">
                    <input
                      type="text"
                      value={detail.AFLT_SHORT_NAME || " "}
                      onChange={(e) =>
                        setDetails({ ...detail, AFLT_SHORT_NAME: e.target.value })
                      }
                    />
                    <label>
                      Orgnization Short Name{" "}

                    </label>
                  </div>
                </div>

                <div className="column">
                  <div className="field">
                    <input
                      type="text"
                      value={detail.AFLT_ID_BY_CUST || " "}
                      onChange={(e) =>
                        setDetails({ ...detail, AFLT_ID_BY_CUST: e.target.value })
                      }

                    />

                    <label>
                      Orgnization Unique code{" "}

                    </label>
                  </div>
                </div>
              </div>
              <div className="row" style={{ marginBottom: "15px" }}>
                <div className="column">
                  <div className="field">
                    <input
                      type="text"
                      value={detail.AFLT_TAN || " "}
                      onChange={(e) =>
                        setDetails({ ...detail, AFLT_TAN: e.target.value })
                      }
                    />
                    <label>
                      TAN

                    </label>
                  </div>
                </div>
                <div className="column">
                  <div className="field">
                    <input
                      type="text"
                      value={detail.AFLT_GST || " "}
                      onChange={(e) =>
                        setDetails({ ...detail, AFLT_GST: e.target.value })
                      }
                    />
                    <label>
                      GST

                    </label>
                  </div>
                </div>
                <div className="column">
                  <div className="field">
                    <input
                      type="text"
                      value={detail.AFLT_PAN || " "}
                      onChange={(e) =>
                        setDetails({ ...detail, AFLT_PAN: e.target.value })
                      }
                    />
                    <label>
                      PAN

                    </label>
                  </div>
                </div>
              </div>

              <hr className="hr_ttl" />
              <p
                style={{
                  fontSize: "25px",
                  paddingTop: "5px",
                  color: "black",
                }}
              >
                Office Address
              </p>
              <hr className="hr_dt" />

              <div className="row" style={{ marginBottom: "20px" }}>
                <div
                  className="column"
                  style={{ display: "flex", alignItems: "center", marginTop: "5px", lineHeight: "12px" }}
                >
                  <label >
                    Address Type{" "} : - <b> Office Address</b>
                  </label>
                </div>

                <div
                  className="column"
                  style={{ display: "flex", alignItems: "center", marginTop: "5px", lineHeight: "12px" }}
                >
                  <label style={{ marginLeft: "5px", marginRight: "10px" }}>
                    Is this Preferred Address?

                  </label>
                  <FormControl>
                    <RadioGroup
                      row
                      aria-labelledby="demo-row-radio-buttons-group-label"
                      value={detail.IS_PRFR_ADD || " "}
                      onChange={(e) =>
                        setDetails({
                          ...detail,
                          IS_PRFR_ADD: e.target.value,
                        })
                      }
                    >
                      <FormControlLabel
                        style={{ padding: "2px" }}
                        value="1"
                        control={<Radio />}
                        label="Yes"
                      />
                      <FormControlLabel
                        value="0"
                        control={<Radio />}
                        label="No"
                      />
                    </RadioGroup>
                  </FormControl>
                </div>
                <div
                  className="column"
                  style={{ display: "flex", alignItems: "center", marginTop: "5px", lineHeight: "12px" }}
                >
                  <label >
                    Country{" "} : - <b> India</b>
                  </label>
                </div>
              </div>
              <div className="row" style={{ marginBottom: "15px" }}>
                <div className="column">
                  <div className="field">
                    <input
                      type="text"
                      value={detail.ADD_LN1 || " "}
                      onChange={(e) =>
                        setDetails({ ...detail, ADD_LN1: e.target.value })
                      }
                    />
                    <label>
                      Address Line 1

                    </label>
                  </div>
                </div>
                <div className="column">
                  <div className="field">
                    <span>
                      <i className="fas fa-address-book"></i>
                    </span>
                    <input
                      type="text"
                      value={detail.ADD_CT || " "}
                      onChange={(e) =>
                        setDetails({ ...detail, ADD_CT: e.target.value })
                      }
                    />
                    <label>
                      City

                    </label>
                  </div>
                </div>
              </div>
              <div className="row" style={{ marginBottom: "15px" }}>
                <div className="column">
                  <div className="field">
                    <input
                      type="text"
                      value={detail.ADD_LN2 || " "}
                      onChange={(e) =>
                        setDetails({ ...detail, ADD_LN2: e.target.value })
                      }
                    />
                    <label>
                      Address Line 2

                    </label>
                  </div>
                </div>
                <div className="column">
                  <div className="field">
                    <input
                      type="text"
                      value={detail.ADD_PIN_ZIP || " "}
                      onChange={(e) =>
                        setDetails({ ...detail, ADD_PIN_ZIP: e.target.value })
                      }
                    />
                    <label>
                      PIN

                    </label>
                  </div>
                </div>
              </div>

              <div className="row" style={{ marginBottom: "1px" }}>
                <div className="column">
                  <div className="field">
                    <input
                      type="text"
                      value={detail.ADD_LN3 || " "}
                      onChange={(e) =>
                        setDetails({ ...detail, ADD_LN3: e.target.value })
                      }
                    />
                    <label>
                      Address Line 3
                    </label>
                  </div>
                </div>
                <div className="column">
                  <div className="field">
                    <input
                      type="text"
                      value={detail.ADD_STAT || " "}
                      onChange={(e) =>
                        setDetails({ ...detail, ADD_STAT: e.target.value })
                      }
                    />
                    <label>
                      State

                    </label>
                  </div>
                </div>
              </div>

              <div className="row" style={{ marginBottom: "15px" }}>
                <div className="column drp_ln_hg">
                  <label className="drp_mg">
                    Phone 1

                  </label>
                  <div className="field">
                    <input
                      type="tel"
                      value={detail.ADD_CELL_PH || " "}
                      onChange={(e) =>
                        setDetails({ ...detail, ADD_CELL_PH: e.target.value })
                      }
                    />

                  </div>
                </div>

                <div className="column drp_ln_hg">
                  <label className="drp_mg">
                    Landmark
                  </label>
                  <div className="field">
                    <input
                      // className="ys"
                      type="text"
                      value={detail.ADD_LAND_PH || " "}
                      onChange={(e) =>
                        setDetails({ ...detail, ADD_LAND_PH: e.target.value })
                      }
                    />
                  </div>
                </div>
              </div>
              <div className="row" style={{ marginBottom: "15px" }}>
                <div className="column">
                  <div className="field">
                    <input
                      type="text"
                      value={detail.ADD_GMAP_LINK || " "}
                      onChange={(e) =>
                        setDetails({ ...detail, ADD_GMAP_LINK: e.target.value })
                      }
                    />
                    <label>
                      Google Map Link
                    </label>
                  </div>
                </div>
                <div className="column">
                  <div className="field">
                    <input
                      type="email"
                      value={detail.ADD_EMAIL || " "}
                      onChange={(e) =>
                        setDetails({ ...detail, ADD_EMAIL: e.target.value })
                      }
                    />
                    <label>
                      Email

                    </label>
                  </div>
                </div>
              </div>

              <div className="row" style={{ marginBottom: "15px" }}>

                <div className="column">
                  {/* <div className="field">
                  <input
                    type="tel"
                    value={detail.officephone2 || " "}
                    onChange={(e) =>
                      setDetails({ ...detail, officephone2: e.target.value })
                    }
                  />
                  <label>
                    Phone 2
                  </label>
                </div> */}
                </div>
              </div>


              <hr className="hr_ttl" />
              <p
                style={{
                  fontSize: "25px",
                  paddingTop: "5px",
                  color: "black",
                }}
              >
                Admin Social Media information
              </p>
              <hr className="hr_dt" style={{ marginBottom: "19px" }} />

              <div className="row" style={{ marginBottom: "15px" }}>
                <div className="column">
                  <div className="field">
                    <input
                      type="text"
                      value={detail.AFLT_SOCIALURL1 || " "}
                      onChange={(e) =>
                        setDetails({ ...detail, AFLT_SOCIALURL1: e.target.value })
                      }
                    />
                    <label>
                      Social Media Link 1

                    </label>
                  </div>
                </div>
                <div className="column">
                  <div className="field">
                    <input
                      type="text"
                      value={detail.AFLT_SOCIALURL2 || " "}
                      onChange={(e) =>
                        setDetails({ ...detail, AFLT_SOCIALURL2: e.target.value })
                      }
                    />
                    <label>
                      Social Media Link 2

                    </label>
                  </div>
                </div>
              </div>
              <div className="row" style={{ marginBottom: "15px" }}>
                <div className="column">
                  <div className="field">
                    <input
                      type="text"
                      value={detail.AFLT_SOCIALURL3 || " "}
                      onChange={(e) =>
                        setDetails({ ...detail, AFLT_SOCIALURL3: e.target.value })
                      }
                    />
                    <label>Social Media Link 3</label>
                  </div>
                </div>
                <div className="column">
                  <div className="field">
                    <input
                      type="text"
                      value={detail.AFLT_SOCIALURL4 || " "}
                      onChange={(e) =>
                        setDetails({ ...detail, AFLT_SOCIALURL4: e.target.value })
                      }
                    />
                    <label>Social Media Link 4</label>
                  </div>
                </div>
              </div>
              <div className="row" style={{ marginBottom: "15px" }}>
                <div className="column" >
                  <div className="field">
                    <input
                      style={{ width: "49.2%" }}
                      type="text"
                      value={detail.AFLT_WEBSITE || " "}
                      onChange={(e) =>
                        setDetails({ ...detail, AFLT_WEBSITE: e.target.value })
                      }
                    />
                    <label>
                      Website
                    </label>
                  </div>
                </div>
              </div>
              <hr className="hr_dt" />
              <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                <button
                  type="button"
                  className="btn-action action-button"
                  onClick={cancelbutton}
                  style={{ margin: "1px", width: "20%" }}
                >
                  Cancel
                </button>

                <button
                  style={{ margin: "1px", width: "20%" }}
                  type="submit"
                  className="btn-action action-button"
                  disabled={!isEditMode}
                >
                  Save
                </button>
              </div>

              <hr className="hr_dt" style={{ marginTop: "5px" }} />

            </div>
          </form>
        ) : (
          // View Form
          <form style={{ backgroundColor: "#fff" }}>
            <div className=" form-container">
              <hr className="hr_ttl" />
              <p
                style={{
                  fontSize: "25px",
                  paddingTop: "5px",
                  color: "black",
                }}
              >
                Organization information
              </p>
              <hr className="hr_dt" style={{ marginBottom: "19px" }} />
              {/* Group fields as needed */}
              <div className="row" style={{ marginBottom: "16px" }}>
                <div className="column">
                  <div className="field">
                    <input
                      className='cursr_view'
                      value={detail.AFLT_NAME || "No Data"}
                    />
                    <label>Organization Full Name</label>
                  </div>
                </div>
                <div className="column">
                  <div className="field">
                    <input
                      className='cursr_view'
                      value={detail.AFLT_SHORT_NAME || "No Data"}
                    />
                    <label>
                      Orgnization Short Name{" "}
                    </label>
                  </div>
                </div>
                <div className="column">
                  <div className="field">
                    <input
                      className='cursr_view'
                      value={detail.AFLT_ID_BY_CUST || "No Data"}
                    />
                    <label>
                      Orgnization Unique code{" "}
                    </label>
                  </div>
                </div>
              </div>
              <div className="row" style={{ marginBottom: "15px" }}>
                <div className="column">
                  <div className="field">
                    <input
                      className='cursr_view'
                      value={detail.AFLT_TAN || "No Data"}
                    />
                    <label>
                      TAN
                    </label>
                  </div>
                </div>
                <div className="column">
                  <div className="field">
                    <input
                      className='cursr_view'
                      value={detail.AFLT_GST || "No Data"}
                    />
                    <label>
                      GST
                    </label>
                  </div>
                </div>
                <div className="column">
                  <div className="field">
                    <input
                      className='cursr_view'
                      value={detail.AFLT_PAN || "No Data"}
                    />
                    <label>
                      PAN
                    </label>
                  </div>
                </div>
              </div>
              <hr className="hr_ttl" />
              <p
                style={{
                  fontSize: "25px",
                  paddingTop: "5px",
                  color: "black",
                }}
              >
                Office Address
              </p>
              <hr className="hr_dt" />
              <div className="row" style={{ marginBottom: "20px" }}>
                <div
                  className="column"
                  style={{ display: "flex", alignItems: "center", marginTop: "5px", lineHeight: "12px" }}
                >
                  <label >
                    Address Type{" "} : - <b> Office Address</b>
                  </label>
                </div>
                <div
                  className="column"
                  style={{ display: "flex", alignItems: "center", marginTop: "5px", lineHeight: "12px" }}
                >
                  <label style={{ marginLeft: "5px", marginRight: "10px" }}>
                    Is this Preferred Address?
                  </label>
                  <FormControl>
                    <RadioGroup
                      row
                      aria-labelledby="demo-row-radio-buttons-group-label"
                      className='cursr_view'
                      value={detail.IS_PRFR_ADD === "1" ? "Yes" : "No" || "No Data"}
                    >
                      <FormControlLabel
                        style={{ padding: "2px" }}
                        className='cursr_view'
                        value="Yes"
                        control={<Radio />}
                        label="Yes"
                      />
                      <FormControlLabel
                        className='cursr_view'
                        value="No"
                        control={<Radio />}
                        label="No"
                      />
                    </RadioGroup>
                  </FormControl>
                </div>
                <div
                  className="column"
                  style={{ display: "flex", alignItems: "center", marginTop: "5px", lineHeight: "12px" }}
                >
                  <label >
                    Country{" "} : - <b> India</b>
                  </label>
                </div>
              </div>
              <div className="row" style={{ marginBottom: "15px" }}>
                <div className="column">
                  <div className="field">
                    <input
                      className='cursr_view'
                      value={detail.ADD_LN1 || "No Data"}
                    />
                    <label>
                      Address Line 1
                    </label>
                  </div>
                </div>
                <div className="column">
                  <div className="field">
                    <span>
                      <i className="fas fa-address-book"></i>
                    </span>
                    <input
                      className='cursr_view'
                      value={detail.ADD_CT || "No Data"}
                    />
                    <label>
                      City
                    </label>
                  </div>
                </div>
              </div>
              <div className="row" style={{ marginBottom: "15px" }}>
                <div className="column">
                  <div className="field">
                    <input
                      className='cursr_view'
                      value={detail.ADD_LN2 || "No Data"}
                    />
                    <label>
                      Address Line 2
                    </label>
                  </div>
                </div>
                <div className="column">
                  <div className="field">
                    <input
                      className='cursr_view'
                      value={detail.ADD_PIN_ZIP || "No Data"}
                    />
                    <label>
                      PIN
                    </label>
                  </div>
                </div>
              </div>
              <div className="row" style={{ marginBottom: "1px" }}>
                <div className="column">
                  <div className="field">
                    <input
                      className='cursr_view'
                      value={detail.ADD_LN3 || "No Data"}
                    />
                    <label>
                      Address Line 3
                    </label>
                  </div>
                </div>
                <div className="column">
                  <div className="field">
                    <input
                      className='cursr_view'
                      value={detail.ADD_STAT || "No Data"}
                    />
                    <label>
                      State
                    </label>
                  </div>
                </div>
              </div>
              <div className="row" style={{ marginBottom: "15px" }}>
                <div className="column drp_ln_hg">
                  <label className="drp_mg">
                    Phone 1
                  </label>
                  <div className="field">
                    <input
                      type="tel"
                      className='cursr_view'
                      value={detail.ADD_CELL_PH || "No Data"}
                    />
                  </div>
                </div>
                <div className="column drp_ln_hg">
                  <label className="drp_mg">
                    Landmark
                  </label>
                  <div className="field">
                    <input
                      className='cursr_view'
                      value={detail.ADD_LAND_PH || "No Data"}
                    />
                  </div>
                </div>
              </div>
              <div className="row" style={{ marginBottom: "15px" }}>
                <div className="column">
                  <div className="field">
                    <input
                      className='cursr_view'
                      value={detail.ADD_GMAP_LINK || "No Data"}
                    />
                    <label>
                      Google Map Link
                    </label>
                  </div>
                </div>
                <div className="column">
                  <div className="field">
                    <input
                      type="email"
                      className='cursr_view'
                      value={detail.ADD_EMAIL || "No Data"}
                    />
                    <label>
                      Email
                    </label>
                  </div>
                </div>
              </div>
              <div className="row" style={{ marginBottom: "15px" }}>
                <div className="column">
                  {/* <div className="field">
                  <input
                    type="tel"
                    className='cursr_view'
   value={detail.officephone2 || "No Data"}
                   
                  />
                  <label>
                    Phone 2
                  </label>
                </div> */}
                </div>
              </div>
              <hr className="hr_ttl" />
              <p
                style={{
                  fontSize: "25px",
                  paddingTop: "5px",
                  color: "black",
                }}
              >
                Admin Social Media information
              </p>
              <hr className="hr_dt" style={{ marginBottom: "19px" }} />
              <div className="row" style={{ marginBottom: "15px" }}>
                <div className="column">
                  <div className="field">
                    <input
                      className='cursr_view'
                      value={detail.AFLT_SOCIALURL1 || "No Data"}
                    />
                    <label>
                      Social Media Link 1
                    </label>
                  </div>
                </div>
                <div className="column">
                  <div className="field">
                    <input
                      className='cursr_view'
                      value={detail.AFLT_SOCIALURL2 || "No Data"}
                    />
                    <label>
                      Social Media Link 2
                    </label>
                  </div>
                </div>
              </div>
              <div className="row" style={{ marginBottom: "15px" }}>

                <div className="column">
                  <div className="field">
                    <input
                      className='cursr_view'
                      value={detail.AFLT_SOCIALURL3 || "No Data"}
                    />
                    <label>Social Media Link 3</label>
                  </div>
                </div>
                <div className="column">
                  <div className="field">
                    <input
                      className='cursr_view'
                      value={detail.AFLT_SOCIALURL4 || "No Data"}
                    />
                    <label>Social Media Link 4</label>
                  </div>
                </div>
              </div>
              <div className="row" style={{ marginBottom: "15px" }}>
                <div className="column">
                  <div className="field">
                    <input
                      style={{ width: "49.2%" }}
                      className='cursr_view'
                      value={detail.AFLT_WEBSITE || "No Data"}
                    />
                    <label>
                      Website
                    </label>
                  </div>
                </div>
              </div>
              <hr className="hr_dt" />

              <div style={{ display: "flex", justifyContent: "flex-end" }}>
                <button
                  type="button"
                  className="btn-action action-button"
                  onClick={cancelbutton}
                  style={{ margin: "1px", width: "20%" }}
                >
                  Cancel
                </button>
              </div>
              <hr className="hr_dt" />
            </div>
          </form>
        )
      ) : (
        <Grid item xs={12} style={{ paddingLeft: "1%", paddingRight: "1%", backgroundColor: "#fff", width: "99.7%" }}>
          <TableContainer component={Paper} style={{ margin: "5px auto 25px auto", overflow: "hidden", boxShadow: "0px 6px 16px 3px #bababa", borderRadius: "20px", border: "2px solid gray" }}>
            <Table style={{ width: "100%", backgroundColor: "#fff" }}>
              <TableHead>
                <TableRow style={{ width: "100%" }}>
                  <TableCell style={{ fontSize: "0.8rem", padding: "1px 18px", backgroundColor: "#4a6d8c", border: "1px solid gray", color: "white", textAlign: "center" }}>Sr. No.</TableCell>
                  <TableCell style={{ fontSize: "0.8rem", padding: "1px 18px", backgroundColor: "#4a6d8c", border: "1px solid gray", color: "white", textAlign: "center" }}>Client ID</TableCell>
                  <TableCell style={{ fontSize: "0.8rem", padding: "1px 18px", backgroundColor: "#4a6d8c", border: "1px solid gray", color: "white", textAlign: "center" }}>Client Name</TableCell>
                  <TableCell style={{ fontSize: "0.8rem", padding: "1px", backgroundColor: "#4a6d8c", border: "1px solid gray", color: "white", textAlign: "center" }}>Organization Unique Identification</TableCell>
                  <TableCell style={{ fontSize: "0.8rem", padding: "1px", backgroundColor: "#4a6d8c", border: "1px solid gray", color: "white", textAlign: "center" }}>Organization Short Name</TableCell>
                  <TableCell style={{ fontSize: "0.8rem", padding: "1px", backgroundColor: "#4a6d8c", border: "1px solid gray", color: "white", textAlign: "center" }}>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody style={{ backgroundColor: "#fff" }}>
                {data.aflt_for_editview_res.map((afltinfo, index) => (
                  <TableRow style={{ height: "10px" }} key={afltinfo.leaveTypeId}>
                    <TableCell align="center" style={{ fontSize: "0.8rem", padding: "1px 18px", border: "1px solid gray" }}>{index + 1}</TableCell>
                    <TableCell align="center" style={{ fontSize: "0.8rem", padding: "1px 18px", border: "1px solid gray" }}>{afltinfo.AFLT_KY}</TableCell>
                    <TableCell align="center" style={{ fontSize: "0.8rem", padding: "1px 18px", border: "1px solid gray" }}>{afltinfo.AFLT_NAME}</TableCell>
                    <TableCell align="center" style={{ fontSize: "0.8rem", padding: "1px", border: "1px solid gray" }}>{afltinfo.AFLT_ID_BY_CUST}</TableCell>
                    <TableCell align="center" style={{ fontSize: "0.8rem", padding: "1px", border: "1px solid gray" }}>{afltinfo.AFLT_SHORT_NAME}</TableCell>
                    <TableCell style={{ padding: "4px", display: "flex", justifyContent: "center", height: "12px", borderBottom: ".1px solid gray" }}>
                      <Button title='View' style={{ minWidth: "auto", padding: "1" }} onClick={() => handleView(afltinfo)} size="small">
                        <VisibilityIcon style={{ color: "#4a6d8c" }} fontSize="small" />
                      </Button>
                      <Button title='Update' style={{ minWidth: "auto", padding: "1" }} onClick={() => handleEdit1(afltinfo)} size="small">
                        <EditIcon style={{ color: "#4a6d8c" }} fontSize="small" />
                      </Button>
                      <Button title='Delete' style={{ minWidth: "auto", padding: "1" }} onClick={() => handleDelete(afltinfo)} size="small" >
                        <CancelIcon style={{ color: "#4a6d8c" }} fontSize="small" />
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
                {/* Add empty rows if there are less than 10 rows */}
                {data.aflt_for_editview_res.length < 5 &&
                  Array.from({ length: 5 - data.aflt_for_editview_res.length }).map((_, index) => (
                    <TableRow style={{ height: "10px" }} key={`empty-row-${index}`}>
                      <TableCell align="center" style={{ fontSize: "0.8rem", padding: "1px 18px", border: "1px solid gray" }}>&nbsp;</TableCell>
                      <TableCell align="center" style={{ fontSize: "0.8rem", padding: "1px 18px", border: "1px solid gray" }}>&nbsp;</TableCell>
                      <TableCell align="center" style={{ fontSize: "0.8rem", padding: "1px 18px", border: "1px solid gray" }}>&nbsp;</TableCell>
                      <TableCell align="center" style={{ fontSize: "0.8rem", padding: "1px", border: "1px solid gray" }}>&nbsp;</TableCell>
                      <TableCell align="center" style={{ fontSize: "0.8rem", padding: "1px", border: "1px solid gray" }}>&nbsp;</TableCell>
                      <TableCell align="center" style={{ fontSize: "0.8rem", padding: "1px", border: "1px solid gray" }}>&nbsp;</TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      )}
    </div>
  );
}