import React, { useState, useEffect } from "react";
import services from "./apiServices";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel"; // Add this line
import EditIcon from '@mui/icons-material/Edit';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Button,
    TextField,
    Grid,
    Typography
} from "@mui/material";
import * as XLSX from "xlsx";
import moment from 'moment';

function Sys_Usr_Aflt_Holiday_Mngment() {
    const [holidayDate, setHolidayDate] = useState(); // Initial state as a Date object
    const [holidayDate1, setHolidayDate1] = useState(); // Initial state as a Date object
    const [holidayType, setHolidayType] = useState('');
    const [holidayType1, setHolidayType1] = useState('');
    const [isDisplay, setIsDisplay] = useState();
    const [isDisplay1, setIsDisplay1] = useState();
    const [holidayDescription, setHolidayDescription] = useState('');
    const [holidayDescription1, setHolidayDescription1] = useState('');
    const [holidays, setHolidays] = useState([]);
    const [selectedOption, setSelectedOption] = useState('');
    const [error, setError] = useState(null);
    const [fileData, setFileData] = useState(null);
    const currentYear = moment().year();
    const [holidaycal, Setholidaycal] = useState({ aflt_lve_hld_calndr_res: [] });
    const [filteredHolidays, setFilteredHolidays] = useState([]);
    const [selectedYear, setSelectedYear] = useState(currentYear);
    const [editingHoliday, setEditingHoliday] = useState(null); // Track the holiday being edited
    const [editingHoliday1, setEditingHoliday1] = useState(null); // Track the holiday being edited
    const [holidaykey, setholidaykey] = useState(null); // Track the holiday being edited
    const [aflttotalcnt, setaflttotalcnt] = useState({
        aflt_cnt_res: [],
        aflt_wise_usr_cnt_res: [],
        alt_wise_lve_types_res: [],
        aflt_wise_lve_apl_cnt_res: [],
        pendng_aprved_aflt_apl_cnt_res: [],
        all_aflt_confg_dt: [],
        all_alf_auth_ntt_res: [],
        aflt_wise_lve_policy_res: []
    });
    const [affiliateFilteredHolidays, setAffiliateFilteredHolidays] = useState([]);
    const [selectedaffilate, setSelectedaffilate] = useState('');
    const [selectedaffilate1, setSelectedaffilate1] = useState('');
    const [selectedaffilate2, setSelectedaffilate2] = useState('');
    const [selectedAfltKey, setSelectedAfltKey] = useState('');
    const [selectedAfltKey1, setSelectedAfltKey1] = useState('');
    const [selectedAfltKey2, setSelectedAfltKey2] = useState('');
    console.log("holidaykey", holidaykey)
    const handleAddHoliday = () => {
        const newHoliday = {
            date: holidayDate,
            type: holidayType,
            display: isDisplay,
            description: holidayDescription,
        };
        setHolidays([...holidays, newHoliday]);
        // Clear input fields
        setHolidayDate('');
        setHolidayType('');
        setIsDisplay('');
        setHolidayDescription('');
    };
    const handleClose = () => {
        setEditingHoliday1(null);
    };
    const handleaffilate = (event) => {
        const selectedAffilate = event.target.value;
        setSelectedaffilate(selectedAffilate);

        // Find the corresponding aflt_key from the aflttotalcnt data
        const selectedAffiliate = aflttotalcnt.all_alf_auth_ntt_res.find(item => item.AFLT_NAME === selectedAffilate);
        if (selectedAffiliate) {
            setSelectedAfltKey(selectedAffiliate.AFLT_KY); // Set the aflt_key
        }

    };
    const handleaffilate1 = (event) => {
        const selectedAffilate = event.target.value;
        setSelectedaffilate1(selectedAffilate);

        // Find the corresponding aflt_key from the aflttotalcnt data
        const selectedAffiliate = aflttotalcnt.all_alf_auth_ntt_res.find(item => item.AFLT_NAME === selectedAffilate);
        if (selectedAffiliate) {
            setSelectedAfltKey1(selectedAffiliate.AFLT_KY); // Set the aflt_key
        }

    };

    const handleaffilate2 = (event) => {
        const selectedAffilate = event.target.value;
        setSelectedaffilate2(selectedAffilate);

        // Find the corresponding aflt_key from the aflttotalcnt data
        const selectedAffiliate = aflttotalcnt.all_alf_auth_ntt_res.find(item => item.AFLT_NAME === selectedAffilate);
        if (selectedAffiliate) {
            setSelectedAfltKey2(selectedAffiliate.AFLT_KY); // Set the aflt_key
        }

        // Filter holidays based on the selected affiliate
        // const filteredHolidays = holidays.filter(holiday => holiday.aflt_key === selectedAffiliate.AFLT_KY);
        // setAffiliateFilteredHolidays(filteredHolidays);
    };
    useEffect(() => {
        const shw_hlod_res = async () => {
            try {
                const holiday_dt = await services.get_aflt_lev_holiday_cal();
                if (holiday_dt) {
                    Setholidaycal(holiday_dt);

                    // Filter holidays for the current year and set to filteredHolidays
                    const currentYearHolidays = holiday_dt.aflt_lve_hld_calndr_res.filter(leave => {
                        const holidayDate = moment(leave.HOLIDAY_DATE);
                        return holidayDate.year() === currentYear;
                    });
                    setFilteredHolidays(currentYearHolidays); // Initialize filtered holidays to current year
                } else {
                    console.error("Unexpected data format:", holiday_dt);
                }
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };
        shw_hlod_res();
    }, [currentYear]); // Add currentYear as a dependency


    const rederafterupdate = () => {

        const shw_hlod_res = async () => {
            try {
                const holiday_dt = await services.get_aflt_lev_holiday_cal();
                if (holiday_dt) {
                    Setholidaycal(holiday_dt);

                    // Filter holidays for the current year and set to filteredHolidays
                    const currentYearHolidays = holiday_dt.aflt_lve_hld_calndr_res.filter(leave => {
                        const holidayDate = moment(leave.HOLIDAY_DATE);
                        return holidayDate.year() === currentYear;
                    });
                    setFilteredHolidays(currentYearHolidays); // Initialize filtered holidays to current year
                } else {
                    console.error("Unexpected data format:", holiday_dt);
                }
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };
        shw_hlod_res();

    };
    console.log("holidaycal", holidaycal)
    console.log("holidayDate1", holidayDate1)
    const handleFilter = () => {
        const filtered = holidaycal.aflt_lve_hld_calndr_res.filter(leave => {
            const holidayDate = moment(leave.HOLIDAY_DATE);
            return holidayDate.year() === selectedYear; // Only filter by year
        });
        setFilteredHolidays(filtered);

    };
    const handleOptionChange = (e) => {
        setSelectedOption(e.target.value);
        if (e.target.value === "editHoliday") {
            // Reset editing state if Edit is selected
            setEditingHoliday(null);
        }
    };
    const handleOptionChange1 = (e) => {
        setSelectedOption(e.target.value);
        if (e.target.value === "editHoliday") {
            // Reset editing state if Edit is selected
            setEditingHoliday1(null);
        }
    };

    const handleDateChange = (e) => {
        const newDate = moment(e.target.value); // Use moment to parse the date
        if (newDate.isValid()) {
            setHolidayDate1(newDate.format('YYYY-MM-DD')); // Set the formatted date
        } else {
            console.error("Invalid date selected");
        }
    };
    const handle_excel_FileUpload = (event) => {
        const file = event.target.files[0];
        const reader = new FileReader();

        reader.onload = (e) => {
            const data = new Uint8Array(e.target.result);
            const workbook = XLSX.read(data, { type: "array" });
            const sheetName = workbook.SheetNames[0];
            const sheet = workbook.Sheets[sheetName];
            const jsonData = XLSX.utils.sheet_to_json(sheet, { header: 1 });

            const mappedData = jsonData
                .slice(1)
                .filter((row) =>
                    row.some((cell) => cell !== undefined && cell !== null && cell !== "")
                )
                .map((row) => ({
                    col1: row[0] ?? null,
                    col2: row[1] ?? null,
                    col3: row[2] ?? null,
                    col4: row[3] ?? null,
                    col5: row[4] ?? null,
                }));
            setFileData(mappedData);
        };
        if (file) {
            reader.readAsArrayBuffer(file);
        }
    };

    const handle_upload_excel_submit = async (event) => {
        event.preventDefault();
        if (!fileData) {
            alert("Please upload a file.");
            return;
        }

        try {
            const response = await fetch(
                "/rdbs/dash_cust/add_holiday_excel_sys_usr",
                {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({
                        aflt_key: selectedAfltKey,
                        data: fileData
                    }),
                }
            );
            if (response.ok) {
                alert("Data uploaded successfully!");
            } else {
                alert("Error uploading data.");
            }
        } catch (error) {
            console.error("Error:", error);
        }
    };

    const handleSubmitAllHolidays = async () => {
        const modifiedholidays = holidays.map(holiday => ({
            ...holiday,
            aflt_key: selectedAfltKey1
        }));
        console.log("modifiedholidays", modifiedholidays)
        try {
            const add_holiday_dt = await services.add_holiday_cal_sys_usr(modifiedholidays);
            if (add_holiday_dt) {
                alert("Holidays Added Successfully...");
                setHolidays([]);
            } else {
                alert("Failed to Add Holidays ...");
            }
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };
    const handleEditHoliday = (holiday) => {
        console.log("Editing holiday:", holiday);
        setEditingHoliday1(holiday);

        // Format the date to YYYY-MM-DD
        const formattedDate = moment(holiday.HOLIDAY_DATE).format('YYYY-MM-DD');
        setHolidayDate1(formattedDate); // Set the formatted date
        console.log("formattedDate", formattedDate)
        setHolidayType1(holiday.HOLIDAY_TYP);
        setIsDisplay1(holiday.IS_DISPLY_YN);
        setHolidayDescription1(holiday.DISCRIPTION);
        setholidaykey(holiday.HOLIDAY_KY)
    };
    const handleSaveEditedHoliday = async () => {
        const updatedHolidays = {
            HOLIDAY_DATE: holidayDate1,
            HOLIDAY_TYP: holidayType1,
            display: isDisplay1,
            DISCRIPTION: holidayDescription1,
            HOLIDAY_KY: holidaykey,
            aflt_ky: selectedAfltKey2
        }
        console.log("Updated Holidays:", updatedHolidays);

        try {
            const upd_holiday_dt = await services.upd_hld_cal_aflt(updatedHolidays);
            if (upd_holiday_dt) {
                alert("Holiday Updated Successfully...");
                setholidaykey([]);
                handleClose();
                rederafterupdate()
            } else {
                alert("Failed to Updated Holiday ...");
            }
        } catch (error) {
            console.error("Error fetching data:", error);
        }

    };
    useEffect(() => {
        const fetch = async () => {
            try {
                const res = await services.get_total_aflt_cnt();
                if (res) {
                    setaflttotalcnt(res);
                }
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };
        fetch();
    }, []);
    const formatDate = (dateString) => {
        return moment(dateString).format('DD/MMM/YYYY').replace(/\/([a-z])/g, (match) => '/' + match[1].toUpperCase());
    };
    const formattedDate = holidayDate1 instanceof Date ? holidayDate1.toISOString().split('T')[0] : '';
    const filterHolidays = () => {
        console.log("selectedYear and selectedaffilate2", selectedYear, selectedaffilate2)
        if (!selectedaffilate2 || !selectedYear) return []; // Return empty if no affiliate or year is selected

        return holidaycal.aflt_lve_hld_calndr_res.filter(holiday => {
            const holidayDate = moment(holiday.HOLIDAY_DATE);
            return holiday.AFLT_KY === selectedAfltKey2 && holidayDate.year() === selectedYear;
        });
    };
    const filteredHoliday = filterHolidays();
    console.log("filteredHoliday", filteredHoliday)
    return (
        <div className="wrapper_form">
            <div className="title" style={{ marginBottom: "1.4%" }}>Holiday Management</div>
            <div className="radio-row" style={{ marginBottom: "5px" }}>
                <label>
                    <input
                        type="radio"
                        name="addOption"
                        value="uploadExcel"
                        checked={selectedOption === "uploadExcel"}
                        onChange={handleOptionChange}
                        style={{ width: "2%" }}
                    />
                    Add .CSV File
                </label> <hr className="hr1" />
                {selectedOption === "uploadExcel" && (
                    <div className="radio-content">
                        <form className="pdm" onSubmit={handle_upload_excel_submit}>
                            <div style={{ margin: "1% 1% 1% 0% " }}>
                                <label htmlFor="affilate">Select Client : </label>
                                <select
                                    id="affilate"
                                    value={selectedaffilate}
                                    onChange={handleaffilate}
                                    style={{ borderRadius: "8px", width: "35%" }}
                                >
                                    <option value="">Select Client</option>
                                    {Array.from(new Set(aflttotalcnt.alt_wise_lve_types_res.map(item => item.AFLT_NAME)))
                                        .map((affilate) => (
                                            <option key={affilate} value={affilate}>
                                                {affilate}
                                            </option>
                                        ))}
                                </select>
                            </div>
                            <input
                                type="file"
                                accept=".xlsx, .xls, .csv"
                                onChange={handle_excel_FileUpload}
                            /><br />
                            <div className="centered-container" style={{ marginTop: "1px", marginBottom: "5px", }}>
                                <button
                                    style={{
                                        marginTop: "5px",
                                        marginBottom: "5px",
                                        width: "12%", // Adjust as needed
                                        textAlign: "center",
                                    }}
                                    type="submit"
                                    className="btn-action action-button "
                                >
                                    Upload File
                                </button>
                            </div>

                        </form>
                    </div>
                )}

            </div>
            <div className="radio-row" style={{ marginBottom: "5px" }}>
                <label>
                    <input
                        type="radio"
                        name="addOption"
                        value="addManually"
                        checked={selectedOption === "addManually"}
                        onChange={handleOptionChange}
                        style={{ width: "2%" }}
                    />
                    Add Manually
                </label> <hr className="hr1" />

                {selectedOption === "addManually" && (
                    <>
                        <form className="frm_wd">

                            <hr className="hr_ttl" />
                            <p
                                style={{
                                    fontSize: "25px",
                                    paddingTop: "5px",
                                    color: "black",
                                }}
                            >
                                Holiday information
                            </p>
                            <hr className="hr_dt" />

                            <div className="row">
                                <div className="column drp_ln_hg">
                                    <label className="drp_mg" style={{ marginLeft: "5px" }}>Select Client :</label>
                                    <div className="field">
                                        <select
                                            id="affilate"
                                            value={selectedaffilate1}
                                            onChange={handleaffilate1}
                                            style={{ borderRadius: "8px", width: "100%" }}
                                        >
                                            <option value="">Select Client</option>
                                            {Array.from(new Set(aflttotalcnt.alt_wise_lve_types_res.map(item => item.AFLT_NAME)))
                                                .map((affilate) => (
                                                    <option key={affilate} value={affilate}>
                                                        {affilate}
                                                    </option>
                                                ))}
                                        </select>
                                    </div>
                                </div>
                                <div className="column drp_ln_hg">
                                    <label className="drp_mg" style={{ marginLeft: "5px" }}>Holiday Date:</label>
                                    <div className="field">
                                        <input
                                            type="date"
                                            value={holidayDate}
                                            onChange={(e) => setHolidayDate(e.target.value)}
                                            style={{ marginBottom: '10px', lineHeight: "25px", height: "25px", padding: "2px", borderRadius: "8px" }}
                                        />
                                    </div>
                                </div>
                                <div className="column drp_ln_hg">
                                    <label className="drp_mg" style={{ marginLeft: "1%" }}>Holiday Type:</label>
                                    <div className="field">
                                        <input
                                            type="text"
                                            value={holidayType}
                                            onChange={(e) => setHolidayType(e.target.value)}
                                            style={{ marginBottom: '10px', lineHeight: "25px", height: "25px", borderRadius: "8px" }}
                                            placeholder="Enter Holiday Type"
                                        />
                                    </div>
                                </div>
                                <div className="column" style={{ lineHeight: "12px", marginTop: "5px" }}>
                                    <label style={{ marginLeft: "0%" }}>Display On Screen?</label>
                                    <div style={{ marginLeft: "0%" }}>
                                        <FormControl>
                                            <RadioGroup row>
                                                <FormControlLabel
                                                    value="Y"
                                                    control={<Radio />}
                                                    label="Yes"
                                                    checked={isDisplay === 'Y'}
                                                    onChange={() => setIsDisplay('Y')}
                                                />
                                                <FormControlLabel
                                                    value="N"
                                                    control={<Radio />}
                                                    label="No"
                                                    checked={isDisplay === 'N'}
                                                    onChange={() => setIsDisplay('N')}
                                                />
                                            </RadioGroup>
                                        </FormControl>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="column drp_ln_hg">
                                    <label className="drp_mg" style={{ marginLeft: "1%" }}>
                                        Holiday Description:
                                        <textarea
                                            value={holidayDescription}
                                            placeholder="Enter Holiday Description"
                                            onChange={(e) => setHolidayDescription(e.target.value)}
                                            style={{ marginBottom: '10px', width: '100%', height: '50px', borderRadius: "8px" }}
                                        />
                                    </label>
                                </div>
                            </div>
                            <hr className="hr_dt" />
                            <Button className="btn-action action-button" style={{ lineHeight: "25px", height: "25px", marginLeft: "5%", width: "10%", fontSize: "bold", textAlign: "right", float: "right", marginBottom: ".5%" }} onClick={handleAddHoliday}>
                                Add Holiday
                            </Button>

                            <hr className="hr_dt" />

                            <Grid item xs={12}>
                                <TableContainer
                                    component={Paper}
                                    style={{
                                        margin: "5px auto 25px auto",
                                        overflow: "hidden",
                                        boxShadow: "0px 6px 16px 3px #bababa",
                                        borderRadius: "20px",
                                        border: "2px solid gray",
                                    }}
                                >
                                    <Table style={{ width: "100%" }}>
                                        <TableHead>
                                            <TableRow style={{ width: "100%" }}>
                                                <TableCell style={{ fontSize: "0.8rem", padding: "1px 18px", backgroundColor: "#4a6d8c", border: "1px solid gray", color: "white", textAlign: "center" }}>
                                                    Sr.No.
                                                </TableCell>
                                                <TableCell style={{ fontSize: "0.8rem", padding: "1px 18px", backgroundColor: "#4a6d8c", border: "1px solid gray", color: "white", textAlign: "center" }}>
                                                    Date
                                                </TableCell>
                                                <TableCell style={{ fontSize: "0.8rem", padding: "1px 18px", backgroundColor: "#4a6d8c", border: "1px solid gray", color: "white", textAlign: "center" }}>
                                                    Holiday Type
                                                </TableCell>
                                                <TableCell style={{ fontSize: "0.8rem", padding: "1px 18px", backgroundColor: "#4a6d8c", border: "1px solid gray", color: "white", textAlign: "center" }}>
                                                    Display
                                                </TableCell>
                                                <TableCell style={{ fontSize: "0.8rem", padding: "1px", backgroundColor: "#4a6d8c", border: "1px solid gray", color: "white", textAlign: "center" }}>
                                                    Description
                                                </TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {holidays.map((leave, index) => (
                                                <TableRow style={{ height: "10px" }} key={index}>
                                                    <TableCell align="center" style={{ fontSize: "0.8rem", padding: "1px 18px", border: "1px solid gray" }}>
                                                        {index + 1}
                                                    </TableCell>
                                                    <TableCell align="center" style={{ fontSize: "0.8rem", padding: "1px 18px", border: "1px solid gray" }}>
                                                        {formatDate(leave.date)}
                                                    </TableCell>
                                                    <TableCell align="center" style={{ fontSize: "0.8rem", padding: "1px 18px", border: "1px solid gray" }}>
                                                        {leave.type}
                                                    </TableCell>
                                                    <TableCell align="center" style={{ fontSize: "0.8rem", padding: "1px", border: "1px solid gray" }}>
                                                        {leave.display}
                                                    </TableCell>
                                                    <TableCell align="center" style={{ fontSize: "0.8rem", padding: "1px", border: "1px solid gray" }}>
                                                        {leave.description}
                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                            {/* Add empty rows if there are less than 3 rows */}
                                            {holidays.length < 3 &&
                                                Array.from({ length: 3 - holidays.length }).map((_, index) => (
                                                    <TableRow style={{ height: "10px" }} key={`empty-row-${index}`}>
                                                        <TableCell align="center" style={{ fontSize: "0.8rem", padding: "1px 18px", border: "1px solid gray" }}>
                                                            &nbsp; {/* Empty cell for spacing */}
                                                        </TableCell>
                                                        <TableCell align="center" style={{ fontSize: "0.8rem", padding: "1px 18px", border: "1px solid gray" }}>
                                                            &nbsp; {/* Empty cell for spacing */}
                                                        </TableCell>
                                                        <TableCell align="center" style={{ fontSize: "0.8rem", padding: "1px 18px", border: "1px solid gray" }}>
                                                            &nbsp; {/* Empty cell for spacing */}
                                                        </TableCell>
                                                        <TableCell align="center" style={{ fontSize: "0.8rem", padding: "1px", border: "1px solid gray" }}>
                                                            &nbsp; {/* Empty cell for spacing */}
                                                        </TableCell>
                                                        <TableCell align="center" style={{ fontSize: "0.8rem", padding: "1px", border: "1px solid gray" }}>
                                                            &nbsp; {/* Empty cell for spacing */}
                                                        </TableCell>
                                                    </TableRow>
                                                ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Grid>

                            {/* Add All Holidays Button */}
                            {holidays.length > 0 && (
                                <div>
                                    <hr className="hr_dt" />
                                    <Button
                                        className="btn-action action-button"
                                        style={{
                                            lineHeight: "30px",
                                            height: "30px",
                                            marginTop: ".3%",
                                            marginLeft: "5%",
                                            width: "10%",
                                            fontSize: "bold",
                                            textAlign: "right",
                                            float: "right",
                                            marginBottom: ".5%"
                                        }}
                                        onClick={handleSubmitAllHolidays}
                                    >
                                        Add Holidays
                                    </Button>
                                    <hr className="hr_dt" />
                                </div>
                            )}
                        </form>
                    </>
                )}
            </div>
            <div className="radio-row" style={{ marginBottom: "5px" }}>
                <label>
                    <input
                        type="radio"
                        name="addOption"
                        value="editHoliday"
                        checked={selectedOption === "editHoliday"}
                        onChange={handleOptionChange1}
                        style={{ width: "2%" }}
                    />
                    Edit Holiday Calendar
                </label>
                {selectedOption === "editHoliday" && (
                    <div>
                        {editingHoliday1 == null ? (
                            <div className="holiday-list">
                                <Grid item xs={12} style={{ paddingLeft: "1%", paddingRight: "1%" }}>
                                    <div className="title" style={{ marginTop: "2%" }}> Holiday Calendar</div>

                                    <div style={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        marginBottom: '20px',
                                        marginTop: "10px"
                                    }}>
                                        <label htmlFor="affilate" style={{ marginRight: '10px', lineHeight: "25px", height: "25px" }}>Select Client : </label>
                                        <select
                                            id="affilate"
                                            value={selectedaffilate2}
                                            onChange={handleaffilate2}
                                            style={{ borderRadius: "8px", width: "20%", lineHeight: "25px", height: "25px" }}
                                        >
                                            <option value="">Select Client</option>
                                            {Array.from(new Set(aflttotalcnt.alt_wise_lve_types_res.map(item => item.AFLT_NAME)))
                                                .map((affilate) => (
                                                    <option key={affilate} value={affilate}>
                                                        {affilate}
                                                    </option>
                                                ))}
                                        </select>

                                        <label style={{ marginLeft: "5%", marginRight: '10px', lineHeight: "25px", height: "25px" }}>Select Year:</label>
                                        <select
                                            value={selectedYear}
                                            onChange={(e) => setSelectedYear(Number(e.target.value))}
                                            style={{ width: "5%", marginRight: '10px', lineHeight: "25px", height: "25px" }}
                                        >
                                            {[currentYear - 1, currentYear, currentYear + 1].map(year => (
                                                <option key={year} value={year}>{year}</option>
                                            ))}
                                        </select>
                                        {/* <Button className="btn-action action-button" style={{ lineHeight: "25px", height: "25px", marginLeft: "5%", width: "8%", fontSize: "bold" }} onClick={handleFilter}>
                                            Show
                                        </Button> */}
                                    </div>
                                    <TableContainer component={Paper} style={{ margin: "5px auto 25px auto", overflow: "hidden", boxShadow: "0px 6px 16px 3px #bababa", borderRadius: "20px", border: "2px solid gray" }}>
                                        <Table style={{ width: "100%" }}>
                                            <TableHead>
                                                <TableRow style={{ width: "100%" }}>
                                                    <TableCell style={{ fontSize: "0.8rem", padding: "1px 18px", backgroundColor: "#4a6d8c", border: "1px solid gray", color: "white", textAlign: "center" }}>Sr. No.</TableCell>
                                                    <TableCell style={{ fontSize: "0.8rem", padding: "1px 18px", backgroundColor: "#4a6d8c", border: "1px solid gray", color: "white", textAlign: "center" }}>Client Name</TableCell>
                                                    <TableCell style={{ fontSize: "0.8rem", padding: "1px 18px", backgroundColor: "#4a6d8c", border: "1px solid gray", color: "white", textAlign: "center" }}>Date</TableCell>
                                                    <TableCell style={{ fontSize: "0.8rem", padding: "1px 18px", backgroundColor: "#4a6d8c", border: "1px solid gray", color: "white", textAlign: "center" }}>Day</TableCell>
                                                    <TableCell style={{ fontSize: "0.8rem", padding: "1px", backgroundColor: "#4a6d8c", border: "1px solid gray", color: "white", textAlign: "center" }}>Particulars / Occasion</TableCell>
                                                    <TableCell style={{ fontSize: "0.8rem", padding: "1px", backgroundColor: "#4a6d8c", border: "1px solid gray", color: "white", textAlign: "center" }}>Action</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {filteredHoliday.length === 0 ? (
                                                    <TableRow>
                                                        <TableCell align="center" colSpan={6} style={{ fontSize: "1rem", padding: "10px", color: "red", border: "1px solid gray" }}>No Holidays Available for Selected Client
                                                        </TableCell>
                                                        {/* <TableCell align="center" style={{ fontSize: "0.8rem", padding: "1px 18px", border: "1px solid gray" }}>&nbsp;</TableCell>
                                                        <TableCell align="center" style={{ fontSize: "0.8rem", padding: "1px 18px", border: "1px solid gray" }}>&nbsp;</TableCell>
                                                        <TableCell align="center" style={{ fontSize: "0.8rem", padding: "1px 18px", border: "1px solid gray" }}>&nbsp;</TableCell>
                                                        <TableCell align="center" style={{ fontSize: "0.8rem", padding: "1px 18px", border: "1px solid gray" }}>&nbsp;</TableCell>
                                                        <TableCell align="center" style={{ fontSize: "0.8rem", padding: "1px 18px", border: "1px solid gray" }}>&nbsp;</TableCell> */}
                                                    </TableRow>
                                                ) : (
                                                    filteredHoliday.map((leave, index) => (
                                                        <TableRow style={{ height: "10px" }} key={leave.HOLIDAY_KY}>
                                                            <TableCell align="center" style={{ fontSize: "0.8rem", padding: "1px 18px", border: "1px solid gray" }}>
                                                                {index + 1}
                                                            </TableCell>
                                                            <TableCell align="center" style={{ fontSize: "0.8rem", padding: "1px 18px", border: "1px solid gray" }}>
                                                                {selectedaffilate2}
                                                            </TableCell>
                                                            <TableCell align="center" style={{ fontSize: "0.8rem", padding: "1px 18px", border: "1px solid gray" }}>
                                                                {formatDate(leave.HOLIDAY_DATE)}
                                                            </TableCell>
                                                            <TableCell align="center" style={{ fontSize: "0.8rem", padding: "1px 18px", border: "1px solid gray" }}>{moment(leave.HOLIDAY_DATE).format('dddd')}</TableCell>
                                                            <TableCell align="center" style={{ fontSize: "0.8rem", padding: "1px 18px", border: "1px solid gray" }}>
                                                                {leave.HOLIDAY_TYP}
                                                            </TableCell>
                                                            <TableCell align="center" style={{ fontSize: "0.8rem", padding: "1px", border: "1px solid gray" }}>
                                                                <EditIcon style={{ color: "#4a6d8c", cursor: "pointer" }} fontSize="small" onClick={() => handleEditHoliday(leave)} />
                                                            </TableCell>
                                                        </TableRow>
                                                    ))
                                                )}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Grid>
                            </div>
                        ) : (
                            <div>
                                <div className="frm_wd1" style={{ padding: "20px", marginTop: "2px" }}>
                                    {/* <Typography variant="h6">Holiday Details</Typography> */}
                                    <div className="titles" style={{ marginBottom: "1.4%" }}>Edit Holiday Details</div>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} sm={4}>
                                            <TextField
                                                fullWidth
                                                label="Date"
                                                type="date"
                                                value={holidayDate1} // Use holidayDate1 directly
                                                onChange={handleDateChange} // Update the state when a new date is selected
                                                variant="filled"
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={4}>
                                            <TextField
                                                fullWidth
                                                label="Leave Type"
                                                value={holidayType1}
                                                onChange={(e) => setHolidayType1(e.target.value)} // Make editable
                                                variant="filled"
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={4}>
                                            <div className="abc" style={{ display: 'flex', alignItems: 'center', marginTop: "1.5%" }}>
                                                <label style={{ marginRight: '10px' }}>Display on Screen?</label>
                                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                                    <input
                                                        type="radio"
                                                        value="Y"
                                                        checked={isDisplay1 == "Y"}
                                                        onChange={(e) => setIsDisplay1(e.target.value)}
                                                        id="yes"
                                                    />
                                                    <label htmlFor="yes" style={{ marginRight: '10px' }}>Yes</label>
                                                    <input
                                                        type="radio"
                                                        value="N"
                                                        checked={isDisplay1 == "N"}
                                                        onChange={(e) => setIsDisplay1(e.target.value)}
                                                        id="no"
                                                    />
                                                    <label htmlFor="no">No</label>
                                                </div>
                                            </div>

                                        </Grid>
                                        <Grid item xs={12} sm={12}>
                                            <TextField
                                                fullWidth
                                                label="Description"
                                                value={holidayDescription1}
                                                onChange={(e) => setHolidayDescription1(e.target.value)} // Make editable
                                                variant="filled"
                                                multiline
                                                rows={3}
                                            />
                                        </Grid>
                                    </Grid>
                                    <div
                                        style={{
                                            marginTop: "10px",
                                            display: "flex",
                                        }}
                                    >
                                        <Button
                                            className="action-button"
                                            style={{ width: "10%", marginRight: "15px" }}
                                            onClick={handleClose}
                                        >
                                            Cancel
                                        </Button>
                                        <Button
                                            className="action-button"
                                            style={{ width: "10%", marginRight: "15px" }}
                                            onClick={handleSaveEditedHoliday}
                                        >
                                            Update
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                )}
            </div>
        </div>
    )
}
export default Sys_Usr_Aflt_Holiday_Mngment