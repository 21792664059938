import { Link, useLocation } from "react-router-dom";
import React, { useEffect, useState } from "react";
import services from "./apiServices";
import "../css/Navbar.css"; // Ensure you have the CSS in this file
import { logout } from "./Logout";

export default function Navbar(props) {

  const sessionDuration = 30 * 60 * 1000; // 30 minutes in milliseconds
  const [remainingTime, setRemainingTime] = useState(sessionDuration / 1000);
  const location = useLocation(); // Get the current location
  const [logoutSuccess, setLogoutSuccess] = useState(false);

  const resetTimer = () => {
    setRemainingTime(sessionDuration / 1000); // Reset remaining time to 30 minutes
  };
  // Determine the active tab based on the current location
  const getActiveTab = () => {
    switch (location.pathname) {
      case "/":
        return "dashboard";

      case "/emp_lev_app":

      case "/obo_emp_lev_app":
        return "application";

      case "/usr_profile":
      case "/change_pass":
        return "profile";

      case "/cust_rep_lve_bal_consume":
      case "/adm_agine_rpt_of_lve":
      case "/cust_rep_lve_apl_print":
      case "/manager_rpt_team_mem_list":
      case "/manager_agine_rpt_of_lve":
      case "/manager_rpt_lve_apl_print":
      case "/adm_affilate_lve_typ":
      case "/adm_rpt_all_emp_lve_dtls":
      case "/adm_projected_on_lve_emp_list":
      case "/manager_rpt_team_mem_le_bal_consm":
      case "/adm_rpt_todays_lev_emp_list":
      case "/client_sts_wise_lst":
      case "/aflt_wise_lve_type":
      case "/aflt_lve_sts_posts":
      case "/aflt_wise_usr_list":
      case "/client_lve_apl_cnt":
      case "/clientconfiginfo":
        return "reports";

      case "/adm_add_usr":
      case "/adm_aflt_lve_type_management":
      case "/adm_historical_annocement_managment":
      case "/adm_change_emp_password":
      case "/sys_usr_crete_new_usr":
      case "/sys_usr_lve_type_managment":
      case "/change_reset_pass":
      case "/sys_usr_lve_confg":
        return "admin";

      case "/log_out":
        return "logout"

      case "/cust_lve_policy":
      case "/adm_add_lve_policy":
      case "/cust_holliday_calendar":
      case "/adm_holiday_calendar":
      case "/adm_add_holiday":
      case "/adm_historical_annocement":
        return "lve_policy"

      case "/register":
      case "/aflt_new_usr":
      case "/sys_usr_Aflt_lve_type_managment":
      case "/sys_usr_chnage_aflt_pass":
      case "/sys_usr_lve_policy_mangment":

        return "client_configration"

      default:
        return "";
    }
  };
  const [activeTab, setActiveTab] = useState(getActiveTab());

  useEffect(() => {
    setActiveTab(getActiveTab()); // Update active tab on location change
  }, [location]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setRemainingTime(prevTime => {
        if (prevTime <= 0) {
          clearInterval(intervalId);
          handleLogout(); // Log out when time reaches 0
          return 0;
        }
        return prevTime - 1; // Decrement time
      });
    }, 1000); // Update every second

    // Add event listeners to reset timer on user activity
    window.addEventListener('mousemove', resetTimer);
    window.addEventListener('keypress', resetTimer);
    window.addEventListener('click', resetTimer);

    return () => {
      clearInterval(intervalId); // Cleanup on unmount
      window.removeEventListener('mousemove', resetTimer);
      window.removeEventListener('keypress', resetTimer);
      window.removeEventListener('click', resetTimer);
    };
  }, []);

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${String(minutes).padStart(2, "0")}:${String(secs).padStart(2, "0")}`;
  };

  const handleLogout = async () => {
    try {
      await logout(); // Call the logout API
      sessionStorage.removeItem('sessionStartTime'); // Clear session start time
      setLogoutSuccess(true);
      setTimeout(() => {
        setLogoutSuccess(false);
        window.location.href = '/'; // Redirect to homepage or login page
      }, 100); // Delay of 1 second
    } catch (error) {
      console.error('Logout failed:', error.message);
    }
  };

  return props.nav == "O" ? (
    <>
      <header class="fixedheader pd1 ">
        <div class="container_12">
          <div class="grid_12">
            <div class="wrapper">
              {" "}
              <Link style={{ cursor: "default" }} class="logo">Sutti</Link>
              <div class="nav">
                <div className={`subnav ${activeTab === "dashboard" ? "active" : ""}`} style={{ marginTop: "1px" }}>
                  <Link to="/" class="subnavbtn">
                    Dashboard<i class="fa fa-caret-down"></i>
                  </Link>
                </div>
                <div className={`subnav ${activeTab === "application" ? "active" : ""}`} style={{ marginTop: "1px" }}>
                  <button class="subnavbtn">
                    Application<i class="fa fa-caret-down"></i>
                  </button>
                  <div class="subnav-content">
                    <Link to="/emp_lev_app" onClick={() => { setActiveTab("application") }}>Application</Link>
                    <Link to="/obo_emp_lev_app" onClick={() => { setActiveTab("application") }}>Application OBO</Link>
                  </div>
                </div>
                <div className={`subnav ${activeTab === "profile" ? "active" : ""}`}>
                  <button class="subnavbtn">
                    Profile <i class="fa fa-caret-down"></i>
                  </button>
                  <div class="subnav-content">
                    <Link to="/usr_profile" onClick={() => { setActiveTab("profile") }} >User Profile</Link>
                    <Link to="/change_pass" onClick={() => { setActiveTab("profile") }}>Change Password</Link>
                  </div>
                </div>

                <div className={`subnav ${activeTab === "admin" ? "active" : ""}`}>
                  <button class="subnavbtn">
                    Admin <i class="fa fa-caret-down"></i>
                  </button>
                  <div class="subnav-content">
                    <Link to="/adm_add_usr" onClick={() => setActiveTab("admin")}>Add User</Link>
                    <Link to="/adm_aflt_lve_type_management" onClick={() => setActiveTab("admin")}>Leave Management</Link>
                    <Link to="/adm_historical_annocement_managment" onClick={() => setActiveTab("admin")}>Add/Edit Annocements</Link>
                    <Link to="/adm_change_emp_password" onClick={() => setActiveTab("admin")}>Change/Reset Password</Link>
                    <Link to="#deliver">Month End Procedure</Link>
                    <Link to="#deliver">Year End Procedure</Link>
                  </div>
                </div>
                <div className={`subnav ${activeTab === "reports" ? "active" : ""}`}>
                  <button class="subnavbtn">
                    Reports<i class="fa fa-caret-down"></i>
                  </button>
                  <div class="subnav-content">
                    <Link to="/cust_rep_lve_bal_consume" onClick={() => setActiveTab("reports")}>Leave Bal. and Consume</Link>
                    <Link to="/adm_agine_rpt_of_lve" onClick={() => setActiveTab("reports")}>Agine Analyasis</Link>
                    <Link to="/manager_rpt_team_mem_list" >Team Members</Link>
                    <Link to="/manager_rpt_team_mem_le_bal_consm" onClick={() => setActiveTab("reports")} >Team members Leave Bal. and Consume</Link>
                    <Link to="/adm_affilate_lve_typ" onClick={() => setActiveTab("reports")} >Leave Types</Link>
                    <Link to="/adm_rpt_all_emp_lve_dtls" onClick={() => setActiveTab("reports")} >All Employees & Leave Details </Link>
                    <Link to="/adm_projected_on_lve_emp_list" onClick={() => setActiveTab("reports")} >Projected Employee On Leave </Link>
                    <Link to="/manager_rpt_lve_apl_print" onClick={() => setActiveTab("reports")} >Application Print</Link>
                    <Link to="/adm_rpt_todays_lev_emp_list" onClick={() => setActiveTab("reports")} >Leave On Today Employees </Link>
                  </div>
                </div>
                <div className={`subnav ${activeTab === "lve_policy" ? "active" : ""}`}>
                  <button className="subnavbtn">
                    Policy<i className="fa fa-caret-down"></i>
                  </button>
                  <div class="subnav-content">
                    <Link to="/cust_lve_policy" onClick={() => setActiveTab("lve_policy")}>Leave Policy</Link>
                    <Link to="/adm_add_lve_policy" onClick={() => setActiveTab("lve_policy")}>Leave Policy Management</Link>
                    <Link to="/adm_holiday_calendar" onClick={() => setActiveTab("lve_policy")}>Holiday Calendar</Link>
                    <Link to="/adm_add_holiday" onClick={() => setActiveTab("lve_policy")}>Holiday Calendar Management</Link>
                    <Link to="/adm_historical_annocement" onClick={() => setActiveTab("lve_policy")}>Historical Annocements </Link>
                  </div>
                </div>
                <div className={`subnav ${activeTab === "logout" ? "active" : ""}`} style={{ marginTop: "1px" }}>
                  <Link to="/log_out" className="subnavbtn" onClick={handleLogout}>
                    Logout
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
        <br style={{ marginBottom: "1%" }} />
        <hr style={{ margin: "1px", color: "rgba(255, 255, 255, 0.59)" }} />


        <div class="row wrapper pd1" style={{ marginBottom: ".1%" }}>
          <div className="column " style={{ marginLeft: "2%" }}>
            <b>User: {props.nav1}</b>
          </div>
          <div className="column" style={{ textAlign: "end", marginRight: '2%' }}>
            <b>
              Session Time: {formatTime(remainingTime)}
            </b>
          </div>
        </div>
      </header>
    </>
  ) : props.nav === "C" ? (
    props.post === "MNGR" ? (
      <>
        <header class="fixedheader pd1 ">
          <div class="container_12">
            <div class="grid_12">
              <div class="wrapper">
                {" "}
                <Link style={{ cursor: "default" }} class="logo">Sutti</Link>
                <div class="nav">
                  <div className={`subnav ${activeTab === "dashboard" ? "active" : ""}`} style={{ marginTop: "1px" }}>
                    <Link to="/" className="subnavbtn" onClick={() => setActiveTab("dashboard")}>
                      Dashboard
                    </Link>
                  </div>
                  <div class="subnav">
                    <button class="subnavbtn">
                      Application<i class="fa fa-caret-down"></i>
                    </button>
                    <div class="subnav-content">
                      <Link to="/emp_lev_app">Application</Link>
                      <Link to="/obo_emp_lev_app">Application OBO</Link>
                    </div>
                  </div>
                  <div className={`subnav ${activeTab === "profile" ? "active" : ""}`}>
                    <button class="subnavbtn">
                      Profile <i class="fa fa-caret-down"></i>
                    </button>
                    <div class="subnav-content">
                      <Link to="/usr_profile" onClick={() => { setActiveTab("profile") }} >User Profile</Link>
                      <Link to="/change_pass" onClick={() => { setActiveTab("profile") }}>Change Password</Link>
                    </div>
                  </div>

                  <div className={`subnav ${activeTab === "reports" ? "active" : ""}`}>
                    <button className="subnavbtn">
                      Reports<i className="fa fa-caret-down"></i>
                    </button>

                    <div class="subnav-content">
                      <Link to="/cust_rep_lve_bal_consume" onClick={() => setActiveTab("reports")}>Leave Bal. and Consume</Link>
                      <Link to="/manager_agine_rpt_of_lve" onClick={() => setActiveTab("reports")}>Aging Analyasis</Link>
                      <Link to="/manager_rpt_team_mem_list" onClick={() => setActiveTab("reports")}>Team members</Link>
                      <Link to="/manager_rpt_team_mem_le_bal_consm" onClick={() => setActiveTab("reports")} >Team members Leave Bal. & Consume</Link>
                      <Link to="/manager_rpt_lve_apl_print" onClick={() => setActiveTab("reports")}>Applications Print </Link>
                    </div>
                  </div>
                  <div className={`subnav ${activeTab === "lve_policy" ? "active" : ""}`}>
                    <button className="subnavbtn">
                      Policy<i className="fa fa-caret-down"></i>
                    </button>
                    <div class="subnav-content">
                      <Link to="/cust_lve_policy" onClick={() => setActiveTab("lve_policy")}>Leave Policy</Link>
                      <Link to="/cust_holliday_calendar" onClick={() => setActiveTab("lve_policy")}>Holiday Calendar</Link>
                    </div>
                  </div>

                  <div className={`subnav ${activeTab === "logout" ? "active" : ""}`} style={{ marginTop: "1px" }}>
                    <Link to="/log_out" className="subnavbtn" onClick={handleLogout}>
                      Logout
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <br style={{ marginBottom: "1%" }} />
          <hr style={{ margin: "1px", color: "rgba(255, 255, 255, 0.59)" }} />
          <div class="row wrapper pd1" style={{ marginBottom: ".1%" }}>
            <div className="column " style={{ marginLeft: "2%" }}>
              <b>User: {props.nav1}</b>
            </div>
            <div className="column" style={{ textAlign: "end", marginRight: '2%' }}>
              <b>
                Session Time: {formatTime(remainingTime)}
              </b>
            </div>
          </div>
        </header>
      </>
    ) : (
      <>
        <header class="fixedheader pd1 ">
          <div class="container_12">
            <div class="grid_12">
              <div class="wrapper">
                {" "}
                <Link style={{ cursor: "default" }} class="logo">Sutti</Link>
                <div className="nav">
                  <div className={`subnav ${activeTab === "dashboard" ? "active" : ""}`} style={{ marginTop: "1px" }}>
                    <Link to="/" className="subnavbtn" onClick={() => setActiveTab("dashboard")}>
                      Dashboard
                    </Link>
                  </div>
                  <div className={`subnav ${activeTab === "application" ? "active" : ""}`}>
                    <button className="subnavbtn">
                      Application<i className="fa fa-caret-down"></i>
                    </button>
                    <div className="subnav-content">
                      <Link to="/emp_lev_app" onClick={() => setActiveTab("application")}>Application</Link>
                      <Link to="/obo_emp_lev_app" onClick={() => setActiveTab("application")}>Application OBO</Link>
                    </div>
                  </div>
                  <div className={`subnav ${activeTab === "profile" ? "active" : ""}`}>
                    <button class="subnavbtn">
                      Profile <i class="fa fa-caret-down"></i>
                    </button>
                    <div class="subnav-content">
                      <Link to="/usr_profile" onClick={() => { setActiveTab("profile") }} >User Profile</Link>
                      <Link to="/change_pass" onClick={() => { setActiveTab("profile") }}>Change Password</Link>
                    </div>
                  </div>
                  <div className={`subnav ${activeTab === "reports" ? "active" : ""}`}>
                    <button className="subnavbtn">
                      Reports<i className="fa fa-caret-down"></i>
                    </button>
                    <div className="subnav-content">
                      <Link to="/cust_rep_lve_bal_consume" onClick={() => setActiveTab("reports")}>Leave Balance and Consumption</Link>
                      <Link to="/cust_rep_lve_apl_print" onClick={() => setActiveTab("reports")}>Applications Print</Link>
                    </div>
                  </div>
                  <div className={`subnav ${activeTab === "lve_policy" ? "active" : ""}`}>
                    <button className="subnavbtn">
                      Policy<i className="fa fa-caret-down"></i>
                    </button>
                    <div class="subnav-content">
                      <Link to="/cust_lve_policy" onClick={() => setActiveTab("lve_policy")}>Leave Policy</Link>
                      <Link to="/cust_holliday_calendar" onClick={() => setActiveTab("lve_policy")}>Holiday Calendar</Link>
                    </div>
                  </div>
                  <div className={`subnav ${activeTab === "logout" ? "active" : ""}`} style={{ marginTop: "1px" }}>
                    <Link to="/log_out" className="subnavbtn" onClick={handleLogout}>
                      Logout
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div >
          <br style={{ marginBottom: "1%" }} />
          <hr style={{ margin: "1px", color: "rgba(255, 255, 255, 0.59)" }} />
          <div class="row wrapper pd1" style={{ marginBottom: ".1%" }}>
            <div className="column " style={{ marginLeft: "2%" }}>
              <b>User: {props.nav1}</b>
            </div>
            <div className="column" style={{ textAlign: "end", marginRight: '2%' }}>
              <b>
                Session Time: {formatTime(remainingTime)}
              </b>
            </div>
          </div>
        </header >
      </>
    )
  ) : props.nav == "Adm" ? (
    <>
      <header class="fixedheader pd1 ">
        <div class="container_12">
          <div class="grid_12">
            <div class="wrapper">
              {" "}
              <Link style={{ cursor: "default" }} class="logo">Sutti</Link>
              <div class="nav">
                <div className={`subnav ${activeTab === "dashboard" ? "active" : ""}`} style={{ marginTop: "1px" }}>
                  <Link to="/" className="subnavbtn" onClick={() => setActiveTab("dashboard")}>
                    Dashboard
                  </Link>
                </div>
                <div className={`subnav ${activeTab === "client_configration" ? "active" : ""}`}>
                  <button className="subnavbtn">
                    Client Configuration<i className="fa fa-caret-down"></i>
                  </button>
                  <div className="subnav-content">
                    <Link to="/register" onClick={() => setActiveTab("client_configration")}>Client Creation </Link>
                    <Link to="/aflteditview" onClick={() => setActiveTab("client_configration")}>Client Management </Link>
                    <Link to="/aflt_new_usr" onClick={() => setActiveTab("client_configration")}>User Creation</Link>
                    <Link to="/sys_usr_Aflt_lve_type_managment" onClick={() => setActiveTab("client_configration")}>Client Leave Types Management</Link>
                    <Link to="/sys_usr_chnage_aflt_pass" onClick={() => setActiveTab("client_configration")}>Change/Reset Client Password</Link>
                    <Link to="/sys_usr_lve_policy_mangment" onClick={() => setActiveTab("client_configration")}>Client Leave Policy Management</Link>
                    <Link to="/sys_usr_aflt_holiday_management" onClick={() => setActiveTab("client_configration")}>Client Holiday Calendar Management</Link>
                  </div>
                </div>
                <div className={`subnav ${activeTab === "profile" ? "active" : ""}`}>
                  <button class="subnavbtn">
                    Profile <i class="fa fa-caret-down"></i>
                  </button>
                  <div class="subnav-content">
                    <Link to="/usr_profile" onClick={() => { setActiveTab("profile") }} >User Profile</Link>
                    <Link to="/change_pass" onClick={() => { setActiveTab("profile") }}>Change Password</Link>
                  </div>
                </div>
                <div className={`subnav ${activeTab === "admin" ? "active" : ""}`}>
                  <button className="subnavbtn">
                    Admin<i className="fa fa-caret-down"></i>
                  </button>
                  <div className="subnav-content">
                    <Link to="/sys_usr_crete_new_usr" onClick={() => setActiveTab("admin")}>Add User </Link>
                    <Link to="/sys_usr_lve_confg" onClick={() => setActiveTab("admin")}>Leave Configuration </Link>
                    <Link to="/sys_usr_lve_typ_managment" onClick={() => setActiveTab("admin")}>Leave Type Management</Link>
                    <Link to="/adm_historical_annocement_managment" onClick={() => setActiveTab("admin")}>Annocements Management</Link>
                    <Link to="/change_reset_pass" onClick={() => setActiveTab("admin")}>Change/Reset Password</Link>
                  </div>
                </div>
                <div className={`subnav ${activeTab === "reports" ? "active" : ""}`}>
                  <button className="subnavbtn">
                    Reports<i className="fa fa-caret-down"></i>
                  </button>
                  <div className="subnav-content">
                    <Link to="/client_sts_wise_lst" onClick={() => setActiveTab("reports")}>Client List  </Link>
                    <Link to="/aflt_wise_lve_type" onClick={() => setActiveTab("reports")}>Client Leave Details </Link>
                    <Link to="/aflt_lve_sts_posts" onClick={() => setActiveTab("reports")}>Client Leave Status & Post </Link>
                    <Link to="/aflt_wise_usr_list" onClick={() => setActiveTab("reports")}>Client Wise Users</Link>
                    <Link to="/client_lve_apl_cnt" onClick={() => setActiveTab("reports")}>Client Applications Counts</Link>
                    <Link to="/clientconfiginfo" onClick={() => setActiveTab("reports")}>Client Configrations</Link>
                  </div>
                </div>
                <div className={`subnav ${activeTab === "logout" ? "active" : ""}`} style={{ marginTop: "1px" }}>
                  <Link to="/log_out" className="subnavbtn" onClick={handleLogout}>
                    Logout
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
        <br style={{ marginBottom: "1%" }} />
        <hr style={{ margin: "1px", color: "rgba(255, 255, 255, 0.59)" }} />
        <div class="row wrapper pd1" style={{ marginBottom: ".1%" }}>
          <div className="column " style={{ marginLeft: "2%" }}>
            <b>User: {props.nav1}</b>
          </div>
          <div className="column" style={{ textAlign: "end", marginRight: '2%' }}>
            <b>
              Session Time: {formatTime(remainingTime)}
            </b>
          </div>
        </div>
      </header>
    </>
  ) : (
    ""
  );
}

