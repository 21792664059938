import React, { useState, useRef, useEffect } from 'react';
import axios from 'axios';
import services from "./apiServices";

export default function Adm_Add_Lve_Policy() {
    const [selectedOption, setSelectedOption] = useState('');
    const [textareaValue, setTextareaValue] = useState({
        lve_policy: ""
    });
    const [textareaValue_edit, settextareaValue_edit] = useState({
        edit_lve_policy: "" // Initialize lve_policy as an empty string
    });
    const [files, setFiles] = useState([]);
    const [file, setFile] = useState(null);
    const fileInputRef = useRef(null); // Create a ref for the file input

    // Handle radio button changes
    const handleOptionChange = (e) => {
        setSelectedOption(e.target.value);
    };

    // Handle saving new Leave Policy
    const handleSave = async (e) => {
        console.log('Saved:', textareaValue);
        try {
            const response = await services.add_lve_policy(textareaValue);
            if (response) {
                console.log(response.data);
                alert("Leave Policy Added Successfully");
            }
        } catch (error) {
            console.error('Error saving policy:', error);
        }
    };
    // Handle saving new Leave Policy
    const handleupdate = async (e) => {
        console.log('Saved:', textareaValue_edit);
        try {
            const response = await services.upd_lve_policy(textareaValue_edit);
            if (response) {
                console.log(response.data);
                alert("Leave Policy Updated Successfully");
            }
        } catch (error) {
            console.error('Error saving policy:', error);
        }
    };


    // Handle file input change
    const handleFileChange = (e) => {
        setFile(e.target.files[0]);
    };

    // Handle file upload
    const handleUploadSubmit = async (e) => {
        e.preventDefault();
        const formData = new FormData();
        formData.append('file', file);

        try {
            const response = await services.upload_file(formData);
            if (response) {
                console.log(response.data);
                alert("Leave Policy Added Successfully");
                setFile(null); // Reset the file state
                if (fileInputRef.current) {
                    fileInputRef.current.value = ''; // Clear the input value
                }
            }
        } catch (error) {
            console.error('Error uploading file:', error);
        }
    };

    // Handle textarea change
    const handleTextareaChange = (e) => {
        const { value } = e.target;
        setTextareaValue({
            ...textareaValue,
            lve_policy: value, // Update the lve_policy field specifically
        });
    };
    const handle_edit_lve_policy = (e) => {
        const { value } = e.target;
        settextareaValue_edit({
            ...textareaValue_edit,
            edit_lve_policy: value, // Update the lve_policy field specifically
        });
    };
    console.log("textareaValue", textareaValue)
    console.log("textareaValue_edit", textareaValue_edit)
    useEffect(() => {
        const fetchFileDetails = async () => {
            try {
                console.log("Fetching file details...");
                const res = await services.get_lve_policy_details();
                console.log("Response received:", res);
                if (res.files) {
                    setFiles(res.files);
                    console.log("Files state updated:", res.files);
                } else {
                    console.error("Unexpected data format:", res);
                }
            } catch (error) {
                console.error("Error fetching file details:", error);
            }
        };
        fetchFileDetails();
    }, []);

    const lastFile = files.length > 0 ? files[files.length - 1] : null;

    // Optionally, load the existing leave policy if you're editing (simulated here)
    useEffect(() => {
        if (selectedOption === 'editPolicy') {
            if (lastFile && lastFile.LVE_ACTUL_NTC) {
                settextareaValue_edit({
                    edit_lve_policy: lastFile.LVE_ACTUL_NTC // Set the leave policy text from lastFile
                });
            } else {
                alert('Warning: No leave policy found to edit.');
            }
        }
    }, [selectedOption, lastFile]);

    return (
        <div className='wrapper_form'>
            <div className="title" style={{ marginTop: ".3%" }}>Leave Policy</div>

            {/* Radio Buttons for Option Selection */}
            <div className="radio-row" style={{ marginBottom: "5px" }}>
                <label>
                    <input
                        type="radio"
                        name="addOption"
                        value="addFile"
                        checked={selectedOption === "addFile"}
                        onChange={handleOptionChange}
                        style={{ marginRight: "5px", width: "3%" }}
                    />
                    Add Files
                </label>
            </div>
            <hr className="hr1" />
            {selectedOption === "addFile" && (
                <div className="radio-content">
                    <form className="pdm" onSubmit={handleUploadSubmit}>
                        <input
                            type="file"
                            accept="*/*" // Allow all file formats
                            onChange={handleFileChange}
                            ref={fileInputRef} // Attach the ref to the input
                        />
                        <button type="submit" className="btn-action action-button pd2">
                            Upload File
                        </button>
                    </form>
                </div>
            )}

            <div className="radio-row" style={{ marginBottom: "5px" }}>
                <label>
                    <input
                        type="radio"
                        name="addOption"
                        value="addManually"
                        checked={selectedOption === "addManually"}
                        onChange={handleOptionChange}
                        style={{ marginRight: "5px", width: "3%" }}
                    />
                    Add Manually
                </label>
            </div>

            {/* Add Manually Form */}
            {selectedOption === "addManually" && (
                <div className="manual-entry">
                    <form className="frm_wd" onSubmit={(e) => { e.preventDefault(); handleSave(); }}>
                        <textarea
                            value={textareaValue.lve_policy} // Bind to lve_policy in the state
                            onChange={handleTextareaChange} // Call handleTextareaChange on user input
                            placeholder="Enter your policy details here..."
                            rows="10"
                            style={{ width: "100%", marginTop: "10px" }}
                            name='lve_policy'
                        />
                        <button
                            className="btn-action action-button"
                            style={{
                                marginTop: "10px",
                                padding: "5px 10px",
                                backgroundColor: "blue",
                                color: "white",
                                border: "none",
                                cursor: "pointer",
                                width: "10%",
                                float: "right"
                            }}
                            type="submit"
                        >
                            Save
                        </button>
                    </form>
                </div>
            )}
            <hr className="hr1" />
            {/* Add/Edit Leave Policy Radio Option */}
            <div className="radio-row" style={{ marginBottom: "5px" }}>
                <label>
                    <input
                        type="radio"
                        name="addOption"
                        value="editPolicy"
                        checked={selectedOption === "editPolicy"}
                        onChange={handleOptionChange}
                        style={{ marginRight: "5px", width: "3%" }}
                    />
                    Edit Leave Policy
                </label>
            </div>
            {/* Edit Leave Policy Form */}
            {selectedOption === "editPolicy" && (
                <div className="manual-entry">
                    <form className="frm_wd" onSubmit={(e) => { e.preventDefault(); handleupdate(); }}>
                        <textarea
                            value={textareaValue_edit.edit_lve_policy} // Bind to lve_policy in the state
                            onChange={handle_edit_lve_policy} // Call handleTextareaChange on user input
                            placeholder="Edit your policy details here..."
                            rows="10"
                            style={{ width: "100%", marginTop: "10px" }}
                            name='edit_lve_policy'
                        />
                        <button
                            className="btn-action action-button"
                            style={{
                                marginTop: "10px",
                                padding: "5px 10px",
                                backgroundColor: "blue",
                                color: "white",
                                border: "none",
                                cursor: "pointer",
                                width: "10%",
                                float: "right"
                            }}
                            type="submit"
                        >
                            Update
                        </button>
                    </form>
                </div>
            )}
        </div>
    );
}
