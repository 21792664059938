import React, { useState, useEffect } from "react";
import services from "./apiServices";
import { Radio, RadioGroup, FormControlLabel, FormControl, FormLabel, Button } from '@mui/material';

function Adm_Aflt_Lve_Type_Management() {
  const [data, setData] = useState({ aflt_lve_conf_res: [] });
  const [selectedLeaveType, setSelectedLeaveType] = useState("");
  const [leaveRecords, setLeaveRecords] = useState({});

  console.log("leaveRecords", leaveRecords)
  useEffect(() => {
    const fetch_aflt_lve_cong = async () => {
      try {
        const res = await services.get_aflt_lve_cong_dt();
        if (res) {
          setData(res);
        } else {
          console.error("Unexpected data format:", res);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetch_aflt_lve_cong();
  }, []);


  const updt_lve_cong = async (e) => {
    e.preventDefault();
    try {
      const res = await services.updt_lve_confg(leaveRecords);
      if (res) {
        alert("Succesfully Updated the Leave Configration Details...")
        leaveRecords({})

      } else {
        alert("Failed to Updated the Leave Configration Details...")
        console.error("Unexpected data format:", res);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleLeaveTypeChange = (event) => {
    const selectedType = event.target.value;
    setSelectedLeaveType(selectedType);

    const filteredRecords = data.aflt_lve_conf_res.filter(
      (item) => item.disp_nm === selectedType
    );

    const initialLeaveRecords = { leaveType: selectedType };

    filteredRecords.forEach((record) => {
      initialLeaveRecords[record.AFLT_CONF_PARA] = record.AFLT_CONF_PARA_VAL;
    });

    setLeaveRecords(initialLeaveRecords);
  };

  const handleInputChange = (parameter, value) => {
    setLeaveRecords((prev) => ({
      ...prev,
      [parameter]: value,
    }));
  };

  return (
    <div className="wrapper_form">
      <div className="title">Leave Type Management</div>

      {/* Dropdown for Leave Types */}
      <div className="drp_mg">
        <label htmlFor="leaveType">Select Leave Type: </label>
        <select
          id="leaveType"
          value={selectedLeaveType}
          onChange={handleLeaveTypeChange}
          style={{ borderRadius: "8px", width: "30%" }}
        >
          <option value="">Select Leave Type</option>
          {Array.from(new Set(data.aflt_lve_conf_res.map(item => item.disp_nm)))
            .map((leaveType) => (
              <option key={leaveType} value={leaveType}>
                {leaveType}
              </option>
            ))}
        </select>
      </div>

      {selectedLeaveType && (
        <form onSubmit={updt_lve_cong}>
          <div className="form-container">
            <div className="row">
              {/* Display Leave Balance on Dashboard */}
              <div className="column" style={{ lineHeight: "12px", marginTop: "5px" }}>
                <label>Display Leave Balance on Dashboard:</label>
                <RadioGroup
                  row
                  name="LVE_BAL_IN_DBORD"
                  value={leaveRecords["LVE_BAL_IN_DBORD"] || ""}
                  onChange={(e) => handleInputChange("LVE_BAL_IN_DBORD", e.target.value)}
                >
                  <FormControlLabel value="yes" control={<Radio />} label="Yes" />
                  <FormControlLabel value="no" control={<Radio />} label="No" />
                </RadioGroup>
              </div>

              {/* Allow Gender */}
              <div className="column" style={{ lineHeight: "12px", marginTop: "5px" }}>
                <label>Allow Gender:</label>
                <RadioGroup
                  row
                  name="LVE_TYP_ALOW_GNDR"
                  value={leaveRecords["LVE_TYP_ALOW_GNDR"] || ""}
                  onChange={(e) => handleInputChange("LVE_TYP_ALOW_GNDR", e.target.value)}
                >
                  <FormControlLabel value="yes" control={<Radio />} label="Yes" />
                  <FormControlLabel value="no" control={<Radio />} label="No" />
                </RadioGroup>
              </div>

              {/* Allowed for Consecutive Combined Leave Type */}
              <div className="column" style={{ lineHeight: "12px", marginTop: "5px" }}>
                <label>Allowed for Consecutive Combined Leave Type?</label>
                <RadioGroup
                  row
                  name="LVE_TYP_LST_CONSQTV"
                  value={leaveRecords["LVE_TYP_LST_CONSQTV"] || ""}
                  onChange={(e) => handleInputChange("LVE_TYP_LST_CONSQTV", e.target.value)}
                >
                  <FormControlLabel value="yes" control={<Radio />} label="Yes" />
                  <FormControlLabel value="no" control={<Radio />} label="No" />
                </RadioGroup>
              </div>
            </div>

            <div className="row">
              {/* Carry Forward Allowed */}
              <div className="column" style={{ lineHeight: "12px", marginTop: "5px" }}>
                <label>Carry Forward Allowed?</label>
                <RadioGroup
                  row
                  name="LVE_CREFW_YN"
                  value={leaveRecords["LVE_CREFW_YN"] || ""}
                  onChange={(e) => handleInputChange("LVE_CREFW_YN", e.target.value)}
                >
                  <FormControlLabel value="yes" control={<Radio />} label="Yes" />
                  <FormControlLabel value="no" control={<Radio />} label="No" />
                </RadioGroup>
              </div>

              {/* Encashment allowed */}
              <div className="column" style={{ lineHeight: "12px", marginTop: "5px" }}>
                <label>Encashment allowed?</label>
                <RadioGroup
                  row
                  name="LVE_NCSH_YN"
                  value={leaveRecords["LVE_NCSH_YN"] || ""}
                  onChange={(e) => handleInputChange("LVE_NCSH_YN", e.target.value)}
                >
                  <FormControlLabel value="yes" control={<Radio />} label="Yes" />
                  <FormControlLabel value="no" control={<Radio />} label="No" />
                </RadioGroup>
              </div>

              {/* Consecutive working days allowed */}
              <div className="column" style={{ lineHeight: "12px", marginTop: "5px" }}>
                <label>Consecutive working days allowed?</label>
                <RadioGroup
                  row
                  name="LVE_WRKDE_ADD_LVETYP"
                  value={leaveRecords["LVE_WRKDE_ADD_LVETYP"] || ""}
                  onChange={(e) => handleInputChange("LVE_WRKDE_ADD_LVETYP", e.target.value)}
                >
                  <FormControlLabel value="yes" control={<Radio />} label="Yes" />
                  <FormControlLabel value="no" control={<Radio />} label="No" />
                </RadioGroup>
              </div>
            </div>

            <div className="row">
              {/* Include weekly off days */}
              <div className="column" style={{ lineHeight: "12px", marginTop: "5px" }}>
                <label>Include weekly off days in leave calculation days?</label>
                <RadioGroup
                  row
                  name="LVE_TYP_CAN_ADD_WIKENDES"
                  value={leaveRecords["LVE_TYP_CAN_ADD_WIKENDES"] || ""}
                  onChange={(e) => handleInputChange("LVE_TYP_CAN_ADD_WIKENDES", e.target.value)}
                >
                  <FormControlLabel value="yes" control={<Radio />} label="Yes" />
                  <FormControlLabel value="no" control={<Radio />} label="No" />
                </RadioGroup>
              </div>

              {/* Consecutive half day allowed */}
              <div className="column" style={{ lineHeight: "12px", marginTop: "5px" }}>
                <label>Consecutive half day allowed with any type of leave?</label>
                <RadioGroup
                  row
                  name="LVE_CONSQT_HD_YN"
                  value={leaveRecords["LVE_CONSQT_HD_YN"] || ""}
                  onChange={(e) => handleInputChange("LVE_CONSQT_HD_YN", e.target.value)}
                >
                  <FormControlLabel value="yes" control={<Radio />} label="Yes" />
                  <FormControlLabel value="no" control={<Radio />} label="No" />
                </RadioGroup>
              </div>

              {/* Maximum Carry Forward */}
              <div className="column drp_ln_hg">
                <label>Maximum Carry Forward:</label>
                <div className="field">
                  <input
                    type="number"
                    name="LVE_TYP_MAX_CREFW"
                    value={leaveRecords["LVE_TYP_MAX_CREFW"] || ""}
                    onChange={(e) => handleInputChange("LVE_TYP_MAX_CREFW", e.target.value)}
                  />
                </div>
              </div>
            </div>

            <div className="row">
              {/* Maximum Leave Count */}
              <div className="column drp_ln_hg">
                <label>Maximum Leave Count:</label>
                <div className="field">
                  <input
                    type="number"
                    name="LVE_TYP_MAX_CNT"
                    value={leaveRecords["LVE_TYP_MAX_CNT"] || ""}
                    onChange={(e) => handleInputChange("LVE_TYP_MAX_CNT", e.target.value)}
                  />
                </div>
              </div>

              {/* Leave Type Increment Days */}
              <div className="column drp_ln_hg">
                <label>Leave Type Increment Days Count:</label>
                <div className="field">
                  <input
                    type="number"
                    name="LVE_TYP_INCRMT_DES"
                    value={leaveRecords["LVE_TYP_INCRMT_DES"] || ""}
                    onChange={(e) => handleInputChange("LVE_TYP_INCRMT_DES", e.target.value)}
                  />
                </div>
              </div>

              {/* Maximum Encashment Count */}
              <div className="column drp_ln_hg">
                <label>Maximum Encashment Count:</label>
                <div className="field">
                  <input
                    type="number"
                    name="LVE_TYP_MAX_NCSH_CNT"
                    value={leaveRecords["LVE_TYP_MAX_NCSH_CNT"] || ""}
                    onChange={(e) => handleInputChange("LVE_TYP_MAX_NCSH_CNT", e.target.value)}
                  />
                </div>
              </div>
            </div>

            <div className="row" style={{ display: "flex", justifyContent: "space-between", marginTop: "2.5%" }}>
              <hr className="hr_dt" style={{ marginBottom: ".5%" }} />
              <Button style={{ width: "10%" }} type="submit" className="btn-action action-button" >
                Submit
              </Button>
            </div>
          </div>
        </form>
      )}
    </div>
  );
}

export default Adm_Aflt_Lve_Type_Management;
