import axios from "axios";

const services = {
  getlvetypdata: async () => {
    try {
      const res = await axios.get("/rdbs/register");
      return res.data;
    } catch (error) {
      throw new Error(`Failed  to receive data ${error}`);
    }
  },
  getaffilatedata: async () => {
    console.log("i am in get_affliate_data api....");
    try {
      const res = await axios.get("/rdbs/login/get_affliate_data");
      return res.data;
    } catch (error) {
      throw new Error(`Failed to receive data ${error}`);
    }
  },

  get_lev_bal_cnt: async () => {
    console.log("hello from get_lev_bal_cnt Api")
    try {
      const res = await axios.get("rdbs/dash_cust/dash_fetch_data");
      return res.data;
    } catch (error) {
      throw new Error(`Failed to receive data ${error}`);
    }
  },
  get_emp_lve_on_today: async () => {
    console.log("hello from get_emp_lve_on_today Api")
    try {
      const res = await axios.get("rdbs/dash_cust/emp_lve_on_today");
      return res.data;
    } catch (error) {
      throw new Error(`Failed to receive data ${error}`);
    }
  },

  getAffiliateLeaveTypes: async () => {
    try {
      console.log("hello from api");
      const res = await axios.get(
        "/rdbs/cust_application_form/get_aff_lve_typ"
      );
      return res.data;
    } catch (error) {
      throw new Error(`Failed  to receive data ${error}`);
    }
  },

  getdropdowndata: async (name) => {
    console.log(name);
    try {
      const res = await axios.post("/rdbs/register", { name });
      return res.data;
    } catch (error) {
      throw new Error(`Failed  to receive data ${error}`);
    }
  },
  getLeaveStatuses: async () => {
    console.log("hello from getLeaveStatuses");
    try {
      const res = await axios.post("/rdbs/cust_application_form/getlevsts");
      return res.data;
    } catch (error) {
      throw new Error(`Failed  to receive data ${error}`);
    }
  },

  getapplicantdata: async () => {
    console.log("hello from getapplicantdata ");
    try {
      const res = await axios.get("/rdbs/cust_application_form/applicantdata");
      return res.data;
    } catch (error) {
      throw new Error(`Failed to receive data ${error}`);
    }
  },

  insert_reg_tab1: async (values) => {
    console.log(values);
    try {
      const res = await axios.post("/rdbs/register/insert_first_tab", values);
      return res.data;
    } catch (error) {
      throw new Error(`Failed  to receive data ${error}`);
    }
  },
  insert_reg_tab2: async (values) => {
    console.log(values);
    try {
      const res = await axios.post("/rdbs/register/insert_second_tab", values);
      return res.data;
    } catch (error) {
      throw new Error(`Failed  to receive data ${error}`);
    }
  },
  add_lve_confg: async (values) => {
    console.log(values);
    try {
      const res = await axios.post("/rdbs/sys_usr_client_confg/insert_lve_types", values);
      return res.data;
    } catch (error) {
      throw new Error(`Failed  to receive data ${error}`);
    }
  },
  add_sys_usr_lve_confg: async (values) => {
    console.log("add_sys_usr_lve_confg from api", values);
    try {
      const res = await axios.post("/rdbs/sys_usr_client_confg/add_sys_usr_lve_types", values);
      return res.data;
    } catch (error) {
      throw new Error(`Failed  to receive data ${error}`);
    }
  },
  insert_reg_tab3: async (values) => {
    console.log("val", values);
    try {
      const res = await axios.post("/rdbs/register/insert_third_tab", values);
      return res.data;
    } catch (error) {
      throw new Error(`Failed  to receive data ${error}`);
    }
  },
  insert_reg_tab4: async (values) => {
    console.log("val", values);
    try {
      const res = await axios.post("/rdbs/register/insert_fourth_tab", values);
      console.log("hello from try");
      return res.data;
    } catch (error) {
      throw new Error(`Failed  to receive data ${error}`);
    }
  },
  usr_login: async (values) => {
    try {
      const res = await axios.post("/rdbs/login/usr_login", values);
      return res.data;
    } catch (error) {
      console.error("Service error caught:", error); // Log any error from the service
      throw error; // Re-throw the error to propagate it to the calling function
    }
  },
  reg_adm_addusr_afflit: async (values) => {
    console.log(values);
    try {
      const res = await axios.post(
        "/rdbs/register/insrt_adm_addusr_afflit",
        values
      );
      return res.data;
    } catch (error) {
      throw new Error(`Failed  to receive data ${error}`);
    }
  },
  aflt_new_admin: async (values) => {
    console.log("hello from api aflt_new_admin", values);
    try {
      const res = await axios.post(
        "/rdbs/register/aflt_new_admin_crt",
        values
      );
      return res.data;
    } catch (error) {
      throw new Error(`Failed  to receive data ${error}`);
    }
  },
  new_sys_usr: async (values) => {
    console.log("hello from api new_sys_usr_cretion", values);
    try {
      const res = await axios.post(
        "/rdbs/register/new_sys_usr_cretion",
        values
      );
      return res.data;
    } catch (error) {
      throw new Error(`Failed  to receive data ${error}`);
    }
  },
  sbmit_lve_app: async (values) => {
    console.log("in am from sbmit_lve_app api");
    // console.log(values);
    try {
      const res = await axios.post(
        "rdbs/cust_application_form/sbmit_lve_app_form",
        values
      );
      return res.data;
    } catch (error) {
      throw new Error(`Failed to receive data ${error}`);
    }
  },
  send_mail: async (values) => {
    console.log("in am from send_mail api", values);
    try {
      const res = await axios.post(
        "rdbs/cust_application_form/send_mail",
        values
      );
      return res.data;
    } catch (error) {
      throw new Error(`Failed to receive data ${error}`);
    }
  },
  approveLeaveApplication: async (values) => {
    console.log("in am from approveLeaveApplication api", values);
    try {
      const res = await axios.post(
        "rdbs/cust_application_form/updtapplsts",
        values
      );
      return res.data;
    } catch (error) {
      throw new Error(`Failed to receive data ${error}`);
    }
  },
  rejectLeaveApplication: async (values) => {
    console.log("in am from rejectLeaveApplication api", values);
    try {
      const res = await axios.post(
        "rdbs/cust_application_form/updt_app_lve_rej",
        values
      );
      return res.data;
    } catch (error) {
      throw new Error(`Failed to receive data ${error}`);
    }
  },
  getselecempdetails: async (values) => {
    console.log("Hello from getselecempdetails", values);
    try {
      const res = await axios.post(
        "rdbs/cust_application_form/getseleentity",
        values
      );
      return res.data;
    } catch (error) {
      throw new Error(`Failed to receive data ${error}`);
    }
  },
  updateLeaveApplication: async (values) => {
    console.log("Hello from updateLeaveApplication", values);
    try {
      const res = await axios.post(
        "rdbs/cust_application_form/updt_canc_lve",
        values
      );
      return res.data;
    } catch (error) {
      throw new Error(`Failed to receive data ${error}`);
    }
  },
  cncl_for_apprval: async (values) => {
    console.log("Hello from cncl_for_apprval API", values)
    try {
      const res = await axios.post("rdbs/cust_application_form/cncl_aprvl", values);
      return res.data;
    } catch (error) {
      throw new Error(`Failed to receive data ${error}`);
    }
  },
  edit_lve_Application: async (values) => {
    console.log("hello from edit_lve_Application Api", values)
    try {
      const res = await axios.post("rdbs/cust_application_form/edit_lve_Apl", values);
      return res.data;
    } catch (error) {
      throw new Error(`Failed to receive data ${error}`);
    }
  },
  generatePdf: async (aplicantdata) => {
    console.log(`In generatePdf API with data: ${JSON.stringify(aplicantdata)}`);
    try {
      // Make a POST request with the entire `orderData`
      const response = await axios.post('rdbs/dash_cust/generate-pdf', aplicantdata, {
        responseType: 'blob'
      });
      return response;
    } catch (error) {
      console.error('Error downloading the invoice:', error);
      throw error;
    }
  },
  get_aging_dt_mn: async () => {
    console.log("Hello from get_aging_dt_mn apl")
    try {
      const res = await axios.get("rdbs/reports/agine_rpt_mng")
      return res.data

    } catch (error) {
      throw new Error(`Failed to receive data ${error}`);
    }
  },
  get_adm_agine_rpt: async () => {
    console.log("hello from get_adm_agine_rpt api")
    try {
      const res = await axios.get("rdbs/reports/adm_agine_rpt");
      return res.data
    } catch (error) {
      throw new Error(`Failed to receive data ${error}`)
    }
  },
  updated_prf_data: async (values) => {
    console.log("hello from the updated_prf_data api", values)
    try {
      const res = await axios.post("rdbs/reports/updt_prf_dt", values)
      return res.data
    } catch (error) {
      throw new Error(`Failed to receive data ${error}`)
    }
  },
  changepassword: async (values) => {
    console.log("Hello from the changepassword api", values);
    try {
      const res = await axios.post("rdbs/login/chng_pass", values)
      return res.data
    } catch (error) {
      throw new Error(`Failed to receive data ${error}`)
    }
  },
  fechdata_forgot_pass: async (values) => {
    console.log("Hello from the fechdata_forgot_pass api", values);
    try {
      const res = await axios.post("rdbs/login/fechdata_forgot_pass", values)
      return res.data
    } catch (error) {
      throw new Error(`Failed to receive data ${error}`)
    }
  },
  forgetpassword: async (values) => {
    console.log("Hello from the forgetpassword api", values);
    try {
      const res = await axios.post("rdbs/login/forget_password", values)
      return res.data
    } catch (error) {
      throw new Error(`Failed to receive data ${error}`)
    }
  },
  get_lev_holiday_cal: async () => {
    try {
      const res = await axios.get("rdbs/dash_cust/lev_holiday_cal")
      return res.data;
    } catch (error) {
      throw new Error(`Failed to receive data ${error}`)
    }
  },
  get_aflt_lev_holiday_cal: async () => {
    try {
      const res = await axios.get("rdbs/sys_usr_client_confg/aflt_lev_holiday_cal")
      return res.data;
    } catch (error) {
      throw new Error(`Failed to receive data ${error}`)
    }
  },
  add_lev_holiday_cal: async (values) => {
    console.log("Hello from the add_lev_holiday_cal api", values);
    try {
      const res = await axios.post("rdbs/dash_cust/add_holiday_cal_dt", values)
      return res.data;
    } catch (error) {
      throw new Error(`Failed to receive data ${error}`)
    }
  },
  add_holiday_cal_sys_usr: async (values) => {
    console.log("Hello from the add_holiday_cal_sys_usr api", values);
    try {
      const res = await axios.post("rdbs/dash_cust/add_holiday_cal", values)
      return res.data;
    } catch (error) {
      throw new Error(`Failed to receive data ${error}`)
    }
  },
  upload_file: async (values) => {
    console.log("Hello from the upload_file api", values);
    try {
      const res = await axios.post("rdbs/lve_policy/upload_lve_policy", values)
      return res.data;
    } catch (error) {
      throw new Error(`Failed to receive data ${error}`)
    }
  },
  upload_file_sys_usr: async (values) => {
    console.log("Hello from the upload_file_sys_usr api", values);
    try {
      const res = await axios.post("rdbs/lve_policy/upload_file_lve_policy", values)
      return res.data;
    } catch (error) {
      throw new Error(`Failed to receive data ${error}`)
    }
  },
  get_lve_policy_details: async () => {
    console.log("Hello from the get_lve_policy_details api");
    try {
      const res = await axios.get("rdbs/lve_policy/lve_policy_details")
      return res.data;
    } catch (error) {
      throw new Error(`Failed to receive data ${error}`)
    }
  },
  add_lve_policy: async (VALUES) => {
    console.log("Hello from the add_lve_policy api", VALUES);
    try {
      const res = await axios.post("rdbs/lve_policy/add_manualy_lve_policy", VALUES)
      return res.data;
    } catch (error) {
      throw new Error(`Failed to receive data ${error}`)
    }
  },
  add_lve_policy_sys_usr: async (VALUES) => {
    console.log("Hello from the add_lve_policy_sys_usr api", VALUES);
    try {
      const res = await axios.post("rdbs/sys_usr_client_confg/add_lve_policy_sysusr", VALUES)
      return res.data;
    } catch (error) {
      throw new Error(`Failed to receive data ${error}`)
    }
  },
  upd_lve_policy: async (VALUES) => {
    console.log("Hello from the upd_lve_policy api", VALUES);
    try {
      const res = await axios.post("rdbs/lve_policy/edit_manualy_lve_policy", VALUES)
      return res.data;
    } catch (error) {
      throw new Error(`Failed to receive data ${error}`)
    }
  },
  lve_policy_upd: async (VALUES) => {
    console.log("Hello from the lve_policy_upd api", VALUES);
    try {
      const res = await axios.post("rdbs/sys_usr_client_confg/edt_mnaul_lve_plocy", VALUES)
      return res.data;
    } catch (error) {
      throw new Error(`Failed to receive data ${error}`)
    }
  },
  upd_lev_holiday_cal: async (VALUES) => {
    console.log("Hello from the upd_lev_holiday_cal api", VALUES);
    try {
      const res = await axios.post("rdbs/lve_policy/upd_lev_holiday_cal_dt", VALUES)
      return res.data;
    } catch (error) {
      throw new Error(`Failed to receive data ${error}`)
    }
  },
  upd_hld_cal_aflt: async (VALUES) => {
    console.log("Hello from the upd_hld_cal_aflt api", VALUES);
    try {
      const res = await axios.post("rdbs/sys_usr_client_confg/aflt_upd_hld_cal", VALUES)
      return res.data;
    } catch (error) {
      throw new Error(`Failed to receive data ${error}`)
    }
  },
  get_aflt_mangr_lst: async () => {
    console.log("Hello from the get_aflt_mangr_lst api");
    try {
      const res = await axios.get("rdbs/lve_policy/aflt_mangr_lst")
      return res.data;
    } catch (error) {
      throw new Error(`Failed to receive data ${error}`)
    }
  },
  add_historical_anncoment: async (VALUES) => {
    console.log("Hello from the add_historical_anncoment api", VALUES);
    try {
      const res = await axios.post("rdbs/lve_policy/add_historical_anncoments", VALUES)
      return res.data;
    } catch (error) {
      throw new Error(`Failed to receive data ${error}`)
    }
  },
  get_annocement_lst: async () => {
    console.log("Hello from the get_annocement_lst api");
    try {
      const res = await axios.get("rdbs/lve_policy/annocement_deatails")
      return res.data;
    } catch (error) {
      throw new Error(`Failed to receive data ${error}`)
    }
  },
  update_historical_anncoment: async (VALUES) => {
    console.log("Hello from the update_historical_anncoment api", VALUES);
    try {
      const res = await axios.post("rdbs/lve_policy/updt_historical_anncoment", VALUES)
      return res.data;
    } catch (error) {
      throw new Error(`Failed to receive data ${error}`)
    }
  },
  get_lev_types: async () => {
    console.log("Hello from the get_lev_types api");
    try {
      const res = await axios.get("rdbs/lve_policy/fetch_lev_types")
      return res.data;
    } catch (error) {
      throw new Error(`Failed to receive data ${error}`)
    }
  },
  get_aflt_emp_lst: async () => {
    console.log("Hello from the get_aflt_emp_lst api");
    try {
      const res = await axios.get("rdbs/login/aflt_emp_lst")
      return res.data;
    } catch (error) {
      throw new Error(`Failed to receive data ${error}`)
    }
  },
  sbmit_emp_chnge_pass: async (VALUES) => {
    console.log("Hello from the sbmit_emp_chnge_pass api", VALUES);
    try {
      const res = await axios.post("rdbs/login/adm_chnage_emp_pass", VALUES)
      return res.data;
    } catch (error) {
      throw new Error(`Failed to receive data ${error}`)
    }
  },
  get_projcted_on_lve_emp: async () => {
    console.log("Hello from the get_projcted_on_lve_emp api");
    try {
      const res = await axios.get("rdbs/reports/projcted_on_lve_emp")
      return res.data;
    } catch (error) {
      throw new Error(`Failed to receive data ${error}`)
    }
  },
  get_aflt_lve_cong_dt: async () => {
    console.log("Hello from the get_aflt_lve_cong_dt api");
    try {
      const res = await axios.get("rdbs/lve_policy/aflt_lve_cong_dt")
      return res.data;
    } catch (error) {
      throw new Error(`Failed to receive data ${error}`)
    }
  },
  updt_lve_confg: async (VALUES) => {
    console.log("Hello from the updt_lve_confg api", VALUES);
    try {
      const res = await axios.post("rdbs/lve_policy/updt_lve_config", VALUES)
      return res.data;
    } catch (error) {
      throw new Error(`Failed to receive data ${error}`)
    }
  },
  updt_obo_lve_confg: async (VALUES) => {
    console.log("Hello from the updt_obo_lve_confg api", VALUES);
    try {
      const res = await axios.post("rdbs/sys_usr_client_confg/updt_obo_lve_config", VALUES)
      return res.data;
    } catch (error) {
      throw new Error(`Failed to receive data ${error}`)
    }
  },
  edit_sys_usr_lve_confg: async (VALUES) => {
    console.log("Hello from the updt_obo_lve_confg api", VALUES);
    try {
      const res = await axios.post("rdbs/sys_usr_client_confg/updt_sys_usr_lve_config", VALUES)
      return res.data;
    } catch (error) {
      throw new Error(`Failed to receive data ${error}`)
    }
  },

  get_total_aflt_cnt: async () => {
    console.log("hello from the get_total_aflt_cnt api")
    try {
      const res = await axios.get("rdbs/sys_usr_dashboard")
      return res.data;
    } catch (error) {
      throw new Error(`Failed to receive data ${error}`)
    }
  },
  get_aflt_confg_info: async () => {
    console.log("hello from the get_aflt_confg_info api")
    try {
      const res = await axios.get("rdbs/sys_usr_client_confg/aflt_confg_info")
      return res.data;
    } catch (error) {
      throw new Error(`Failed to receive data ${error}`)
    }
  },
  updt_aflt_pass: async (VALUES) => {
    console.log("hello from the updt_aflt_pass api", VALUES);
    try {
      const res = await axios.post("rdbs/sys_usr_client_confg/change_reset_aflt_pass", VALUES);
      return res.data;
    } catch (error) {
      throw new Error(`Failed to receive data ${error}`)
    }
  },
  aflt_lve_types: async () => {
    console.log("hello from the aflt_lve_types api");
    try {
      const res = await axios.get("rdbs/sys_usr_client_confg/aflt_lve_typ");
      return res.data;
    } catch (error) {
      throw new Error(`Failed to receive data ${error}`)
    }
  },
  get_lvests_posts: async () => {
    console.log("hello from the get_lvests_posts api");
    try {
      const res = await axios.get("/rdbs/sys_usr_dashboard/lvests_posts");
      return res.data;
    } catch (error) {
      throw new Error(`Error to receive data ${error}`)
    }
  },
  getafltdt: async () => {
    try {
      const res = await axios.get("/rdbs/sys_usr_client_confg/afltdetails")
      return res.data;
    } catch (error) {
      throw new Error(`Error to receive data ${error}`)
    }
  },
  deleteaffilate: async (VALUES) => {
    console.log("HEllo from deleteaffilate", VALUES)
    try {
      const res = await axios.post("/rdbs/sys_usr_client_confg/deleteafltfromdb", VALUES)
      return res.data;
    } catch (error) {
      throw new Error(`Error to receive data ${error}`)
    }
  },
  updateAffiliate: async (values) => {
    console.log("HEllo from updateAffiliate", values)
    try {
      const res = await axios.post("/rdbs/sys_usr_client_confg/updtafltdb", values)
      return res.data;
    } catch (error) {
      throw new Error(`Error to receive data ${error}`)
    }
  },
  getsyslveinfo: async () => {
    console.log("hello from getsyslveinfo api");
    try {
      const res = await axios.get("/rdbs/sys_usr_client_confg/syslveinfo")
      return res.data;
    } catch (error) {
      throw new Error(`Error to receive data ${error}`)
    }
  },
  addlvesysusr: async (VALUES) => {
    console.log("hello from addlvesysusr api", VALUES);
    try {
      const res = await axios.post("/rdbs/sys_usr_client_confg/sysaddlvetyp", VALUES)
      return res.data;
    } catch (error) {
      throw new Error(`Error to receive th data ${error}`)
    }
  },
  addlvesysusrmaintyp: async (VALUES) => {
    console.log("hello from addlvesysusrmaintyp api", VALUES);
    try {
      const res = await axios.post("/rdbs/sys_usr_client_confg/addlvesysusrmaintypes", VALUES)
      return res.data;
    } catch (error) {
      throw new Error(`Error to receive th data ${error}`)
    }
  },
  updtsyslve: async (VALUES) => {
    console.log(`Hello from the updtsyslve api  ${VALUES}`)
    try {
      const res = await axios.post("/rdbs/sys_usr_client_confg/updtsyslatestlve", VALUES);
      return res.data;
    } catch (error) {
      throw new Error(`Error to receive the data ${error}`)
    }
  },
  deletecustomlve: async (VALUES) => {
    console.log("Hello from api deletecustomlve", VALUES);
    try {
      const res = await axios.post("/rdbs/sys_usr_client_confg/deletecustomlvetyp", VALUES);
      return res.data;
    } catch (error) {
      throw new Error(`error to receive data ${error}`)
    }
  }



};

export default services;
