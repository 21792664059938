import React, { useState, useEffect } from "react";
import services from "./apiServices";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Grid,
  Button,
} from "@mui/material";
import moment from 'moment';

function Cust_Holliday_Calendar() {
  const currentYear = moment().year();
  const [holidaycal, Setholidaycal] = useState({ lve_hld_calndr: [] });
  const [filteredHolidays, setFilteredHolidays] = useState([]);
  const [selectedYear, setSelectedYear] = useState(currentYear);

  useEffect(() => {
    const shw_hlod_res = async () => {
      try {
        const holiday_dt = await services.get_lev_holiday_cal();
        if (holiday_dt) {
          Setholidaycal(holiday_dt);

          // Filter holidays for the current year and set to filteredHolidays
          const currentYearHolidays = holiday_dt.lve_hld_calndr.filter(leave => {
            const holidayDate = moment(leave.HOLIDAY_DATE);
            return holidayDate.year() === currentYear;
          });
          setFilteredHolidays(currentYearHolidays); // Initialize filtered holidays to current year
        } else {
          console.error("Unexpected data format:", holiday_dt);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    shw_hlod_res();
  }, [currentYear]); // Add currentYear as a dependency

  const handleFilter = () => {
    const filtered = holidaycal.lve_hld_calndr.filter(leave => {
      const holidayDate = moment(leave.HOLIDAY_DATE);
      return holidayDate.year() === selectedYear; // Only filter by year
    });
    setFilteredHolidays(filtered);
  };

  const formatDate = (dateString) => {
    return moment(dateString).format('DD/MMM/YYYY').replace(/\/([a-z])/g, (match) => '/' + match[1].toUpperCase());
  };
  console.log("holidaycal", holidaycal)
  return (
    <div className='wrapper_form'>
      <Grid item xs={12} style={{ paddingLeft: "1%", paddingRight: "1%" }}>
        <div className="title" style={{ marginTop: "2%" }}> Holiday Calendar</div>

        {/* Filter Controls */}
        <div style={{
          display: 'flex',
          justifyContent: 'center',
          marginBottom: '20px',
          marginTop: "10px"
        }}>
          <label style={{ marginRight: '10px', lineHeight: "30px", height: "30px" }}>Year:</label>
          <select
            value={selectedYear}
            onChange={(e) => setSelectedYear(Number(e.target.value))}
            style={{ marginRight: '10px', lineHeight: "30px", height: "30px" }}
          >
            {[currentYear - 1, currentYear, currentYear + 1].map(year => (
              <option key={year} value={year}>{year}</option>
            ))}
          </select>
          <Button className="btn-action action-button" style={{ lineHeight: "30px", height: "30px", marginLeft: "5%", width: "8%", fontSize: "bold" }} onClick={handleFilter}>
            Show
          </Button>
        </div>

        <TableContainer
          component={Paper}
          style={{
            margin: "5px auto 25px auto",
            overflow: "hidden",
            boxShadow: "0px 6px 16px 3px #bababa",
            borderRadius: "20px",
            border: "2px solid gray",
          }}
        >
          <Table style={{ width: "100%" }}>
            <TableHead>
              <TableRow style={{ width: "100%" }}>
                <TableCell
                  style={{
                    fontSize: "0.8rem",
                    padding: "1px 18px",
                    backgroundColor: "#4a6d8c",
                    border: "1px solid gray",
                    color: "white",
                    textAlign: "center"
                  }}
                >
                  Sr. No.
                </TableCell>
                <TableCell
                  style={{
                    fontSize: "0.8rem",
                    padding: "1px 18px",
                    backgroundColor: "#4a6d8c",
                    border: "1px solid gray",
                    color: "white",
                    textAlign: "center"
                  }}
                >
                  Date
                </TableCell>
                <TableCell
                  style={{
                    fontSize: "0.8rem",
                    padding: "1px 18px",
                    backgroundColor: "#4a6d8c",
                    border: "1px solid gray",
                    color: "white",
                    textAlign: "center"
                  }}
                >
                  Day
                </TableCell>
                <TableCell
                  style={{
                    fontSize: "0.8rem",
                    padding: "1px",
                    backgroundColor: "#4a6d8c",
                    border: "1px solid gray",
                    color: "white",
                    textAlign: "center"
                  }}
                >
                  Particulars / Occasion
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredHolidays.length === 0 ? (
                <TableRow>
                  <TableCell align="center" style={{ fontSize: "1rem", padding: "10px", color: "red", border: "1px solid gray" }}>
                    {/* No data available to show for this year */}
                  </TableCell>
                  <TableCell align="center" style={{ fontSize: "1rem", padding: "10px", color: "red", border: "1px solid gray" }}>
                    {/* No data available to show for this year */}
                  </TableCell>
                  <TableCell align="center" style={{ fontSize: "1rem", padding: "10px", color: "red", border: "1px solid gray" }}>
                    Data Not Available......
                  </TableCell>
                  <TableCell align="center" style={{ fontSize: "1rem", padding: "10px", color: "red", border: "1px solid gray" }}>
                    {/* No data available to show for this year */}
                  </TableCell>
                </TableRow>
              ) : (
                filteredHolidays
                  .sort((a, b) => new Date(a.HOLIDAY_DATE) - new Date(b.HOLIDAY_DATE))
                  .map((leave, index) => (
                    <TableRow style={{ height: "10px" }} key={leave.leaveTypeId}>
                      <TableCell align="center" style={{ fontSize: "0.8rem", padding: "1px 18px", border: "1px solid gray" }}>
                        {index + 1}
                      </TableCell>
                      <TableCell align="center" style={{ fontSize: "0.8rem", padding: "1px 18px", border: "1px solid gray" }}>
                        {formatDate(leave.HOLIDAY_DATE)}
                      </TableCell>
                      <TableCell align="center" style={{ fontSize: "0.8rem", padding: "1px 18px", border: "1px solid gray" }}>
                        {moment(leave.HOLIDAY_DATE).format('dddd')}
                      </TableCell>
                      <TableCell align="center" style={{ fontSize: "0.8rem", padding: "1px", border: "1px solid gray" }}>
                        {leave.HOLIDAY_TYP}
                      </TableCell>
                    </TableRow>
                  ))
              )}
              {filteredHolidays.length < 5 &&
                Array.from({ length: 5 - filteredHolidays.length }).map((_, index) => (
                  <TableRow style={{ height: "10px" }} key={`empty-row-${index}`}>
                    <TableCell align="center" style={{ fontSize: "0.8rem", padding: "1px 18px", border: "1px solid gray" }}>
                      &nbsp;
                    </TableCell>
                    <TableCell align="center" style={{ fontSize: "0.8rem", padding: "1px 18px", border: "1px solid gray" }}>
                      &nbsp;
                    </TableCell>
                    <TableCell align="center" style={{ fontSize: "0.8rem", padding: "1px 18px", border: "1px solid gray" }}>
                      &nbsp;
                    </TableCell>
                    <TableCell align="center" style={{ fontSize: "0.8rem", padding: "1px", border: "1px solid gray" }}>
                      &nbsp;
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </div>
  );
}

export default Cust_Holliday_Calendar;