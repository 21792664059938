import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import services from "./apiServices";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import Checkbox from "@mui/material/Checkbox";
import LveTypRegTab from "./LveTypRegTab";
import * as XLSX from "xlsx";
export default function Sys_Usr_2nd_Usr_cretion() {
    const [validationErrors, setValidationErrors] = useState({});
    const [errors, setErrors] = useState({});
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [revdata, setrevdata] = useState({ tabdata: [], seledesgination: [] });
    const [formData, setFormData] = useState({
        usr_typ: "",
        userCategory: "",
        userPost: "",
        userTitle: "",
        usr_fnm: "",
        usr_mnm: "",
        usr_lnm: "",
        usr_dnm: "",
        usrgender: "",
        usernm: "",
        usr_pass: "",
        phone1: "",
        phone2: "",
        landlineno: "",
        cellphone: "",
        empid: "",
        ofcilemail: "",
        birthdate: "",
        addressLine1: "",
        addressLine2: "",
        addressLine3: "",
        city: "",
        pin: "",
        state: "",
        addtype: "",
        country: 100,
        email: "",
        landmark: "",
        gmapLink: "",
        geoloc: "",
        icrdno: "",
        aprval_id: "",
        emp_ctrct: "",
        prefadd: "",
        jondate: "",
        resdate: "",
        resnote: "",
        aflt_key: ""
    });
    const [usrdata, setUsrdata] = useState({
        usrgender: [],
        usrtype: [],
        usrcat: [],
        usrtitle: [],
        Desgn: [],
        add_type: [],
        user_contract: [],
    });
    console.log("usrdata", usrdata)
    const [data, setData] = useState({
        result: [],
        result2: [],
        country_res: [],
    });
    const [data1, setData1] = useState([]);

    const handleChange = (e, field) => {
        setFormData({ ...formData, [field]: e.target.value });
    };

    useEffect(() => {
        const fetch = async () => {
            try {
                const result = await services.getlvetypdata(revdata);
                if (result) {
                    setData(result);
                } else {
                    console.error("Unexpected data format:", result);
                }
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };
        fetch();
    }, []);

    useEffect(() => {
        const fetch = async () => {
            try {
                const affdata = await services.getaffilatedata();
                if (affdata) {
                    setData1(affdata);
                } else {
                    console.log("Error for fetching data:");
                }
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };
        fetch();
    }, []);
    const handleChange1 = (e) => {
        const { name, value } = e.target;

        setFormData((prevFormData) => {
            // Create a copy of the current state
            const updatedFields = {
                ...prevFormData,
                [name]: value, // Update the specific field
            };

            // Special logic to handle empid and birthdate changes
            if (name === "empid") {
                // If empid is cleared, also clear usernm and update usr_pass
                if (value === "") {
                    updatedFields.usernm = ""; // Clear usernm
                    updatedFields.usr_pass = ""; // Clear usr_pass
                } else {
                    updatedFields.usernm = value; // Set usernm to empid value
                    // Compute usr_pass with formatted birthdate first, then empid
                    const formattedBirthdate = formatDate(updatedFields.birthdate);
                    updatedFields.usr_pass = `${formattedBirthdate}${value}`;
                }
            } else if (name === "birthdate") {
                // Update usr_pass when birthdate changes
                const formattedBirthdate = formatDate(value);
                updatedFields.usr_pass = `${formattedBirthdate}${updatedFields.empid || ""
                    }`;
            }

            return updatedFields;
        });
    };
    const formatDate = (dateString) => {
        if (!dateString) return "";

        const date = new Date(dateString);
        const monthNames = [
            "Jan",
            "Feb",
            "Mar",
            "Apr",
            "May",
            "Jun",
            "Jul",
            "Aug",
            "Sep",
            "Oct",
            "Nov",
            "Dec",
        ];
        const month = monthNames[date.getMonth()];
        const year = date.getFullYear();

        return `${month}${year}`;
    };
    useEffect(() => {
        const fg = async () => {
            try {
                // Make multiple requests
                const res = await Promise.all([
                    services.getdropdowndata("APCBL_GNDR"),
                    services.getdropdowndata("User_Type"),
                    services.getdropdowndata("User_Category"),
                    services.getdropdowndata("User_Title"),
                    services.getdropdowndata("APCBL_POST"),
                    services.getdropdowndata("Address_Type"),
                    services.getdropdowndata("EMP_NATUR"),
                ]);

                const newUsrdata = {
                    usrgender: [],
                    usrtype: [],
                    usrcat: [],
                    usrtitle: [],
                    Desgn: [],
                    add_type: [],
                    user_contract: [],
                };

                // Process responses based on the name identifier
                res.forEach((response) => {
                    const { name, dpresult } = response;

                    switch (name) {
                        case "APCBL_GNDR":
                            newUsrdata.usrgender = dpresult;
                            break;
                        case "User_Type":
                            newUsrdata.usrtype = dpresult;
                            break;
                        case "User_Category":
                            newUsrdata.usrcat = dpresult;
                            break;
                        case "User_Title":
                            newUsrdata.usrtitle = dpresult;
                            break;
                        case "APCBL_POST":
                            newUsrdata.Desgn = dpresult;
                            break;
                        case "Address_Type":
                            newUsrdata.add_type = dpresult;
                            break;
                        case "EMP_NATUR":
                            newUsrdata.user_contract = dpresult;
                            break;

                        default:
                            break;
                    }
                });

                setUsrdata(newUsrdata);
            } catch (err) {
                // Handle errors
                setError(err);
            } finally {
                // Set loading to false when requests are completed
                setLoading(false);
            }
        };

        fg();
    }, []);

    const handleSubmit = async (e) => {
        e.preventDefault();
        console.log("Form Data:", formData);
    };
    console.log("formData :", formData);

    const emptyfieldvalidation = () => {
        const newErrors = {};
        if (!formData.usr_typ) newErrors.usr_typ = "User  Type is required";
        if (!formData.userCategory) newErrors.userCategory = "User  Category is required";
        if (!formData.usr_fnm) newErrors.usr_fnm = "User  First Name is required";
        if (!formData.usr_lnm) newErrors.usr_lnm = "User  Last Name is required";
        if (!formData.phone1) newErrors.phone1 = "Phone 1 is required";
        if (!formData.email) newErrors.email = "Email is required";
        if (!formData.userPost) newErrors.userPost = "User  Post is required";
        if (!formData.empid) newErrors.empid = "Emp ID is required";
        if (!formData.usrgender) newErrors.usrgender = "Gender is required";
        if (!formData.birthdate) newErrors.birthdate = "Birth date is required";
        if (!formData.jondate) newErrors.jondate = "Joining date is required";
        if (!formData.icrdno) newErrors.icrdno = "Access ICRD No is required";
        if (!formData.aprval_id) newErrors.aprval_id = "Approval ID is required";
        if (!formData.emp_ctrct) newErrors.emp_ctrct = "Employee Contract is required";
        if (!formData.addressLine1) newErrors.addressLine1 = "Address Line 1 is required";
        if (!formData.city) newErrors.city = "City is required";
        if (!formData.pin) newErrors.pin = "PIN is required";
        if (!formData.country) newErrors.country = "Country is required";
        if (!formData.ofcilemail) newErrors.ofcilemail = "Official email is required";
        if (!formData.addtype) newErrors.addtype = "Address Type is required";
        if (!formData.prefadd) newErrors.prefadd = "Preferred Address is required";

        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };
    console.log("formData", formData)
    const sub_addusr_data = () => {
        if (emptyfieldvalidation()) {
            const insrt_tab1 = async () => {
                try {
                    const insrt_res = await services.new_sys_usr(formData);
                    if (insrt_res) {
                        alert("User  Added successfully");
                        setFormData({});
                    } else {
                        alert("Failed to Add User");
                    }
                } catch (error) {
                    alert("Failed to Add User. Please fill out all required fields once again");
                }
            };
            insrt_tab1();
        } else {
            alert("Please fill out all required fields");
        }
    };
    const cleardata = () => {
        setFormData({
            usr_typ: "",
            userCategory: "",
            userPost: "",
            userTitle: "",
            usr_fnm: "",
            usr_mnm: "",
            usr_lnm: "",
            usr_dnm: "",
            usrgender: "",
            usernm: "",
            usr_pass: "",
            phone1: "",
            phone2: "",
            landlineno: "",
            cellphone: "",
            empid: "",
            ofcilemail: "",
            birthdate: "",
            addressLine1: "",
            addressLine2: "",
            addressLine3: "",
            city: "",
            pin: "",
            state: "",
            addtype: "",
            country: 100,
            email: "",
            landmark: "",
            gmapLink: "",
            geoloc: "",
            icrdno: "",
            aprval_id: "",
            emp_ctrct: "",
            prefadd: "",
            jondate: "",
            resdate: "",
            resnote: "",
            aflt_key: ""
        })
    }


    return (
        <div className="wrapper_form">
            <div className="title">Add System User</div>
            <div className="form-container">
                <form onSubmit={handleSubmit} method="Post" className="frm_wd" style={{ backgroundColor: "#dddddf" }}>
                    <div className="form-container">

                        <hr className="hr_ttl" />
                        <p style={{ fontSize: "25px", padding: "5px 10px 1px 10px", color: "black", borderRadius: "10px" }}>
                            Personal information
                        </p>
                        <hr className="hr_dt" style={{ marginBottom: "15px" }} />
                        <div className="row" style={{ marginBottom: "15px",marginTop:"2%" }}>
                            <div>
                                <div className="field">
                                    <select
                                        style={{ marginLeft: "13%", width: '100%' }}
                                        value={formData.userTitle}
                                        onChange={(e) => handleChange(e, 'userTitle')}
                                        required
                                    >
                                        <option value="">Select Title</option>
                                        {usrdata.usrtitle.map((type) => (
                                            <option key={type.ATTRIB_CD} value={type.ATTRIB_CD}>{type.ATTRIB_NAME}</option>
                                        ))}
                                    </select>
                                </div>
                            </div>
                            <div className="column mg_l">
                                <div className="field">
                                    <input placeholder="First Name" type="text" value={formData.usr_fnm || ""} onChange={(e) => setFormData({ ...formData, usr_fnm: e.target.value })} />
                                    < label>
                                        User First Name{" "}
                                        {errors.usr_fnm && <span className="error-message">{errors.usr_fnm}</span>}
                                    </label>
                                </div>
                            </div>
                            <div className="column">
                                <div className="field">
                                    <input placeholder="Middle Name" type="text" value={formData.usr_mnm || ""} onChange={(e) => setFormData({ ...formData, usr_mnm: e.target.value })} />
                                    <label>User Middle Name</label>
                                    {errors.usr_mnm && <span className="error-message">{errors.usr_mnm}</span>}
                                </div>
                            </div>
                            <div className="column">
                                <div className="field">
                                    <input placeholder="Last Name" type="text" value={formData.usr_lnm || ""} onChange={(e) => setFormData({ ...formData, usr_lnm: e.target.value })} />
                                    <label>
                                        User Last Name
                                        {errors.usr_lnm && <span className="error-message">{errors.usr_lnm}</span>}
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="column">
                                <div className="field">
                                    <input placeholder="Display Name" type="text" value={formData.usr_dnm || ""} onChange={(e) => setFormData({ ...formData, usr_dnm: e.target.value })} />
                                    <label>User Display Name</label>
                                    {errors.usr_dnm && <span className="error-message">{errors.usr_dnm}</span>}
                                </div>
                            </div>
                            <div className="column">
                                <div className="field">
                                    <input type="date" name="birthdate" value={formData.birthdate || ""} onChange={handleChange1} />
                                    <label>
                                        Date of Birth{" "}
                                        {errors.birthdate && <span className="error-message">{errors.birthdate}</span>}
                                    </label>
                                </div>
                            </div>
                            <div className="column">
                                <div className="field">
                                    <input placeholder="Personal Email" type="email" value={formData.email || ""} onChange={(e) => setFormData({ ...formData, email: e.target.value })} />
                                    <label>
                                        Personal Email{" "}
                                        {errors.email && <span className="error-message">{errors.email}</span>}
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div className="row" style={{ marginBottom: "1px" }}>
                            <div className="column drp_ln_hg">
                                <label className="drp_mg">
                                    User Type{" "}
                                    {errors.usr_typ && <span className="error-message">{errors.usr_typ}</span>}
                                </label>
                                <div className="field">
                                    <select className="dropdown_p1" value={formData.usr_typ} onChange={(e) => handleChange(e, "usr_typ")}>
                                        <option value="">Select User Type</option>
                                        <option key="Adm" value="Adm">Application Affilate Admin</option>
                                        {/* {usrdata.usrtype.map((type) => (
                                            <option key={type.ATTRIB_CD} value={type.ATTRIB_CD}>{type.ATTRIB_NAME}</option>
                                        ))} */}
                                    </select>
                                </div>
                            </div>
                            <div className="column drp_ln_hg">
                                <label className="drp_mg">
                                    User Category{" "}
                                    {errors.userCategory && <span className="error-message">{errors.userCategory}</span>}
                                </label>
                                <div className="field">
                                    <select className="dropdown_p1" value={formData.userCategory} onChange={(e) => handleChange(e, "userCategory")}>
                                        <option value="">Select User Category</option>
                                        {usrdata.usrcat.map((type) => (
                                            <option key={type.ATTRIB_CD} value={type.ATTRIB_CD}>{type.ATTRIB_NAME}</option>
                                        ))}
                                    </select>
                                </div>
                            </div>
                            <div className="column drp_ln_hg">
                                <label className="drp_mg">
                                    User Gender{" "}
                                    {errors.usrgender && <span className="error-message">{errors.usrgender}</span>}
                                </label>
                                <div className="field">
                                    <select className="dropdown_p1" value={formData.usrgender} onChange={(e) => handleChange(e, "usrgender")}>
                                        <option value="">Select User Gender</option>
                                        {usrdata.usrgender.map((type) => (
                                            <option key={type.ATTRIB_CD} value={type.ATTRIB_CD}>{type.ATTRIB_NAME}</option>
                                        ))}
                                    </select>
                                </div>
                            </div>
                        </div>
                        <hr className="hr_ttl" />
                        <p style={{ fontSize: "25px", color: "black", }}>
                            Official Details
                        </p>
                        <hr className="hr_dt" style={{ marginBottom: "1.5%" }} />
                        <div className="row" style={{ marginBottom: "2px" }}>
                            <div className="column">
                                <div className="field">
                                    <span>
                                        <i className="fas fa-envelope"></i>
                                    </span>
                                    <input placeholder="Emp ID"
                                        type="text"
                                        name="empid" // Name attribute must match formData key
                                        value={formData.empid || ""}
                                        onChange={handleChange1} // Use combined handler
                                    />
                                    <label>
                                        Emp ID{" "}
                                        {errors.empid && (
                                            <span className="error-message">{errors.empid}</span>
                                        )}
                                    </label>
                                </div>
                            </div>
                            <div className="column">
                                <div className="field">
                                    <span>
                                        <i className="fas fa-envelope"></i>
                                    </span>
                                    <input placeholder=" Access ICRD No"
                                        type="number"
                                        value={formData.icrdno || ""}
                                        onChange={(e) =>
                                            setFormData({ ...formData, icrdno: e.target.value })
                                        }
                                    />
                                    <label>
                                        Access ICRD No
                                        {errors.icrdno && (
                                            <span className="error-message">{errors.icrdno}</span>
                                        )}
                                    </label>
                                </div>
                            </div>
                            <div className="column">
                                <div className="field">
                                    <span>
                                        <i className="fas fa-envelope"></i>
                                    </span>
                                    <input placeholder="Approval ID"
                                        type="number"
                                        value={formData.aprval_id || ""}
                                        onChange={(e) =>
                                            setFormData({
                                                ...formData,
                                                aprval_id: e.target.value,
                                            })
                                        }
                                    />
                                    <label>
                                        Approval ID :
                                        {errors.aprval_id && (
                                            <span className="error-message">
                                                {errors.aprval_id}
                                            </span>
                                        )}
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div className="row" style={{ marginBottom: "1px" }}>
                            <div className="column drp_ln_hg">
                                <label className="drp_mg"> User Name </label>
                                <div className="field">
                                    <input placeholder="User Name " type="text" name="usernm" value={formData.usernm || ""} readOnly />
                                    {validationErrors.usernm && <span className="error-message">{validationErrors.usernm}</span>}
                                </div>
                            </div>
                            <div className="column drp_ln_hg">
                                <label className="drp_mg"> Password</label>
                                <div className="field">
                                    <input placeholder="Password" type="password" value={formData.usr_pass || ""} readOnly />
                                    {errors.usr_pass && <span className="error-message">{errors.usr_pass}</span>}
                                </div>
                            </div>
                        </div>
                        <div className="row" style={{ marginBottom: "1px" }}>
                            <div className="column">
                                <div className="field">
                                    <input type="date" value={formData.jondate || ""} onChange={(e) => setFormData({ ...formData, jondate: e.target.value })} />
                                    <label>
                                        Joining Date{" "}
                                        {errors.jondate && <span className="error-message">{errors.jondate}</span>}
                                    </label>
                                </div>
                            </div>
                            <div className="column">
                                <div className="field">
                                    <input placeholder="Official Email" type="email" value={formData.ofcilemail || ""} onChange={(e) => setFormData({ ...formData, ofcilemail: e.target.value })} />
                                    <label>
                                        Official Email{"  "}
                                        {errors.ofcilemail && <span className="error-message">{errors.ofcilemail}</span>}
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div className="row" style={{ marginBottom: "20px" }}>
                            <div className="column drp_ln_hg">
                                <label className="drp_mg">
                                    Employee Nature{" "}
                                    {errors.emp_ctrct && <span className="error-message">{errors.emp_ctrct}</span>}
                                </label>
                                <div className="field">
                                    <select className="dropdown_p1" value={formData.emp_ctrct} onChange={(e) => handleChange(e, "emp_ctrct")}>
                                        <option value="">Select Employee Nature</option> {usrdata.user_contract.map((type) => (
                                            <option key={type.ATTRIB_CD} value={type.ATTRIB_CD}>{type.ATTRIB_NAME}</option>
                                        ))}
                                    </select>
                                </div>
                            </div>
                            <div className="column drp_ln_hg">
                                <label className="drp_mg">
                                    User Post{" "}
                                    {errors.userPost && <span className="error-message">{errors.userPost}</span>}
                                </label>
                                <div className="field">
                                    <select className="dropdown_p1" value={formData.userPost} onChange={(e) => handleChange(e, "userPost")}>
                                        <option value="">Select User Post</option>
                                        {usrdata.Desgn.map((type) => (
                                            <option key={type.ATTRIB_CD} value={type.ATTRIB_CD}>{type.ATTRIB_NAME}</option>
                                        ))}
                                    </select>
                                </div>
                            </div>
                        </div>
                        <hr className="hr_ttl" />
                        <p style={{ fontSize: "25px", padding: "5px 10px 1px 10px", color: "black", borderRadius: "10px" }}>
                            Address Details
                        </p>
                        <hr className="hr_dt" />
                        <div className="row" style={{ marginBottom: "10px" }}>
                            <div className="column drp_ln_hg">
                                <label className="drp_mg">
                                    Address Type{" "}
                                    {errors.addtype && (
                                        <span className="error-message">{errors.addtype}</span>
                                    )}
                                </label>
                                <div className="field">
                                    <span>
                                        <i className="fas fa-unlock"></i>
                                    </span>
                                    <select
                                        className="dropdown_p1"
                                        value={formData.addtype}
                                        onChange={(e) => handleChange(e, "addtype")}
                                    >
                                        <option value="">Select Address Type</option>
                                        {usrdata.add_type.map((type) => (
                                            <option value={type.ATTRIB_CD}>
                                                {type.ATTRIB_NAME}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                            </div>

                            <div
                                className="column"
                                style={{ display: "flex", alignItems: "center", marginTop: "5px", lineHeight: "12px" }}
                            >
                                <label style={{ marginLeft: "5px", marginRight: "10px" }}>
                                    Is this Preferred Address?
                                    {errors.prefadd && <span className="error-message">{errors.prefadd}</span>}
                                </label>
                                <FormControl>
                                    <RadioGroup
                                        row
                                        aria-labelledby="demo-row-radio-buttons-group-label"
                                        value={formData.prefadd || ""}
                                        onChange={(e) =>
                                            setFormData({
                                                ...formData,
                                                prefadd: e.target.value,
                                            })
                                        }
                                    >
                                        <FormControlLabel
                                            style={{ padding: "2px" }}
                                            value="Yes"
                                            control={<Radio />}
                                            label="Yes"
                                        />
                                        <FormControlLabel
                                            value="No"
                                            control={<Radio />}
                                            label="No"
                                        />
                                    </RadioGroup>
                                </FormControl>
                            </div>

                        </div>
                        <div className="row" style={{ marginBottom: "15px" }}>
                            <div className="column">
                                <div className="field">
                                    <input placeholder="Line 1" type="text" value={formData.addressLine1 || ""} onChange={(e) => setFormData({ ...formData, addressLine1: e.target.value })} />
                                    <label>
                                        Address Line 1
                                        {errors.addressLine1 && <span className="error-message">{errors.addressLine1}</span>}
                                    </label>
                                </div>
                            </div>
                            <div className="column">
                                <div className="field">
                                    <input placeholder="City" type="text" value={formData.city || ""} onChange={(e) => setFormData({ ...formData, city: e.target.value })} />
                                    <label>
                                        City{" "}
                                        {errors.city && <span className="error-message">{errors.city}</span>}
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div className="row" style={{ marginBottom: "15px" }}>
                            <div className="column">
                                <div className="field">
                                    <input placeholder="Line 2" type="text" value={formData.addressLine2 || ""} onChange={(e) => setFormData({ ...formData, addressLine2: e.target.value })} />
                                    <label>Address Line 2</label>
                                </div>
                            </div>
                            <div className="column">
                                <div className="field">
                                    <input placeholder="PIN" type="text" value={formData.pin || ""} onChange={(e) => setFormData({ ...formData, pin: e.target.value })} />
                                    <label>
                                        PIN{" "}
                                        {errors.pin && <span className="error-message">{errors.pin}</span>}
                                    </label>
                                </div>
                                {/* ```javascript */}
                            </div>
                        </div>
                        <div className="row" style={{ marginBottom: "1px" }}>
                            <div className="column">
                                <div className="field">
                                    <input placeholder="Line 3" type="text" value={formData.addressLine3 || ""} onChange={(e) => setFormData({ ...formData, addressLine3: e.target.value })} />
                                    <label>Address Line 3</label>
                                </div>
                            </div>
                            <div className="column">
                                <div className="field">
                                    <input placeholder="State" type="text" value={formData.state || ""} onChange={(e) => setFormData({ ...formData, state: e.target.value })} />
                                    <label>State</label>
                                    {errors.state && <span className="error-message">{errors.state}</span>}
                                </div>
                            </div>
                        </div>
                        <div className="row" style={{ marginBottom: "15px" }}>
                            <div className="column drp_ln_hg">
                                <label className="drp_mg">Country</label>
                                <div className="">
                                    <select className="dropdown_p1" onChange={(e) => handleChange(e, "country")} value={formData.country}>
                                        <option value="">---Select Country---</option>
                                        {data.country_res.map((type) => (
                                            <option key={type.CNTRY_KY} value={type.CNTRY_KY}>{type.CNTRY_NM}</option>
                                        ))}
                                    </select>
                                </div>
                            </div>
                            <div className="column drp_ln_hg">
                                <label className="drp_mg">Landmark</label>
                                <div className="field">
                                    <input placeholder="Landmark" type="text" value={formData.landmark || ""} onChange={(e) => setFormData({ ...formData, landmark: e.target.value })} />
                                    {errors.landmark && <span className="error-message">{errors.landmark}</span>}
                                </div>
                            </div>
                        </div>
                        <div className="row" style={{ marginBottom: "15px" }}>
                            <div className="column">
                                <div className="field">
                                    <input placeholder="Google Map Link" type="text" value={formData.gmapLink || ""} onChange={(e) => setFormData({ ...formData, gmapLink: e.target.value })} />
                                    <label>Google Map Link</label>
                                    {errors.gmapLink && <span className="error-message">{errors.gmapLink}</span>}
                                </div>
                            </div>
                            <div className="column">
                                <div className="field">
                                    <input placeholder="Branch Location" type="text" value={formData.geoloc || ""} onChange={(e) => setFormData({ ...formData, geoloc: e.target.value })} />
                                    <label>Branch Location</label>
                                    {errors.geoloc && <span className="error-message">{errors.geoloc}</span>}
                                </div>
                            </div>
                        </div>
                        <div className="row" style={{ marginBottom: "15px" }}>
                            <div className="column">
                                <div className="field">
                                    <input placeholder="Phone 1" type="number" value={formData.phone1 || ""} onChange={(e) => setFormData({ ...formData, phone1: e.target.value })} />
                                    <label>Phone 1{" "}{errors.phone1 && <span className="error-message">{errors.phone1}</span>}</label>
                                </div>
                            </div>
                            <div className="column">
                                <div className="field">
                                    <input placeholder="Phone 2" type="number" value={formData.phone2 || ""} onChange={(e) => setFormData({ ...formData, phone2: e.target.value })} />
                                    <label>Phone 2</label>
                                </div>
                            </div>
                        </div>
                        <div className="row" style={{ marginBottom: "15px" }}>
                            <div className="column">
                                <div className="field">
                                    <input placeholder="Landline No" type="number" value={formData.landlineno || ""} onChange={(e) => setFormData({ ...formData, landlineno: e.target.value })} />
                                    <label>Landline No</label>
                                    {errors.landlineno && <span className="error-message">{errors.landlineno}</span>}
                                </div>
                            </div>
                            <div className="column">
                                <div className="field">
                                    <input placeholder="Cell Phone" type="number" value={formData.cellphone || ""}
                                        onChange={(e) => setFormData({ ...formData, cellphone: e.target.value })} />
                                    <label>Cell Phone</label>
                                    {errors.cellphone && <span className="error-message">{errors.cellphone}</span>}
                                </div>
                            </div>
                        </div>
                        <hr className="hr1" style={{ marginTop: "3%" }} />
                        <div className="row" style={{ marginBottom: "15px" }}>
                            <div className="column"></div>
                            <div className="column"></div>
                            <div className="column"></div>
                            <div className="column"></div>
                            <div className="column">
                                <div className="field">
                                    <button type="submit" className="btn-action action-button" onClick={sub_addusr_data}>
                                        Submit
                                    </button>
                                </div>
                            </div>
                        </div>
                        <hr className="hr1" style={{ marginTop: "2.5%", padding: "1px" }} />
                    </div>
                </form>
            </div>
        </div>
    )
}
