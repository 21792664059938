import React, { useState, useRef, useEffect } from 'react';
import axios from 'axios';
import services from "./apiServices";

export default function Sys_Usr_Lve_Policy_Management() {
    const [selectedOption, setSelectedOption] = useState('');
    const [textareaValue, setTextareaValue] = useState({
        lve_policy: "",
        aflt_key: ""
    });
    const [aflttotalcnt, setaflttotalcnt] = useState({
        aflt_cnt_res: [],
        aflt_wise_usr_cnt_res: [],
        alt_wise_lve_types_res: [],
        aflt_wise_lve_apl_cnt_res: [],
        pendng_aprved_aflt_apl_cnt_res: [],
        all_aflt_confg_dt: [],
        all_alf_auth_ntt_res: [],
        aflt_wise_lve_policy_res: []
    });
    const [selectedaffilate, setSelectedaffilate] = useState('');
    const [selectedaffilate1, setSelectedaffilate1] = useState('');
    const [selectedaffilate2, setSelectedaffilate2] = useState('');
    const [selectedAfltKey, setSelectedAfltKey] = useState('');  // Store the selected affiliate key
    const [textareaValue_edit, settextareaValue_edit] = useState({
        edit_lve_policy: "",
        aflt_key: ''
    });
    console.log("textareaValue_edit", textareaValue_edit)
    console.log("aflttotalcnt", aflttotalcnt)
    const [files, setFiles] = useState([]);
    const [file, setFile] = useState(null);
    const fileInputRef = useRef(null); // Create a ref for the file input

    // Handle radio button changes
    const handleOptionChange = (e) => {
        setSelectedOption(e.target.value);
    };

    // Handle saving new Leave Policy
    const handleSave = async (e) => {
        // Prevent saving if no affiliate is selected
        if (!selectedaffilate1) {
            alert("Please select an affiliate before adding a leave policy.");
            return;
        }

        console.log('Saved:', textareaValue);
        try {
            const response = await services.add_lve_policy_sys_usr(textareaValue);
            if (response) {
                console.log(response.data);
                alert("Leave Policy Added Successfully");
                setTextareaValue({ lve_policy: "", aflt_key: "" })
                setSelectedaffilate1([])
            } else {
                alert("Failed to add Leave Policy ");
            }
        } catch (error) {
            console.error('Error saving policy:', error);
            alert("Failed to add Leave Policy ");
        }
    };

    // Handle saving new Leave Policy
    const handleupdate = async (e) => {
        // Prevent saving if no affiliate is selected
        if (!selectedaffilate2) {
            alert("Please select an affiliate before updating the leave policy.");
            return;
        }

        console.log('Saved:', textareaValue_edit);
        try {
            const response = await services.lve_policy_upd(textareaValue_edit);
            if (response) {
                // console.log(response.data);
                alert("Leave Policy Updated Successfully");
                settextareaValue_edit({ edit_lve_policy: "", aflt_key: "" })
                setSelectedaffilate2([])
            }
        } catch (error) {
            console.error('Error saving policy:', error);
        }
    };

    useEffect(() => {
        const fetch = async () => {
            try {
                const res = await services.get_total_aflt_cnt();
                if (res) {
                    setaflttotalcnt(res);
                }
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };
        fetch();
    }, []);

    // Handle file input change
    const handleFileChange = (e) => {
        setFile(e.target.files[0]);
    };

    // Handle file upload
    const handleUploadSubmit = async (e) => {
        e.preventDefault();
        if (!selectedaffilate) {
            alert("Please select an affiliate before uploading a file.");
            return;
        }

        if (!file) {
            alert("Please select a file to upload.");
            return;
        }

        const formData = new FormData();
        formData.append('file', file);

        // Append aflt_key to the formData when uploading the file
        formData.append('aflt_key', selectedAfltKey);
        console.log("formData", formData)
        try {
            const response = await services.upload_file_sys_usr(formData);
            if (response) {
                console.log(response.data);
                alert("Leave Policy Added Successfully");
                setFile(null); // Reset the file state
                if (fileInputRef.current) {
                    fileInputRef.current.value = ''; // Clear the input value
                }
                setSelectedaffilate([])
            }
        } catch (error) {
            console.error('Error uploading file:', error);
        }
    };

    // Handle textarea change
    const handleTextareaChange = (e) => {
        const { value } = e.target;
        setTextareaValue({
            ...textareaValue,
            lve_policy: value, // Update the lve_policy field specifically
        });
    };

    const handle_edit_lve_policy = (e) => {
        const { value } = e.target;
        settextareaValue_edit({
            ...textareaValue_edit,
            edit_lve_policy: value, // Update the lve_policy field specifically
        });
    };

    // Handle affiliate selection
    const handleaffilate = (event) => {
        const selectedAffilate = event.target.value;
        setSelectedaffilate(selectedAffilate);

        // Find the corresponding aflt_key from the aflttotalcnt data
        const selectedAffiliate = aflttotalcnt.all_alf_auth_ntt_res.find(item => item.AFLT_NAME === selectedAffilate);
        if (selectedAffiliate) {
            setSelectedAfltKey(selectedAffiliate.AFLT_KY); // Set the aflt_key
            setTextareaValue({ aflt_key: selectedAffiliate.AFLT_KY }); // Set the aflt_key
        }
    };
    const handleaffilate1 = (event) => {
        const selectedAffilate = event.target.value;
        setSelectedaffilate1(selectedAffilate);

        // Find the corresponding aflt_key from the aflttotalcnt data
        const selectedAffiliate = aflttotalcnt.all_alf_auth_ntt_res.find(item => item.AFLT_NAME === selectedAffilate);
        if (selectedAffiliate) {
            setSelectedAfltKey(selectedAffiliate.AFLT_KY); // Set the aflt_key
            setTextareaValue({ aflt_key: selectedAffiliate.AFLT_KY }); // Set the aflt_key
        }
    };
    const handleaffilate2 = (event) => {
        const selectedAffilate = event.target.value;
        setSelectedaffilate2(selectedAffilate);

        // Find the corresponding aflt_key from the aflttotalcnt data
        const selectedAffiliate = aflttotalcnt.aflt_wise_lve_policy_res.find(item => item.AFLT_NAME === selectedAffilate);
        if (selectedAffiliate) {
            if (!selectedAffiliate.LVE_ACTUL_NTC) {
                alert("For this Affilate there is file format leave policy")
                settextareaValue_edit({ edit_lve_policy: "", aflt_key: "" });
            } else {
                settextareaValue_edit({ edit_lve_policy: selectedAffiliate.LVE_ACTUL_NTC, aflt_key: selectedAffiliate.AFLT_KEY }); // Set the aflt_key
            }
        }
    };
    useEffect(() => {
        const fetchFileDetails = async () => {
            try {
                console.log("Fetching file details...");
                const res = await services.get_lve_policy_details();
                console.log("Response received:", res);
                if (res.files) {
                    setFiles(res.files);
                    console.log("Files state updated:", res.files);
                } else {
                    console.error("Unexpected data format:", res);
                }
            } catch (error) {
                console.error("Error fetching file details:", error);
            }
        };
        fetchFileDetails();
    }, []);

    const lastFile = files.length > 0 ? files[files.length - 1] : null;

    return (
        <div className='wrapper_form'>
            <div className="title" style={{ marginTop: ".3%" }}>Leave Policy Management</div>
            <div className="radio-row" style={{ marginBottom: "5px" }}>
                <label>
                    <input
                        type="radio"
                        name="addOption"
                        value="addFile"
                        checked={selectedOption === "addFile"}
                        onChange={handleOptionChange}
                        style={{ marginRight: "5px", width: "3%" }}
                    />
                    Add Files
                </label>
            </div>
            <hr className="hr1" />
            {selectedOption === "addFile" && (
                <div className="radio-content">
                    <form className="pdm" onSubmit={handleUploadSubmit}>
                        <div style={{ margin: "1% 1% 1% 0% " }}>
                            <label htmlFor="affilate">Select Client : </label>
                            <select
                                id="affilate"
                                value={selectedaffilate}
                                onChange={handleaffilate}
                                style={{ borderRadius: "8px", width: "35%" }}
                            >
                                <option value="">Select Client</option>
                                {Array.from(new Set(aflttotalcnt.alt_wise_lve_types_res.map(item => item.AFLT_NAME)))
                                    .map((affilate) => (
                                        <option key={affilate} value={affilate}>
                                            {affilate}
                                        </option>
                                    ))}
                            </select>
                        </div>
                        <input
                            type="file"
                            accept="*/*" // Allow all file formats
                            onChange={handleFileChange}
                            ref={fileInputRef} // Attach the ref to the input
                        /><br />
                        <div className="centered-container" style={{ marginBottom: "5px", }}>
                            <button
                                style={{
                                    marginTop: "5px",
                                    width: "50%", // Adjust as needed
                                    textAlign: "center",
                                }}
                                type="submit"
                                className="btn-action action-button pd2"
                            >
                                Upload File
                            </button>
                        </div>

                    </form>
                </div>
            )}

            <div className="radio-row" style={{ marginBottom: "5px" }}>
                <label>
                    <input
                        type="radio"
                        name="addOption"
                        value="addManually"
                        checked={selectedOption === "addManually"}
                        onChange={handleOptionChange}
                        style={{ marginRight: "5px", width: "3%" }}
                    />
                    Add Manually
                </label>
            </div>
            <hr className="hr1" />
            {/* Add Manually Form */}
            {selectedOption === "addManually" && (
                <div className="manual-entry">

                    <form className="frm_wd" onSubmit={(e) => { e.preventDefault(); handleSave(); }}>
                        <div style={{ margin: "1% 1% 1% 0% " }}>
                            <label htmlFor="affilate">Select Client : </label>
                            <select
                                id="affilate"
                                value={selectedaffilate1}
                                onChange={handleaffilate1}
                                style={{ borderRadius: "8px", width: "30%" }}
                            >
                                <option value="">Select Client</option>
                                {Array.from(new Set(aflttotalcnt.alt_wise_lve_types_res.map(item => item.AFLT_NAME)))
                                    .map((affilate) => (
                                        <option key={affilate} value={affilate}>
                                            {affilate}
                                        </option>
                                    ))}
                            </select>
                        </div>
                        <textarea
                            value={textareaValue.lve_policy} // Bind to lve_policy in the state
                            onChange={handleTextareaChange} // Call handleTextareaChange on user input
                            placeholder="Enter your policy details here..."
                            rows="10"
                            style={{ width: "100%", marginTop: "10px" }}
                            name='lve_policy'
                        />
                        <hr className="hr_dt" style={{ marginTop: "25px" }} />
                        <button
                            className="btn-action action-button"
                            style={{
                                marginTop: "10px",
                                marginBottom: "15px",
                                // padding: "5px 10px",
                                backgroundColor: "blue",
                                color: "white",
                                border: "none",
                                cursor: "pointer",
                                width: "10%",
                                float: "right"
                            }}
                            type="submit"
                        >
                            Save
                        </button>
                        <hr className="hr_dt" />
                    </form>
                </div>
            )}

            <div className="radio-row" style={{ marginBottom: "5px" }}>
                <label>
                    <input
                        type="radio"
                        name="addOption"
                        value="editPolicy"
                        checked={selectedOption === "editPolicy"}
                        onChange={handleOptionChange}
                        style={{ marginRight: "5px", width: "3%" }}
                    />
                    Edit Leave Policy
                </label>
            </div>
            {selectedOption === "editPolicy" && (
                <div className="manual-entry">
                    <form className="frm_wd" onSubmit={(e) => { e.preventDefault(); handleupdate(); }}>
                        <div style={{ margin: "1% 1% 1% 0% " }}>
                            <label htmlFor="affilate">Select Client : </label>
                            <select
                                id="affilate"
                                value={selectedaffilate2}
                                onChange={handleaffilate2}
                                style={{ borderRadius: "8px", width: "30%" }}
                            >
                                <option value="">Select Client</option>
                                {Array.from(new Set(aflttotalcnt.alt_wise_lve_types_res.map(item => item.AFLT_NAME)))
                                    .map((affilate) => (
                                        <option key={affilate} value={affilate}>
                                            {affilate}
                                        </option>
                                    ))}
                            </select>
                        </div>
                        <textarea
                            value={textareaValue_edit.edit_lve_policy} // Bind to lve_policy in the state
                            onChange={handle_edit_lve_policy} // Call handleTextareaChange on user input
                            placeholder="Select 'Client' from the dropdown."
                            rows="10"
                            style={{ width: "100%", marginTop: "10px", marginBottom: "10px", }}
                            name='edit_lve_policy'
                        />
                        <hr className="hr_dt" />
                        <button
                            className="btn-action action-button"
                            style={{
                                marginTop: "10px",
                                marginBottom: "10px",
                                backgroundColor: "blue",
                                color: "white",
                                border: "none",
                                cursor: "pointer",
                                width: "10%",
                                float: "right"
                            }}
                            type="submit"
                        >
                            Update
                        </button>
                        <hr className="hr_dt" />
                    </form>
                </div>
            )}
        </div>
    );
}
