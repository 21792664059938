import React, { useState, useEffect } from "react";
import services from "./apiServices";
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Grid,
    IconButton
} from "@mui/material";
import DownloadIcon from '@mui/icons-material/Download';

const headerCellStyle = {
    fontSize: "0.8rem",
    padding: "1px 18px",
    backgroundColor: "#4a6d8c",
    border: "1px solid gray",
    color: "white",
    textAlign: "center",
};

const cellStyle = {
    fontSize: "0.8rem",
    padding: "1px 18px",
    border: "1px solid gray",
};

function Sys_Usr_Aflt_Lve_Typ_Rpt() {
    const [aflttotalcnt, setaflttotalcnt] = useState({
        aflt_cnt_res: [],
        aflt_wise_usr_cnt_res: [],
        alt_wise_lve_types_res: [],
        aflt_wise_lve_apl_cnt_res: [],
        pendng_aprved_aflt_apl_cnt_res: [],
        all_aflt_confg_dt: [],
        all_alf_auth_ntt_res: [],
        aflt_wise_lve_policy_res: [],
        sys_usr_lve_db_res: []
    });

    const [groupedLeaveTypes, setGroupedLeaveTypes] = useState({});

    // Group leave types by affiliate ID
    const groupLeaveTypesByAffiliate = (leaveTypes) => {
        const grouped = leaveTypes.reduce((acc, leaveType) => {
            const affiliateId = leaveType.AFLT_ID_BY_CUST; // Assuming this is the affiliate ID
            if (!acc[affiliateId]) {
                acc[affiliateId] = [];
            }
            acc[affiliateId].push(leaveType);
            return acc;
        }, {});
        setGroupedLeaveTypes(grouped);
    };

    useEffect(() => {
        const fetch = async () => {
            try {
                const res = await services.get_total_aflt_cnt();
                if (res) {
                    setaflttotalcnt(res);
                    groupLeaveTypesByAffiliate(res.alt_wise_lve_types_res);
                }
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };
        fetch();
    }, []);

    // Download leave types for the specific affiliate
    const handleDownload = async (affiliateId) => {
        // Get the leave types of the clicked affiliate
        const affiliateLeaveTypes = groupedLeaveTypes[affiliateId];
        console.log("affiliateLeaveTypes", affiliateLeaveTypes)
        if (!affiliateLeaveTypes) {
            console.error("No leave types found for this affiliate");
            return;
        }

        try {
            // Send only the selected affiliate's leave types to the backend
            const response = await fetch('rdbs/sys_usr_rpt/aflt_wise_lvetyp', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(affiliateLeaveTypes), // Send the leave types for the clicked affiliate
            });

            if (response.ok) {
                const blob = await response.blob();
                const url = window.URL.createObjectURL(blob);
                window.open(url, '_blank'); // Open the PDF in a new tab
            } else {
                console.error('Error generating PDF:', response.statusText);
            }
        } catch (error) {
            console.error('Error fetching PDF:', error);
        }
    };

    return (
        <div className="wrapper_form" style={{ marginBlock: "1%" }}>
            <Grid item xs={12} style={{ padding: "1%" }}>
                <div className="title" style={{ marginTop: "2%", textAlign: "center", fontSize: "1.5rem" }}>
                    Client Wise Leave Types
                </div>
                <TableContainer
                    component={Paper}
                    style={{
                        margin: "5px auto 25px auto",
                        boxShadow: "0px 6px 16px 3px #bababa",
                        borderRadius: "20px",
                        border: "2px solid gray",
                        maxHeight: "500px",
                        overflowY: "auto",
                    }}
                >
                    <Table style={{ width: "100%" }}>
                        <TableHead>
                            <TableRow>
                                <TableCell style={headerCellStyle}>Sr. No</TableCell>
                                <TableCell style={headerCellStyle}>Client Name</TableCell>
                                <TableCell style={headerCellStyle}>Organization Short Name</TableCell>
                                <TableCell style={headerCellStyle}>Print</TableCell>
                                <TableCell style={headerCellStyle}>Leave Types</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {Object.entries(groupedLeaveTypes).flatMap(([affiliateId, leaveTypes], index) =>
                                leaveTypes.map((leaveType, leaveIndex) => (
                                    <TableRow key={`${affiliateId}-${leaveIndex}`} style={{ verticalAlign: "top" }}>
                                        {leaveIndex === 0 && (
                                            <TableCell rowSpan={leaveTypes.length} align="center" style={cellStyle}>
                                                {index + 1}
                                            </TableCell>
                                        )}
                                        {leaveIndex === 0 && (
                                            <TableCell rowSpan={leaveTypes.length} align="center" style={cellStyle}>
                                                {leaveType.AFLT_NAME}
                                            </TableCell>
                                        )}
                                        {leaveIndex === 0 && (
                                            <TableCell rowSpan={leaveTypes.length} align="center" style={cellStyle}>
                                                {leaveType.AFLT_SHORT_NAME}
                                            </TableCell>
                                        )}
                                        {leaveIndex === 0 && (
                                            <TableCell rowSpan={leaveTypes.length} align="center" style={cellStyle}>
                                                <IconButton onClick={() => handleDownload(leaveType.AFLT_ID_BY_CUST)}>
                                                    <DownloadIcon style={{ color: "#4a6d8c" }} fontSize="small" />
                                                </IconButton>
                                            </TableCell>
                                        )}
                                        <TableCell align="center" style={cellStyle}>
                                            {leaveType.disp_nm}
                                        </TableCell>
                                    </TableRow>
                                ))
                            )}
                            {Object.keys(groupedLeaveTypes).length < 2 &&
                                Array.from({ length: 2 - Object.keys(groupedLeaveTypes).length }).map((_, index) => (
                                    <TableRow key={`empty-row-${index}`}>
                                        <TableCell align="center" style={cellStyle}>
                                            &nbsp;
                                        </TableCell>
                                        <TableCell align="center" style={cellStyle}>
                                            &nbsp;
                                        </TableCell>
                                        <TableCell align="center" style={cellStyle}>
                                            &nbsp;
                                        </TableCell>
                                        <TableCell align="center" style={cellStyle}>
                                            &nbsp;
                                        </TableCell>
                                    </TableRow>
                                ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>
        </div>
    );
}

export default Sys_Usr_Aflt_Lve_Typ_Rpt;
