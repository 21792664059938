import React, { useState, useEffect } from "react";
import services from "./apiServices";
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Grid,
    IconButton,
    Tooltip
} from "@mui/material";
import DownloadIcon from '@mui/icons-material/Download';

const headerCellStyle = {
    fontSize: "0.8rem",
    padding: "1px 18px",
    backgroundColor: "#4a6d8c",
    border: "1px solid gray",
    color: "white",
    textAlign: "center",
};

const cellStyle = {
    fontSize: "0.8rem",
    padding: "1px 18px",
    border: "1px solid gray",
};

function Sys_Usr_Aflt_Wise_Usr_List() {
    const [aflttotalcnt, setaflttotalcnt] = useState({
        aflt_cnt_res: [],
        aflt_wise_usr_cnt_res: [],
        alt_wise_lve_types_res: [],
        aflt_wise_lve_apl_cnt_res: [],
        pendng_aprved_aflt_apl_cnt_res: [],
    });
    const [groupedUsers, setGroupedUsers] = useState({});

    useEffect(() => {
        const fetch = async () => {
            try {
                const res = await services.get_total_aflt_cnt();
                console.log("API Response:", res); // Log the API response for debugging

                if (res) {
                    setaflttotalcnt(res);
                    groupUsersByAffiliate(res.aflt_wise_usr_cnt_res);
                }
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };
        fetch();
    }, []);

    const groupUsersByAffiliate = (users) => {
        console.log("Fetched Users:", users); // Log the users data to check what we are working with

        // Temporarily skip filtering to see all the users
        const allUsers = users; // All users before filtering

        console.log("All Users:", allUsers); // Log all users to verify

        // Group users by their affiliate ID (AFLT_ID_BY_CUST)
        const grouped = allUsers.reduce((acc, user) => {
            const affiliateId = user.AFLT_ID_BY_CUST; // Assuming this is the affiliate ID
            if (!acc[affiliateId]) {
                acc[affiliateId] = [];
            }
            acc[affiliateId].push(user);
            return acc;
        }, {});

        console.log("Grouped Users:", grouped); // Log the grouped users to verify the output
        setGroupedUsers(grouped);
    };

    const totalUserCount = aflttotalcnt.aflt_wise_usr_cnt_res.length;

    const handleDownload = async (affiliateId, affiliateData) => {
        console.log("Download triggered for affiliate:", affiliateId);
        console.log("Data to be downloaded:", affiliateData);

        try {
            const response = await fetch('rdbs/sys_usr_rpt/aflt_wise_usrs', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    affiliateData,
                }),
            });

            if (response.ok) {
                const blob = await response.blob();
                const url = window.URL.createObjectURL(blob);
                window.open(url, '_blank'); // Open the PDF in a new tab
            } else {
                console.error('Error generating PDF:', response.statusText);
            }
        } catch (error) {
            console.error('Error fetching PDF:', error);
        }
    };

    return (
        <div className="wrapper_form" style={{ marginBlock: "1%" }}>
            <Grid item xs={12} style={{ paddingRight: "1%", marginLeft: "1%" }}>
                <div className="title" style={{ marginTop: "1%" }}>
                    Total Client wise User Count
                </div>
                <TableContainer
                    component={Paper}
                    style={{
                        margin: "5px auto 25px auto",
                        borderRadius: "20px",
                        border: "2px solid gray",
                        maxHeight: "400px",
                    }}
                >
                    <Table style={{ width: "100%" }}>
                        <TableHead>
                            <TableRow style={{ width: "100%" }}>
                                <TableCell style={headerCellStyle}>Sr.No.</TableCell>
                                <TableCell style={headerCellStyle}>Client Name</TableCell>
                                <TableCell style={headerCellStyle}>Client Admin</TableCell>
                                <TableCell style={headerCellStyle}>User Name</TableCell>
                                <TableCell style={headerCellStyle}>Post</TableCell>
                                <TableCell style={headerCellStyle}>Print Report</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {Object.entries(groupedUsers).flatMap(([affiliateId, users], index) => {
                                const affiliateRows = users.map((user, userIndex) => (
                                    <TableRow key={`${affiliateId}-${userIndex}`} style={{ verticalAlign: "top" }}>
                                        {/* Display the Client Info only for the first admin of the group */}
                                        {userIndex === 0 && (
                                            <>
                                                <TableCell rowSpan={users.length} align="center" style={cellStyle}>
                                                    {index + 1}
                                                </TableCell>
                                                <TableCell rowSpan={users.length} align="center" style={cellStyle}>
                                                    {user.AFLT_NAME}
                                                </TableCell>
                                            </>
                                        )}
                                        {/* Display the Client Admin and User Name for each user in the affiliate group */}
                                        <TableCell align="center" style={cellStyle}>
                                            {user['Admin Usr']}
                                        </TableCell>
                                        <TableCell align="center" style={cellStyle}>
                                            {user.USR_DISPLAY_NAME}
                                        </TableCell>
                                        <TableCell align="center" style={cellStyle}>
                                            {user.ATTRIB_NAME}
                                        </TableCell>
                                        {userIndex === 0 && (
                                            <>
                                                <TableCell rowSpan={users.length} align="center" style={cellStyle}>
                                                    <Tooltip title="Download PDF" arrow>
                                                        <IconButton onClick={() => handleDownload(affiliateId, users)}>
                                                            <DownloadIcon title="download pdf" style={{ color: "#4a6d8c" }} fontSize="small" />
                                                        </IconButton>
                                                    </Tooltip>
                                                </TableCell>
                                            </>
                                        )}
                                    </TableRow>
                                ));

                                // Add an empty row after all the users for the current affiliate
                                affiliateRows.push(
                                    <TableRow key={`${affiliateId}-empty-row`} style={{ backgroundColor: '#dddddf' }}>
                                        <TableCell colSpan={5} style={cellStyle}>&nbsp;</TableCell>
                                    </TableRow>
                                );

                                return affiliateRows; // Return the rows for this affiliate group
                            })}

                            {Object.keys(groupedUsers).length < 2 &&
                                Array.from({ length: 2 - Object.keys(groupedUsers).length }).map((_, index) => (
                                    <TableRow key={`empty-row-${index}`}>
                                        <TableCell align="center" style={cellStyle}>
                                            &nbsp;
                                        </TableCell>
                                        <TableCell align="center" style={cellStyle}>
                                            &nbsp;
                                        </TableCell>
                                        <TableCell align="center" style={cellStyle}>
                                            &nbsp;
                                        </TableCell>
                                        <TableCell align="center" style={cellStyle}>
                                            &nbsp;
                                        </TableCell>
                                        <TableCell align="center" style={cellStyle}>
                                            &nbsp;
                                        </TableCell>
                                    </TableRow>
                                ))}

                            <TableRow>
                                <TableCell align="center" style={{ ...cellStyle, fontWeight: "bold" }}>
                                    Total Users
                                </TableCell>
                                <TableCell align="center" style={cellStyle}>
                                    &nbsp;
                                </TableCell>
                                <TableCell align="center" style={cellStyle}>
                                    &nbsp;
                                </TableCell>
                                <TableCell align="center" style={cellStyle}>
                                    &nbsp;
                                </TableCell>
                                <TableCell align="center" style={{ ...cellStyle, fontWeight: "bold" }}>
                                    {totalUserCount}
                                </TableCell>
                                <TableCell align="center" style={cellStyle}>
                                    &nbsp;
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>
        </div>
    );
}

export default Sys_Usr_Aflt_Wise_Usr_List;
