import React, { useState, useEffect } from "react";
import services from "./apiServices";
import VisibilityIcon from "@mui/icons-material/Visibility";
import Box from "@mui/material/Box";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import CancelIcon from '@mui/icons-material/Cancel';
import CheckIcon from '@mui/icons-material/Check';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  Grid,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  TextField
} from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import moment from 'moment';

export default function Dashboard_Manager() {
  const formatDate1 = (isoDateString) => {
    // Check for null or empty strings
    if (!isoDateString || typeof isoDateString !== 'string' || isoDateString.trim() === "") {
      return "N/A"; // Return "N/A" for null or empty strings
    }

    // Create a Date object from the ISO string
    const date = new Date(isoDateString);

    // Check if the date is valid
    if (isNaN(date.getTime())) {
      console.warn(`Invalid date format: ${isoDateString}`);
      return "Invalid Date"; // Return "Invalid Date" for unexpected formats
    }

    // Define an array for month names (abbreviated)
    const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

    // Get day, month, and year
    const day = date.getUTCDate(); // Use UTC date
    const monthIndex = date.getUTCMonth(); // Use UTC month
    const year = date.getUTCFullYear(); // Use UTC year

    // Format the date as 'DD MMM YYYY'
    return `${day}/${monthNames[monthIndex]}/${year}`;
  };
  const [data, setData] = useState({ result: [] });
  const [data1, setData1] = useState({
    result: [],
    resul1: [],
    result2: [],
    result3: [],
  });
  const [entity, setEntity] = useState();
  const [selectedApp, setSelectedApp] = useState();
  const [selectedApp1, setSelectedApp1] = useState();
  const [approvalComment, setApprovalComment] = useState("");
  const [leaveStatuses, setLeaveStatuses] = useState({ result: [] });
  const [formVisible, setFormVisible] = useState(false);
  const [formVisible1, setFormVisible1] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState(null);
  const [currentWeekLeaves, setCurrentWeekLeaves] = useState([]);
  const storedUserData = sessionStorage.getItem("user_data");
  let usr_log = null;
  if (storedUserData) {
    usr_log = JSON.parse(storedUserData);
  }
  console.log(data);
  console.log(data1);
  console.log(selectedApp);
  console.log(leaveStatuses);
  const formatDate = (dateString) => {
    if (!dateString) return "N/A"; // Return a default value if dateString is undefined or null
    const options = { day: '2-digit', month: 'short', year: 'numeric' };
    return new Date(dateString).toLocaleDateString('en-GB', options);
  };
  const [leavecnt, Setleavecnt] = useState({
    login_lve_bal_cnt: [], lve_apl_dt: [], login_team_membr_inf_res: [],
    shw_upcom_hlod_res: [], empwise_lve_bal_res: [], all_usr_cnt_data: [], aflt_annocement_news_result: []
  })

  useEffect(() => {
    const lev_bal_cnt = async () => {
      try {
        const lve_cnt = await services.get_lev_bal_cnt();

        if (lve_cnt) {
          Setleavecnt(lve_cnt); // Wrap it in an object if necessary
        } else {
          console.error("Unexpected data format:", lve_cnt);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    lev_bal_cnt();

  }, [])
  // Get the last 10 leave applications
  const last10Applications = leavecnt.lve_apl_dt
    .sort((a, b) => new Date(b.APP_DT) - new Date(a.APP_DT)) // Sort by application date descending
    .slice(0, 10); // Get the first 10 entries
  const fetchApplicantData = async () => {
    try {
      const result = await services.getapplicantdata();
      console.log("Fetched applicant data:", result); // Log the result for debugging
      if (result) {
        setData({ result: result.result || [] });
      } else {
        console.log("No data fetched", result);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const fetchLeaveStatuses = async () => {
    try {
      const name = "LVE_STTS"; // Example name you want to send
      const res = await services.getLeaveStatuses();
      console.log("Fetched leave statuses:", res);
      setLeaveStatuses(res);
    } catch (error) {
      console.error("Error fetching leave statuses:", error);
    }
  };
  useEffect(() => {
    const fetch = async () => {
      try {
        const lve_tp = await services.getAffiliateLeaveTypes();

        if (lve_tp) {
          setData1(lve_tp); // Wrap it in an object if necessary
        } else {
          console.error("Unexpected data format:", lve_tp);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetch();
  }, []);

  useEffect(() => {
    fetchApplicantData();
    fetchLeaveStatuses(); // Fetch leave statuses on component mount
  }, []);

  const handleOpen = (app) => {
    setSelectedApp(app);
    setApprovalComment("");
  };

  const handleClose = () => {
    setSelectedApp(null);
  };

  const getNextLeaveStatus = (currentStatus, action) => {
    console.log("Current status:", currentStatus); // Log the current status
    console.log("Action:", action); // Log the action (Approve or Reject)

    // Access leaveStatuses.result to ensure we are working with the array
    const statuses = leaveStatuses.result || []; // Fallback to an empty array if result is not available

    if (!Array.isArray(statuses) || statuses.length === 0) {
      console.warn("leaveStatuses is not an array or is empty:", leaveStatuses);
      return null;
    }

    console.log(
      "Leave statuses:",
      statuses.map((status) => status.ATTRIB_NAME)
    ); // Log leave status names

    // Define logic based on current status and action
    if (currentStatus === "Pending") {
      if (action === "Approve") {
        return statuses.find((status) => status.ATTRIB_NAME === "Approved");
      } else if (action === "Reject") {
        return statuses.find((status) => status.ATTRIB_NAME === "Rejected");
      }
    } else if (currentStatus === "Cancel for Approval") {
      if (action === "Approve") {
        return statuses.find(
          (status) => status.ATTRIB_NAME === "Cancel Approved"
        );
      } else if (action === "Reject") {
        return statuses.find(
          (status) => status.ATTRIB_NAME === "Cancel Rejected"
        );
      }
    }

    // Log if no matching logic is found
    console.log("No valid status change available for this action");
    return null; // Return null if no valid status is found
  };

  const handleApprove = async () => {
    const currentDate = new Date();
    const formattedDate = currentDate
      .toISOString()
      .slice(0, 19)
      .replace("T", " ");

    const actualapprovalId = usr_log[0]?.USR_EMP_ID || "Unknown";
    const nextStatus = getNextLeaveStatus(selectedApp.APP_LVE_STTS, "Approve"); // Pass "Approve" as the action

    if (!nextStatus) {
      console.error("No next leave status available");
      alert("Allready Changed Leave Status");
      return;
    }

    const updatedApp = {
      ...selectedApp,
      APP_LVE_STTS: nextStatus.ATTRIB_NAME,
      LEAVE_STATUS_CHANGE_DATE: formattedDate,
      approvalComment: approvalComment,
      actualapprovalID: actualapprovalId,
      APP_LVE_STTS_DT: formattedDate,
      opn_lvl_bal_cnt: data1.result[0].LVE_TYP_OP_BAL_CNT,
    };

    try {
      await services.approveLeaveApplication(updatedApp);
      alert("Leave application approved successfully.");
      handleClose();
      fetchApplicantData();
    } catch (error) {
      console.error("Error approving application:", error);
      alert("Failed to approve leave application. Please try again.");
    }
  };

  const handleApproveDirectly = async (app) => {
    console.log("data comes from user selection", app)
    const selectedData = data.result.find((dt) => dt.APP_EMP_ID === app.APP_EMP_ID && dt.APP_ID === app.APP_ID);
    console.log("finded data", selectedData)
    console.log("selectedData.APP_LVE_STTS", selectedData.APP_LVE_STTS)

    if (selectedData) {
      setSelectedApp(selectedData);
      const currentDate = new Date();
      const formattedDate = currentDate
        .toISOString()
        .slice(0, 19)
        .replace("T", " ");

      const actualapprovalId = usr_log[0]?.USR_EMP_ID || "Unknown";
      const nextStatus = getNextLeaveStatus(selectedData.APP_LVE_STTS, "Approve"); // Pass "Approve" as the action

      if (!nextStatus) {
        console.error("No next leave status available");
        alert("Allready Changed Leave Status");
        handleClose();
        return;
      }

      const updatedApp = {
        ...selectedData,
        APP_LVE_STTS: nextStatus.ATTRIB_NAME,
        LEAVE_STATUS_CHANGE_DATE: formattedDate,
        approvalComment: "",
        actualapprovalID: actualapprovalId,
        APP_LVE_STTS_DT: formattedDate,
        opn_lvl_bal_cnt: data1.result[0].LVE_TYP_OP_BAL_CNT,
        lve_bal_id: data1.result[0].LVE_BAL_ID,
        lve_ntt_id: data1.result[0].LVE_BAL_NTT_ID,
      };

      try {
        await services.approveLeaveApplication(updatedApp);
        alert("Leave application approved successfully.");
        handleClose();
        fetchApplicantData();
      } catch (error) {
        console.error("Error approving application:", error);
        alert("Failed to approve leave application. Please try again.");
      }
    } else {
      console.error("Selected data not found");
    }
  };

  const handleReject = async () => {
    handleOpen(selectedApp);
    const currentDate = new Date();
    const formattedDate = currentDate
      .toISOString()
      .slice(0, 19)
      .replace("T", " ");

    const actualapprovalId = usr_log[0]?.USR_EMP_ID || "Unknown";
    const nextStatus = getNextLeaveStatus(selectedApp.APP_LVE_STTS, "Reject"); // Pass "Reject" as the action

    if (!nextStatus) {
      console.error("No next leave status available");
      alert("Allready Changed Leave Status");
      return;
    }

    const updatedApp = {
      ...selectedApp,
      APP_LVE_STTS: nextStatus.ATTRIB_NAME,
      LEAVE_STATUS_CHANGE_DATE: formattedDate,
      approvalComment: approvalComment,
      actualapprovalID: actualapprovalId,
      APP_LVE_STTS_DT: formattedDate,
      opn_lvl_bal_cnt: data1.result[0].LVE_TYP_OP_BAL_CNT,
    };

    try {
      await services.rejectLeaveApplication(updatedApp);
      alert("Leave application rejected successfully.");
      handleClose();
      fetchApplicantData();
    } catch (error) {
      console.error("Error rejecting application:", error);
      alert("Failed to reject leave application. Please try again.");
    }
  };

  const handleRejectdirectly = async (app) => {
    console.log("data comes from user selection", app)
    const selectedData = data.result.find((dt) => dt.APP_EMP_ID === app.APP_EMP_ID && dt.APP_ID === app.APP_ID);
    console.log("finded data", selectedData)
    console.log("selectedData.APP_LVE_STTS", selectedData.APP_LVE_STTS)
    if (selectedData) {
      setSelectedApp(selectedData);
      const currentDate = new Date();
      const formattedDate = currentDate
        .toISOString()
        .slice(0, 19)
        .replace("T", " ");

      const actualapprovalId = usr_log[0]?.USR_EMP_ID || "Unknown";
      const nextStatus = getNextLeaveStatus(selectedData.APP_LVE_STTS, "Reject"); // Pass "Reject" as the action

      if (!nextStatus) {
        console.error("No next leave status available");
        alert("Allready Changed Leave Status");
        handleClose();
        return;
      }

      const updatedApp = {
        ...selectedData,
        APP_LVE_STTS: nextStatus.ATTRIB_NAME,
        LEAVE_STATUS_CHANGE_DATE: formattedDate,
        approvalComment: "",
        actualapprovalID: actualapprovalId,
        APP_LVE_STTS_DT: formattedDate,
        opn_lvl_bal_cnt: data1.result[0].LVE_TYP_OP_BAL_CNT,
      };

      try {
        await services.rejectLeaveApplication(updatedApp);
        alert("Leave application rejected successfully.");
        handleClose();
        fetchApplicantData();
      } catch (error) {
        console.error("Error rejecting application:", error);
        alert("Failed to reject leave application. Please try again.");
        handleClose();
      }
    } else {
      console.error("Selected data not found");
      handleClose();
    }
  };

  const handleCancel = async () => {
    try {
      await services.cancelLeaveApplication(selectedApp.APP_ID);
      handleClose();
      fetchApplicantData(); // Refresh data after cancellation
    } catch (error) {
      console.error("Error canceling application:", error);
    }
  };
  const toggleFormVisibility = () => {
    setFormVisible((prev) => !prev); // Toggle form visibility
  };
  const toggleFormVisibility1 = (resetSelectedRecord = false) => {
    if (resetSelectedRecord) {
      setSelectedRecord(null);
    }
    setFormVisible1((prev) => !prev); // Toggle form visibility
  };

  const handleSubmit1 = (event) => {
    event.preventDefault();
    // Handle form submission logic
    toggleFormVisibility(); // Optionally hide the form after submission
  };

  const handleEdit = (id) => {
    // Logic for editing the record with the given id
    console.log("Edit record with ID:", id);
  };

  const handleDelete = (id) => {
    // Logic for deleting the record with the given id
    console.log("Delete record with ID:", id);
  };

  const handleView = (record) => {
    setSelectedRecord(record);
    toggleFormVisibility1(); // If this opens the view form/modal
  };

  useEffect(() => {
    const fetch = async () => {
      try {
        const res = await services.getselecempdetails(selectedApp);
        if (res) {
          setEntity(res);
        }
      } catch (error) {
        console.error("Failed to fetch the data");
      }
    };
    fetch();
  }, [selectedApp]);
  console.log(entity)

  useEffect(() => {
    // Calculate current week's start and end dates
    const startOfWeek = moment().startOf('week').startOf('day'); // Start of the week
    const endOfWeek = moment().endOf('week').endOf('day'); // End of the week

    // Filter team members' leave applications for the current week
    const currentWeekLeaves = leavecnt.login_team_membr_inf_res.filter(leave => {
      const leaveStartDate = moment(leave.APP_LVE_FRM_DT); // Start date of the leave
      const leaveEndDate = moment(leave.APP_LVE_TO_DT); // End date of the leave
      console.log("Start of Week:", startOfWeek.format());
      console.log("End of Week:", endOfWeek.format());
      console.log("Leave Start Date:", leaveStartDate.format());
      console.log("Leave End Date:", leaveEndDate.format());
      // Check if the leave overlaps with the current week
      return (
        (leaveStartDate.isBetween(startOfWeek, endOfWeek, null, '[]') || // Starts during the week
          leaveEndDate.isBetween(startOfWeek, endOfWeek, null, '[]') || // Ends during the week
          (leaveStartDate.isBefore(startOfWeek) && leaveEndDate.isAfter(endOfWeek))) // Spans the entire week
      );
    });

    setCurrentWeekLeaves(currentWeekLeaves);
  }, [leavecnt.login_team_membr_inf_res]); // Re-run when login_team_membr_inf_res changes
  console.log("currentWeekLeaves", currentWeekLeaves)
  console.log("levcnt", leavecnt)

  const maxLeaveConsumption = Math.max(...leavecnt.login_lve_bal_cnt.map(leave => leave.LVE_TYP_CNMSN_CNT));
  const maxConsumptionEmployees = leavecnt.login_lve_bal_cnt.filter(leave => leave.LVE_TYP_CNMSN_CNT === maxLeaveConsumption);


  const oboApplications = data.result.filter(application => application.APP_OBO_ID);
  console.log("oboApplications", oboApplications)


  //for Annocements    
  const [popupOpen, setPopupOpen] = useState(false);
  const [selectedAnnouncement, setSelectedAnnouncement] = useState('');
  const [isPaused, setIsPaused] = useState(false);


  const handleMouseEnter = () => {
    setIsPaused(true); // Pause the marquee
  };

  const handleMouseLeave = () => {
    setIsPaused(false); // Resume the marquee
  };

  const handleAnnouncementClick = (announcement) => {
    setSelectedAnnouncement(announcement);
    setPopupOpen(true);
  };

  const handleClosePopup = () => {
    setPopupOpen(false);
    setSelectedAnnouncement('');
  };
  console.log("selectedAnnouncement", selectedAnnouncement)

  const marqueeItems = leavecnt.aflt_annocement_news_result.map((app, index) => (
    <span key={app.LVE_NEWS_KEY} // Assuming LVE_NEWS_KEY is unique
      onClick={() => handleAnnouncementClick(app)} // Assuming app holds the full announcement text
      style={{ cursor: 'pointer', marginRight: '20px' }} // Add pointer cursor and margin
    >
      {app.LVE_NTC_HEAD_SUB}
      {index < leavecnt.aflt_annocement_news_result.length - 1 && <span className="pipe">|</span>} {/* Add pipe with CSS class */}
    </span>
  ));
  return (
    <>
      <div
        className={`marquee ${isPaused ? 'paused' : ''}`}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <div className="marquee-content">{marqueeItems}</div>
      </div>

      {/* Popup for Announcement */}
      <Dialog open={popupOpen} onClose={handleClosePopup} maxWidth="lg" fullWidth>
        <DialogTitle style={{ backgroundColor: "#4a6d8c", padding: "10px 24px", textAlign: "center", fontSize: "20px", fontWeight: "bold" }}>
          Announcement Details
          <IconButton
            edge="end"
            color="inherit"
            onClick={handleClosePopup}
            aria-label="close"
            style={{ position: 'absolute', right: 8, top: 8 }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent style={{ padding: "1px 1px", margin: "5px 5px 10px 5px", borderRadius: "15px", height: "300px" }}>
          <Typography style={{ padding: "5px 10px" }}>Announcement Heading :- <b>{selectedAnnouncement.LVE_NTC_HEAD_SUB}</b></Typography>
          <Typography style={{ padding: "5px 10px" }}>Actual Announcement :- <b>{selectedAnnouncement.LVE_ACTUL_NTC}</b></Typography>
          {/* Add more fields as needed */}
        </DialogContent>

      </Dialog>

      <div className="wrapper_form">
        <div className="title" style={{ marginTop: "1%", marginLeft: "1%", marginRight: "1%" }}>Pending Cancellation Application List</div>
        {selectedApp ? (
          <div style={{ padding: "20px", marginTop: "20px" }}>
            <Typography variant="h6">Application Details</Typography>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="Employee ID"
                  value={selectedApp?.APP_EMP_ID || "empty"}
                  InputProps={{ readOnly: true }}
                  variant="filled"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="OBO ID"
                  value={selectedApp?.APP_OBO_ID || "empty"}
                  InputProps={{ readOnly: true }}
                  variant="filled"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="Approval ID"
                  value={selectedApp?.APP_APRVL_ID || "empty"}
                  InputProps={{ readOnly: true }}
                  variant="filled"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="CC ID"
                  value={selectedApp?.APP_CC_TO_ID || "empty"}
                  InputProps={{ readOnly: true }}
                  variant="filled"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="Date Submitted"
                  value={
                    selectedApp?.APP_DT
                      ? new Date(selectedApp.APP_DT).toLocaleDateString()
                      : ""
                  }
                  InputProps={{ readOnly: true }}
                  variant="filled"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="Status"
                  value={selectedApp?.APP_LVE_STTS || "empty"}
                  InputProps={{ readOnly: true }}
                  variant="filled"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="Leave Type"
                  value={selectedApp?.APP_TYP || "empty"}
                  InputProps={{ readOnly: true }}
                  variant="filled"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="Leave Balance count"
                  value={selectedApp?.APP_LVE_BAL || "empty"}
                  InputProps={{ readOnly: true }}
                  variant="filled"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="Leave From"
                  value={selectedApp?.APP_LVE_FRM_DT || "empty"}
                  InputProps={{ readOnly: true }}
                  variant="filled"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="Leave To"
                  value={selectedApp?.APP_LVE_TO_DT || "empty"}
                  InputProps={{ readOnly: true }}
                  variant="filled"
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="Half Day ?"
                  value={selectedApp?.APP_HLF_DE || "N"}
                  InputProps={{ readOnly: true }}
                  variant="filled"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="1st or 2nd Half"
                  value={selectedApp?.APP_HLF_1_2 || "empty"}
                  InputProps={{ readOnly: true }}
                  variant="filled"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="Conjugative Half Day ?"
                  value={selectedApp?.APP_CNZTV_HLF_DE_YN || "N"}
                  InputProps={{ readOnly: true }}
                  variant="filled"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="Daily Half Day ?"
                  value={selectedApp?.APP_DLY_HLF_DE_YN || "N"}
                  InputProps={{ readOnly: true }}
                  variant="filled"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="Half Day From"
                  value={selectedApp?.APP_HLF_FRM_DT || "empty"}
                  InputProps={{ readOnly: true }}
                  variant="filled"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="Half Day TO"
                  value={selectedApp?.APP_HLF_TO_DT || "empty"}
                  InputProps={{ readOnly: true }}
                  variant="filled"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="Leave Reason"
                  value={selectedApp?.APP_LVE_RES || "empty"}
                  InputProps={{ readOnly: true }}
                  variant="filled"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="Address"
                  value={selectedApp?.APP_LVE_ADD || "empty"}
                  InputProps={{ readOnly: true }}
                  variant="filled"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="Phone"
                  value={selectedApp?.APP_PH_NO || "empty"}
                  InputProps={{ readOnly: true }}
                  variant="filled"
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="Approval Remarks"
                  value={approvalComment}
                  onChange={(e) => setApprovalComment(e.target.value)}
                  variant="filled"
                  multiline
                // rows={2}
                />
              </Grid>
            </Grid>
            <div
              style={{
                marginTop: "10px",
                display: "flex",
                // justifyContent: "flex-start",
              }}
            >
              <Button
                className="action-button"
                style={{ width: "10%", marginRight: "15px" }}
                onClick={handleApprove}
              >
                Approve
              </Button>
              <Button
                className="action-button"
                style={{ width: "10%", marginRight: "15px" }}
                onClick={handleReject}
              >
                Reject
              </Button>

              {/* <Button
              className="action-button"
              style={{ width: "10%", marginRight: "15px" }}
              onClick={handleCancel}
            >
              Cancel
            </Button> */}
              <Button
                className="action-button"
                style={{ width: "10%", marginRight: "15px" }}
                onClick={handleClose}
              >
                Cancel
              </Button>
            </div>
          </div>
        ) : (
          <div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >

              <TableContainer
                style={{
                  // margin: "10px auto",
                  maxWidth: "97%", // Keeps the table centered and limited to 90% width
                  // overflow: "visible", // Prevents scrollbars
                  margin: "5px auto 25px auto",
                  // maxWidth: "50%",
                  overflow: "hidden", // Prevents content overflow
                  boxShadow: "0px 6px 16px 3px #bababa", // Shadow only on the top and bottom
                  borderRadius: "20px", // Rounded corners for the container
                  border: "2px solid gray",
                }}
              >
                <Table style={{ width: "100%" }}>
                  <TableHead>
                    <TableRow style={{ lineHeight: "0.3" }}>
                      <TableCell
                        style={{
                          fontSize: "0.8rem",
                          padding: "1px 18px",
                          backgroundColor: "#4a6d8c",
                          border: "1px solid gray", color: "white", textAlign: "center"
                        }}
                        className="txt_ttl"
                      >
                        Name
                      </TableCell>
                      <TableCell
                        style={{
                          fontSize: "0.8rem",
                          padding: "1px 18px",
                          backgroundColor: "#4a6d8c",
                          border: "1px solid gray", color: "white", textAlign: "center"
                        }}
                        className="txt_ttl"
                      >
                        Employee ID
                      </TableCell>

                      <TableCell
                        style={{
                          fontSize: "0.8rem",
                          padding: "1px 18px",
                          backgroundColor: "#4a6d8c",
                          border: "1px solid gray", color: "white", textAlign: "center"
                        }}
                        className="txt_ttl"
                      >
                        OBO ID
                      </TableCell>
                      <TableCell
                        style={{
                          fontSize: "0.8rem",
                          padding: "1px 18px",
                          backgroundColor: "#4a6d8c",
                          border: "1px solid gray", color: "white", textAlign: "center"
                        }}
                        className="txt_ttl"
                      >
                        Date Submitted
                      </TableCell>
                      <TableCell
                        style={{
                          fontSize: "0.8rem",
                          padding: "1px 18px",
                          backgroundColor: "#4a6d8c",
                          border: "1px solid gray", color: "white", textAlign: "center"
                        }}
                        className="txt_ttl"
                      >
                        Leave Type
                      </TableCell>
                      <TableCell
                        style={{
                          fontSize: "0.8rem",
                          padding: "1px 18px",
                          backgroundColor: "#4a6d8c",
                          border: "1px solid gray", color: "white", textAlign: "center"
                        }}
                        className="txt_ttl"
                      >
                        Leave From
                      </TableCell>
                      <TableCell
                        style={{
                          fontSize: "0.8rem",
                          padding: "1px 18px",
                          backgroundColor: "#4a6d8c",
                          border: "1px solid gray", color: "white", textAlign: "center"
                        }}
                        className="txt_ttl"
                      >
                        Leave To
                      </TableCell>

                      <TableCell
                        style={{
                          fontSize: "0.8rem",
                          padding: "1px 18px",
                          backgroundColor: "#4a6d8c",
                          border: "1px solid gray", color: "white", textAlign: "center"
                        }}
                        className="txt_ttl"
                      >
                        Status
                      </TableCell>
                      <TableCell
                        style={{
                          fontSize: "0.8rem",
                          padding: "1px 18px",
                          backgroundColor: "#4a6d8c",
                          border: "1px solid gray", color: "white", textAlign: "center"
                        }}
                        className="txt_ttl"
                      >
                        Actions
                      </TableCell>
                    </TableRow>
                    <TableRow >
                      <TableCell colSpan={9} style={{ padding: 0 }}>
                        {/* <hr style={{ border: "1px solid gray", margin: 0 }} /> */}
                      </TableCell>
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    {data.result
                      .filter(
                        (app) =>
                          app.APP_LVE_STTS === "Pending" ||
                          app.APP_LVE_STTS === "Cancel for Approval"
                      )
                      .map((app, index) => (
                        <React.Fragment key={index}>
                          <TableRow style={{ border: "1px solid gray", height: "10px" }}>
                            <TableCell style={{ fontSize: "0.8rem", padding: "1px 18px", border: "1px solid gray", height: "12px", textAlign: "center" }}>
                              {app.USR_DISPLAY_NAME}
                            </TableCell>
                            <TableCell style={{ fontSize: "0.8rem", padding: "1px 18px", border: "1px solid gray", height: "12px", textAlign: "center" }}>
                              {app.APP_EMP_ID}
                            </TableCell>
                            <TableCell style={{ fontSize: "0.8rem", padding: "1px 18px", border: "1px solid gray", height: "12px", textAlign: "center" }}>
                              {app.APP_OBO_ID}
                            </TableCell>
                            <TableCell style={{ fontSize: "0.8rem", padding: "1px 18px", border: "1px solid gray", height: "12px", textAlign: "center" }}>
                              {app.APP_DT ? formatDate1(app.APP_DT) : "empty"} {/* Use formatDate1 */}
                            </TableCell>
                            <TableCell style={{ fontSize: "0.8rem", padding: "1px 18px", border: "1px solid gray", height: "12px", textAlign: "center" }}>
                              {app.disp_nm}
                            </TableCell>
                            <TableCell style={{ fontSize: "0.8rem", padding: "1px 18px", border: "1px solid gray", height: "12px", textAlign: "center" }}>
                              {app.APP_LVE_FRM_DT ? formatDate1(app.APP_LVE_FRM_DT) : "empty"} {/* Use formatDate1 */}
                            </TableCell>
                            <TableCell style={{ fontSize: "0.8rem", padding: "1px 18px", border: "1px solid gray", height: "12px", textAlign: "center" }}>
                              {app.APP_LVE_TO_DT ? formatDate1(app.APP_LVE_TO_DT) : "empty"} {/* Use formatDate1 */}
                            </TableCell>
                            <TableCell style={{ fontSize: "0.8rem", padding: "1px 18px", border: "1px solid gray", height: "12px", textAlign: "center" }}>
                              {app.APP_LVE_STTS}
                            </TableCell>
                            <TableCell
                              style={{
                                padding: "4px",
                                display: "flex",
                                justifyContent: "center",
                                height: "12px"
                              }}
                            >
                              <Button
                                style={{ minWidth: "auto" }}
                                onClick={() => handleApproveDirectly(app)}
                                size="small"
                              >
                                <CheckIcon style={{ color: "#4a6d8c" }} fontSize="small" />
                              </Button>
                              <Button
                                style={{ minWidth: "auto" }}
                                onClick={() => handleRejectdirectly(app)}
                                size="small"
                              >
                                <CancelIcon style={{ color: "#4a6d8c" }} fontSize="small" />
                              </Button>

                              <Button
                                style={{ minWidth: "auto" }}
                                onClick={() => handleOpen(app)}
                                size="small"
                              >
                                <VisibilityIcon style={{ color: "#4a6d8c" }} fontSize="small" />
                              </Button>
                            </TableCell>
                          </TableRow>
                        </React.Fragment>
                      ))}
                    {data.result.length < 5 &&
                      Array.from({ length: 5 - data.result.length }).map((_, index) => (
                        <TableRow style={{ height: "5px" }} key={`empty-row-${index}`}>
                          <TableCell align="center" style={{ fontSize: "0.8rem", padding: "1px 18px", border: "1px solid gray", lineHeight: ".2" }}>
                            &nbsp; {/* Empty cell for spacing */}
                          </TableCell>
                          <TableCell align="center" style={{ fontSize: "0.8rem", padding: "1px 18px", border: "1px solid gray" }}>
                            &nbsp; {/* Empty cell for spacing */}
                          </TableCell>
                          <TableCell align="center" style={{ fontSize: "0.8rem", padding: "1px 18px", border: "1px solid gray" }}>
                            &nbsp; {/* Empty cell for spacing */}
                          </TableCell>
                          <TableCell align="center" style={{ fontSize: "0.8rem", padding: "1px", border: "1px solid gray" }}>
                            &nbsp; {/* Empty cell for spacing */}
                          </TableCell>
                          <TableCell align="center" style={{ fontSize: "0.8rem", padding: "1px", border: "1px solid gray" }}>
                            &nbsp; {/* Empty cell for spacing */}
                          </TableCell>
                          <TableCell align="center" style={{ fontSize: "0.8rem", padding: "1px", border: "1px solid gray" }}>
                            &nbsp; {/* Empty cell for spacing */}
                          </TableCell>
                          <TableCell align="center" style={{ fontSize: "0.8rem", padding: "1px", border: "1px solid gray" }}>
                            &nbsp; {/* Empty cell for spacing */}
                          </TableCell>
                          <TableCell align="center" style={{ fontSize: "0.8rem", padding: "1px", border: "1px solid gray" }}>
                            &nbsp; {/* Empty cell for spacing */}
                          </TableCell>
                          <TableCell align="center" style={{ fontSize: "0.8rem", padding: "1px", border: "1px solid gray" }}>
                            &nbsp; {/* Empty cell for spacing */}
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>


                </Table>
              </TableContainer>



              <hr
                style={{
                  border: "1px solid #4a6d8c",
                  width: "97%", // Set HR width to 90%
                  marginBottom: ".1%", // Remove margin
                }}

              />{" "}
            </div>
            <div>
              <Grid container spacing={2} justifyContent="center">
                <Grid item xs={6} style={{ paddingLeft: "2%", paddingRight: "1%" }}>
                  <div className="title" style={{ marginTop: "1%" }}>Upcoming Holidays</div>
                  <TableContainer
                    component={Paper}
                    style={{
                      margin: "5px auto 25px auto",
                      // maxWidth: "50%",
                      overflow: "hidden", // Prevents content overflow
                      boxShadow: "0px 6px 16px 3px #bababa", // Shadow only on the top and bottom
                      borderRadius: "20px", // Rounded corners for the container
                      border: "2px solid gray",

                    }}
                  >
                    <Table style={{ width: "100%", }}>
                      <TableHead>
                        <TableRow style={{ width: "100%", }}>
                          <TableCell
                            style={{
                              fontSize: "0.8rem",
                              padding: "1px 18px",
                              backgroundColor: "#4a6d8c",
                              border: "1px solid gray", color: "white", textAlign: "center"
                            }}
                            className=" txt_ttl1"
                          >
                            Sr. No.
                          </TableCell>
                          <TableCell
                            style={{
                              fontSize: "0.8rem",
                              padding: "1px 18px",
                              backgroundColor: "#4a6d8c",
                              border: "1px solid gray", color: "white", textAlign: "center"
                            }}
                            className=" txt_ttl1"
                          >
                            Date
                          </TableCell>
                          <TableCell
                            style={{
                              fontSize: "0.8rem",
                              padding: "1px 18px",
                              backgroundColor: "#4a6d8c",
                              border: "1px solid gray", color: "white", textAlign: "center"
                            }}
                            className=" txt_ttl1"
                          >
                            Day
                          </TableCell>
                          <TableCell
                            style={{
                              fontSize: "0.8rem",
                              padding: "1px",
                              backgroundColor: "#4a6d8c",
                              border: "1px solid gray", color: "white", textAlign: "center"
                            }}
                            className=" txt_ttl1"
                          >
                            Particulars / Occasion
                          </TableCell>

                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {leavecnt.shw_upcom_hlod_res
                          .sort((a, b) => new Date(a.HOLIDAY_DATE) - new Date(b.HOLIDAY_DATE)) // Sort by HOLIDAY_DATE
                          .map((leave, index) => (
                            <TableRow style={{ height: "10px" }} key={leave.leaveTypeId}>
                              <TableCell align="center" style={{ fontSize: "0.8rem", padding: "1px 18px", border: "1px solid gray" }}>
                                {index + 1} {/* Serial number */}
                              </TableCell>
                              <TableCell align="center" style={{ fontSize: "0.8rem", padding: "1px 18px", border: "1px solid gray" }}>
                                {formatDate(leave.HOLIDAY_DATE)} {/* Assuming HOLIDAY_DATE is in a valid format */}
                              </TableCell>
                              <TableCell align="center" style={{ fontSize: "0.8rem", padding: "1px 18px", border: "1px solid gray" }}>
                                {moment(leave.HOLIDAY_DATE).format('dddd')} {/* Day of the week */}
                              </TableCell>
                              <TableCell align="center" style={{ fontSize: "0.8rem", padding: "1px", border: "1px solid gray" }}>
                                {leave.HOLIDAY_TYP}
                              </TableCell>
                            </TableRow>
                          ))}
                        {/* Add empty rows if there are less than 10 rows */}
                        {leavecnt.shw_upcom_hlod_res.length < 10 &&
                          Array.from({ length: 10 - leavecnt.shw_upcom_hlod_res.length }).map((_, index) => (
                            <TableRow style={{ height: "10px" }} key={`empty-row-${index}`}>
                              <TableCell align="center" style={{ fontSize: "0.8rem", padding: "1px 18px", border: "1px solid gray" }}>
                                &nbsp; {/* Empty cell for spacing */}
                              </TableCell>
                              <TableCell align="center" style={{ fontSize: "0.8rem", padding: "1px 18px", border: "1px solid gray" }}>
                                &nbsp; {/* Empty cell for spacing */}
                              </TableCell>
                              <TableCell align="center" style={{ fontSize: "0.8rem", padding: "1px 18px", border: "1px solid gray" }}>
                                &nbsp; {/* Empty cell for spacing */}
                              </TableCell>
                              <TableCell align="center" style={{ fontSize: "0.8rem", padding: "1px", border: "1px solid gray" }}>
                                &nbsp; {/* Empty cell for spacing */}
                              </TableCell>
                            </TableRow>
                          ))}
                      </TableBody>



                    </Table>
                  </TableContainer>
                </Grid>
                <Grid item xs={6} style={{ paddingLeft: "1%", paddingRight: "1%" }}>
                  <div className="title" style={{ marginTop: "1%" }}>Current Week Team Member On Leave</div>
                  <TableContainer
                    component={Paper}
                    style={{
                      margin: "5px auto 25px auto",
                      // maxWidth: "50%",
                      overflow: "hidden", // Prevents content overflow
                      boxShadow: "0px 6px 16px 3px #bababa", // Shadow only on the top and bottom
                      borderRadius: "20px", // Rounded corners for the container
                      border: "2px solid gray",

                    }}
                  >
                    <Table style={{ width: "100%", }}>
                      <TableHead>
                        <TableRow style={{ width: "100%", }}>
                          <TableCell
                            style={{
                              fontSize: "0.8rem",
                              padding: "1px 18px",
                              backgroundColor: "#4a6d8c",
                              border: "1px solid gray", color: "white", textAlign: "center"
                            }}
                            className=" txt_ttl1"
                          >
                            Name
                          </TableCell>
                          <TableCell
                            style={{
                              fontSize: "0.8rem",
                              padding: "1px 18px",
                              backgroundColor: "#4a6d8c",
                              border: "1px solid gray", color: "white", textAlign: "center"
                            }}
                            className=" txt_ttl1"
                          >
                            EMP ID
                          </TableCell>
                          <TableCell
                            style={{
                              fontSize: "0.8rem",
                              padding: "1px 18px",
                              backgroundColor: "#4a6d8c",
                              border: "1px solid gray", color: "white", textAlign: "center"
                            }}
                            className=" txt_ttl1"
                          >
                            From
                          </TableCell>
                          <TableCell
                            style={{
                              fontSize: "0.8rem",
                              padding: "1px",
                              backgroundColor: "#4a6d8c",
                              border: "1px solid gray", color: "white", textAlign: "center"
                            }}
                            className=" txt_ttl1"
                          >
                            To
                          </TableCell>
                          <TableCell
                            style={{
                              fontSize: "0.8rem",
                              padding: "1px",
                              backgroundColor: "#4a6d8c",
                              border: "1px solid gray", color: "white", textAlign: "center"
                            }}
                            className=" txt_ttl1"
                          >
                            Duration
                          </TableCell>
                          <TableCell
                            style={{
                              fontSize: "0.8rem",
                              padding: "1px",
                              backgroundColor: "#4a6d8c",
                              border: "1px solid gray", color: "white", textAlign: "center"
                            }}
                            className=" txt_ttl1"
                          >
                            Status
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {currentWeekLeaves.map((leave) => (
                          <TableRow style={{ height: "15px" }} key={leave.leaveTypeId}>
                            <TableCell align="center" style={{ fontSize: "0.8rem", padding: "1px 18px", border: "1px solid gray" }}>
                              {leave.USR_DISPLAY_NAME}
                            </TableCell>
                            <TableCell align="center" style={{ fontSize: "0.8rem", padding: "1px 18px", border: "1px solid gray" }}>
                              {leave.APP_EMP_ID}
                            </TableCell>
                            <TableCell align="center" style={{ fontSize: "0.8rem", padding: "1px 18px", border: "1px solid gray" }}>
                              {formatDate1(leave.APP_LVE_FRM_DT)}
                            </TableCell>
                            <TableCell align="center" style={{ fontSize: "0.8rem", padding: "1px", border: "1px solid gray" }}>
                              {formatDate1(leave.APP_LVE_TO_DT)}
                            </TableCell>
                            <TableCell align="center" style={{ fontSize: "0.8rem", padding: "1px", border: "1px solid gray" }}>
                              {leave.APP_LVE_DES} days
                            </TableCell>
                            <TableCell align="center" style={{ fontSize: "0.8rem", padding: "1px", border: "1px solid gray" }}>
                              {leave.APP_LVE_STTS}
                            </TableCell>
                          </TableRow>
                        ))}
                        {currentWeekLeaves.length < 10 &&
                          Array.from({ length: 10 - currentWeekLeaves.length }).map((_, index) => (
                            <TableRow style={{ height: "5px" }} key={`empty-row-${index}`}>
                              <TableCell align="center" style={{ fontSize: "0.8rem", padding: "1px 18px", border: "1px solid gray", lineHeight: ".2" }}>
                                &nbsp; {/* Empty cell for spacing */}
                              </TableCell>
                              <TableCell align="center" style={{ fontSize: "0.8rem", padding: "1px 18px", border: "1px solid gray" }}>
                                &nbsp; {/* Empty cell for spacing */}
                              </TableCell>
                              <TableCell align="center" style={{ fontSize: "0.8rem", padding: "1px 18px", border: "1px solid gray" }}>
                                &nbsp; {/* Empty cell for spacing */}
                              </TableCell>
                              <TableCell align="center" style={{ fontSize: "0.8rem", padding: "1px", border: "1px solid gray" }}>
                                &nbsp; {/* Empty cell for spacing */}
                              </TableCell>
                              <TableCell align="center" style={{ fontSize: "0.8rem", padding: "1px", border: "1px solid gray" }}>
                                &nbsp; {/* Empty cell for spacing */}
                              </TableCell>
                              <TableCell align="center" style={{ fontSize: "0.8rem", padding: "1px", border: "1px solid gray" }}>
                                &nbsp; {/* Empty cell for spacing */}
                              </TableCell>
                            </TableRow>
                          ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Grid>
              </Grid>
              <hr
                style={{
                  border: "1px solid #4a6d8c",
                  width: "97%", // Set HR width to 90%
                  marginBottom: "1%", // Remove margin
                }}
              />{" "}
              <Grid container spacing={2} justifyContent="center">

                {/* Left Table */}
                <Grid item xs={6} style={{ paddingLeft: "2%" }}>
                  <div className="title" style={{ marginTop: "2%" }}>Leave Balance Details</div>
                  <TableContainer
                    component={Paper}
                    style={{
                      margin: "5px auto 25px auto",
                      // maxWidth: "50%",
                      overflow: "hidden", // Prevents content overflow
                      boxShadow: "0px 6px 16px 3px #bababa", // Shadow only on the top and bottom
                      borderRadius: "20px", // Rounded corners for the container
                      border: "2px solid gray",

                    }}
                  >
                    <Table style={{ width: "100%", }}>
                      <TableHead>
                        <TableRow style={{ width: "100%", }}>
                          <TableCell
                            style={{
                              fontSize: "0.8rem",
                              padding: "1px 18px",
                              backgroundColor: "#4a6d8c",
                              border: "1px solid gray", color: "white", textAlign: "center"
                            }}
                            className=" txt_ttl1"
                          >
                            Leave Type
                          </TableCell>
                          <TableCell
                            style={{
                              fontSize: "0.8rem",
                              padding: "1px 18px",
                              backgroundColor: "#4a6d8c",
                              border: "1px solid gray", color: "white", textAlign: "center"
                            }}
                            className=" txt_ttl1"
                          >
                            Op. Bal.
                          </TableCell>
                          <TableCell
                            style={{
                              fontSize: "0.8rem",
                              padding: "1px 18px",
                              backgroundColor: "#4a6d8c",
                              border: "1px solid gray", color: "white", textAlign: "center"
                            }}
                            className=" txt_ttl1"
                          >
                            Added in Year
                          </TableCell>
                          <TableCell
                            style={{
                              fontSize: "0.8rem",
                              padding: "1px",
                              backgroundColor: "#4a6d8c",
                              border: "1px solid gray", color: "white", textAlign: "center"
                            }}
                            className=" txt_ttl1"
                          >
                            Consume
                          </TableCell>
                          <TableCell
                            style={{
                              fontSize: "0.8rem",
                              padding: "1px",
                              backgroundColor: "#4a6d8c",
                              border: "1px solid gray", color: "white", textAlign: "center"
                            }}
                            className=" txt_ttl1"
                          >
                            Balance
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {leavecnt.login_lve_bal_cnt.slice(0, 10).map((leave) => (
                          <TableRow style={{ height: "10px" }} key={leave.leaveTypeId}>
                            <TableCell align="center" style={{ fontSize: "0.8rem", padding: "1px 18px", border: "1px solid gray" }}>
                              {leave.disp_nm}
                            </TableCell>
                            <TableCell align="center" style={{ fontSize: "0.8rem", padding: "1px 18px", border: "1px solid gray" }}>
                              {leave.LVE_TYP_OP_BAL_CNT}
                            </TableCell>
                            <TableCell align="center" style={{ fontSize: "0.8rem", padding: "1px 18px", border: "1px solid gray" }}>
                              {/* If you want to display something, do it here */}
                            </TableCell>
                            <TableCell align="center" style={{ fontSize: "0.8rem", padding: "1px", border: "1px solid gray" }}>
                              {leave.LVE_TYP_CNMSN_CNT}
                            </TableCell>
                            <TableCell align="center" style={{ fontSize: "0.8rem", padding: "1px", border: "1px solid gray" }}>
                              {leave.LVE_TYP_RN_BAL_CNT}
                            </TableCell>
                          </TableRow>
                        ))}
                        {/* Add empty rows if there are less than 10 rows */}
                        {leavecnt.login_lve_bal_cnt.length < 10 &&
                          Array.from({ length: 10 - leavecnt.login_lve_bal_cnt.length }).map((_, index) => (
                            <TableRow style={{ height: "10px" }} key={`empty-row-${index}`}>
                              <TableCell align="center" style={{ fontSize: "0.8rem", padding: "1px 18px", border: "1px solid gray", lineHeight: ".2" }}>
                                &nbsp; {/* Empty cell for spacing */}
                              </TableCell>
                              <TableCell align="center" style={{ fontSize: "0.8rem", padding: "1px 18px", border: "1px solid gray" }}>
                                &nbsp; {/* Empty cell for spacing */}
                              </TableCell>
                              <TableCell align="center" style={{ fontSize: "0.8rem", padding: "1px 18px", border: "1px solid gray" }}>
                                &nbsp; {/* Empty cell for spacing */}
                              </TableCell>
                              <TableCell align="center" style={{ fontSize: "0.8rem", padding: "1px", border: "1px solid gray" }}>
                                &nbsp; {/* Empty cell for spacing */}
                              </TableCell>
                              <TableCell align="center" style={{ fontSize: "0.8rem", padding: "1px", border: "1px solid gray" }}>
                                &nbsp; {/* Empty cell for spacing */}
                              </TableCell>
                            </TableRow>
                          ))}
                      </TableBody>



                    </Table>
                  </TableContainer>
                </Grid>

                <Grid item xs={6} style={{ paddingRight: "1%" }}>
                  <div className="title" style={{ marginTop: "2%" }}>Last 10 Leave Application Status </div>
                  <TableContainer
                    component={Paper}
                    style={{
                      margin: "5px auto 25px auto",
                      // maxWidth: "50%",
                      overflow: "hidden", // Prevents content overflow
                      // boxShadow: "-10px 12px 16px 3px #bababa", // Gray shadow
                      boxShadow: "0px 6px 16px 3px #bababa", // Shadow only on the top and bottom
                      borderRadius: "20px", // Rounded corners for the container
                      border: "2px solid gray",

                    }}
                  >
                    <Table style={{ width: "100%", }}>
                      <TableHead>
                        <TableRow style={{ width: "100%", }}>
                          <TableCell
                            style={{
                              fontSize: "0.8rem",
                              padding: "1px 18px",
                              backgroundColor: "#4a6d8c",
                              border: "1px solid gray", color: "white", textAlign: "center"
                            }}
                            className=" txt_ttl1"
                          >
                            Date
                          </TableCell>
                          <TableCell
                            style={{
                              fontSize: "0.8rem",
                              padding: "1px 18px",
                              backgroundColor: "#4a6d8c",
                              border: "1px solid gray", color: "white", textAlign: "center"
                            }}
                            className=" txt_ttl1"
                          >
                            From
                          </TableCell>
                          <TableCell
                            style={{
                              fontSize: "0.8rem",
                              padding: "1px",
                              backgroundColor: "#4a6d8c",
                              border: "1px solid gray", color: "white", textAlign: "center"
                            }}
                            className=" txt_ttl1"
                          >
                            To
                          </TableCell>
                          <TableCell
                            style={{
                              fontSize: "0.8rem",
                              padding: "1px",
                              backgroundColor: "#4a6d8c",
                              border: "1px solid gray", color: "white", textAlign: "center"
                            }}
                            className=" txt_ttl1"
                          >
                            Duration
                          </TableCell>
                          <TableCell
                            style={{
                              fontSize: "0.8rem",
                              padding: "1px",
                              backgroundColor: "#4a6d8c",
                              border: "1px solid gray", color: "white", textAlign: "center"
                            }}
                            className=" txt_ttl1"
                          >
                            Type
                          </TableCell>
                          <TableCell
                            style={{
                              fontSize: "0.8rem",
                              padding: "1px",
                              backgroundColor: "#4a6d8c",
                              border: "1px solid gray", color: "white", textAlign: "center"
                            }}
                            className=" txt_ttl1"
                          >
                            Status
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {last10Applications.slice(0, 10).map((aplicantdata) => (
                          <TableRow style={{ height: "10px" }} key={aplicantdata.leaveTypeId}>
                            <TableCell
                              align="center" // Center the content horizontally
                              style={{
                                fontSize: "0.8rem",
                                padding: "1px 18px",
                                border: "1px solid gray",
                              }}
                            >
                              {formatDate(aplicantdata.APP_DT)}
                            </TableCell>
                            <TableCell
                              align="center" // Center the content horizontally
                              style={{
                                fontSize: "0.8rem",
                                padding: "1px 18px",
                                border: "1px solid gray",
                              }}
                            >
                              {formatDate(aplicantdata.APP_LVE_TO_DT)} {/* Format leave to date */}
                            </TableCell>
                            <TableCell
                              align="center" // Center the content horizontally
                              style={{
                                fontSize: "0.8rem",
                                padding: "1px",
                                border: "1px solid gray",
                              }}
                            >
                              {formatDate(aplicantdata.APP_LVE_TO_DT)}
                            </TableCell>
                            <TableCell
                              align="center" // Center the content horizontally
                              style={{
                                fontSize: "0.8rem",
                                padding: "1px",
                                border: "1px solid gray",
                              }}
                            >
                              {aplicantdata.APP_LVE_DES} days
                            </TableCell>
                            <TableCell
                              align="center" // Center the content horizontally
                              style={{
                                fontSize: "0.8rem",
                                padding: "1px",
                                border: "1px solid gray",
                              }}
                            >
                              {aplicantdata.disp_nm}
                            </TableCell>
                            <TableCell
                              align="center" // Center the content horizontally
                              style={{
                                fontSize: "0.8rem",
                                padding: "1px",
                                border: "1px solid gray",
                              }}
                            >
                              {aplicantdata.APP_LVE_STTS}
                            </TableCell>
                          </TableRow>
                        ))}
                        {last10Applications.length < 10 &&
                          Array.from({ length: 10 - last10Applications }).map((_, index) => (
                            <TableRow style={{ height: "10px" }} key={`empty-row-${index}`}>
                              <TableCell align="center" style={{ fontSize: "0.8rem", padding: "1px 18px", border: "1px solid gray", lineHeight: ".2" }}>
                                &nbsp; {/* Empty cell for spacing */}
                              </TableCell>
                              <TableCell align="center" style={{ fontSize: "0.8rem", padding: "1px 18px", border: "1px solid gray" }}>
                                &nbsp; {/* Empty cell for spacing */}
                              </TableCell>
                              <TableCell align="center" style={{ fontSize: "0.8rem", padding: "1px 18px", border: "1px solid gray" }}>
                                &nbsp; {/* Empty cell for spacing */}
                              </TableCell>
                              <TableCell align="center" style={{ fontSize: "0.8rem", padding: "1px", border: "1px solid gray" }}>
                                &nbsp; {/* Empty cell for spacing */}
                              </TableCell>
                              <TableCell align="center" style={{ fontSize: "0.8rem", padding: "1px", border: "1px solid gray" }}>
                                &nbsp; {/* Empty cell for spacing */}
                              </TableCell>
                              <TableCell align="center" style={{ fontSize: "0.8rem", padding: "1px", border: "1px solid gray" }}>
                                &nbsp; {/* Empty cell for spacing */}
                              </TableCell>
                            </TableRow>
                          ))}
                      </TableBody>


                    </Table>
                  </TableContainer>
                </Grid>
              </Grid>
              <hr
                style={{
                  border: "1px solid #4a6d8c",
                  width: "97%", // Set HR width to 90%
                  marginBottom: "1%", // Remove margin
                }}
              />{" "}

              <Grid container spacing={2} justifyContent="center">
                <Grid item xs={6} style={{ paddingLeft: "2%" }}>
                  <div className="title" style={{ marginTop: "2%" }}>Employee-wise Balances</div>
                  <TableContainer
                    component={Paper}
                    style={{
                      margin: "5px auto 25px auto",
                      overflow: "hidden",
                      boxShadow: "0px 6px 16px 3px #bababa", // Shadow only on the top and bottom
                      borderRadius: "20px",
                      border: "2px solid gray",
                      maxHeight: "350px", // Set a fixed height for the table container
                      overflowY: "auto", // Enable vertical scrolling
                      scrollbarWidth: "thin",
                      scrollbarColor: "red"
                    }}
                  >
                    <Table style={{ width: "100%" }}>
                      <TableHead>
                        <TableRow style={{ width: "100%" }}>
                          <TableCell style={{ fontSize: "0.8rem", padding: "1px 18px", backgroundColor: "#4a6d8c", border: "1px solid gray", color: "white", textAlign: "center" }}>Sr. No.</TableCell>
                          <TableCell style={{ fontSize: "0.8rem", padding: "1px 18px", backgroundColor: "#4a6d8c", border: "1px solid gray", color: "white", textAlign: "center" }}>Employee ID</TableCell>
                          <TableCell style={{ fontSize: "0.8rem", padding: "1px 18px", backgroundColor: "#4a6d8c", border: "1px solid gray", color: "white", textAlign: "center" }}>Name</TableCell>
                          <TableCell style={{ fontSize: "0.8rem", padding: "1px 18px", backgroundColor: "#4a6d8c", border: "1px solid gray", color: "white", textAlign: "center" }}>Leave Type</TableCell>
                          <TableCell style={{ fontSize: "0.8rem", padding: "1px 18px", backgroundColor: "#4a6d8c", border: "1px solid gray", color: "white", textAlign: "center" }}>Yearly Consume</TableCell>
                          <TableCell style={{ fontSize: "0.8rem", padding: "1px 18px", backgroundColor: "#4a6d8c", border: "1px solid gray", color: "white", textAlign: "center" }}>Leave Balance</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {/* Your existing TableBody logic */}
                        {Object.values(
                          leavecnt.empwise_lve_bal_res.reduce((acc, current) => {
                            const employeeId = current.USR_EMP_ID;
                            const employeeName = current.USR_DISPLAY_NAME;
                            const leaveType = current.disp_nm;
                            const conjumbal = current.LVE_TYP_CNMSN_CNT;
                            const remainingBalance = current.LVE_TYP_RN_BAL_CNT;

                            if (!acc[employeeId]) {
                              acc[employeeId] = {
                                employeeId,
                                employeeName,
                                leaveTypes: [],
                              };
                            }

                            acc[employeeId].leaveTypes.push({
                              leaveType,
                              conjumbal,
                              remainingBalance,
                            });

                            return acc;
                          }, {})
                        ).map((employee, index) => (
                          <React.Fragment key={index}>
                            <TableRow style={{ height: "10px" }}>
                              <TableCell align="center" style={{ fontSize: "0.8rem", padding: "1px 18px", border: "1px solid gray" }}>{index + 1}</TableCell>
                              <TableCell align="center" style={{ fontSize: "0.8rem", padding: "1px 18px", border: "1px solid gray" }}>{employee.employeeId}</TableCell >
                              <TableCell align="center" style={{ fontSize: "0.8rem", padding: "1px 18px", border: "1px solid gray" }}>{employee.employeeName}</TableCell>
                              <TableCell align="center" style={{ fontSize: "0.8rem", padding: "1px 18px", border: "1px solid gray" }}>&nbsp;</TableCell>
                              <TableCell align="center" style={{ fontSize: "0.8rem", padding: "1px 18px", border: "1px solid gray" }}>&nbsp;</TableCell>
                            </TableRow>
                            {employee.leaveTypes.map((leave, index) => (
                              <TableRow style={{ height: "10px" }} key={index}>
                                <TableCell align="center" style={{ fontSize: "0.8rem", padding: "1px 18px", border: "1px solid gray" }}>&nbsp;</TableCell>
                                <TableCell align="center" style={{ fontSize: "0.8rem", padding: "1px 18px", border: "1px solid gray" }}>&nbsp;</TableCell>
                                <TableCell align="center" style={{ fontSize: "0.8rem", padding: "1px 18px", border: "1px solid gray" }}>&nbsp;</TableCell>
                                <TableCell align="center" style={{ fontSize: "0.8rem", padding: "1px 18px", border: "1px solid gray" }}>{leave.leaveType}</TableCell>
                                <TableCell align="center" style={{ fontSize: "0.8rem", padding: "1px 18px", border: "1px solid gray" }}>{leave.conjumbal}</TableCell>
                                <TableCell align="center" style={{ fontSize: "0.8rem", padding: "1px 18px", border: "1px solid gray" }}>{leave.remainingBalance}</TableCell>
                              </TableRow>
                            ))}
                          </React.Fragment>
                        ))}
                        {/* Add empty rows if there are less than 5 rows */}
                        {leavecnt.empwise_lve_bal_res.length < 5 &&
                          Array.from({ length: 5 - leavecnt.empwise_lve_bal_res.length }).map((_, index) => (
                            <TableRow style={{ height: "10px" }} key={`empty-row-${index}`}>
                              <TableCell align="center" style={{ fontSize: "0.8rem", padding: "1px 18px", border: "1px solid gray" }}>&nbsp;</TableCell>
                              <TableCell align="center" style={{ fontSize: "0.8rem", padding: "1px 18px", border: "1px solid gray" }}>&nbsp;</TableCell>
                              <TableCell align="center" style={{ fontSize: "0.8rem", padding: "1px 18px", border: "1px solid gray" }}>&nbsp;</TableCell>
                              <TableCell align="center" style={{ fontSize: "0.8rem", padding: "1px 18px", border: "1px solid gray" }}>&nbsp;</TableCell>
                              <TableCell align="center" style={{ fontSize: "0.8rem", padding: "1px 18px", border: "1px solid gray" }}>&nbsp;</TableCell>
                            </TableRow>
                          ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Grid>
                {/* New Table for Maximum Leave Consumption Employees */}
                <Grid item xs={6} style={{ paddingLeft: "1%", paddingRight: "1%" }}>
                  <div className="title" style={{ marginTop: "2%" }}>Max Leave Consumption Employees</div>
                  <TableContainer
                    component={Paper}
                    style={{
                      margin: "5px auto 25px auto",
                      overflow: "hidden",
                      boxShadow: "0px 6px 16px 3px #bababa", // Shadow only on the top and bottom
                      borderRadius: "20px",
                      border: "2px solid gray",
                      maxHeight: "350px", // Set a fixed height for the table container
                      overflowY: "auto", // Enable vertical scrolling
                      scrollbarWidth: "thin"
                    }}
                  >
                    <Table style={{ width: "100%" }}>
                      <TableHead>
                        <TableRow style={{ width: "100%" }}>
                          <TableCell style={{ fontSize: "0.8rem", padding: "1px 18px", backgroundColor: "#4a6d8c", border: "1px solid gray", color: "white", textAlign: "center" }}>Sr.No</TableCell>
                          <TableCell style={{ fontSize: "0.8rem", padding: "1px 18px", backgroundColor: "#4a6d8c", border: "1px solid gray", color: "white", textAlign: "center" }}>Leave Type</TableCell>
                          <TableCell style={{ fontSize: "0.8rem", padding: "1px 18px", backgroundColor: "#4a6d8c", border: "1px solid gray", color: "white", textAlign: "center" }}>Employee Name</TableCell>
                          <TableCell style={{ fontSize: "0.8rem", padding: "1px 18px", backgroundColor: "#4a6d8c", border: "1px solid gray", color: "white", textAlign: "center" }}>Consumed Balance</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {
                          (() => {
                            const leaveTypeGroups = leavecnt.empwise_lve_bal_res.reduce((acc, leave) => {
                              if (!acc[leave.disp_nm]) {
                                acc[leave.disp_nm] = {
                                  leaveType: leave.disp_nm,
                                  employees: [],
                                };
                              }
                              acc[leave.disp_nm].employees.push({
                                name: leave.USR_DISPLAY_NAME,
                                consumed: leave.LVE_TYP_CNMSN_CNT,
                              });
                              return acc;
                            }, {});

                            const result = Object.values(leaveTypeGroups).map(group => {
                              const topEmployees = group.employees
                                .sort((a, b) => b.consumed - a.consumed)
                                .slice(0, 5);

                              return {
                                leaveType: group.leaveType,
                                topEmployees: topEmployees,
                              };
                            });

                            let srNo = 1;
                            const rows = result.flatMap((item) => {
                              const employeeRows = item.topEmployees.map((emp, index) => (
                                <TableRow key={`${item.leaveType}-${emp.name}`}>
                                  {index === 0 && (
                                    <>
                                      <TableCell rowSpan={item.topEmployees.length} align="center" style={{ fontSize: "0.8rem", padding: "1px 18px", border: "1px solid gray" }}>{srNo++}</TableCell>
                                      <TableCell rowSpan={item.topEmployees.length} align="center" style={{ fontSize: "0.8rem", padding: "1px 18px", border: "1px solid gray" }}>{item.leaveType}</TableCell>
                                    </>
                                  )}
                                  <TableCell align="center" style={{ fontSize: "0.8rem", padding: "1px 18px", border: "1px solid gray" }}>{emp.name}</TableCell>
                                  <TableCell align="center" style={{ fontSize: "0.8rem", padding: "1px 18px", border: "1px solid gray" }}>{emp.consumed}</TableCell>
                                </TableRow>
                              ));

                              // Add an empty row after all employees for the current leaveType
                              employeeRows.push(
                                <TableRow key={`${item.leaveType}-empty`}>
                                  <TableCell colSpan={4} style={{ fontSize: "0.8rem", padding: "1px 18px", border: "1px solid gray" }}>&nbsp;</TableCell>
                                </TableRow>
                              );

                              return employeeRows;
                            });

                            // Determine how many empty rows are needed to reach 10 rows
                            const emptyRows = 10 - rows.length;
                            const emptyRowElements = Array.from({ length: Math.max(0, emptyRows) }).map((_, index) => (
                              <TableRow key={`empty-row-${index}`}>
                                <TableCell align="center" style={{ fontSize: "0.8rem", padding: "1px 18px", border: "1px solid gray" }}>&nbsp;</TableCell>
                                <TableCell align="center" style={{ fontSize: "0.8rem", padding: "1px 18px", border: "1px solid gray" }}>&nbsp;</TableCell>
                                <TableCell align="center" style={{ fontSize: "0.8rem", padding: "1px 18px", border: "1px solid gray" }}>&nbsp;</TableCell>
                                <TableCell align="center" style={{ fontSize: "0.8rem", padding: "1px 18px", border: "1px solid gray" }}>&nbsp;</TableCell>
                              </TableRow>
                            ));

                            return [...rows, ...emptyRowElements]; // Combine data rows with empty rows
                          })()
                        }
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Grid>

              </Grid>
              <hr
                style={{

                  border: "1px solid #4a6d8c",
                  width: "97%", // Set HR width to 90%
                  marginTop: "1%", // Remove margin
                }}
              />{" "}
              <Grid item xs={12} style={{ paddingLeft: "1%", paddingRight: "1%" }}>
                <div className="title" style={{ marginTop: "2%" }}>OBO Applications</div>
                <TableContainer
                  component={Paper}
                  style={{
                    margin: "5px auto 25px auto",
                    overflow: "hidden",
                    boxShadow: "0px 6px 16px 3px #bababa",
                    borderRadius: "20px",
                    border: "2px solid gray",
                  }}
                >
                  <Table style={{ width: "100%" }}>
                    <TableHead>
                      <TableRow>
                        <TableCell style={{ fontSize: "0.8rem", padding: "1px 18px", backgroundColor: "#4a6d8c", border: "1px solid gray", color: "white", textAlign: "center" }}>Sr.No.</TableCell>
                        <TableCell style={{ fontSize: "0.8rem", padding: "1px 18px", backgroundColor: "#4a6d8c", border: "1px solid gray", color: "white", textAlign: "center" }}>Emp ID</TableCell>
                        <TableCell style={{ fontSize: "0.8rem", padding: "1px 18px", backgroundColor: "#4a6d8c", border: "1px solid gray", color: "white", textAlign: "center" }}>Name</TableCell>
                        <TableCell style={{ fontSize: "0.8rem", padding: "1px 18px", backgroundColor: "#4a6d8c", border: "1px solid gray", color: "white", textAlign: "center" }}>OBO ID</TableCell>
                        <TableCell style={{ fontSize: "0.8rem", padding: "1px 18px", backgroundColor: "#4a6d8c", border: "1px solid gray", color: "white", textAlign: "center" }}>Date</TableCell>
                        <TableCell style={{ fontSize: "0.8rem", padding: "1px 18px", backgroundColor: "#4a6d8c", border: "1px solid gray", color: "white", textAlign: "center" }}>From</TableCell>
                        <TableCell style={{ fontSize: "0.8rem", padding: "1px 18px", backgroundColor: "#4a6d8c", border: "1px solid gray", color: "white", textAlign: "center" }}>To</TableCell>
                        <TableCell style={{ fontSize: "0.8rem", padding: "1px 18px", backgroundColor: "#4a6d8c", border: "1px solid gray", color: "white", textAlign: "center" }}>Duration</TableCell>
                        <TableCell style={{ fontSize: "0.8rem", padding: "1px 18px", backgroundColor: "#4a6d8c", border: "1px solid gray", color: "white", textAlign: "center" }}>Type</TableCell>
                        <TableCell style={{ fontSize: "0.8rem", padding: "1px 18px", backgroundColor: "#4a6d8c", border: "1px solid gray", color: "white", textAlign: "center" }}>Status</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {oboApplications.map((application, index) => (
                        <TableRow key={application.APP_OBO_ID}>
                          <TableCell align="center" style={{ fontSize: "0.8rem", padding: "1px 18px", border: "1px solid gray" }}>
                            {index + 1}
                          </TableCell>
                          <TableCell align="center" style={{ fontSize: "0.8rem", padding: "1px 18px", border: "1px solid gray" }}>
                            {application.APP_EMP_ID}
                          </TableCell>
                          <TableCell align="center" style={{ fontSize: "0.8rem", padding: "1px 18px", border: "1px solid gray" }}>
                            {application.USR_DISPLAY_NAME}
                          </TableCell>
                          <TableCell align="center" style={{ fontSize: "0.8rem", padding: "1px 18px", border: "1px solid gray" }}>
                            {application.APP_OBO_ID}
                          </TableCell>
                          <TableCell align="center" style={{ fontSize: "0.8rem", padding: "1px 18px", border: "1px solid gray" }}>
                            {formatDate(application.APP_DT)}
                          </TableCell>
                          <TableCell align="center" style={{ fontSize: "0.8rem", padding: "1px 18px", border: "1px solid gray" }}>
                            {formatDate(application.APP_LVE_FRM_DT)}
                          </TableCell>
                          <TableCell align="center" style={{ fontSize: "0.8rem", padding: "1px 18px", border: "1px solid gray" }}>
                            {formatDate(application.APP_LVE_TO_DT)}
                          </TableCell>
                          <TableCell align="center" style={{ fontSize: "0.8rem", padding: "1px 18px", border: "1px solid gray" }}>
                            {application.APP_LVE_DES} days
                          </TableCell>
                          <TableCell align="center" style={{ fontSize: "0.8rem", padding: "1px 18px", border: "1px solid gray" }}>
                            {application.disp_nm}
                          </TableCell>
                          <TableCell align="center" style={{ fontSize: "0.8rem", padding: "1px 18px", border: "1px solid gray" }}>
                            {application.APP_LVE_STTS}
                          </TableCell>
                        </TableRow>
                      ))}
                      {oboApplications.length < 5 &&
                        Array.from({ length: 5 - oboApplications.length }).map((_, index) => (
                          <TableRow style={{ height: "10px" }} key={`empty-row-${index}`}>
                            <TableCell align="center" style={{ fontSize: "0.8rem", padding: "1px 18px", border: "1px solid gray", lineHeight: ".2" }}>
                              &nbsp; {/* Empty cell for spacing */}
                            </TableCell>
                            <TableCell align="center" style={{ fontSize: "0.8rem", padding: "1px 18px", border: "1px solid gray", lineHeight: ".2" }}>
                              &nbsp; {/* Empty cell for spacing */}
                            </TableCell>
                            <TableCell align="center" style={{ fontSize: "0.8rem", padding: "1px 18px", border: "1px solid gray", lineHeight: ".2" }}>
                              &nbsp; {/* Empty cell for spacing */}
                            </TableCell>
                            <TableCell align="center" style={{ fontSize: "0.8rem", padding: "1px 18px", border: "1px solid gray" }}>
                              &nbsp; {/* Empty cell for spacing */}
                            </TableCell>
                            <TableCell align="center" style={{ fontSize: "0.8rem", padding: "1px 18px", border: "1px solid gray" }}>
                              &nbsp; {/* Empty cell for spacing */}
                            </TableCell>
                            <TableCell align="center" style={{ fontSize: "0.8rem", padding: "1px", border: "1px solid gray" }}>
                              &nbsp; {/* Empty cell for spacing */}
                            </TableCell>
                            <TableCell align="center" style={{ fontSize: "0.8rem", padding: "1px", border: "1px solid gray" }}>
                              &nbsp; {/* Empty cell for spacing */}
                            </TableCell>
                            <TableCell align="center" style={{ fontSize: "0.8rem", padding: "1px 18px", border: "1px solid gray" }}>
                              &nbsp; {/* Empty cell for spacing */}
                            </TableCell>
                            <TableCell align="center" style={{ fontSize: "0.8rem", padding: "1px", border: "1px solid gray" }}>
                              &nbsp; {/* Empty cell for spacing */}
                            </TableCell>
                            <TableCell align="center" style={{ fontSize: "0.8rem", padding: "1px", border: "1px solid gray" }}>
                              &nbsp; {/* Empty cell for spacing */}
                            </TableCell>
                          </TableRow>
                        ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
            </div>
          </div>
        )}
      </div>
    </>
  );
}
