import React, { useState, useEffect } from "react";
import services from "./apiServices";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Grid,
} from "@mui/material";
const headerCellStyle = {
  fontSize: "0.8rem",
  padding: "1px 18px",
  backgroundColor: "#4a6d8c",
  border: "1px solid gray",
  color: "white",
  textAlign: "center",
};

const cellStyle = {
  fontSize: "0.8rem",
  padding: "1px 18px",
  border: "1px solid gray",
};
function Sys_Usr_Client_Sts_wise_Lst_Rpt() {
  const [aflttotalcnt, setaflttotalcnt] = useState({ aflt_cnt_res: [], aflt_wise_usr_cnt_res: [], alt_wise_lve_types_res: [], aflt_wise_lve_apl_cnt_res: [], pendng_aprved_aflt_apl_cnt_res: [], all_aflt_confg_dt: [], all_alf_auth_ntt_res: [], aflt_wise_lve_policy_res: [], sys_usr_lve_db_res: [] });

  useEffect(() => {
    const fetch = async () => {
      try {
        const res = await services.get_total_aflt_cnt();
        if (res) {
          setaflttotalcnt(res);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetch();
  }, []);
  const totalAffiliateCount = aflttotalcnt.aflt_cnt_res.length;
  // console.log("aflttotalcnt", aflttotalcnt)
  const handleDownload = async () => {
    const aplicantdata = aflttotalcnt.aflt_cnt_res.map((lst) => {
      return {
        ...lst,
      };
    });

    console.log("hello from handleDownload", aplicantdata);
    try {
      const response = await fetch('rdbs/sys_usr_rpt/aflt_client_lst', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(aplicantdata), // Send the applicant data to the backend
      });

      if (response.ok) {
        // Convert the response to a blobcd
        const blob = await response.blob();

        // Create a URL for the blob
        const url = window.URL.createObjectURL(blob);

        // Open the PDF in a new window or tab
        window.open(url, '_blank');
      } else {
        console.error('Error generating PDF:', response.statusText);
      }
    } catch (error) {
      console.error('Error fetching PDF:', error);
    }
  };

  return (
    <div className="wrapper_form" style={{ marginBlock: "1%" }}>
      <Grid item xs={12} style={{ paddingRight: "1%", marginLeft: "1%" }}>
        <div className="title" style={{ marginTop: "1%" }}>
          Total Client Count
        </div>
        <TableContainer
          component={Paper}
          style={{
            margin: "5px auto 2px auto",
            overflow: "auto",
            borderRadius: "20px",
            border: "2px solid gray",
            maxHeight: "400px",
          }}
        >
          <Table style={{ width: "100%" }}>
            <TableHead>
              <TableRow style={{ width: "100%" }}>
                <TableCell style={headerCellStyle}>Sr.No.</TableCell>
                <TableCell style={headerCellStyle}>Organization Unique identification</TableCell>
                <TableCell style={headerCellStyle}>Organization Short Name</TableCell>
                <TableCell style={headerCellStyle}>Client Name</TableCell>
                <TableCell style={headerCellStyle}>Status</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {aflttotalcnt.aflt_cnt_res.map((afltcntdt, index) => (
                <TableRow key={afltcntdt.leaveTypeId}>
                  <TableCell align="center" style={cellStyle}>
                    {index + 1}
                  </TableCell>
                  <TableCell align="center" style={cellStyle}>
                    {afltcntdt.AFLT_ID_BY_CUST}
                  </TableCell>
                  <TableCell align="center" style={cellStyle}>
                    {afltcntdt.AFLT_SHORT_NAME}
                  </TableCell>
                  <TableCell align="center" style={cellStyle}>
                    {afltcntdt.AFLT_NAME}
                  </TableCell>
                  <TableCell align="center" style={cellStyle}>
                    {afltcntdt.IS_ACTIVE == "Y" ? "Active" : "Non-Active"}
                  </TableCell>
                </TableRow>
              ))}
              {aflttotalcnt.aflt_cnt_res.length < 2 &&
                Array.from({ length: 2 - aflttotalcnt.aflt_cnt_res.length }).map((_, index) => (
                  <TableRow key={`empty-row-manager-${index}`}>
                    <TableCell align="center" style={cellStyle}>
                      &nbsp;
                    </TableCell>
                    <TableCell align="center" style={cellStyle}>
                      &nbsp;
                    </TableCell>
                    <TableCell align="center" style={cellStyle}>
                      &nbsp;
                    </TableCell>
                    <TableCell align="center" style={cellStyle}>
                      &nbsp;
                    </TableCell>
                    <TableCell align="center" style={cellStyle}>
                      &nbsp;
                    </TableCell>
                  </TableRow>
                ))}
              <TableRow>
                <TableCell align="center" style={{ ...cellStyle, fontWeight: "bold" }}>
                  Total Client
                </TableCell>
                <TableCell align="center" style={cellStyle}>
                  &nbsp;
                </TableCell>
                <TableCell align="center" style={cellStyle}>
                  &nbsp;
                </TableCell>
                <TableCell align="center" style={cellStyle}>
                  &nbsp;
                </TableCell>
                <TableCell align="center" style={{ ...cellStyle, fontWeight: "bold" }}>
                  {totalAffiliateCount}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
      <div style={{ display: "flex", justifyContent: "flex-end", width: "100%" }}>
        <button
          className="action-button"
          style={{ width: "10%", margin: "1%" }}
          onClick={handleDownload}
        >
          Print All Client
        </button>
      </div>
    </div>
  )
}

export default Sys_Usr_Client_Sts_wise_Lst_Rpt