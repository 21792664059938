import React, { useState, useEffect } from 'react';
import { Box, Grid, Button } from '@mui/material';
import moment from 'moment';
import services from "./apiServices";

function Profile() {
    const [userData, setUserData] = useState({});

    useEffect(() => {
        const storedUser = sessionStorage.getItem("user_data");
        if (storedUser) {
            setUserData(JSON.parse(storedUser)[0]); // Assuming usr_log is an array
        }
    }, []);

    function formatDate(dateString) {
        return moment(dateString).format('DD/MMM/YYYY').replace(/\/([a-z])/g, (match) => '/' + match[1].toUpperCase());
    }

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setUserData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };
    console.log("Updated user data:", userData);
    const handleSubmit = (e) => {
        e.preventDefault();
        // Save updated data back to sessionStorage or handle it as needed
        sessionStorage.setItem("user_data", JSON.stringify([userData]));
        console.log("Updated user data from submit:", userData);
    };
    function formatDateForInput(dateString) {
        return moment(dateString).format('YYYY-MM-DD');
    }
    const save_updt_data = async () => {

        try {
            // Call the submission service with the updated data
            const insrt_res = await services.updated_prf_data(userData);

            if (insrt_res) {
                console.log("Profile Updated Successfully....");
                alert("Profile Updated Successfully");
            }
        } catch (error) {
            console.error(" failed to Profile Update...", error);
            alert("Failed to Profile Updated.. ");
        }

    };

    return (
        <div className='wrapper_form' >
            <div className="title" style={{ marginTop: ".3%" }}>
                Profile
            </div>
            <Box sx={{ padding: '1rem' }}>
                <form method="Post" onSubmit={handleSubmit} >
                    <div className="form-container">
                        <hr className="hr_ttl" />
                        <p style={{
                            fontSize: "25px",
                            paddingTop: "5px",
                            color: "black",
                        }}>
                            Employee information
                        </p>
                        <hr className="hr_dt" style={{ marginBottom: "19px" }} />

                        <Grid container spacing={3} style={{ marginTop: ".5%" }}>
                            <Grid item xs={12} sm={4} className="column drp_ln_hg">
                                <label className="drp_mg">Name</label>
                                <div className="field">
                                    <input
                                        className="emp_add_text"
                                        id="name"
                                        name="USR_DISPLAY_NAME"
                                        value={userData.USR_DISPLAY_NAME || ''}
                                        onChange={handleInputChange}
                                    />
                                </div>
                            </Grid>
                            <Grid item xs={12} sm={4} className="column drp_ln_hg">
                                <label className="drp_mg">Employee ID:</label>
                                <div className="field">
                                    <input
                                        className="emp_add_text"
                                        type="text"
                                        id="empId"
                                        name="USR_EMP_ID"
                                        value={userData.USR_EMP_ID || ''}
                                        // onChange={handleInputChange}
                                        readOnly
                                    />
                                </div>
                            </Grid>
                            <Grid item xs={12} sm={4} className="column drp_ln_hg">
                                <label className="drp_mg">User  Type:</label>
                                <div className="field">
                                    <input
                                        className="emp_add_text"
                                        type="text"
                                        id="userType"
                                        name="usrtyp"
                                        value={userData.usrtyp || ''}
                                        // onChange={handleInputChange}
                                        readOnly
                                    />
                                </div>
                            </Grid>
                        </Grid>

                        <Grid container spacing={3} style={{ marginTop: "1%" }}>
                            <Grid item xs={12} sm={4} className="column drp_ln_hg">
                                <label className="drp_mg">Post:</label>
                                <div className="field">
                                    <input
                                        className="emp_add_text"
                                        type="text"
                                        id="post"
                                        name="usrpost"
                                        value={userData.usrpost || ''}
                                        // onChange={handleInputChange}
                                        readOnly
                                    />
                                </div>
                            </Grid>
                            <Grid item xs={12} sm={4} className="column drp_ln_hg">
                                <label className="drp_mg">Login ID:</label>
                                <div className="field">
                                    <input
                                        className="emp_add_text"
                                        type="text"
                                        id="loginId"
                                        name="NTT_LOGIN_ID"
                                        value={userData.NTT_LOGIN_ID || ''}
                                        // onChange={handleInputChange}
                                        readOnly
                                    />
                                </div>
                            </Grid>
                        </Grid>

                        <hr className="hr_ttl" style={{ marginTop: "20px" }} />
                        <p style={{
                            fontSize: "25px",
                            paddingTop: "5px",
                            color: "black",
                        }}>
                            Address information
                        </p>
                        <hr className="hr_dt" style={{ marginBottom: "19px" }} />

                        <Grid container spacing={3} style={{ marginTop: ".5%" }}>
                            <Grid item xs={12} sm={6} className="column drp_ln_hg">
                                <label className="drp_mg">Email:</label>
                                <div className="field">
                                    <input
                                        className="emp_add_text"
                                        type="text"
                                        id="email"
                                        name="USR_EMAIL"
                                        value={userData.USR_EMAIL || ''}
                                        onChange={handleInputChange}
                                    />
                                </div>
                            </Grid>
                            <Grid item xs={12} sm={6} className="column drp_ln_hg">
                                <label className="drp_mg">Mobile No:</label>
                                <div className="field">
                                    <input
                                        className="emp_add_text"
                                        type="text"
                                        id="mobileNo"
                                        name="USR_CELL_PH"
                                        value={userData.USR_CELL_PH || ''}
                                        onChange={handleInputChange}
                                    />
                                </div>
                            </Grid>
                            <Grid item xs={12} sm={6} className="column drp_ln_hg">
                                <label className="drp_mg">Date of Birth:</label>
                                <div className="field">
                                    <input
                                        className="emp_add_text"
                                        type="date"
                                        id="dob"
                                        name="USR_DOB"
                                        value={formatDateForInput(userData.USR_DOB || '')}
                                        onChange={handleInputChange}
                                    />
                                </div>
                            </Grid>
                        </Grid>

                        <Grid container spacing={3} style={{ marginTop: ".1%" }}>
                            <Grid item xs={12} sm={6} className="column drp_ln_hg">
                                <label style={{ marginLeft: "1%" }}>Address Line 1:</label>
                                <div className="field">
                                    <input
                                        className="emp_add_text"
                                        type="text"
                                        id="addressLine1"
                                        name="ADD_LN1"
                                        value={userData.ADD_LN1 || ''}
                                        onChange={handleInputChange}
                                    />
                                </div>
                            </Grid>

                            <Grid item xs={12} sm={6} className="column drp_ln_hg">
                                <label style={{ marginLeft: "1%" }}>Address Line 2:</label>
                                <div className="field">
                                    <input
                                        className="emp_add_text"
                                        type="text"
                                        id="addressLine2"
                                        name="ADD_LN2"
                                        value={userData.ADD_LN2 || ''}
                                        onChange={handleInputChange}
                                    />
                                </div>
                            </Grid>

                            <Grid item xs={12} sm={6} className="column drp_ln_hg">
                                <label style={{ marginLeft: "1%" }}>Address Line 3:</label>
                                <div className="field">
                                    <input
                                        className="emp_add_text"
                                        type="text"
                                        id="addressLine3"
                                        name="ADD_LN3"
                                        value={userData.ADD_LN3 || ''}
                                        onChange={handleInputChange}
                                    />
                                </div>
                            </Grid>
                        </Grid>
                        <Grid container spacing={3} style={{ marginTop: ".1%" }}>
                            <Grid item xs={12} sm={4} className="column drp_ln_hg">
                                <label style={{ marginLeft: "1%" }}>City:</label>
                                <div className="field">
                                    <input
                                        className="emp_add_text"
                                        type="text"
                                        id="city"
                                        name="ADD_CT"
                                        value={userData.ADD_CT || ''}
                                        onChange={handleInputChange}
                                    />
                                </div>
                            </Grid>

                            <Grid item xs={12} sm={4} className="column drp_ln_hg">
                                <label style={{ marginLeft: "1%" }}>Postal/ZIP Code:</label>
                                <div className="field">
                                    <input
                                        className="emp_add_text"
                                        type="text"
                                        id="postalCode"
                                        name="ADD_PIN_ZIP"
                                        value={userData.ADD_PIN_ZIP || ''}
                                        onChange={handleInputChange}
                                    />
                                </div>
                            </Grid>
                        </Grid>

                        <div className="row" style={{ display: "flex", justifyContent: "right", marginTop: "2.5%" }}>
                            <hr class="hr_dt" />

                            <Button
                                className="btn-action action-button"
                                style={{ width: "10%", }}
                                type="submit"
                                onClick={save_updt_data}
                            >
                                Save
                            </Button>
                            <hr class="hr_dt" style={{ marginTop: "5px" }} />
                        </div>
                    </div>
                </form>
            </Box>
        </div>
    );
}

export default Profile;