import React, { useState, useEffect } from "react";
import services from "./apiServices";
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Grid,
    IconButton,
    Tooltip
} from "@mui/material";
import DownloadIcon from '@mui/icons-material/Download';

const headerCellStyle = {
    fontSize: "0.8rem",
    padding: "1px 18px",
    backgroundColor: "#4a6d8c",
    border: "1px solid gray",
    color: "white",
    textAlign: "center",
};

const cellStyle = {
    fontSize: "0.8rem",
    padding: "1px 18px",
    border: "1px solid gray",
};

function Sys_Usr_Aflt_Lve_Sts_Post() {
    const [lvests_post, setlvests_post] = useState({ aflt_lvests_post_res: [] });

    useEffect(() => {
        const fetchData = async () => {
            try {
                const res = await services.get_lvests_posts();
                if (res) {
                    setlvests_post(res);
                }
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };
        fetchData();
    }, []);

    const handledownload = async (rows) => {
        console.log("Selected rows data for download:", rows); // Log all rows' data

        const apcblPostItems = rows.find(item => item.ATTRIB_GRP_TYP);

        const aplicablepost = apcblPostItems.ATTRIB_GRP_TYP

        const endpoint = aplicablepost === 'LVE_STTS' ? 'rdbs/sys_usr_rpt/aplicable_lve_sts' : 'rdbs/sys_usr_rpt/aplicable_user_posts';

        console.log("slelceted api is:", endpoint)
        try {
            const response = await fetch(endpoint, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(rows), // Send all rows data to the backend
            });

            if (response.ok) {
                // Convert the response to a blob
                const blob = await response.blob();

                // Create a URL for the blob
                const url = window.URL.createObjectURL(blob);

                // Open the PDF in a new window or tab
                window.open(url, '_blank');
            } else {
                console.error('Error generating PDF:', response.statusText);
            }
        } catch (error) {
            console.error('Error fetching PDF:', error);
        }
    };


    // Filter rows for LVE_STTS and APCBL_POST
    const lveSttsRows = lvests_post.aflt_lvests_post_res.filter(leavests => leavests.ATTRIB_GRP_TYP === 'LVE_STTS');
    const apcblPostRows = lvests_post.aflt_lvests_post_res.filter(leavests => leavests.ATTRIB_GRP_TYP === 'APCBL_POST');

    // Function to render a table with the given rows
    const renderTable = (rows, title) => (
        <TableContainer component={Paper} style={{ margin: "5px auto 25px auto", boxShadow: "0px 6px 16px 3px #bababa", borderRadius: "10px" }}>
            <Table style={{ width: "100%" }}>
                <TableHead>
                    <TableRow>
                        <TableCell style={headerCellStyle}>Sr. No.</TableCell>
                        <TableCell style={headerCellStyle}>Leave Code</TableCell>
                        <TableCell style={headerCellStyle}>Leave Status</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {rows.map((leavests, index) => (
                        <TableRow key={leavests.leaveTypeId} style={{ height: "10px" }}>
                            <TableCell align="center" style={cellStyle}>{index + 1}</TableCell>
                            <TableCell align="center" style={cellStyle}>{leavests.ATTRIB_CD}</TableCell>
                            <TableCell align="center" style={cellStyle}>{leavests.ATTRIB_NAME}</TableCell>
                        </TableRow>
                    ))}

                    {/* If the table has less than 3 rows, fill in the empty rows */}
                    {rows.length < 3 && Array.from({ length: 3 - rows.length }).map((_, index) => (
                        <TableRow style={{ height: "10px" }} key={`empty-row-${index}`}>
                            <TableCell align="center" style={cellStyle}>&nbsp;</TableCell>
                            <TableCell align="center" style={cellStyle}>&nbsp;</TableCell>
                            <TableCell align="center" style={cellStyle}>&nbsp;</TableCell>
                        </TableRow>
                    ))}

                    {/* Add a final row with the download button */}
                    <TableRow>
                        <TableCell align="center" style={cellStyle}>&nbsp;</TableCell>
                        <TableCell align="center" style={cellStyle}>&nbsp;</TableCell>
                        <TableCell align="center" style={cellStyle}>
                            <Tooltip title="Download PDF" arrow>
                                <IconButton onClick={() => handledownload(rows)}>
                                    <DownloadIcon title="download pdf" style={{ color: "#4a6d8c" }} fontSize="small" />
                                </IconButton>
                            </Tooltip>

                        </TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        </TableContainer>
    );
    const renderTable1 = (rows, title) => (
        <TableContainer component={Paper} style={{ margin: "5px auto 25px auto", boxShadow: "0px 6px 16px 3px #bababa", borderRadius: "10px" }}>
            <Table style={{ width: "100%" }}>
                <TableHead>
                    <TableRow>
                        <TableCell style={headerCellStyle}>Sr. No.</TableCell>
                        <TableCell style={headerCellStyle}>Post Code</TableCell>
                        <TableCell style={headerCellStyle}>Post Names</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {rows.map((leavests, index) => (
                        <TableRow key={leavests.leaveTypeId} style={{ height: "10px" }}>
                            <TableCell align="center" style={cellStyle}>{index + 1}</TableCell>
                            <TableCell align="center" style={cellStyle}>{leavests.ATTRIB_CD}</TableCell>
                            <TableCell align="center" style={cellStyle}>{leavests.ATTRIB_NAME}</TableCell>
                        </TableRow>
                    ))}

                    {/* If the table has less than 3 rows, fill in the empty rows */}
                    {rows.length < 3 && Array.from({ length: 3 - rows.length }).map((_, index) => (
                        <TableRow style={{ height: "10px" }} key={`empty-row-${index}`}>
                            <TableCell align="center" style={cellStyle}>&nbsp;</TableCell>
                            <TableCell align="center" style={cellStyle}>&nbsp;</TableCell>
                            <TableCell align="center" style={cellStyle}>&nbsp;</TableCell>
                        </TableRow>
                    ))}

                    {/* Add a final row with the download button */}
                    <TableRow>
                        <TableCell align="center" style={cellStyle}>&nbsp;</TableCell>
                        <TableCell align="center" style={cellStyle}>&nbsp;</TableCell>
                        <TableCell align="center" style={cellStyle}>
                            <Tooltip title="Download PDF" arrow>
                                <IconButton onClick={() => handledownload(rows)}>
                                    <DownloadIcon title="download pdf" style={{ color: "#4a6d8c" }} fontSize="small" />
                                </IconButton>
                            </Tooltip>

                        </TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        </TableContainer>
    );

    return (
        <div className="wrapper_form" style={{ marginBlock: "1%" }}>
            <Grid item xs={12} style={{ paddingLeft: "2%", paddingRight: "1%", width: "100%" }}>
                <div className="title" style={{ marginTop: "1%", height: "10%" }}>Applicable Leave Status</div>

                {/* First Table for 'LVE_STTS' */}
                {renderTable(lveSttsRows, 'LVE_STTS')}

                <hr
                    style={{
                        border: "4px solid #4a6d8c",
                        width: "99%", // Set HR width to 90%
                        marginBottom: "1%", // Remove margin
                        borderRadius: "5px"
                    }}
                />{" "}
                <div className="title" style={{ marginTop: "1%", height: "10%" }}>Applicable Users Post</div>
                {/* Second Table for 'APCBL_POST' */}
                {renderTable1(apcblPostRows, 'APCBL_POST')}
            </Grid>
        </div>
    );
}

export default Sys_Usr_Aflt_Lve_Sts_Post;
