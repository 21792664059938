import React, { useState, useEffect } from "react";
import services from "./apiServices";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Grid,
  IconButton,
  Tooltip
} from "@mui/material";
import DownloadIcon from '@mui/icons-material/Download';

const headerCellStyle = {
  fontSize: "0.8rem",
  padding: "1px 18px",
  backgroundColor: "#4a6d8c",
  border: "1px solid gray",
  color: "white",
  textAlign: "center",
};

const cellStyle = {
  fontSize: "0.8rem",
  padding: "1px 18px",
  border: "1px solid gray",
};

function Sys_Usr_Client_Lve_Aplcation_Cnt_Rpt() {
  const [aflttotalcnt, setaflttotalcnt] = useState({ aflt_cnt_res: [], aflt_wise_usr_cnt_res: [], alt_wise_lve_types_res: [], aflt_wise_lve_apl_cnt_res: [], pendng_aprved_aflt_apl_cnt_res: [] });

  useEffect(() => {
    const fetch = async () => {
      try {
        const res = await services.get_total_aflt_cnt();
        if (res) {
          setaflttotalcnt(res);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetch();
  }, []);

  // Handle Download and log the selected affiliate data
  const handleDownload = (affiliateData) => {
    console.log("Data to be sent to backend:", affiliateData); // Log here to ensure data is correct
    fetch('rdbs/sys_usr_rpt/aflt_wise_appl_cnt', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(affiliateData), // Send selected affiliate data
    })
      .then(response => {
        if (response.ok) {
          return response.blob();
        } else {
          throw new Error("Error generating PDF");
        }
      })
      .then(blob => {
        const url = window.URL.createObjectURL(blob);
        window.open(url, '_blank'); // Open the PDF in a new tab
      })
      .catch(error => {
        console.error('Error fetching PDF:', error);
      });
  };


  return (
    <div className="wrapper_form" style={{ marginBlock: "1%" }}>
      <Grid item xs={12} style={{ padding: "1%" }}>
        <div
          className="title"
          style={{ marginTop: "2%", textAlign: "center", fontSize: "1.5rem" }}
        >
          Client wise Leave Application count (Status wise)
        </div>
        <TableContainer
          component={Paper}
          style={{
            margin: "5px auto 25px auto",
            boxShadow: "0px 6px 16px 3px #bababa",
            borderRadius: "20px",
            border: "2px solid gray",
            maxHeight: "500px",
            overflowY: "auto",
          }}
        >
          <Table style={{ width: "100%" }}>
            <TableHead>
              <TableRow>
                <TableCell style={headerCellStyle}>Sr. No</TableCell>
                <TableCell style={headerCellStyle}>Client Name</TableCell>
                <TableCell style={headerCellStyle}>Leave Type</TableCell>
                <TableCell style={headerCellStyle}>Leave Status</TableCell>
                <TableCell style={headerCellStyle}>Application Count</TableCell>
                <TableCell style={headerCellStyle}>Print Reports</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {Object.entries(
                aflttotalcnt.pendng_aprved_aflt_apl_cnt_res.reduce((acc, leaveApp) => {
                  const key = `${leaveApp.AFLT_NAME}-${leaveApp.AFLT_SHORT_NAME}`;
                  if (!acc[key]) {
                    acc[key] = {
                      ...leaveApp,
                      leaveTypes: [
                        {
                          Leave_Type: leaveApp.Leave_Type,
                          APP_LVE_STTS: leaveApp.APP_LVE_STTS,
                          Application_Count: leaveApp.Application_Count,
                        },
                      ],
                    };
                  } else {
                    acc[key].leaveTypes.push({
                      Leave_Type: leaveApp.Leave_Type,
                      APP_LVE_STTS: leaveApp.APP_LVE_STTS,
                      Application_Count: leaveApp.Application_Count,
                    });
                  }
                  return acc;
                }, {})
              ).map(([key, leaveApp], index) => {
                const leaveTypeRows = leaveApp.leaveTypes.map((leaveType, leaveIndex) => (
                  <TableRow
                    key={`${leaveApp.AFLT_NAME}-${leaveType.Leave_Type}-${leaveType.APP_LVE_STTS}`}
                  >
                    {leaveIndex === 0 && (
                      <>
                        <TableCell rowSpan={leaveApp.leaveTypes.length} align="center" style={cellStyle}>
                          {index + 1}
                        </TableCell>
                        <TableCell rowSpan={leaveApp.leaveTypes.length} align="center" style={cellStyle}>
                          {leaveApp.AFLT_NAME}
                        </TableCell>
                      </>
                    )}
                    <TableCell align="center" style={cellStyle}>
                      {leaveType.Leave_Type}
                    </TableCell>
                    <TableCell align="center" style={cellStyle}>
                      {leaveType.APP_LVE_STTS}
                    </TableCell>
                    <TableCell align="center" style={cellStyle}>
                      {leaveType.Application_Count}
                    </TableCell>
                    {leaveIndex === 0 && (
                      <TableCell rowSpan={leaveApp.leaveTypes.length} align="center" style={cellStyle}>
                        <Tooltip title="Download PDF" arrow>
                          <IconButton onClick={() => handleDownload(leaveApp)}>
                            <DownloadIcon title="download pdf" style={{ color: "#4a6d8c" }} fontSize="small" />
                          </IconButton>
                        </Tooltip>
                      </TableCell>
                    )}
                  </TableRow>
                ));

                leaveTypeRows.push(
                  <TableRow key={`${leaveApp.AFLT_NAME}-empty-row`} style={{ backgroundColor: "#dddddf" }}>
                    <TableCell colSpan={6} style={cellStyle}>&nbsp;</TableCell>
                  </TableRow>
                );

                return leaveTypeRows;
              })}

              <TableRow>
                <TableCell align="center" style={{ ...cellStyle, fontWeight: "bold" }}>
                  Total Applications
                </TableCell>
                <TableCell align="center" style={{ ...cellStyle, fontWeight: "bold" }}>
                  &nbsp;
                </TableCell>
                <TableCell align="center" style={{ ...cellStyle, fontWeight: "bold" }}>
                  &nbsp;
                </TableCell>
                <TableCell align="center" style={{ ...cellStyle, fontWeight: "bold" }}>
                  &nbsp;
                </TableCell>
                <TableCell align="center" style={{ ...cellStyle, fontWeight: "bold" }}>
                  &nbsp;
                </TableCell>
                <TableCell align="center" style={{ ...cellStyle, fontWeight: "bold" }}>
                  {Object.entries(
                    aflttotalcnt.pendng_aprved_aflt_apl_cnt_res.reduce((acc, leaveApp) => {
                      const key = `${leaveApp.AFLT_NAME}-${leaveApp.AFLT_SHORT_NAME}`;
                      if (!acc[key]) {
                        acc[key] = { totalCount: 0 };
                      }
                      acc[key].totalCount += leaveApp.Application_Count;
                      return acc;
                    }, {})
                  ).reduce((total, [key, value]) => total + value.totalCount, 0)}
                </TableCell>
              </TableRow>

              {aflttotalcnt.pendng_aprved_aflt_apl_cnt_res.length < 2 &&
                Array.from({ length: 2 - aflttotalcnt.pendng_aprved_aflt_apl_cnt_res.length }).map(
                  (_, index) => (
                    <TableRow key={`empty-row-leave-app-${index}`}>
                      <TableCell align="center" style={cellStyle}>
                        &nbsp;
                      </TableCell>
                      <TableCell align="center" style={cellStyle}>
                        &nbsp;
                      </TableCell>
                      <TableCell align="center" style={cellStyle}>
                        &nbsp;
                      </TableCell>
                      <TableCell align="center" style={cellStyle}>
                        &nbsp;
                      </TableCell>
                      <TableCell align="center" style={cellStyle}>
                        &nbsp;
                      </TableCell>
                    </TableRow>
                  )
                )}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </div>
  );
}

export default Sys_Usr_Client_Lve_Aplcation_Cnt_Rpt;
