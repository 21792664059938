import React, { useState, useEffect } from "react";
import services from "./apiServices";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Grid,
  IconButton
} from "@mui/material";

import DownloadIcon from '@mui/icons-material/Download';
import moment from 'moment';
import jsPDF from 'jspdf';
import 'jspdf-autotable';

export default function Manager_Rep_Lve_App_Print() {


  const [leavecnt, Setleavecnt] = useState({
    login_lve_bal_cnt: [], lve_apl_dt: [], login_team_membr_inf_res: [],
    shw_upcom_hlod_res: [], empwise_lve_bal_res: [], all_usr_cnt_data: [], aflt_annocement_news_result: []
  })
  const [data, setData] = useState({ result: [] });

  useEffect(() => {
    const lev_bal_cnt = async () => {
      try {
        const lve_cnt = await services.get_lev_bal_cnt();

        if (lve_cnt) {
          Setleavecnt(lve_cnt); // Wrap it in an object if necessary
        } else {
          console.error("Unexpected data format:", lve_cnt);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    lev_bal_cnt();

  }, [])

  const fetchApplicantData = async () => {
    try {
      const result = await services.getapplicantdata();
      console.log("Fetched applicant data:", result); // Log the result for debugging
      if (result) {
        setData({ result: result.result || [] });
      } else {
        console.log("No data fetched", result);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  useEffect(() => {
    fetchApplicantData();
  }, []);
  console.log("data", data)
  function formatDate(dateString) {
    // Format the date to 'DD/MMM/YYYY' with the first letter of the month capitalized
    return moment(dateString).format('DD/MMM/YYYY').replace(/\/([a-z])/g, (match) => '/' + match[1].toUpperCase());
  }
  console.log("leavecnt", leavecnt)

  const handleDownload = async (aplicantdata) => {
    try {
      console.log("hello from handleDownload");

      // Fetch the PDF from the server
      const response = await fetch('rdbs/dash_cust/generate-pdf', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(aplicantdata), // Send the applicant data to the backend
      });

      if (response.ok) {
        // Convert the response to a blob
        const blob = await response.blob();

        // Create a URL for the blob
        const url = window.URL.createObjectURL(blob);

        // Open the PDF in a new window or tab
        window.open(url, '_blank');
      } else {
        console.error('Error generating PDF:', response.statusText);
      }
    } catch (error) {
      console.error('Error fetching PDF:', error);
    }
  };


  return (

    <>
      <div className="wrapper_form" style={{ marginBlock: "1%" }}>
        <Grid item xs={12} style={{ paddingRight: "1%", marginLeft: "1%" }}>
          <div className="title" style={{ marginTop: "1%" }}>Leave Applications </div>
          <TableContainer
            component={Paper}
            style={{
              margin: "5px auto 25px auto",
              // maxWidth: "50%",
              overflow: "hidden", // Prevents content overflow
              // boxShadow: "-10px 12px 16px 3px #bababa", // Gray shadow
              boxShadow: "0px 6px 16px 3px #bababa", // Shadow only on the top and bottom
              borderRadius: "20px", // Rounded corners for the container
              border: "2px solid gray",

            }}
          >
            <Table style={{ width: "100%", }}>
              <TableHead>
                <TableRow style={{ width: "100%", }}>
                  <TableCell
                    style={{
                      fontSize: "0.8rem",
                      padding: "1px 18px",
                      backgroundColor: "#4a6d8c",
                      border: "1px solid gray", color: "white", textAlign: "center"
                    }}
                    className=" txt_ttl1"
                  >
                    Sr.No.
                  </TableCell>
                  <TableCell
                    style={{
                      fontSize: "0.8rem",
                      padding: "1px 18px",
                      backgroundColor: "#4a6d8c",
                      border: "1px solid gray", color: "white", textAlign: "center"
                    }}
                    className=" txt_ttl1"
                  >
                    Date
                  </TableCell>
                  <TableCell
                    style={{
                      fontSize: "0.8rem",
                      padding: "1px 18px",
                      backgroundColor: "#4a6d8c",
                      border: "1px solid gray", color: "white", textAlign: "center"
                    }}
                    className=" txt_ttl1"
                  >
                    From
                  </TableCell>
                  <TableCell
                    style={{
                      fontSize: "0.8rem",
                      padding: "1px",
                      backgroundColor: "#4a6d8c",
                      border: "1px solid gray", color: "white", textAlign: "center"
                    }}
                    className=" txt_ttl1"
                  >
                    To
                  </TableCell>
                  <TableCell
                    style={{
                      fontSize: "0.8rem",
                      padding: "1px",
                      backgroundColor: "#4a6d8c",
                      border: "1px solid gray", color: "white", textAlign: "center"
                    }}
                    className=" txt_ttl1"
                  >
                    Duration
                  </TableCell>
                  <TableCell
                    style={{
                      fontSize: "0.8rem",
                      padding: "1px",
                      backgroundColor: "#4a6d8c",
                      border: "1px solid gray", color: "white", textAlign: "center"
                    }}
                    className=" txt_ttl1"
                  >
                    Type
                  </TableCell>
                  <TableCell
                    style={{
                      fontSize: "0.8rem",
                      padding: "1px",
                      backgroundColor: "#4a6d8c",
                      border: "1px solid gray", color: "white", textAlign: "center"
                    }}
                    className=" txt_ttl1"
                  >
                    Status
                  </TableCell>
                  <TableCell
                    style={{
                      fontSize: "0.8rem",
                      padding: "1px",
                      backgroundColor: "#4a6d8c",
                      border: "1px solid gray", color: "white", textAlign: "center"
                    }}
                    className=" txt_ttl1"
                  >
                    Print
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {leavecnt.lve_apl_dt.map((aplicantdata, index) => (
                  <TableRow key={aplicantdata.leaveTypeId}>
                    <TableCell
                      align="center" // Center the content horizontally
                      style={{
                        fontSize: "0.8rem",
                        padding: "1px",
                        border: "1px solid gray",
                      }}
                    >
                      {index + 1}
                    </TableCell>
                    <TableCell
                      align="center" // Center the content horizontally
                      style={{
                        fontSize: "0.8rem",
                        padding: "1px 18px",
                        border: "1px solid gray",
                      }}
                    >
                      {formatDate(aplicantdata.APP_DT)}
                    </TableCell>
                    <TableCell
                      align="center" // Center the content horizontally
                      style={{
                        fontSize: "0.8rem",
                        padding: "1px 18px",
                        border: "1px solid gray",
                      }}
                    >
                      {formatDate(aplicantdata.APP_LVE_FRM_DT)} {/* Format leave to date */}
                    </TableCell>
                    <TableCell
                      align="center" // Center the content horizontally
                      style={{
                        fontSize: "0.8rem",
                        padding: "1px",
                        border: "1px solid gray",
                      }}
                    >
                      {formatDate(aplicantdata.APP_LVE_TO_DT)}
                    </TableCell>
                    <TableCell
                      align="center" // Center the content horizontally
                      style={{
                        fontSize: "0.8rem",
                        padding: "1px",
                        border: "1px solid gray",
                      }}
                    >
                      {aplicantdata.APP_LVE_DES} days
                    </TableCell>
                    <TableCell
                      align="center" // Center the content horizontally
                      style={{
                        fontSize: "0.8rem",
                        padding: "1px",
                        border: "1px solid gray",
                      }}
                    >
                      {aplicantdata.disp_nm}
                    </TableCell>
                    <TableCell
                      align="center" // Center the content horizontally
                      style={{
                        fontSize: "0.8rem",
                        padding: "1px",
                        border: "1px solid gray",
                      }}
                    >
                      {aplicantdata.APP_LVE_STTS}
                    </TableCell>
                    <TableCell align="center" style={{ padding: " 0px 1px", border: "1px solid gray" }}>
                      <IconButton style={{ padding: " 0px 1px"}} onClick={() => handleDownload(aplicantdata)}>
                        <DownloadIcon style={{ color: "#4a6d8c" }} fontSize="2px" />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
                {leavecnt.lve_apl_dt.length < 10 &&
                  Array.from({ length: 10 - leavecnt.lve_apl_dt.length }).map((_, index) => (
                    <TableRow key={`empty-row-manager-${index}`}>
                      <TableCell align="center" style={{ fontSize: "0.8rem", padding: "1px 18px", border: "1px solid gray" }}>&nbsp;</TableCell>
                      <TableCell align="center" style={{ fontSize: "0.8rem", padding: "1px 18px", border: "1px solid gray" }}>&nbsp;</TableCell>
                      <TableCell align="center" style={{ fontSize: "0.8rem", padding: "1px 18px", border: "1px solid gray" }}>&nbsp;</TableCell>
                      <TableCell align="center" style={{ fontSize: "0.8rem", padding: "1px 18px", border: "1px solid gray" }}>&nbsp;</TableCell>
                      <TableCell align="center" style={{ fontSize: "0.8rem", padding: "1px 18px", border: "1px solid gray" }}>&nbsp;</TableCell>
                      <TableCell align="center" style={{ fontSize: "0.8rem", padding: "1px 18px", border: "1px solid gray" }}>&nbsp;</TableCell>
                      <TableCell align="center" style={{ fontSize: "0.8rem", padding: "1px 18px", border: "1px solid gray" }}>&nbsp;</TableCell>
                      <TableCell align="center" style={{ fontSize: "0.8rem", padding: "1px 18px", border: "1px solid gray" }}>&nbsp;</TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </div>
      <hr
        style={{

          border: "1px solid #4a6d8c",
          width: "97%", // Set HR width to 90%

        }}
      />{" "}
      <div className="wrapper_form" style={{ marginTop: "1%" }}>
        <Grid item xs={12} style={{ paddingRight: "1%", marginLeft: "1%" }}>
          <div className="title" style={{ marginTop: "1%" }}>Team Members Leave Applications </div>
          <TableContainer
            component={Paper}
            style={{
              margin: "5px auto 25px auto",
              // maxWidth: "50%",
              overflow: "hidden", // Prevents content overflow
              // boxShadow: "-10px 12px 16px 3px #bababa", // Gray shadow
              boxShadow: "0px 6px 16px 3px #bababa", // Shadow only on the top and bottom
              borderRadius: "20px", // Rounded corners for the container
              border: "2px solid gray",

            }}
          >
            <Table style={{ width: "100%", }}>
              <TableHead>
                <TableRow style={{ width: "100%", }}>
                  <TableCell
                    style={{
                      fontSize: "0.8rem",
                      padding: "1px 18px",
                      backgroundColor: "#4a6d8c",
                      border: "1px solid gray", color: "white", textAlign: "center"
                    }}
                    className=" txt_ttl1"
                  >
                    Sr.No.
                  </TableCell>
                  <TableCell
                    style={{
                      fontSize: "0.8rem",
                      padding: "1px 18px",
                      backgroundColor: "#4a6d8c",
                      border: "1px solid gray", color: "white", textAlign: "center"
                    }}
                    className=" txt_ttl1"
                  >
                    Emp. ID
                  </TableCell>
                  <TableCell
                    style={{
                      fontSize: "0.8rem",
                      padding: "1px 18px",
                      backgroundColor: "#4a6d8c",
                      border: "1px solid gray", color: "white", textAlign: "center"
                    }}
                    className=" txt_ttl1"
                  >
                    Name
                  </TableCell>
                  <TableCell
                    style={{
                      fontSize: "0.8rem",
                      padding: "1px 18px",
                      backgroundColor: "#4a6d8c",
                      border: "1px solid gray", color: "white", textAlign: "center"
                    }}
                    className=" txt_ttl1"
                  >
                    Date
                  </TableCell>
                  <TableCell
                    style={{
                      fontSize: "0.8rem",
                      padding: "1px 18px",
                      backgroundColor: "#4a6d8c",
                      border: "1px solid gray", color: "white", textAlign: "center"
                    }}
                    className=" txt_ttl1"
                  >
                    From
                  </TableCell>
                  <TableCell
                    style={{
                      fontSize: "0.8rem",
                      padding: "1px",
                      backgroundColor: "#4a6d8c",
                      border: "1px solid gray", color: "white", textAlign: "center"
                    }}
                    className=" txt_ttl1"
                  >
                    To
                  </TableCell>
                  <TableCell
                    style={{
                      fontSize: "0.8rem",
                      padding: "1px",
                      backgroundColor: "#4a6d8c",
                      border: "1px solid gray", color: "white", textAlign: "center"
                    }}
                    className=" txt_ttl1"
                  >
                    Duration
                  </TableCell>
                  <TableCell
                    style={{
                      fontSize: "0.8rem",
                      padding: "1px",
                      backgroundColor: "#4a6d8c",
                      border: "1px solid gray", color: "white", textAlign: "center"
                    }}
                    className=" txt_ttl1"
                  >
                    Type
                  </TableCell>
                  <TableCell
                    style={{
                      fontSize: "0.8rem",
                      padding: "1px",
                      backgroundColor: "#4a6d8c",
                      border: "1px solid gray", color: "white", textAlign: "center"
                    }}
                    className=" txt_ttl1"
                  >
                    Status
                  </TableCell>
                  <TableCell
                    style={{
                      fontSize: "0.8rem",
                      padding: "1px",
                      backgroundColor: "#4a6d8c",
                      border: "1px solid gray", color: "white", textAlign: "center"
                    }}
                    className=" txt_ttl1"
                  >
                    Print
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data.result.map((aplicantdata, index) => (
                  <TableRow key={aplicantdata.leaveTypeId}>
                    <TableCell
                      align="center" // Center the content horizontally
                      style={{
                        fontSize: "0.8rem",
                        padding: "1px",
                        border: "1px solid gray",
                      }}
                    >
                      {index + 1}
                    </TableCell>
                    <TableCell
                      align="center" // Center the content horizontally
                      style={{
                        fontSize: "0.8rem",
                        padding: "1px",
                        border: "1px solid gray",
                      }}
                    >
                      {aplicantdata.APP_EMP_ID}
                    </TableCell>
                    <TableCell
                      align="center" // Center the content horizontally
                      style={{
                        fontSize: "0.8rem",
                        padding: "1px",
                        border: "1px solid gray",
                      }}
                    >
                      {aplicantdata.USR_DISPLAY_NAME}
                    </TableCell>
                    <TableCell
                      align="center" // Center the content horizontally
                      style={{
                        fontSize: "0.8rem",
                        padding: "1px 18px",
                        border: "1px solid gray",
                      }}
                    >
                      {formatDate(aplicantdata.APP_DT)}
                    </TableCell>
                    <TableCell
                      align="center" // Center the content horizontally
                      style={{
                        fontSize: "0.8rem",
                        padding: "1px 18px",
                        border: "1px solid gray",
                      }}
                    >
                      {formatDate(aplicantdata.APP_LVE_FRM_DT)} {/* Format leave to date */}
                    </TableCell>
                    <TableCell
                      align="center" // Center the content horizontally
                      style={{
                        fontSize: "0.8rem",
                        padding: "1px",
                        border: "1px solid gray",
                      }}
                    >
                      {formatDate(aplicantdata.APP_LVE_TO_DT)}
                    </TableCell>
                    <TableCell
                      align="center" // Center the content horizontally
                      style={{
                        fontSize: "0.8rem",
                        padding: "1px",
                        border: "1px solid gray",
                      }}
                    >
                      {aplicantdata.APP_LVE_DES} days
                    </TableCell>
                    <TableCell
                      align="center" // Center the content horizontally
                      style={{
                        fontSize: "0.8rem",
                        padding: "1px",
                        border: "1px solid gray",
                      }}
                    >
                      {aplicantdata.disp_nm}
                    </TableCell>
                    <TableCell
                      align="center" // Center the content horizontally
                      style={{
                        fontSize: "0.8rem",
                        padding: "1px",
                        border: "1px solid gray",
                      }}
                    >
                      {aplicantdata.APP_LVE_STTS}
                    </TableCell>
                    <TableCell align="center" style={{ padding: " 0px 1px", border: "1px solid gray" }}>
                      <IconButton style={{ padding: " 0px 1px"}} onClick={() => handleDownload(aplicantdata)}>
                        <DownloadIcon  style={{padding: "0px 1px", color: "#4a6d8c" }} fontSize="2px" />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
                {data.result.length < 10 &&
                  Array.from({ length: 10 - data.result.length }).map((_, index) => (
                    <TableRow key={`empty-row-manager-${index}`}>
                      <TableCell align="center" style={{ fontSize: "0.8rem", padding: "1px 18px", border: "1px solid gray" }}>&nbsp;</TableCell>
                      <TableCell align="center" style={{ fontSize: "0.8rem", padding: "1px 18px", border: "1px solid gray" }}>&nbsp;</TableCell>
                      <TableCell align="center" style={{ fontSize: "0.8rem", padding: "1px 18px", border: "1px solid gray" }}>&nbsp;</TableCell>
                      <TableCell align="center" style={{ fontSize: "0.8rem", padding: "1px 18px", border: "1px solid gray" }}>&nbsp;</TableCell>
                      <TableCell align="center" style={{ fontSize: "0.8rem", padding: "1px 18px", border: "1px solid gray" }}>&nbsp;</TableCell>
                      <TableCell align="center" style={{ fontSize: "0.8rem", padding: "1px 18px", border: "1px solid gray" }}>&nbsp;</TableCell>
                      <TableCell align="center" style={{ fontSize: "0.8rem", padding: "1px 18px", border: "1px solid gray" }}>&nbsp;</TableCell>
                      <TableCell align="center" style={{ fontSize: "0.8rem", padding: "1px 18px", border: "1px solid gray" }}>&nbsp;</TableCell>
                      <TableCell align="center" style={{ fontSize: "0.8rem", padding: "1px 18px", border: "1px solid gray" }}>&nbsp;</TableCell>
                      <TableCell align="center" style={{ fontSize: "0.8rem", padding: "1px 18px", border: "1px solid gray" }}>&nbsp;</TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </div>
    </>


  )
}
