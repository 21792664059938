import React, { useState, useEffect } from "react";
import services from "./apiServices";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  Checkbox,
  Grid
} from "@mui/material";
import EditIcon from '@mui/icons-material/Edit';
import FormLabel from '@mui/material/FormLabel';
import { FormControl, FormControlLabel, Radio, RadioGroup, Divider } from '@mui/material';
import { FormGroup, Typography } from '@mui/material';
import LveTypRegTab from "./LveTypRegTab";

function Sys_Usr_Aflt_Lve_Type_Mangment() {
  const [aflttotalcnt, setaflttotalcnt] = useState({
    aflt_cnt_res: [],
    aflt_wise_usr_cnt_res: [],
    alt_wise_lve_types_res: [],
    aflt_wise_lve_apl_cnt_res: [],
    pendng_aprved_aflt_apl_cnt_res: [],
    all_aflt_confg_dt: [],
    sys_usr_lve_db_res: []
  });
  const [selectedaffilate, setSelectedaffilate] = useState('');
  const [selectedaffilate1, setSelectedaffilate1] = useState('');
  const [data, setData] = useState({ result: [] });
  const [selectedOption, setSelectedOption] = useState('');
  const [checked, setChecked] = useState({
    check: [],
    checkName: [],
    checkedckbox: [],
    aflt_ky: []

  });
  const [gender, setgender] = useState([]);

  const [checkh, setCheckh] = useState({
    check: [],
    checkName: []
  });
  const [parentChecked, setParentChecked] = useState({}); // Track visual state of parent checkboxes
  const [checkboxError, setCheckboxError] = useState("");
  const [secondcomplete, setsecondcomplete] = useState(false);
  const [tabdata, settabdata] = useState([])
  const [tabValue, setTabValue] = useState(0);
  const [revdata, setrevdata] = useState({ tabdata: [], seledesgination: [] });
  const [thirdcomplete, setthirdcomplete] = useState(false);
  const [editleavetyp, seteditleavetyp] = useState();
  const [selectedLeaveType, setSelectedLeaveType] = useState("");
  const [leaveRecords, setLeaveRecords] = useState({});

  console.log("leaveRecords", leaveRecords)
  console.log("checked", checked)
  useEffect(() => {
    const fetch = async () => {
      try {
        const res = await services.get_total_aflt_cnt();
        if (res) {
          setaflttotalcnt(res);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetch();
  }, []);

  const handleaffilate = (event) => {
    const selectedAffilate = event.target.value;
    setSelectedaffilate(selectedAffilate);
  };
  const handleaffilate1 = (event) => {
    const selectedAffilate1 = event.target.value;
    setSelectedaffilate1(selectedAffilate1);
  };
  const cancelbutton = () => {
    setLeaveRecords([]);
    seteditleavetyp(null)
  }
  useEffect(() => {
    const fetch = async () => {
      try {
        const result = await services.getlvetypdata();
        if (result) {
          setData(result);
        } else {
          console.error('Unexpected data format:', result);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    fetch();
  }, []);

  const affiliateLeaveTypeCodes = new Set(
    aflttotalcnt.alt_wise_lve_types_res
      .filter(leave => leave.AFLT_NAME === selectedaffilate)
      .map(leave => leave.lve_typ_cd)
  );
  console.log("aflttotalcnt", aflttotalcnt)
  const handleChange1 = (event) => {
    const name = event.target.name;
    const dispName = event.target.getAttribute("data-disp-name"); // Get the disp_nm from the checkbox's data attribute

    // Check if the leave type is already in the affiliate leave types
    if (affiliateLeaveTypeCodes.has(name)) {
      alert("Already have this leave type with us.");
      return; // Prevent adding to checked state
    }

    setChecked((prev) => {
      let updatedCheck = [...prev.check];
      let updatedCheckName = [...prev.checkName];

      if (updatedCheck.includes(name)) {
        // If it's already in the checked state, remove it
        updatedCheck = updatedCheck.filter((item) => item !== name);
        updatedCheckName = updatedCheckName.filter((item) => item !== dispName);
      } else {
        // If it's not in the checked state, add it
        updatedCheck = [...updatedCheck, name];
        updatedCheckName = [...updatedCheckName, dispName];
      }

      return { ...prev, check: updatedCheck, checkName: updatedCheckName };
    });
  };
  const handleParentChange = (event) => {
    const parentCd = event.target.name; // Parent checkbox ID
    const isChecked = event.target.checked; // Whether the checkbox is checked or not

    // Get all child IDs under the current parent
    const childChecks = data.result
      .filter(item => item.ATTRIB_GRP_TYP === parentCd && item.IS_PARENT === null)
      .map(item => item.ATTRIB_CD);

    // If the parent has no child checkboxes, it should act as a single checkbox
    if (childChecks.length === 0) {
      // Check if the leave type code is already present in affiliate leave types
      if (affiliateLeaveTypeCodes.has(parentCd)) {
        alert("This leave type is already in our affiliate leave types.");
        return; // Prevent adding this parent leave type to the checked state
      }
      // If not in affiliate leave types, proceed to add/remove it
      setChecked(prev => {
        let val = prev.check.includes(parentCd)
          ? prev.check.filter(item => item !== parentCd)
          : [...prev.check, parentCd];
        return { ...prev, check: val };
      });
    } else {
      // If the parent has child checkboxes, proceed with usual parent-child behavior
      if (isChecked) {
        // If there are children, add them to checked state
        const newChecked = childChecks.filter(child => !affiliateLeaveTypeCodes.has(child));
        setChecked(prev => ({
          ...prev,
          check: [...new Set([...prev.check, ...newChecked])] // Add new checked values
        }));
      } else {
        // If the parent is unchecked, remove its children from checked state
        setChecked(prev => ({
          ...prev,
          check: prev.check.filter(name => !childChecks.includes(name))
        }));
      }
    }

    // Update the visual state of the parent checkbox
    setParentChecked(prev => ({
      ...prev,
      [parentCd]: isChecked // This will visually check/uncheck the parent checkbox
    }));
  };
  const validateCheckboxSelection = () => {
    // Check if any checkbox is selected
    const isAnyChecked = checked.check.length > 0;

    if (!isAnyChecked) {
      setCheckboxError("Please select at least one checkbox.");
    } else {
      setCheckboxError("");
    }

    return isAnyChecked;
  };
  const sub_second_tab = () => {
    if (validateCheckboxSelection()) {
      // Step 1: Map selected leave type codes to their display names
      const updatedCheckNames = checked.check.map(code => {
        const leave = data.result.find(item => item.ATTRIB_CD === code);
        return leave ? leave.ATTRIB_NAME : ''; // Use the display name from the result
      });

      // Step 3: Extract aflt_ky from aflttotalcnt based on selectedaffilate
      const selectedAffiliate = aflttotalcnt.alt_wise_lve_types_res.find(
        (item) => item.AFLT_NAME === selectedaffilate
      );
      const aflt_ky = selectedAffiliate ? selectedAffiliate.AFLT_KY : ''; // Get aflt_ky

      if (!aflt_ky) {
        alert("Client key not found.");
        return; // Stop if no valid aflt_ky is found
      }

      console.log("Selected affiliate key (aflt_ky):", aflt_ky);
      // Step 2: Update the checked state with the display names
      setChecked(prev => ({
        ...prev,
        checkName: updatedCheckNames,
        aflt_ky: aflt_ky,
      }));

      // Step 4: Send the request to the backend including aflt_ky
      const insrt_tab2 = async () => {
        try {
          const insrt_res2 = await services.add_lve_confg({
            check: checked.check, // Leave type codes
            checkName: updatedCheckNames, // Display names
            aflt_ky: aflt_ky, // Send the affiliate key (aflt_ky)
          });

          // Check if the response is successful
          if (insrt_res2) {
            setsecondcomplete(true);
            console.log("Leave types Added successfully");
            alert("Leave types Added successfully");
            // setSelectedaffilate([]);
            // setChecked([])
            nextStep()
          } else {
            console.error("Leave types insertion failed");
          }
        } catch (error) {
          console.error("Leave types insertion failed", error);
        }
      };

      // Call the function to submit the data
      insrt_tab2();
    } else {
      alert("Please select at least one leave type.");
    }
  };
  const handleTabChange = (newValue) => {
    setTabValue(newValue);
  };
  const nextStep = () => {
    if (tabValue < 2) {
      setTabValue(tabValue + 1);
    }
  };
  const setrecivedData = (data) => {
    setrevdata(data);
  };
  const validatethirdtab = () => {
    // Initialize the error flag
    let errortab = false;

    for (let i = 0; i < revdata.tabdata.length; i++) {
      if (revdata.tabdata[i].ques_code !== "LVE_TYP_ALOW_POST") {
        for (let j = 0; j < revdata.tabdata[i].lve_types.length; j++) {
          if (
            revdata.tabdata[i].ques_code == "" ||
            revdata.tabdata[i].ans[j] == ""
          ) {
            errortab = true;
            break;
          }
        }
      } else {
        for (let j = 0; j < revdata.seledesgination.length; j++) {
          console.log(revdata.seledesgination[j].length);

          if (revdata.seledesgination[j].length < 1) {
            errortab = true;
            break;
          }
        }
      }

      if (errortab) break; // Exit outer loop if an error is found
    }

    return errortab;
  };
  const sub_third_tab = async () => {
    if (!validatethirdtab()) {
      // Validation succeeded (no errors)
      try {
        const insrt_res3 = await services.insert_reg_tab3(revdata);
        console.log("Fetched data:", insrt_res3);

        if (insrt_res3) {
          setthirdcomplete(true);
          console.log("Leave types Configration Parameters Added successfully");
          alert("Leave Types Configration Parameters Added Successfully");
          setTabValue(0)
          // nextStep();
        } else {
          console.error("Leave types ques insertion failed");
        }
      } catch (error) {
        console.error("Error occurred during insertion:", error);
      }
    } else {
      alert("Please fill all fields");
    }
  };
  const handleEditHoliday = (leavetypinfo) => {
    console.log("Editing holiday:", leavetypinfo);
    seteditleavetyp(leavetypinfo);

    // Extract the leave type code from the leavetypinfo
    const selectedTypeCode = leavetypinfo.disp_nm;
    const selectedCode = leavetypinfo.lve_typ_cd;
    const affiliate_nm = leavetypinfo.AFLT_NAME;
    // console.log("selectedTypeCode", selectedTypeCode)
    // console.log("selectedCode", selectedCode)
    // console.log("affiliate_nm", affiliate_nm)
    // Filter the records based on the selected leave type code
    const filteredRecords = aflttotalcnt.all_aflt_confg_dt.filter(
      (item) => item.disp_nm === selectedTypeCode
    );
    console.log("filteredRecords", filteredRecords)
    // Check if any records were found
    if (filteredRecords.length > 0) {
      // Create an initial leave records object
      const initialLeaveRecords = { aflt_ky: leavetypinfo.aflt_ky, leaveType: selectedTypeCode, affiliatenm: affiliate_nm };

      // Populate the initialLeaveRecords with the filtered data
      filteredRecords.forEach((record) => {
        initialLeaveRecords[record.AFLT_CONF_PARA] = record.AFLT_CONF_PARA_VAL;
      });

      // Set the leave records state
      setLeaveRecords(initialLeaveRecords);
      console.log("Filtered Leave Records:", initialLeaveRecords);
    } else {
      console.warn("No records found for the selected leave type.");
      setLeaveRecords({}); // Reset or handle the case where no records are found
    }
  };

  const handleInputChange = (parameter, value) => {
    setLeaveRecords((prev) => ({
      ...prev,
      [parameter]: value,
    }));
  };
  const updt_lve_cong = async (e) => {
    e.preventDefault();
    try {
      const res = await services.updt_obo_lve_confg(leaveRecords);
      if (res) {
        alert("Succesfully Updated the Leave Configration Details...")
        // leaveRecords({})

      } else {
        alert("Failed to Updated the Leave Configration Details...")
        console.error("Unexpected data format:", res);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  console.log("editleavetyp", editleavetyp)
  return (
    <div className="wrapper_form">
      <div className="title" style={{ marginBottom: "1.4%" }}>Client Holiday Management</div>
      <div className="radio-row" style={{ marginBottom: "5px" }}>
        <label>
          <input
            type="radio"
            name="addOption"
            value="addManually"
            checked={selectedOption === "addManually"}
            onChange={(e) => setSelectedOption(e.target.value)}
            style={{ width: "2%" }}
          />
          Add Leave Types
        </label>
        <hr className="hr1" />

        {selectedOption === "addManually" && (
          <>
            <form className="frm_wd">
              <div style={{ margin: "1% 1% 1% 0% " }}>
                <label htmlFor="affilate">Select Client : </label>
                <select
                  id="affilate"
                  value={selectedaffilate}
                  onChange={handleaffilate}
                  style={{ borderRadius: "8px", width: "30%" }}
                >
                  <option value="">Select Client</option>
                  {Array.from(new Set(aflttotalcnt.alt_wise_lve_types_res.map(item => item.AFLT_NAME)))
                    .map((affilate) => (
                      <option key={affilate} value={affilate}>
                        {affilate}
                      </option>
                    ))}
                </select>
              </div>

              {selectedaffilate && (
                <>
                  <hr className="hr_dt" />
                  <Grid item xs={12} style={{ paddingLeft: "2%", paddingRight: "1%", width: "100%" }}>
                    <div className="title" style={{ marginTop: "1%", height: "10%" }}>Selected Client Holidays</div>
                    <TableContainer component={Paper} style={{ margin: "5px auto 25px auto", boxShadow: "0px 6px 16px 3px #bababa", borderRadius: "10px" }}>
                      <Table style={{ width: "100%" }}>
                        <TableHead>
                          <TableRow style={{ lineHeight: "0.3" }}>
                            <TableCell style={{ fontSize: "0.8rem", padding: "1px 18px", backgroundColor: "#4a6d8c", border: "1px solid gray", color: "white", textAlign: "center" }} className="txt_ttl">
                              Sr. No.
                            </TableCell>
                            <TableCell style={{ fontSize: "0.8rem", padding: "1px 18px", backgroundColor: "#4a6d8c", border: "1px solid gray", color: "white", textAlign: "center" }} className="txt_ttl">
                              Leave Type
                            </TableCell>
                            <TableCell style={{ fontSize: "0.8rem", padding: "1px 18px", backgroundColor: "#4a6d8c", border: "1px solid gray", color: "white", textAlign: "center" }} className="txt_ttl">
                              Parent Leave
                            </TableCell>
                            <TableCell style={{ fontSize: "0.8rem", padding: "1px 18px", backgroundColor: "#4a6d8c", border: "1px solid gray", color: "white", textAlign: "center" }} className="txt_ttl">
                              Child Leave
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {aflttotalcnt.alt_wise_lve_types_res
                            .filter(leave => leave.AFLT_NAME === selectedaffilate)
                            .map((leave, index) => (
                              <TableRow style={{ height: "10px" }} key={leave.leaveTypeId}>
                                <TableCell align="center" style={{ fontSize: "0.8rem", padding: "1px 18px", border: "1px solid gray" }}>
                                  {index + 1}
                                </TableCell>
                                <TableCell align="center" style={{ fontSize: "0.8rem", padding: "1px 18px", border: "1px solid gray" }}>
                                  {/* {leave.main_typ} */}
                                  {leave.main_typ === 'LVE_TYP' ? leave.ATTRIB_GRP_TYP : leave.main_typ}
                                </TableCell>
                                <TableCell align="center" style={{ fontSize: "0.8rem", padding: "1px 18px", border: "1px solid gray" }}>
                                  {leave.ATTRIB_GRP_TYP}
                                </TableCell>
                                <TableCell align="center" style={{ fontSize: "0.8rem", padding: "1px 18px", border: "1px solid gray" }}>
                                  {leave.disp_nm}
                                </TableCell>
                              </TableRow>
                            ))}
                          {aflttotalcnt.alt_wise_lve_types_res.length < 10 && Array.from({ length: 10 - aflttotalcnt.alt_wise_lve_types_res.length }).map((_, index) => (
                            <TableRow style={{ height: "10px" }} key={`empty-row-${index}`}>
                              <TableCell align="center" style={{ fontSize: "0.8rem", padding: "1px 18px", border: "1px solid gray" }}>
                                &nbsp;
                              </TableCell>
                              <TableCell align="center" style={{ fontSize: "0.8rem", padding: "1px 18px", border: "1px solid gray" }}>
                                &nbsp;
                              </TableCell>
                              <TableCell align="center" style={{ fontSize: "0.8rem", padding: "1px 18px", border: "1px solid gray" }}>
                                &nbsp;
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Grid>
                  <hr className="hr_dt" />
                </>

              )}

              {selectedaffilate ? (
                <div className="tab-content">
                  <div className="form-container">
                    <div className="tabs" style={{ marginBottom: "1%" }}>

                      <button
                        type="button"
                        // disabled={!firstcomplete}
                        className={`tab-button ${tabValue === 0 ? "active" : ""}`}
                        onClick={() => handleTabChange(0)}
                      >
                        Leave Types
                      </button>
                      <button
                        type="button"
                        // disabled={!secondcomplete}

                        className={`tab-button ${tabValue === 1 ? "active" : ""}`}
                        onClick={() => handleTabChange(1)}
                      >
                        Configuration Setup 1
                      </button>
                    </div>
                    <hr className="hr_dt" />
                    {tabValue === 0 && (
                      <>
                        {data.result.map((item) => {
                          return item.ATTRIB_CD === "paid" ? (
                            <div key={item.ATTRIB_CD} className="row" style={{ marginBottom: "15px" }}>
                              <div>
                                <label style={{ fontSize: "30px" }}>
                                  <b>{item.ATTRIB_NAME}</b>
                                </label>
                              </div>
                            </div>
                          ) : item.ATTRIB_GRP_TYP === "paid" ? (
                            <div key={item.ATTRIB_CD} className="row group-container">
                              <div className="label-container">
                                <label>
                                  <Checkbox
                                    name={item.ATTRIB_CD}
                                    checked={parentChecked[item.ATTRIB_CD] || false} // Use visual state
                                    onChange={handleParentChange}
                                    inputProps={{ "aria-label": "controlled" }}
                                  />
                                  <b>{item.ATTRIB_NAME}</b>
                                </label>
                              </div>

                              <div className="checkbox-container">
                                {data.result
                                  .filter((item1) => item1.ATTRIB_GRP_TYP === item.ATTRIB_CD)
                                  .map((item1) => (
                                    <div key={item1.ATTRIB_CD} className="checkbox-item">
                                      <Checkbox
                                        checked={checked.check.includes(item1.ATTRIB_CD)}
                                        name={item1.ATTRIB_CD}
                                        onChange={handleChange1}
                                        inputProps={{ "aria-label": "controlled" }}
                                      />
                                      {item1.ATTRIB_NAME}
                                    </div>
                                  ))}
                              </div>
                            </div>
                          ) : null;
                        })
                        }
                        {data.result.map((item) => {
                          return item.ATTRIB_CD === "unpaid" ? (
                            <div key={item.ATTRIB_CD} className="row" style={{ marginBottom: "15px" }}>
                              <div>
                                <label style={{ fontSize: "30px" }}>
                                  <b>{item.ATTRIB_NAME}</b>
                                </label>
                              </div>
                            </div>
                          ) : item.ATTRIB_GRP_TYP === "unpaid" ? (
                            <div key={item.ATTRIB_CD} className="row group-container">
                              <div className="label-container">
                                <label>
                                  <Checkbox
                                    name={item.ATTRIB_CD}
                                    checked={parentChecked[item.ATTRIB_CD] || false} // Use visual state
                                    onChange={handleParentChange}
                                    inputProps={{ "aria-label": "controlled" }}
                                  />
                                  <b>{item.ATTRIB_NAME}</b>
                                </label>
                              </div>

                              <div className="checkbox-container">
                                {data.result
                                  .filter((item1) => item1.ATTRIB_GRP_TYP === item.ATTRIB_CD)
                                  .map((item1) => (
                                    <div key={item1.ATTRIB_CD} className="checkbox-item">
                                      <Checkbox
                                        checked={checked.check.includes(item1.ATTRIB_CD)}
                                        name={item1.ATTRIB_CD}
                                        onChange={handleChange1}
                                        inputProps={{ "aria-label": "controlled" }}
                                      />
                                      {item1.ATTRIB_NAME}
                                    </div>
                                  ))}
                              </div>
                            </div>
                          ) : null;
                        })}
                        <div className="row" style={{ marginBottom: "15px" }}><hr className="hr_dt" />
                          <div className="column"></div>
                          <div className="column"></div>
                          <div className="column"></div>
                          <div className="column"></div>
                          <div className="column">
                            <div className="field">
                              <button
                                type="button"
                                className="btn-action action-button"
                                style={{ margin: "3% 0 4% 0" }}
                                onClick={sub_second_tab}
                              >
                                Submit & Next
                              </button>
                            </div>
                          </div>
                        </div>
                        <hr className="hr_dt" />
                      </>
                    )}
                  </div>
                </div>

              ) : ""}

              {tabValue === 1 && (
                <>
                  <LveTypRegTab
                    holidays={checked}
                    gender={gender}
                    gtabdata={setrecivedData}
                  /><hr className="hr_dt" />
                  <div className="row" >
                    <div className="column">
                      <button
                        type="button"
                        className="btn-action action-button"
                        onClick={() => handleTabChange(0)}
                      >
                        Previous
                      </button>
                    </div>
                    <div className="column"></div>
                    <div className="column"></div>
                    <div className="column"></div>
                    <div className="column">
                      <div className="field">
                        <button
                          type="button"
                          className="btn-action action-button"
                          onClick={sub_third_tab}
                        >
                          Submit & Next
                        </button>
                      </div>
                    </div>
                  </div><hr className="hr_dt" />
                </>
              )}
            </form>
          </>
        )}
      </div>

      <div className="radio-row" style={{ marginBottom: "5px" }}>
        <label>
          <input
            type="radio"
            name="addOption"
            value="editHoliday"
            checked={selectedOption === "editHoliday"}
            onChange={(e) => setSelectedOption(e.target.value)}
            style={{ width: "2%" }}
          />
          Edit Leave Types
        </label>
        <hr className="hr1" />
        {selectedOption === "editHoliday" && (
          <form className="frm_wd">

            {selectedaffilate1 ? (
              <>
                {editleavetyp ? (

                  <div className="form-container">
                    <form>
                      <hr className="hr_dt" style={{ marginBottom: ".5%" }} />
                      <div className="form-container">
                        <div className="row">
                          {/* Maximum Leave Count */}
                          <div className="column drp_ln_hg">
                            <label>Client Name:</label>
                            <div className="field">
                              <input
                                type="text"
                                name="affiliatenm"
                                value={leaveRecords["affiliatenm"] || ""}
                                // onChange={(e) => handleInputChange("LVE_TYP_MAX_CNT", e.target.value)}
                                disabled
                              />
                            </div>
                          </div>

                          {/* Leave Type Increment Days */}
                          <div className="column drp_ln_hg">
                            <label>Leave Type</label>
                            <div className="field">
                              <input
                                type="text"
                                name="leaveType"
                                value={leaveRecords["leaveType"] || ""}
                                // onChange={(e) => handleInputChange("LVE_TYP_INCRMT_DES", e.target.value)}
                                disabled
                              />
                            </div>
                          </div>
                          <div className="column drp_ln_hg">
                            {/* <label>Leave Type</label> */}
                            <div className="field">
                              {/* <input
          type="text"
          name="leaveType"
          value={leaveRecords["leaveType"] || ""}
          // onChange={(e) => handleInputChange("LVE_TYP_INCRMT_DES", e.target.value)}
          disabled
        /> */}
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          {/* Display Leave Balance on Dashboard */}
                          <div className="column" style={{ lineHeight: "12px", marginTop: "5px" }}>
                            <label>Display Leave Balance on Dashboard:</label>
                            <RadioGroup
                              row
                              name="LVE_BAL_IN_DBORD"
                              value={leaveRecords["LVE_BAL_IN_DBORD"] || ""}
                              onChange={(e) => handleInputChange("LVE_BAL_IN_DBORD", e.target.value)}
                            >
                              <FormControlLabel value="yes" control={<Radio />} label="Yes" />
                              <FormControlLabel value="no" control={<Radio />} label="No" />
                            </RadioGroup>
                          </div>

                          {/* Allow Gender */}
                          <div className="column" style={{ lineHeight: "12px", marginTop: "5px" }}>
                            <label>Allow Gender:</label>
                            <RadioGroup
                              row
                              name="LVE_TYP_ALOW_GNDR"
                              value={leaveRecords["LVE_TYP_ALOW_GNDR"] || ""}
                              onChange={(e) => handleInputChange("LVE_TYP_ALOW_GNDR", e.target.value)}
                            >
                              <FormControlLabel value="yes" control={<Radio />} label="Yes" />
                              <FormControlLabel value="no" control={<Radio />} label="No" />
                            </RadioGroup>
                          </div>

                          {/* Allowed for Consecutive Combined Leave Type */}
                          <div className="column" style={{ lineHeight: "12px", marginTop: "5px" }}>
                            <label>Allowed for Consecutive Combined Leave Type?</label>
                            <RadioGroup
                              row
                              name="LVE_TYP_LST_CONSQTV"
                              value={leaveRecords["LVE_TYP_LST_CONSQTV"] || ""}
                              onChange={(e) => handleInputChange("LVE_TYP_LST_CONSQTV", e.target.value)}
                            >
                              <FormControlLabel value="yes" control={<Radio />} label="Yes" />
                              <FormControlLabel value="no" control={<Radio />} label="No" />
                            </RadioGroup>
                          </div>
                        </div>

                        <div className="row">
                          {/* Carry Forward Allowed */}
                          <div className="column" style={{ lineHeight: "12px", marginTop: "5px" }}>
                            <label>Carry Forward Allowed?</label>
                            <RadioGroup
                              row
                              name="LVE_CREFW_YN"
                              value={leaveRecords["LVE_CREFW_YN"] || ""}
                              onChange={(e) => handleInputChange("LVE_CREFW_YN", e.target.value)}
                            >
                              <FormControlLabel value="yes" control={<Radio />} label="Yes" />
                              <FormControlLabel value="no" control={<Radio />} label="No" />
                            </RadioGroup>
                          </div>

                          {/* Encashment allowed */}
                          <div className="column" style={{ lineHeight: "12px", marginTop: "5px" }}>
                            <label>Encashment allowed?</label>
                            <RadioGroup
                              row
                              name="LVE_NCSH_YN"
                              value={leaveRecords["LVE_NCSH_YN"] || ""}
                              onChange={(e) => handleInputChange("LVE_NCSH_YN", e.target.value)}
                            >
                              <FormControlLabel value="yes" control={<Radio />} label="Yes" />
                              <FormControlLabel value="no" control={<Radio />} label="No" />
                            </RadioGroup>
                          </div>

                          {/* Consecutive working days allowed */}
                          <div className="column" style={{ lineHeight: "12px", marginTop: "5px" }}>
                            <label>Consecutive working days allowed?</label>
                            <RadioGroup
                              row
                              name="LVE_WRKDE_ADD_LVETYP"
                              value={leaveRecords["LVE_WRKDE_ADD_LVETYP"] || ""}
                              onChange={(e) => handleInputChange("LVE_WRKDE_ADD_LVETYP", e.target.value)}
                            >
                              <FormControlLabel value="yes" control={<Radio />} label="Yes" />
                              <FormControlLabel value="no" control={<Radio />} label="No" />
                            </RadioGroup>
                          </div>
                        </div>

                        <div className="row">
                          {/* Include weekly off days */}
                          <div className="column" style={{ lineHeight: "12px", marginTop: "5px" }}>
                            <label>Include weekly off days in leave calculation days?</label>
                            <RadioGroup
                              row
                              name="LVE_TYP_CAN_ADD_WIKENDES"
                              value={leaveRecords["LVE_TYP_CAN_ADD_WIKENDES"] || ""}
                              onChange={(e) => handleInputChange("LVE_TYP_CAN_ADD_WIKENDES", e.target.value)}
                            >
                              <FormControlLabel value="yes" control={<Radio />} label="Yes" />
                              <FormControlLabel value="no" control={<Radio />} label="No" />
                            </RadioGroup>
                          </div>

                          {/* Consecutive half day allowed */}
                          <div className="column" style={{ lineHeight: "12px", marginTop: "5px" }}>
                            <label>Consecutive half day allowed with any type of leave?</label>
                            <RadioGroup
                              row
                              name="LVE_CONSQT_HD_YN"
                              value={leaveRecords["LVE_CONSQT_HD_YN"] || ""}
                              onChange={(e) => handleInputChange("LVE_CONSQT_HD_YN", e.target.value)}
                            >
                              <FormControlLabel value="yes" control={<Radio />} label="Yes" />
                              <FormControlLabel value="no" control={<Radio />} label="No" />
                            </RadioGroup>
                          </div>

                          {/* Maximum Carry Forward */}
                          <div className="column drp_ln_hg">
                            <label>Maximum Carry Forward:</label>
                            <div className="field">
                              <input
                                type="number"
                                name="LVE_TYP_MAX_CREFW"
                                value={leaveRecords["LVE_TYP_MAX_CREFW"] || ""}
                                onChange={(e) => handleInputChange("LVE_TYP_MAX_CREFW", e.target.value)}
                              />
                            </div>
                          </div>
                        </div>

                        <div className="row">
                          {/* Maximum Leave Count */}
                          <div className="column drp_ln_hg">
                            <label>Maximum Leave Count:</label>
                            <div className="field">
                              <input
                                type="number"
                                name="LVE_TYP_MAX_CNT"
                                value={leaveRecords["LVE_TYP_MAX_CNT"] || ""}
                                onChange={(e) => handleInputChange("LVE_TYP_MAX_CNT", e.target.value)}
                              />
                            </div>
                          </div>

                          {/* Leave Type Increment Days */}
                          <div className="column drp_ln_hg">
                            <label>Leave Type Increment Days Count:</label>
                            <div className="field">
                              <input
                                type="number"
                                name="LVE_TYP_INCRMT_DES"
                                value={leaveRecords["LVE_TYP_INCRMT_DES"] || ""}
                                onChange={(e) => handleInputChange("LVE_TYP_INCRMT_DES", e.target.value)}
                              />
                            </div>
                          </div>

                          {/* Maximum Encashment Count */}
                          <div className="column drp_ln_hg">
                            <label>Maximum Encashment Count:</label>
                            <div className="field">
                              <input
                                type="number"
                                name="LVE_TYP_MAX_NCSH_CNT"
                                value={leaveRecords["LVE_TYP_MAX_NCSH_CNT"] || ""}
                                onChange={(e) => handleInputChange("LVE_TYP_MAX_NCSH_CNT", e.target.value)}
                              />
                            </div>
                          </div>
                        </div>

                        <hr className="hr_dt" />
                        <div className="row" style={{ marginBottom: "1px" }}>
                          <div className="column">
                            <button
                              type="button"
                              className="btn-action action-button"
                              onClick={cancelbutton}
                              style={{ margin: "1px" }}
                            >
                              Cancel
                            </button>
                          </div>
                          <div className="column"></div>
                          <div className="column"></div>
                          <div className="column"></div>
                          <div className="column">
                            <div className="field">
                              <button style={{ margin: "1px", width: "80%" }} type="submit" className="btn-action action-button" onClick={updt_lve_cong} >
                                Save
                              </button>
                            </div>
                          </div>
                        </div> 
                        <hr className="hr_dt" />
                      </div>
                    </form>
                  </div>
                ) : (
                  <>
                    <div style={{ margin: "1% 1% 1% 0% " }}>
                      <label htmlFor="affilate">Select Client : </label>
                      <select
                        id="affilate"
                        value={selectedaffilate1}
                        onChange={handleaffilate1}
                        style={{ borderRadius: "8px", width: "30%" }}
                      >
                        <option value="">Select Client</option>
                        {Array.from(new Set(aflttotalcnt.alt_wise_lve_types_res.map(item => item.AFLT_NAME)))
                          .map((affilate) => (
                            <option key={affilate} value={affilate}>
                              {affilate}
                            </option>
                          ))}
                      </select>
                    </div>
                    <hr className="hr_dt" />
                    <Grid item xs={12} style={{ paddingLeft: "2%", paddingRight: "1%", width: "100%" }}>
                      <div className="title" style={{ marginTop: "1%", height: "10%" }}>Selected Client Holidays</div>
                      <TableContainer component={Paper} style={{ margin: "5px auto 25px auto", boxShadow: "0px 6px 16px 3px #bababa", borderRadius: "10px" }}>
                        <Table style={{ width: "100%" }}>
                          <TableHead>
                            <TableRow style={{ lineHeight: "0.3" }}>
                              <TableCell style={{ fontSize: "0.8rem", padding: "1px 18px", backgroundColor: "#4a6d8c", border: "1px solid gray", color: "white", textAlign: "center" }} className="txt_ttl">
                                Sr. No.
                              </TableCell>
                              <TableCell style={{ fontSize: "0.8rem", padding: "1px 18px", backgroundColor: "#4a6d8c", border: "1px solid gray", color: "white", textAlign: "center" }} className="txt_ttl">
                                Leave Type
                              </TableCell>
                              <TableCell style={{ fontSize: "0.8rem", padding: "1px 18px", backgroundColor: "#4a6d8c", border: "1px solid gray", color: "white", textAlign: "center" }} className="txt_ttl">
                                Parent Leave
                              </TableCell>
                              <TableCell style={{ fontSize: "0.8rem", padding: "1px 18px", backgroundColor: "#4a6d8c", border: "1px solid gray", color: "white", textAlign: "center" }} className="txt_ttl">
                                Child Leave
                              </TableCell>
                              <TableCell style={{ fontSize: "0.8rem", padding: "1px 18px", backgroundColor: "#4a6d8c", border: "1px solid gray", color: "white", textAlign: "center" }} className="txt_ttl">
                                Action
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {aflttotalcnt.alt_wise_lve_types_res
                              .filter(leave => leave.AFLT_NAME === selectedaffilate1)
                              .map((leave, index) => (
                                <TableRow style={{ height: "10px" }} key={leave.leaveTypeId}>
                                  <TableCell align="center" style={{ fontSize: "0.8rem", padding: "1px 18px", border: "1px solid gray" }}>
                                    {index + 1}
                                  </TableCell>
                                  <TableCell align="center" style={{ fontSize: "0.8rem", padding: "1px 18px", border: "1px solid gray" }}>
                                    {/* {leave.main_typ} */}
                                    {leave.main_typ === 'LVE_TYP' ? leave.ATTRIB_GRP_TYP : leave.main_typ}
                                  </TableCell>
                                  <TableCell align="center" style={{ fontSize: "0.8rem", padding: "1px 18px", border: "1px solid gray" }}>
                                    {leave.ATTRIB_GRP_TYP}
                                  </TableCell>
                                  <TableCell align="center" style={{ fontSize: "0.8rem", padding: "1px 18px", border: "1px solid gray" }}>
                                    {leave.disp_nm}
                                  </TableCell>
                                  <TableCell align="center" style={{ fontSize: "0.8rem", padding: "1px", border: "1px solid gray" }}>
                                    <EditIcon style={{ color: "#4a6d8c", cursor: "pointer" }} fontSize="small" onClick={() => handleEditHoliday(leave)} />
                                  </TableCell>
                                </TableRow>
                              ))}
                            {aflttotalcnt.alt_wise_lve_types_res.length < 10 && Array.from({ length: 10 - aflttotalcnt.alt_wise_lve_types_res.length }).map((_, index) => (
                              <TableRow style={{ height: "10px" }} key={`empty-row-${index}`}>
                                <TableCell align="center" style={{ fontSize: "0.8rem", padding: "1px 18px", border: "1px solid gray" }}>
                                  &nbsp;
                                </TableCell>
                                <TableCell align="center" style={{ fontSize: "0.8rem", padding: "1px 18px", border: "1px solid gray" }}>
                                  &nbsp;
                                </TableCell>
                                <TableCell align="center" style={{ fontSize: "0.8rem", padding: "1px 18px", border: "1px solid gray" }}>
                                  &nbsp;
                                </TableCell>
                                <TableCell align="center" style={{ fontSize: "0.8rem", padding: "1px 18px", border: "1px solid gray" }}>
                                  &nbsp;
                                </TableCell>
                                <TableCell align="center" style={{ fontSize: "0.8rem", padding: "1px 18px", border: "1px solid gray" }}>
                                  &nbsp;
                                </TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Grid>
                    <hr className="hr_dt" />

                  </>

                )}
              </>
            ) : (
              <div style={{ margin: "1% 1% 1% 0% " }}>
                <label htmlFor="affilate">Select Client : </label>
                <select
                  id="affilate"
                  value={selectedaffilate1}
                  onChange={handleaffilate1}
                  style={{ borderRadius: "8px", width: "30%" }}
                >
                  <option value="">Select Client</option>
                  {Array.from(new Set(aflttotalcnt.alt_wise_lve_types_res.map(item => item.AFLT_NAME)))
                    .map((affilate) => (
                      <option key={affilate} value={affilate}>
                        {affilate}
                      </option>
                    ))}
                </select>

              </div>)}
          </form>
        )}
      </div>
    </div>
  );
}

export default Sys_Usr_Aflt_Lve_Type_Mangment;